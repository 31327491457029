import * as React from "react";
import {
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Button,
  DialogActions,
} from "@mui/material";
import { getAvailablePurchaseOrderStatus } from "../../presentation/helpers";

const OrderStatusModal = ({
  open,
  handleClose,
  order,
  handleFormChange,
  handleSubmit,
}) => {
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Update Status{" "}
            </Typography>
          </DialogContentText>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Status</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              label="Status"
              value={order?.order?.status}
              name="status"
              onChange={handleFormChange}
            >
              {getAvailablePurchaseOrderStatus(order?.prevStatus)?.map(
                (status, index) => (
                  <MenuItem
                    disabled={status === order?.prevStatus}
                    key={index}
                    value={status}
                  >
                    {status}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderStatusModal;
