import {
  Box,
  Paper,
  Checkbox,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
} from "@mui/material";
import {
  refundPolicies,
  retryPolicies,
} from "../components/common/dropdownsData";

const ProductConfiguration = ({ productConfig, setProductConfig, type }) => {
  const handleProductConfigChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (
      [
        "inputWeightRequired",
        "inputVolumeRequired",
        "inputMeasurementRequired",
        "inputDeliveryContactRequired",
        "inputDeliveryAddressRequired",
        "enabled",
      ].includes(name)
    ) {
      setProductConfig((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: checked,
      }));
    } else {
      setProductConfig((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: value,
      }));
    }
  };

  return (
    <Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",

          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">
                Refund Policy
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Refund policy"
                required
                value={productConfig?.refundPolicy}
                name="refundPolicy"
                onChange={handleProductConfigChange}
              >
                {refundPolicies.map((policy) => (
                  <MenuItem value={policy.value}>{policy.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">Retry Policy</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Retry policy"
                required
                value={productConfig?.retryPolicy}
                name="retryPolicy"
                onChange={handleProductConfigChange}
              >
                {retryPolicies.map((policy) => (
                  <MenuItem value={policy}>{policy}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                name="inputWeightRequired"
                checked={productConfig?.inputWeightRequired}
                onChange={handleProductConfigChange}
                control={<Checkbox />}
                label="Does input require weight?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                name="inputVolumeRequired"
                checked={productConfig?.inputVolumeRequired}
                onChange={handleProductConfigChange}
                control={<Checkbox />}
                label="Does input require volume?"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                name="inputMeasurementRequired"
                checked={productConfig?.inputMeasurementRequired}
                onChange={handleProductConfigChange}
                control={<Checkbox />}
                label="Does input require dimensions?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <span>
                <FormControlLabel
                  name="inputDeliveryContactRequired"
                  checked={productConfig?.inputDeliveryContactRequired}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Does it require delivery contact?"
                />
              </span>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                name="inputDeliveryAddressRequired"
                checked={productConfig?.inputDeliveryAddressRequired}
                onChange={handleProductConfigChange}
                control={<Checkbox />}
                label="Does it require delivery address?"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                name="enabled"
                checked={productConfig?.enabled}
                onChange={handleProductConfigChange}
                control={<Checkbox />}
                label="Do want to enable this service?"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProductConfiguration;
