import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { productConfigUrl } from "../presentation/shared/ApiUrls";

export const productConfigurationsApi = createApi({
  reducerPath: "productConfigurationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: productConfigUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["ProductionConfigurations"],
  endpoints: (builder) => ({
    getAllProductConfigurations: builder.query({
      query: () => ({
        url: `/product-configurations`,
        method: "GET",
      }),
      providesTags: ["ProductConfigurations"],
    }),
    searchProductConfigurations: builder.mutation({
      query: (filterProductConfigurationRequest) => ({
        url: `/product-configurations?${filterProductConfigurationRequest}`,
        method: "GET",
      }),
      providesTags: ["ProductConfigurations"],
    }),
    getProductConfiguration: builder.query({
      query: (id) => ({
        url: `/product-configurations/${id}`,
        method: "GET",
      }),
      providesTags: ["ProductConfigurations"],
    }),
    updateProductConfiguration: builder.mutation({
      query: (updateProductConfigurationRequest) => ({
        url: "/product-configurations",
        method: "PUT",
        body: updateProductConfigurationRequest,
      }),
      invalidatesTags: ["ProductConfigurations"],
    }),
    createProductConfiguration: builder.mutation({
      query: (createProductConfigurationRequest) => ({
        url: "/product-configurations",
        method: "POST",
        body: createProductConfigurationRequest,
      }),
      invalidatesTags: ["ProductConfigurations"],
    }),
    deleteProductConfiguration: builder.mutation({
      query: (id) => ({
        url: `/product-configurations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductConfigurations"],
    }),
  }),
});

export const {
  useGetAllProductConfigurationsQuery,
  useSearchProductConfigurationsMutation,
  useGetProductConfigurationQuery,
  useCreateProductConfigurationMutation,
  useUpdateProductConfigurationMutation,
  useDeleteProductConfigurationMutation,
} = productConfigurationsApi;
