import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FaTruck } from "react-icons/fa";
import { FiCalendar, FiMap, FiUser } from "react-icons/fi";
import { useSearchOrdersQuery } from "../services/OrderService";
import LoadingPage from "../components/LoadingPage";
import { FormAnswersTable } from "../data/FormAnswersTable";
import moment from "moment";
import FormAnswerModal from "../components/modals/FormAnswerModal";
import { DataGridService } from "../core/dataGridService";

const SingleOrderItem = () => {
  const dataGridService = new DataGridService(
    "SingleOrderItem/FormQuestion-View-More"
  );
  const [filter, setFilter] = useState({});
  const { data, isLoading } = useSearchOrdersQuery(filter);
  const [open, setOpen] = useState(false);
  const [selectedFormAnswer, setSelectedFormAnswer] = useState(null);
  const order = JSON.parse(localStorage.getItem("view-more"));
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handleClose = () => {
    setOpen(false);
    setSelectedFormAnswer(null);
  };
  const handleViewAction = (row) => {
    setSelectedFormAnswer(row);
    setOpen(true);
  };
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const columns = FormAnswersTable({ handleViewAction });

  if (order) {
    return (
      <Box sx={{ pt: "50px", pb: "20px" }}>
        <Typography
          variant="h5"
          sx={{ display: "flex", justifyContent: "space-around", pt: "50px" }}
        >
          Order Items Details
        </Typography>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ my: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <FiCalendar />
                <Typography variant="h5">Order</Typography>
              </Box>
            </Box>
            <Box>
              <span
                style={{
                  padding: "3px 10px",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                status
              </span>
            </Box>
          </Box>
          <Divider />
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Order Info
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order item ID:{" "}
                    <span style={{ opacity: 0.7 }}>{order?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Inventory Item ID:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {order?.inventoryItemId}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Quantity:{" "}
                    <span style={{ opacity: 0.7 }}>{order?.quantity}</span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FaTruck />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Order Measurements
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", m: 2 }}
                  >
                    Indicated Weight:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {` 
                            ${order?.indicatedWeight?.value ?? "N/A"}, 
                            ${order?.indicatedWeight?.metric ?? "N/A"}
                          `}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", m: 2 }}
                  >
                    Indicated Volume:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {` ${order?.indicatedVolume?.value ?? "N/A"}  , ${
                        order?.indicatedVolume?.metric ?? "N/A"
                      }`}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FiMap />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Delivery Details
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Delivery Address ID:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {order?.deliveryAddress?.id}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Postal Address:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {order?.deliveryAddress?.postalAddress ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Delivery Street Address :{" "}
                    <span style={{ opacity: 0.7 }}>
                      {order?.deliveryAddress?.streetAddress ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Delivery Address :{" "}
                    <span style={{ opacity: 0.7 }}>
                      {` ${order?.deliveryAddress?.streetAddress ?? "N/A"},
                          ${order?.deliveryAddress?.city ?? "N/A"},
                          ${order?.deliveryAddress?.regionOrState ?? "N/A"}`}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Delivery Contact :{" "}
                    <span style={{ opacity: 0.7 }}>
                      {order?.deliveryContacts.length === 0 ? (
                        "No Contacts available"
                      ) : (
                        <Box sx={{ display: "flex", flexFlow: "wrap" }}>
                          {order?.deliveryContacts.map((contact, index) => (
                            <Chip
                              key={index}
                              label={`${contact.contactType}: ${contact.contact}`}
                            />
                          ))}
                        </Box>
                      )}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Grid container spacing={14} sx={{ py: 3, px: 5 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2">
                  <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                    {" "}
                    Date Created:
                  </span>{" "}
                  {moment(order?.created).format("LLLL")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <Typography variant="subtitle2">
                  <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                    {" "}
                    Date Updated:
                  </span>{" "}
                  {moment(order?.updated).format("LLLL")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Box sx={{ pt: "50px", pb: "20px" }}>
          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
              Form Answers
            </Typography>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              loading={isLoading}
              rowCount={order?.formAnswers?.length}
              columns={columns}
              rows={order?.formAnswers ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </Paper>

          <FormAnswerModal
            open={open}
            onClose={handleClose}
            formAnswer={selectedFormAnswer}
          />
        </Box>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleOrderItem;

const DomainType = {
  SUPER_MAN: 1,
  BACK_OFFICE: 2,
  MERCHANT_ADMIN: 3,
  EMPLOYEE: 4,
};
