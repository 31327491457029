import { FiberManualRecord, WarningOutlined, Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

export const ProductSpecDataTable = (handleDeleteAction) => {
  return [
   
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "name",
        flex: 1,
        headerAlign: "center",
        align: "center",
        filterable: true,
        headerName: "name",
        valueGetter: ({ value }) => value ?? "N/A",
      },
    {
      field: "productNumber",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Product Number",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "brand",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        headerName: "Brand",
        filterable: true,
        valueGetter: ({ value }) => value ?? "N/A",
      },
      {
        field: "status",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        headerName: "Status",
        filterable: true,
        valueGetter: ({ value }) => value ?? "N/A",
      },
    
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/loyalty/product-spec/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              disabled={row?.paid}
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
