import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { rootReducers } from "./rootReducers";
import { merchantsApi } from "../services/MerchantService";
import { inventoryApi } from "../services/InventoryService";
import { merchantCategoryApi } from "../services/MerchantCategoryService";
import { warehousesApi } from "../services/WarehouseService";
import { ordersApi } from "../services/OrderService";
import { countriesApi } from "../services/CountryService";
import { productsApi } from "../services/ProductService";
import { merchantSubCategoryApi } from "../services/MerchantSubCategoryService";
import { productConfigurationsApi } from "../services/ProductConfigurationService";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { transactionsApi } from "../services/TransactionService";
import { serviceTaskApi } from "../services/ServiceTaskService";
import { httpHeadersApi } from "../services/HttpHeaderService";
import { serviceTaskResponseConfigurationsApi } from "../services/ServiceTaskResponseConfigurationService";
import { serviceTaskConfigurationsApi } from "../services/ServiceTaskConfigurationService";
import { formQuestionsApi } from "../services/FormQuestionService";
import { dropDownSuggestionsApi } from "../services/DropDownSuggestionService";
import { rolesApi } from "../services/RoleService";
import { domainAccountApi } from "../services/DomainAcountService";
import { passwordApi } from "../services/PasswordService";
import { transactionsActivityApi } from "../services/TransactionActivityService";
import { orderActivityApi } from "../services/OrderActivityService";
import { remittanceActivityApi } from "../services/RemittanceActivityService";
import { procurementActivityApi } from "../services/ProcurementActivityService";
import { forexApi } from "../services/ForexService";
import { currenciesApi } from "../services/CurrencyService";
import { paymentsApi } from "../services/PaymentsService";
import { forexActivityLogApi } from "../services/ForexActivityLogService";
import { remittanceJobApi } from "../services/RemittanceJobService";
import { disputesApi } from "../services/DisputesService";
import { disputesActivityLogApi } from "../services/DisputesActivityLogService";
import { caseApi } from "../services/disputeServices/CasesServices";
import { caseAgentApi } from "../services/disputeServices/CaseAgentService";
import { rulingApi } from "../services/disputeServices/RullingService";
import { chatRoomApi } from "../services/disputeServices/ChatRoomService";
import { chatMessageApi } from "../services/disputeServices/ChatMessageService";
import { responseCodeMapsApi } from "../services/ResponseCodeMapService";
import { authApi } from "../services/AuthService";
import { inventoryMediaApi } from "../services/InventoryMediaService";
import { clientApi } from "../services/ClientAuthService";
import { merchantPurchaseOrderApi } from "../services/MerchantPurchaseOrderService";
import { paymentProviderApi } from "../services/PaymentProviderService";
import { mobileUsersApi } from "../services/MobileUsersService";
import { merchantDocumentsApi } from "../services/MerchantDocumentService";
import { merchantMediaApi } from "../services/MerchantMediaService";
import { remittanceMapApi } from "../services/RemittanceMapsService";
import { remittanceConfigurationApi } from "../services/RemittanceConfigurationService";
import { merchantContactsApi } from "../services/MerchantContactService";
import { notificationsApi } from "../services/NotificationService";
import { devicesApi } from "../services/DevicesService";
import { transactionLimitsApi } from "../services/TransactionLimitService";
import { paymentTaskApi } from "../services/PaymentTaskService";
import { paymentMediaApi } from "../services/PaymentMediaService";
import { remittanceInstitutionApi } from "../services/RemittanceInstitutionService";
import { remittancePayloadCustomMapApi } from "../services/RemittancePayloadCustomMapService";
import { remittanceAttemptApi } from "../services/RemittanceAttemptService";
import { remittanceProviderApi } from "../services/RemittanceProviderService";
import { logoutApi } from "../services/LogoutService";
import { kycApi } from "../services/KYCService";
import { questionToOrderItemMapApi } from "../services/QuestionToOrderItemMapService";
import thunk from "redux-thunk";
import { ProgramMembersApiService } from "../services/loyalty/programMembersService";
import { ProductSpecApiService } from "../services/loyalty/productSpecService";
import { LoyaltyTransactionApiService } from "../services/loyalty/loyaltyTransaction";
import { ProductApiService } from "../services/loyalty/productService";
import { ActionApiService } from "../services/loyalty/actionService";

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      thunk,
      merchantsApi.middleware,
      countriesApi.middleware,
      inventoryApi.middleware,
      merchantCategoryApi.middleware,
      merchantSubCategoryApi.middleware,
      merchantContactsApi.middleware,
      ordersApi.middleware,
      productsApi.middleware,
      warehousesApi.middleware,
      merchantMediaApi.middleware,
      transactionsApi.middleware,
      serviceTaskApi.middleware,
      questionToOrderItemMapApi.middleware,
      productConfigurationsApi.middleware,
      serviceTaskConfigurationsApi.middleware,
      serviceTaskResponseConfigurationsApi.middleware,
      responseCodeMapsApi.middleware,
      httpHeadersApi.middleware,
      formQuestionsApi.middleware,
      dropDownSuggestionsApi.middleware,
      transactionsActivityApi.middleware,
      orderActivityApi.middleware,
      remittanceActivityApi.middleware,
      procurementActivityApi.middleware,
      forexApi.middleware,
      currenciesApi.middleware,
      paymentsApi.middleware,
      forexActivityLogApi.middleware,
      remittanceJobApi.middleware,
      disputesActivityLogApi.middleware,
      merchantPurchaseOrderApi.middleware,
      disputesApi.middleware,
      rolesApi.middleware,
      logoutApi.middleware,
      authApi.middleware,
      domainAccountApi.middleware,
      passwordApi.middleware,
      caseApi.middleware,
      caseAgentApi.middleware,
      rulingApi.middleware,
      chatRoomApi.middleware,
      chatMessageApi.middleware,
      paymentProviderApi.middleware,
      inventoryMediaApi.middleware,
      mobileUsersApi.middleware,
      merchantDocumentsApi.middleware,
      clientApi.middleware,
      remittanceConfigurationApi.middleware,
      devicesApi.middleware,
      remittanceMapApi.middleware,
      transactionLimitsApi.middleware,
      paymentTaskApi.middleware,
      paymentMediaApi.middleware,
      notificationsApi.middleware,
      transactionLimitsApi.middleware,
      remittanceInstitutionApi.middleware,
      remittancePayloadCustomMapApi.middleware,
      remittanceAttemptApi.middleware,
      remittanceProviderApi.middleware,
      kycApi.middleware,
      ProgramMembersApiService.middleware,
      ProductSpecApiService.middleware,
      LoyaltyTransactionApiService.middleware,
      ProductApiService.middleware,
      ActionApiService.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;
