import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { paymentsUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: paymentsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  endpoints: (builder) => ({
    getAllPayments: builder.query({
      query: (request) => ({
        url: `/payments/?${request}`,
        method: "GET",
      }),
      providesTags: ["Payments"],
    }),

    getPayment: builder.query({
      query: (id) => ({
        url: `/payments/${id}`,
        method: "GET",
      }),
      providesTags: ["Payments"],
    }),

    searchPayments: builder.query({
      query: (request) => ({
        url: `/payments?${queryString.stringify(request, queryStringOptions)}`,
        method: "GET",
      }),
      providesTags: ["Payments"],
    }),
    updatePayment: builder.mutation({
      query: (request) => ({
        url: "/payments",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Payments"],
    }),
    createPayment: builder.mutation({
      query: (request) => ({
        url: "/payments",
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Payments"],
    }),
    deletePayment: builder.mutation({
      query: (id) => ({
        url: `/payments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payments"],
    }),
  }),
});

export const {
  useGetAllPaymentsQuery,
  useGetPaymentQuery,
  useSearchPaymentsQuery,
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
} = paymentsApi;
