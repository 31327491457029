import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { forexUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const forexApi = createApi({
  reducerPath: "forexApi",
  baseQuery: fetchBaseQuery({
    baseUrl: forexUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Forex"],
  endpoints: (builder) => ({
    getAllForexes: builder.query({
      query: (filterForexRequest) => ({
        url: `/forexes?${filterForexRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
      providesTags: ["Forex"],
    }),
    searchForexes: builder.query({
      query: (filterForexRequest) => ({
        url: `/forexes?${queryString.stringify(
          filterForexRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
      providesTags: ["Forex"],
    }),
    getForex: builder.query({
      query: (id) => ({
        url: `/forexes/${id}`,
        method: "GET",
      }),
      providesTags: ["Forex"],
      transformResponse: (response) => response.data,
    }),
    updateForex: builder.mutation({
      query: (updateForexRequest) => ({
        url: "/forexes",
        method: "PUT",
        body: updateForexRequest,
      }),
      invalidatesTags: ["Forex"],
    }),
    createForex: builder.mutation({
      query: (createForexRequest) => ({
        url: "/forexes",
        method: "POST",
        body: createForexRequest,
      }),
      invalidatesTags: ["Forex"],
    }),
    getForexDetails: builder.query({
      query: (id) => ({
        url: `/forex/activities?sortOrder=DESC&forexId=${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Forex"],
    }),
    deleteForex: builder.mutation({
      query: (id) => ({
        url: `/forexes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Forex"],
    }),

    getDashboardForexData: builder.query({
      query: () => ({
        url: "/forexes",
        method: "GET",
      }),
      providesTags: ["Forex"],
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetAllForexesQuery,
  useSearchForexesQuery,
  useGetForexQuery,
  useCreateForexMutation,
  useUpdateForexMutation,
  useDeleteForexMutation,
  useGetDashboardForexDataQuery,
  useGetForexDetailsQuery,
} = forexApi;
