import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formQuestionsUrl } from "../presentation/shared/ApiUrls";

export const formQuestionsApi = createApi({
  reducerPath: "formQuestionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: formQuestionsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["FormQuestions"],
  endpoints: (builder) => ({
    getAllFormQuestions: builder.query({
      query: (filterFormQuestionRequest) => ({
        url: `/form-questions?${filterFormQuestionRequest}`,
        method: "GET",
      }),
      providesTags: ["FormQuestions"],
      transformResponse: (response) => response.data.data,
    }),

    getFormQuestion: builder.query({
      query: (id) => ({
        url: `/form-questions/${id}`,
        method: "GET",
      }),
      providesTags: ["FormQuestions"],
      transformResponse: (response) => response?.data,
    }),

    searchFormQuestions: builder.mutation({
      query: (filterFormQuestionRequest) => ({
        url: `/form-questions?${filterFormQuestionRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["FormQuestions"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateFormQuestion: builder.mutation({
      query: (updateFormQuestionRequest) => ({
        url: "/form-questions",
        method: "PUT",
        body: updateFormQuestionRequest,
      }),
      invalidatesTags: ["FormQuestions"],
    }),
    createFormQuestion: builder.mutation({
      query: (addFormQuestionRequest) => ({
        url: "/form-questions",
        method: "POST",
        body: addFormQuestionRequest,
      }),
      invalidatesTags: ["FormQuestions"],
    }),
    deleteFormQuestion: builder.mutation({
      query: (id) => ({
        url: `/form-questions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FormQuestions"],
    }),
  }),
});

export const {
  useGetAllFormQuestionsQuery,
  useGetFormQuestionQuery,
  useSearchFormQuestionsMutation,
  useCreateFormQuestionMutation,
  useUpdateFormQuestionMutation,
  useDeleteFormQuestionMutation,
} = formQuestionsApi;
