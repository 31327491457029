import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { questionToOrderItemMapUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

export const questionToOrderItemMapApi = createApi({
  reducerPath: "questionToOrderItemMapApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
    baseUrl: questionToOrderItemMapUrl,
  }),
  refetchOnReconnect: true,
  tagTypes: ["QuestionToOrderItemMap"],
  endpoints: (builder) => ({
    searchQuestionToOrderItemMap: builder.query({
      query: (filterQuestionToOrderItemMapRequest) => ({
        url: `/question-maps?${queryString.stringify(
          filterQuestionToOrderItemMapRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["QuestionToOrderItemMap"],
      transformResponse: (response) => response.data.data,
    }),
    getQuestionToOrderItemMap: builder.query({
      query: (id) => ({
        url: `/question-maps/${id}`,
        method: "POST",
      }),
    }),
    createQuestionToOrderItemMap: builder.mutation({
      query: (createQuestionToOrderItemMapRequest) => ({
        url: "/question-maps",
        method: "POST",
        body: createQuestionToOrderItemMapRequest,
      }),
    }),
    updateQuestionToOrderItemMap: builder.mutation({
      query: (updateQuestionToOrderItemMapRequest) => ({
        url: "/question-maps",
        method: "PUT",
        body: updateQuestionToOrderItemMapRequest,
      }),
      invalidatesTags: ["QuestionToOrderItemMap"],
    }),
  }),
});

export const {
  useSearchQuestionToOrderItemMapQuery,
  useGetQuestionToOrderItemMapQuery,
  useCreateQuestionToOrderItemMapMutation,
  useUpdateQuestionToOrderItemMapMutation,
} = questionToOrderItemMapApi;
