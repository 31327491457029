import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

export const PaymentProviderTable = (handleDeleteAction) => {
  return [
    // {
    //   field: "id",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   filterable: false,
    //   headerName: "ID",
    // },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "enabled",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Enabled",
    },
    {
      field: "countriesAvailable",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Countries",
      valueGetter: ({ row }) => {
        let countries = row?.countriesAvailable;
        if (!countries) return;
        if (countries.length < 3) {
          return countries.map((country) => country?.isoCode).join(", ");
        } else {
          return countries
            .slice(0, 3)
            .map((country) => country?.isoCode)
            .join(", ");
        }
      },
    },
    // {
    //   field: "created",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   filterable: false,
    //   type: "date",
    //   headerName: "Date",
    //   valueGetter: ({ row }) => new Date(row?.created)
    // },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/payment-provider/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/payment-provider/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
