import {
  remittanceAttemptStatus,
  remittanceJobStatus,
  verificationStatus,
} from "../components/common/dropdownsData";

export function merchantsToOptions(merchants) {
  return merchants.map((merchant) => {
    return {
      label: merchant.name,
      value: merchant,
    };
  });
}
export function wareHousesToOptions(warehouses) {
  return warehouses.map((warehouse) => {
    return {
      label: warehouse.name,
      value: warehouse,
    };
  });
}

export function productsToOptions(products) {
  return products.map((product) => {
    return {
      label: `${product.name}-${product.categories[0].label}`,
      value: product,
    };
  });
}

export const checkDomainType = () => {
  const domainType = sessionStorage.getItem("domainType");
  switch (domainType) {
    case DomainType.BACK_OFFICE:
      return [
        DomainType.EMPLOYEE,
        DomainType.BACK_OFFICE,
        DomainType.MERCHANT_ADMIN,
      ];
    case DomainType.SUPERMAN:
      return [
        DomainType.EMPLOYEE,
        DomainType.BACK_OFFICE,
        DomainType.MERCHANT_ADMIN,
        DomainType.SUPERMAN,
      ];
    default:
      return [];
  }
};

export const DomainType = {
  SUPERMAN: "SUPERMAN",
  BACK_OFFICE: "BACK_OFFICE",
  MERCHANT_ADMIN: "MERCHANT_ADMIN",
  EMPLOYEE: "EMPLOYEE",
};

export const getAvailablePurchaseOrderStatus = (status) => {
  if (status === "INITIATED") {
    return [
      PurchaseOrderStatus.INITIATED,
      PurchaseOrderStatus.CANCELED,
      PurchaseOrderStatus.ACCEPTED,
      PurchaseOrderStatus.READY_FOR_PICKUP,
    ];
  } else if (status === "ACCEPTED") {
    return [
      PurchaseOrderStatus.ACCEPTED,
      PurchaseOrderStatus.CANCELED,
      PurchaseOrderStatus.DELIVERY_IN_PROGRESS,
      PurchaseOrderStatus.READY_FOR_PICKUP,
    ];
  } else if (status === "DELIVERY_IN_PROGRESS") {
    return [
      PurchaseOrderStatus.DELIVERY_IN_PROGRESS,
      PurchaseOrderStatus.DELIVERED,
    ];
  } else if (status === "DELIVERED") {
    return [PurchaseOrderStatus.DELIVERED];
  } else {
    return [PurchaseOrderStatus.CANCELED];
  }
};
export const PurchaseOrderStatus = {
  INITIATED: "INITIATED",
  ACCEPTED: "ACCEPTED",
  DELIVERY_IN_PROGRESS: "DELIVERY_IN_PROGRESS",
  DELIVERED: "DELIVERED",
  CANCELED: "CANCELED",
  READY_FOR_PICKUP: "READY_FOR_PICKUP",
};

export const updateColors = (status) => {
  if (status === "INITIATED") {
    return "primary";
  } else if (status === "ACCEPTED") {
    return "secondary";
  } else if (status === "DELIVERY_IN_PROGRESS") {
    return "warning";
  } else if (status === "DELIVERED") {
    return "success";
  } else {
    return "error";
  }
};
export const updateColor = (status) => {
  switch (status) {
    case remittanceJobStatus.ATTEMPTING:
      return "primary";
    case remittanceJobStatus.FAILED:
      return "error";
    case remittanceJobStatus.FAILED_NO_RETRY_ENABLED:
      return "error";
    case remittanceJobStatus.FAILED_NO_RETRY_MAX_ATTEMPT_REACHED:
      return "error";
    case remittanceJobStatus.INIT:
      return "primary";
    case remittanceJobStatus.PENDING_RESPONSE:
      return "warning";
    case remittanceJobStatus.REQUEST_FAILED:
      return "error";
    case remittanceJobStatus.REQUEST_TIMED_OUT:
      return "error";
    case remittanceJobStatus.SENT:
      return "success";
    case remittanceJobStatus.SUCCESSFUL:
      return "success";
    default:
      return "default";
  }
};

export const updateAttemptColor = (status) => {
  switch (status) {
    case remittanceAttemptStatus.INIT:
      return "primary";
    case remittanceAttemptStatus.FAILED:
      return "error";
    case remittanceAttemptStatus.ACK_FAILED:
      return "error";
    case remittanceAttemptStatus.ACK_TIMED_OUT:
      return "error";
    case remittanceAttemptStatus.SENT:
      return "sucsess";
    case remittanceAttemptStatus.SKIPPED:
      return "warning";
    case remittanceAttemptStatus.SUCCESS:
      return "success";
    case remittanceAttemptStatus.UNKNOWN:
      return "error";
    case remittanceAttemptStatus.ACK:
      return "success";
    default:
      return "default";
  }
};

export const kycStatusColor = (status) => {
  switch (status) {
    case verificationStatus.VERIFIED:
      return "success";
    case verificationStatus.UNVERIFIED:
      return "error";
    case verificationStatus.UPDATE_REQUIRED:
      return "primary";
    case verificationStatus.PENDING_REVIEW:
      return "primary";
    default:
      return "default";
  }
};
