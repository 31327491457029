import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";

export const CountriesTable = ({
  handleViewAction,
  handleEditAction,
  handleDeleteAction,
}) => {
  return [
    {
      field: "name",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "flag",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Flag",
      renderCell: ({ row }) => (
        <ReactCountryFlag countryCode={row?.isoCode} svg />
      ),
    },
    {
      field: "timeZone",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "TimeZone",
    },
    {
      field: "callingCode",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Calling code",
    },
    {
      field: "regionInitial",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Region Initial",
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
