import React, { useContext, useState } from "react";
import {
  Box,
  Paper,
  Checkbox,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  TableContainer,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  useCreateServiceTaskMutation,
  useDeleteServiceTaskMutation,
  useUpdateServiceTaskMutation,
} from "../services/ServiceTaskService";
import {
  serviceTaskTypeEnums,
  serviceTaskTypes,
} from "../components/common/dropdownsData";
import { ProductContext } from "./EditProductLayout";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { Add, Info } from "@mui/icons-material";
import { FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ServiceTasksTable } from "../data/ServiceTasksTable";
import {
  createServiceTaskRequest,
  updateServiceTaskRequest,
} from "../models/serviceTask";
import FormQuestion from "./FormQuestion";
import { Item } from "../components/common/Item";
import DropDownSuggestion from "./DropDownSuggestion";
import Chip from "@mui/material/Chip";
import PaperWrapper from "../components/common/PaperWrapper";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditServiceTask = () => {
  const [editServiceTask, { isLoading: isServiceTaskUpdateLoading }] =
    useUpdateServiceTaskMutation();
  const [addServiceTask, { isLoading: isCreateServiceTaskLoading }] =
    useCreateServiceTaskMutation();
  const [deleteServiceTask] = useDeleteServiceTaskMutation();
  const [serviceTaskRequest, setServiceTaskRequest] = useState(null);
  const {
    refetchProduct,
    productData: { configuration },
    setTab,
    isProductLoading,
  } = useContext(ProductContext);
  const [open, setOpen] = useState(false);
  const [showDeleteServiceTaskDialog, setShowDeleteServiceTaskDialog] =
    useState(false);
  const [selectedServiceTaskToDelete, setSelectedTaskToDelete] = useState(null);
  const [selectedServiceTask, setSelectedServiceTask] = useState(null);

  const handleServiceTaskChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (["useResponseAsDropDownQuestion"].includes(name)) {
      setServiceTaskRequest((prevServiceTask) => ({
        ...prevServiceTask,
        [name]: checked,
      }));
    } else {
      setServiceTaskRequest((prevServiceTask) => ({
        ...prevServiceTask,
        [name]: value,
      }));
    }
  };

  const handleCancelServiceTaskRequest = () => {
    setServiceTaskRequest(null);
  };

  const handleCloseServiceTaskDialog = () => {
    setOpen(false);
  };
  const handleShowServiceTaskDialogOnNoConfiguration = () => {
    setOpen(true);
  };
  const handleSwitchTab = () => {
    setTab(1);
  };
  const handleCloseDeleteServiceTaskDialog = () => {
    setShowDeleteServiceTaskDialog(false);
  };

  const handleDeleteServiceTask = async (event) => {
    event.preventDefault();
    setShowDeleteServiceTaskDialog(false);

    await deleteServiceTask(selectedServiceTaskToDelete.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task deleted successfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to delete service task ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete service task ");
      });
  };

  const handleUpdateServiceTask = async (event) => {
    event.preventDefault();

    await editServiceTask(serviceTaskRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task updated succesfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to update service task ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update service task ");
      });
  };

  const handleCreateServiceTask = async (event) => {
    event.preventDefault();

    await addServiceTask(serviceTaskRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task create succesfully");
          setServiceTaskRequest(null);
          refetchProduct();
        } else {
          ErrorToast("Failed to created service task ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to created service task ");
      });
  };

  const initCreateServiceTaskRequest = () => {
    if (configuration) {
      const toCreateServiceTask = createServiceTaskRequest;
      toCreateServiceTask.productConfigurationId = configuration.id;
      setServiceTaskRequest(toCreateServiceTask);
    }
  };

  const handleDeleteAction = (row) => {
    setSelectedTaskToDelete(row);
    setShowDeleteServiceTaskDialog(true);
  };
  const handleEditAction = (row) => {
    const toUpdateServiceTaskRequest = updateServiceTaskRequest;
    toUpdateServiceTaskRequest.id = row?.id;
    toUpdateServiceTaskRequest.description = row?.description;
    toUpdateServiceTaskRequest.taskType = row?.taskType;
    toUpdateServiceTaskRequest.executionOrder = row?.executionOrder;
    toUpdateServiceTaskRequest.labelActionButton = row?.labelActionButton;
    toUpdateServiceTaskRequest.productConfigurationId = configuration.id;
    toUpdateServiceTaskRequest.name = row?.name;
    toUpdateServiceTaskRequest.labelNextTask = row?.labelNextTask;

    setServiceTaskRequest(toUpdateServiceTaskRequest);
    setSelectedServiceTask(row);
  };

  const columns = ServiceTasksTable({ handleDeleteAction, handleEditAction });

  if (configuration) {
    const { customerTasks } = configuration;

    return (
      <Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "90%",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              p: "20px",
              my: 2,
              borderWidth: "1px",
              borderColor: "divider",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}
            >
              {serviceTaskRequest?.id && (
                <Typography variant="h6">Edit Service task</Typography>
              )}
              <Button
                startIcon={<Add />}
                // disabled={serviceTaskRequest}
                variant="contained"
                onClick={initCreateServiceTaskRequest}
              >
                Add Service task
              </Button>
            </Box>
            {serviceTaskRequest && (
              <Grid container spacing={2} sx={{ mb: 10 }}>
                <Grid item xs={6} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name of Task"
                    variant="outlined"
                    rows={4}
                    placeholder="Example: Send money"
                    value={serviceTaskRequest?.name}
                    name="name"
                    onChange={handleServiceTaskChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Label for next task"
                    variant="outlined"
                    rows={4}
                    placeholder="Example: Pay bills"
                    value={serviceTaskRequest?.labelNextTask}
                    name="labelNextTask"
                    onChange={handleServiceTaskChange}
                    size="small"
                  />
                </Grid>

                <Grid item xs={6} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Label for action button"
                    variant="outlined"
                    rows={4}
                    placeholder="Example: Pay bills"
                    value={serviceTaskRequest?.labelActionButton}
                    name="labelActionButton"
                    onChange={handleServiceTaskChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Execution order"
                    variant="outlined"
                    rows={4}
                    placeholder="Example: Pay bill"
                    value={serviceTaskRequest?.executionOrder}
                    name="executionOrder"
                    onChange={handleServiceTaskChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    placeholder="Hello world..."
                    value={serviceTaskRequest?.description}
                    rows={10}
                    multiline
                    name="description"
                    required
                    onChange={handleServiceTaskChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">
                      Service task type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Service task type"
                      required
                      value={serviceTaskRequest?.taskType}
                      defaultValue={""}
                      name="taskType"
                      onChange={handleServiceTaskChange}
                    >
                      {serviceTaskTypes.map((taskType, index) => (
                        <MenuItem key={index} value={taskType}>
                          {taskType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-select-small-label">
                      Service task type enum
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Service task type enum"
                      required
                      value={serviceTaskRequest?.taskTypeEnum}
                      defaultValue={""}
                      name="taskTypeEnum"
                      onChange={handleServiceTaskChange}
                    >
                      {serviceTaskTypeEnums.map((taskTypeEnum, index) => (
                        <MenuItem key={index} value={taskTypeEnum}>
                          {taskTypeEnum}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!serviceTaskRequest?.id && (
                  <Grid item xs={12} sm={12} md={6}>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="useResponseAsDropDownQuestion"
                          checked={
                            serviceTaskRequest?.useResponseAsDropDownQuestion
                          }
                          onChange={handleServiceTaskChange}
                          control={<Checkbox />}
                          size="small"
                          label="Do you want to use response as dropdown?"
                        />
                      </span>
                    </FormGroup>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={handleCancelServiceTaskRequest}
                      disabled={
                        isCreateServiceTaskLoading ||
                        isServiceTaskUpdateLoading ||
                        isProductLoading
                      }
                      variant="contained"
                      color="error"
                    >
                      {(isCreateServiceTaskLoading ||
                        isServiceTaskUpdateLoading ||
                        isProductLoading) && (
                        <CircularProgress
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                          color="primary"
                        />
                      )}
                      Cancel
                    </Button>

                    <Button
                      disabled={
                        isCreateServiceTaskLoading ||
                        isServiceTaskUpdateLoading ||
                        isProductLoading ||
                        serviceTaskRequest.description?.length === 0
                      }
                      variant="contained"
                      onClick={
                        serviceTaskRequest?.id
                          ? handleUpdateServiceTask
                          : handleCreateServiceTask
                      }
                    >
                      {(isCreateServiceTaskLoading ||
                        isServiceTaskUpdateLoading ||
                        isProductLoading) && (
                        <CircularProgress
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                          color="primary"
                        />
                      )}
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Box>
              <Typography variant="h6">Service tasks</Typography>
              <TableContainer component={Paper}>
                <DataGrid
                  autoHeight
                  sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: "gray",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                  loading={isProductLoading}
                  filterMode="client"
                  paginationMode="client"
                  rows={customerTasks}
                  rowCount={customerTasks.length}
                  columns={columns}
                  disableColumnSelector
                  disableDensitySelector
                  slotProps={{
                    panel: {
                      sx: {
                        top: "-120px !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.id}
                  slots={{ toolbar: GridToolbar }}
                />
              </TableContainer>
            </Box>
          </Paper>

          {selectedServiceTask && (
            <>
              <Divider>
                <Chip
                  label="Advanced Configuration"
                  variant="outlined"
                  size="sm"
                />
              </Divider>
              <PaperWrapper
                sx={{
                  my: 2,
                }}
              >
                <Item>
                  <FormQuestion
                    refetch={refetchProduct}
                    serviceTaskId={serviceTaskRequest?.id}
                    formQuestionData={selectedServiceTask?.formQuestions}
                  />
                </Item>
                <Divider />
                <Item>
                  <DropDownSuggestion  formQuestionData={selectedServiceTask?.formQuestions} />
                </Item>
              </PaperWrapper>
            </>
          )}
        </Paper>
        <CustomAlertDialog
          open={showDeleteServiceTaskDialog}
          handleClose={handleCloseDeleteServiceTaskDialog}
          handleSubmit={handleDeleteServiceTask}
          title={"Delete Service task"}
          subTitle={`Are you sure you want to delete service task: ${selectedServiceTaskToDelete?.name}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
      </Box>
    );
  } else {
    return (
      <>
        <CustomAlertDialog
          open={open}
          handleClose={handleCloseServiceTaskDialog}
          handleSubmit={handleSwitchTab}
          title={"Add Service task"}
          subTitle={`To add a service task, you are required to add a configuration for the product. Would you like to add a confguration?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<Info size={30} />}
          fullWidth={true}
        />
        <Button
          variant="contained"
          onClick={handleShowServiceTaskDialogOnNoConfiguration}
        >
          Add Service task
        </Button>
      </>
    );
  }
};

export default EditServiceTask;
