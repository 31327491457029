import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSearchTransactionActivitiesQuery } from "../services/TransactionActivityService";
import { TransactionsActivityTable } from "../data/TransactionActivityTable";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import { DomainType } from "../presentation/helpers";
import SecuredComponent from "../components/common/SecuredComponent";
import { DataGridService } from "../core/dataGridService";

const TransactionActivity = () => {
  const dataGridService = new DataGridService("TransactionActivity");

  const [filter, setFilter] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);
  const { data: transactionActivities, isLoading } =
    useSearchTransactionActivitiesQuery(filter);

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const printRef = useRef();

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction(null);
  };

  const handleViewActivity = async (row) => {
    setOpen(true);
    setSelectedTransaction(row);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const columns = TransactionsActivityTable(handleViewActivity);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Transaction Activities</Typography>
        </Box>
        <Dialog
          open={open}
          fullWidth={true}
          onClose={handleClose}
          sx={{ mx: "auto", my: "0px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Activity Details </DialogTitle>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <DialogContent ref={printRef}>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Id:{" "}
              <span style={{ opacity: 0.7 }}>{selectedTransaction?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Created By:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.createdby ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Activity:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.activity}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Status:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.status}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Date:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedTransaction?.created).format("LLL") ?? "N/A"}
              </span>
            </Typography>

            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Updated:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedTransaction?.updated).format("LLL") ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Last Modified by:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedTransaction?.lastModifiedby).format("LLL") ??
                  "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              External Id:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.request &&
                  (JSON.stringify(selectedTransaction?.request) ?? "N/A")}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Response:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.response &&
                  (JSON.stringify(selectedTransaction?.response) ?? "null")}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Response:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedTransaction?.response &&
                  (JSON.stringify(selectedTransaction?.response) ?? "null")}
              </span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>

        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              loading={isLoading}
              rowCount={transactionActivities?.size ?? 0}
              columns={columns}
              rows={transactionActivities?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default TransactionActivity;
