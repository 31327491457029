import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  remittanceCustomMapTypes
} from "../components/common/dropdownsData";
import moment from "moment";

export const RemittancePayloadCustomMapTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "ID",
    },
    {
      field: "merchantId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Merchant Id",
    },
    {
      field: "inventoryId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Inventory Id",
    },
    {
      field: "remittanceAttribute",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Attriute",
    },
    {
      field: "type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Type",
      valueGetter: ({ row }) => remittanceCustomMapTypes[row.type],
    },
    {
      field: "remittanceAttributeValue",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Attribute Value",
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/remittance/custom-map/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/remittance/custom-map/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
