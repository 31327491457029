import { Box } from "@mui/system";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";
import { updateColor } from "../presentation/helpers";

export const RemittanceJobsTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => moment(value).format("LLL") ?? "N/A",
    },
    {
      field: "orderId",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Order Id",
    },
    {
      field: "createdBy",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Created By",
    },
    {
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant="outlined"
          color={updateColor(value)}
          label={value}
        />
      ),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/remittance-job/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
