import { Chip, Box, IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiTrash2, FiCopy } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const ProductsTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
      valueGetter: ({ row }) =>
        `${row?.name}-${row?.categories[0]?.label ?? "N/A"}`,
    },
    {
      field: "type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: "Type",
    },
    {
      field: "platformSKU",
      flex: 1,
      headerAlign: "center",
      type: "boolean",
      align: "center",
      headerName: "PlatformSKU",
      renderCell: ({ value }) =>
        value ? (
          <Chip size={"small"} variant="outlined" color="primary" label="YES" />
        ) : (
          <Chip size={"small"} variant="outlined" color="error" label="NO" />
        ),
    },
    {
      field: "service",
      flex: 0.5,
      headerAlign: "center",
      type: "boolean",
      align: "center",
      headerName: "Service",
      renderCell: ({ value }) =>
        value ? (
          <Chip size={"small"} variant="outlined" color="primary" label="YES" />
        ) : (
          <Chip size={"small"} variant="outlined" color="error" label="NO" />
        ),
    },
    {
      field: "enabled",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Enabled",
      renderCell: ({ value }) =>
        value ? (
          <Chip size={"small"} variant="outlined" color="primary" label="YES" />
        ) : (
          <Chip size={"small"} variant="outlined" color="error" label="NO" />
        ),
    },
    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/* <Tooltip arrow placement="top" title="Duplicate">
            <IconButton color="primary">
              <FiCopy size={18} />
            </IconButton>
          </Tooltip>{" "} */}
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/product/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
