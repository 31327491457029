import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { disputesActivityLogUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const disputesActivityLogApi = createApi({
  reducerPath: "disputesActivityLogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: disputesActivityLogUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["DisputesActivityLog"],
  endpoints: (builder) => ({
    getAllDisputesActivityLogs: builder.query({
      query: (filterDisputesActivityLogRequest) => ({
        url: `/case-activities?${filterDisputesActivityLogRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ["DisputesActivityLog"],
    }),
    searchDisputesActivityLogs: builder.query({
      query: (filterDisputesActivityLogRequest) => ({
        url: `/case-activities?${queryString.stringify(
          filterDisputesActivityLogRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
      providesTags: ["DisputesActivityLog"],
    }),
    getDisputesActivityLog: builder.query({
      query: (id) => ({
        url: `/case-activities/${id}`,
        method: "GET",
      }),
      providesTags: ["DisputesActivityLog"],
      transformResponse: (response) => response.data,
    }),
    updateDisputesActivityLog: builder.mutation({
      query: (updateDisputesActivityLogRequest) => ({
        url: "/case-activities",
        method: "PUT",
        body: updateDisputesActivityLogRequest,
      }),
      invalidatesTags: ["DisputesActivityLog"],
    }),
    createDisputesActivityLog: builder.mutation({
      query: (createDisputesActivityLogRequest) => ({
        url: "/case-activities",
        method: "POST",
        body: createDisputesActivityLogRequest,
      }),
      invalidatesTags: ["DisputesActivityLog"],
    }),
    deleteDisputesActivityLog: builder.mutation({
      query: (id) => ({
        url: `/case-activities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DisputesActivityLog"],
    }),
  }),
});

export const {
  useGetAllDisputesActivityLogsQuery,
  useSearchDisputesActivityLogsQuery,
  useGetDisputesActivityLogQuery,
  useCreateDisputesActivityLogMutation,
  useUpdateDisputesActivityLogMutation,
  useDeleteDisputesActivityLogMutation,
} = disputesActivityLogApi;
