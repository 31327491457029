import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEye } from "react-icons/fi";

export const FormAnswersTable = ({ handleViewAction }) => {
  return [
    {
      field: "created",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "answeredProvided",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Answer Provided",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "updated",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Updated",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
