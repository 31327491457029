import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { currenciesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const currenciesApi = createApi({
  reducerPath: "currenciesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: currenciesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Currency"],
  endpoints: (builder) => ({
    getAllCurrency: builder.query({
      query: (filterCurrencyRequest) => ({
        url: `/currencies?${filterCurrencyRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ["Currency"],
    }),
    searchCurrency: builder.query({
      query: (filterCurrencyRequest) => ({
        url: `/currencies?${queryString.stringify(
          filterCurrencyRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Currency"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    getCurrency: builder.query({
      query: (id) => ({
        url: `/currencies/${id}`,
        method: "GET",
      }),
      providesTags: ["Currency"],
      transformResponse: (response) => response.data,
    }),
    updateCurrency: builder.mutation({
      query: (updateCurrencyRequest) => ({
        url: "/currencies",
        method: "PUT",
        body: updateCurrencyRequest,
      }),
      invalidatesTags: ["Currency"],
    }),
    createCurrency: builder.mutation({
      query: (createCurrencyRequest) => ({
        url: "/currencies",
        method: "POST",
        body: createCurrencyRequest,
      }),
      invalidatesTags: ["Currency"],
    }),
    deleteCurrency: builder.mutation({
      query: (id) => ({
        url: `/currencies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Currency"],
    }),
  }),
});

export const {
  useGetAllCurrencyQuery,
  useSearchCurrencyQuery,
  useGetCurrencyQuery,
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation,
} = currenciesApi;
