import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Grid,
} from "@mui/material";
import { useGetAllCountriesQuery } from "../../services/CountryService";
import queryString from "query-string";
import { getEmojiFlag } from "countries-list";

const CurrencyModal = ({
  open,
  handleClose,
  currency,
  type,
  handleFormChange,
  handleSubmitCurrency,
}) => {
  const { data: countries } = useGetAllCountriesQuery(
    queryString.stringify({ size: 10000 })
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle textAlign={"center"}>{`${type} CURRENCY`}</DialogTitle>
      <DialogContent>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Id: <span style={{ opacity: 0.7 }}>{currency?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Name: <span style={{ opacity: 0.7 }}>{currency?.name}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Initial: <span style={{ opacity: 0.7 }}>{currency?.initial}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Major Denomination:{" "}
              <span style={{ opacity: 0.7 }}>
                {currency?.majorDenomination}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Major Denomination:{" "}
              <span style={{ opacity: 0.7 }}>
                {currency?.minorDenomination}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container padding={1}>
            {type === "ADD" && (
              <Grid sm={12} xs={12} item md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small-label">Country</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small-label"
                    label="Country"
                    name="countryId"
                    onChange={handleFormChange}
                  >
                    {countries?.map((country,index) => (
                      <MenuItem value={country} key={index}>{`${getEmojiFlag(
                        country?.isoCode
                      )} ${country?.name}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                value={currency?.name}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="Initial"
                name="initial"
                variant="outlined"
                value={currency?.initial}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="Major Denomination"
                name="majorDenomination"
                variant="outlined"
                value={currency?.majorDenomination}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="Minor Denomination"
                name="minorDenomination"
                variant="outlined"
                value={currency?.minorDenomination}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>
            <Button onClick={handleSubmitCurrency} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CurrencyModal;
