import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import {
  useDeleteHttpHeaderMutation,
  useGetAllHttpHeadersQuery,
} from "../services/HttpHeaderService";
import HttpHeaderDialog from "../components/modals/HttpHeaderDialog";
import queryString from "query-string";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditHttpHeader = ({ serviceTaskConfigurationId }) => {
  const { data: httpHeaderData, isLoading } = useGetAllHttpHeadersQuery(
    queryString.stringify({
      size: 100,
      serviceTaskConfigurationId: serviceTaskConfigurationId,
    })
  );
  const [expanded, setExpanded] = React.useState(false);
  const [selectedHttpHeader, setSelectedHttpHeader] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteHttpHeader] = useDeleteHttpHeaderMutation();
  const [dialogType, setDialogType] = React.useState("");

  const handleAccodionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleShowDialog = (httpHeader) => {
    if (httpHeader?.id) {
      setDialogType("UPDATE");
      setSelectedHttpHeader(httpHeader);
    } else {
      setSelectedHttpHeader((prev) => ({
        ...prev,
        serviceTaskConfigurationId: serviceTaskConfigurationId,
      }));
      setDialogType("ADD");
    }

    setShowDialog(true);
  };

  const handleDeleteHttpHeader = async (httpHeader) => {
    await deleteHttpHeader(httpHeader?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Header deleted succesfully");
        } else {
          ErrorToast("Failed to delete header");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete header");
      });
  };

  const handleCloseDialog = () => {
    setSelectedHttpHeader(null);
    setShowDialog(false);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccodionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h5" sx={{ width: "100%", flexShrink: 0 }}>
              Configure Additional headers
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="caption">
                Here you can append headers to your service task configuration
              </Typography>{" "}
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleShowDialog}
              >
                Add Headers
              </Button>
            </Box>
            {httpHeaderData &&
              httpHeaderData.map((httpHeader, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}
                >
                  <TextField
                    fullWidth
                    label="Header Key"
                    variant="outlined"
                    disabled
                    placeholder="Content-Type"
                    value={httpHeader?.headerKey}
                    name="headerKey"
                    size="small"
                  />

                  <TextField
                    fullWidth
                    label="Header Value"
                    variant="outlined"
                    disabled
                    placeholder="application/json"
                    value={httpHeader?.headerValue}
                    name="headerValue"
                    size="small"
                  />

                  <Chip
                    variant="outlined"
                    color="error"
                    label="Edit"
                    onClick={() => {
                      handleShowDialog(httpHeader);
                    }}
                  />
                  <Chip
                    variant="outlined"
                    color="error"
                    label="Remove"
                    onClick={() => {
                      handleDeleteHttpHeader(httpHeader);
                    }}
                  />
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>
        <HttpHeaderDialog
          type={dialogType}
          open={showDialog}
          handleClose={handleCloseDialog}
          selectedHttpHeader={selectedHttpHeader}
        />
      </Box>
    );
  }
};

export default EditHttpHeader;
