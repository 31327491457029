import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { rolesUrl } from "../presentation/shared/ApiUrls";

export const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: rolesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Roles"],
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: () => ({
        url: `/roles`,
        method: "GET",
      }),
      providesTags: ["Roles"],
      transformResponse: (response) => response.data,
    }),

    getRole: builder.query({
      query: (id) => ({
        url: `/roles/${id}`,
        method: "GET",
      }),
      providesTags: ["Roles"],
    }),
  }),
});

export const { useGetAllRolesQuery, useGetRoleQuery } = rolesApi;
