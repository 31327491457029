import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { BsFillChatDotsFill } from "react-icons/bs";

import LoadingPage from "../../components/LoadingPage";
import { useGetCaseQuery } from "../../services/disputeServices/CasesServices";
import { useGetMerchantQuery } from "../../services/MerchantService";
import AssignCaseAgentModal from "../../components/modals/AssignCaseAgentModal";
import { KeyboardBackspace } from "@mui/icons-material";

const SingleCase = () => {
  const { id: caseId } = useParams();
  const { data, refetch: refetchCase } = useGetCaseQuery(caseId);
  const { data: merchantData } = useGetMerchantQuery(data?.merchantId);
  const chatRoomId = data?.room?.id;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  if (data) {
    return (
      <Box
        sx={{
          pt: "80px",
          pb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <AssignCaseAgentModal
          open={open}
          handleClose={handleClose}
          refetchCase={refetchCase}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/pages/cases"}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5">Case Details</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ fontSize: "20px" }}>
              Case ID: {caseId}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                sx={{ borderRadius: "20px", marginRight: "10px" }}
                onClick={() => setOpen(true)}
              >
                Assign Agent
              </Button>

              <Link
                to={`/pages/chat/${chatRoomId}/${caseId}`}
                style={{
                  padding: "10px 15px",
                  float: "right",
                  color: "#049bf5",
                  backgroundColor: "rgba(4, 155, 245,0.2)",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                <BsFillChatDotsFill />
              </Link>
            </Box>
          </Box>

          {/* <Divider /> */}
          <Grid
            container
            spacing={2}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    CustomerID:{" "}
                    <span style={{ opacity: 0.7 }}>{data?.customerId}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Reason: <span style={{ opacity: 0.7 }}>{data?.reason}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Merchant:{" "}
                    <span style={{ opacity: 0.7 }}>{merchantData?.name}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Assigned Agent:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {data?.assignedAgent?.firstName}{" "}
                      {data?.assignedAgent?.lastName}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              {" "}
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order Category:{" "}
                    <span style={{ opacity: 0.7 }}>{data?.orderCategory}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(data?.created).format("LLL")}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(data?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}></Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleCase;
