import { Box, Paper, TableContainer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { useSearchUsersQuery } from "../services/MobileUsersService";
import { MobileUsersTable } from "../data/MobileUsersTable";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import { useVerifyKycMutation } from "../services/KYCService";
import KycStatusModal from "../components/modals/KycModal";
import { QuestionAnswer } from "@mui/icons-material";
import UserProfileModal from "../components/modals/UserProfileModal";

const Users = () => {
  const dataGridService = new DataGridService("MobileUsers");

  const [selectedUser, setSelectedUser] = useState(null);
  const [filter, setFilter] = useState({});
  const {
    data: userData,
    isLoading: isUsersLoading,
    refetch,
  } = useSearchUsersQuery(filter);

  const [type, setType] = useState("");

  const [verifyKyc] = useVerifyKycMutation();

  const [open, setOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setUserOpen(false);
    setSelectedUser(null);
  };

  const handleCloseVerifyDialog = () => {
    setOpenVerifyDialog(false);
  };

  const handleSubmitVerify = () => {
    verifyKyc({
      phone: selectedUser?.user?.kyc?.phone,
      verificationStatus: selectedUser?.user?.kyc?.verificationStatus,
    })
      .then((response) => {
        if (response.data) {
          refetch();
          SuccessToast("Account updated successfully");
        } else {
          ErrorToast("Failed to update account");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update account");
      })
      .finally(() => {
        setOpenVerifyDialog(false);
        setOpen(false);
      });
    handleCloseVerifyDialog();
    handleClose();
  };

  const handleSubmitKycDialog = () => {
    setOpenVerifyDialog(true);
  };

  const handleViewAction = (row) => {
    setType("VIEW");
    setSelectedUser(row);
    setOpen(true);
  };

  const handleEditAction = (row) => {
    setType("UPDATE");
    setSelectedUser(row);
    setOpen(true);
  };

  const handleViewProfileAction = (row) => {
    setSelectedUser(row);
    setUserOpen(true);
  };

  const columns = MobileUsersTable({
    handleViewAction,
    handleEditAction,
    handleViewProfileAction,
  });

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Mobile Users</Typography>
      </Box>
      <UserProfileModal
        open={userOpen}
        user={selectedUser}
        handleClose={handleClose}
      />
      <KycStatusModal
        open={open}
        user={selectedUser}
        type={type}
        handleClose={handleClose}
        handleFormChange={(e) =>
          setSelectedUser((prev) => ({
            ...prev,
            user: {
              ...prev?.kyc,
              kyc: { ...prev?.user?.kyc, [e.target.name]: e.target.value },
            },
          }))
        }
        handleSubmit={handleSubmitKycDialog}
      />
      <CustomAlertDialog
        open={openVerifyDialog}
        handleClose={handleCloseVerifyDialog}
        handleSubmit={handleSubmitVerify}
        title={"KYC verification"}
        subTitle={`Are you sure you want update this account?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<QuestionAnswer size={25} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": { color: "primary.main" },
            }}
            slotProps={{ panel: { sx: { top: "-120px !important" } } }}
            loading={isUsersLoading}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            rowCount={userData?.size ?? 0}
            rows={userData?.data ?? []}
            disableColumnSelector
            disableDensitySelector
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Users;
