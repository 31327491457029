import React, { useState } from 'react';
import {
    Box,
    Paper,
    Grid,
    MenuItem,
    TextField,
    Button
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAddProductSpecMutation } from '../../services/loyalty/productSpecService';
import { ErrorToast, SuccessToast } from "../../components/common/Toast";
import { useNavigate } from 'react-router-dom';


const AddProductSpecRule = () => {
    const navigate = useNavigate()
    const [addProductSpec] = useAddProductSpecMutation();

    const [formData, setFormData] = useState([
        { name: 'commonName', value: '' },
        { name: 'description', value: '' },
        { name: 'eventType', value: '' },
        { name: 'actionId', value: '' },
        { name: 'productSpecId', value: '' },
        { name: 'policyName', value: '' },
        { name: 'usage', value: '' },
        { name: 'keywords', value: '' },
        { name: 'hasSubRules', value: '' },
        { name: 'externalId', value: '' },
    ]);

    const handleChange = (name, value) => {
        setFormData(prevState =>
            prevState.map(field =>
                field.name === name ? { ...field, value } : field
            )
        );
    };


    const handleSubmit = async () => {
        const formDataWithDateString = formData.map(field => {
            if (field.name === 'startDate' || field.name === 'endDate') {
                return { ...field, value: field.value ? field.value.format('YYYY-MM-DD') : null };
            }
            return field;
        });

        const formDataJSON = {
            commonName: '',
            description: '',
            externalId: '',
            cnf: true,
            eventType: '',
            mandatoryEvaluation: true,
            policyName: '',
            actionId: '',
            usage: '',
            keywords: '',
            productSpecId: '',
            hasSubRules: ''
        };

        formDataWithDateString.forEach(field => {
            switch (field.name) {
                case 'commonName':
                case 'description':
                case 'externalId':
                case 'cnf':
                case 'eventType':
                case 'mandatoryEvaluation':
                case 'policyName':
                case 'actionId':
                case 'usage':
                case 'keywords':
                case 'productSpecId':
                case 'hasSubRules':
                    formDataJSON[field.name] = field.value;
                    break;
            }
        });

        console.log("formData", formDataJSON)

        try {
            const response = await addProductSpec(formDataJSON);
            if (response.data.code == 0) {
                SuccessToast("Product Spec rule successfully create")
                setFormData([
                    { name: 'commonName', value: '' },
                    { name: 'description', value: '' },
                    { name: 'eventType', value: '' },
                    { name: 'actionId', value: '' },
                    { name: 'productSpecId', value: '' },
                    { name: 'policyName', value: '' },
                    { name: 'usage', value: '' },
                    { name: 'keywords', value: '' },
                    { name: 'keywords', value: '' },
                ])
            } else {
                ErrorToast("Failed to add product spec rule")
            }
        } catch (error) {
            ErrorToast('Error submitting form:', error);
        }

    };

    return (
        <Box sx={{ pt: "80px", pb: "20px" }}>
            <div style={{ margin: "20px 0px", fontWeight: "900", fontSize: "25px" }}>Add Product Spec Rule</div>

            <Paper
                sx={{
                    boxShadow: "none !important",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "divider",
                    padding: "50px 50px",
                    overflow: "hidden",
                    minHeight: "400px",
                    height: "auto"
                }}
            >
                <Grid container spacing={2}>
                    {formData.map((field, index) => (
                        <Grid key={index} item xs={6} sm={12} md={6}>
                            {field.name === 'startDate' || field.name === 'endDate' ? (
                                <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label={field.name === 'startDate' ? 'Start Date' : 'End Date'}
                                        value={field.value}
                                        onChange={(newValue) => handleChange(field.name, newValue)}
                                        fullWidth
                                        size="large"
                                        sx={{ width: "100%", margin: "5px 0px" }}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <TextField
                                    label={field.name === 'needsLoyalty' ? 'Needs Loyalty' : field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                                    variant="outlined"
                                    value={field.value}
                                    onChange={(e) => handleChange(field.name, e.target.value)}
                                    fullWidth
                                    size="large"
                                    select={field.name === 'needsLoyalty'}
                                    SelectProps={{ displayEmpty: true }}
                                >
                                    {field.name === 'needsLoyalty' && (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                    <MenuItem value="true">True</MenuItem>
                                    <MenuItem value="false">False</MenuItem>
                                </TextField>
                            )}
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{
                    display: "flex",
                    margin: "30px",
                    padding: "16px",
                    justifyContent: "end"
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/pages/loyalty/product-spec`)}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ margin: "0px 10px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Add
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default AddProductSpecRule;
