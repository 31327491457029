import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const TransactionsTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "left",
      align: "left",
      filterable: false,
      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "id",
      flex: 0.4,
      headerAlign: "left",
      align: "left",
      headerName: "ID",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "amount",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      headerName: "Amount",
      valueGetter: ({ row }) => {
        return `${row?.monetaryAmount?.currency} ${row?.monetaryAmount?.amount}`;
      },
    },

    {
      field: "type",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      headerName: "Type",
    },
    {
      field: "sender",
      flex: 0.8,
      headerAlign: "left",
      align: "left",
      headerName: "Sender",
      valueGetter: ({ row }) => {
        return row?.sender?.name;
      },
    },
    {
      field: "recipient",
      flex: 0.8,
      headerAlign: "left",
      align: "left",
      headerName: "Recipient",
      valueGetter: ({ row }) => {
        return row?.recipient?.name;
      },
    },
    {
      field: "status",
      flex: 1,
      headerAlign: "left",
      align: "left",
      headerName: "Status",
    },

    {
      field: "paymentProcessingId",
      flex: 0.9,
      headerAlign: "left",
      align: "left",
      headerName: "Payment Processing ID",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "action",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      filterable: false,
      sortable: false,
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/transaction/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
