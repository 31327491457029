import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetRemittanceConfigurationQuery } from "../services/RemittanceConfigurationService";
import moment from "moment";
import { MdBusiness } from "react-icons/md";
import { useDeleteMerchantCategoryMutation } from "../services/MerchantCategoryService";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace } from "@mui/icons-material";

const SingleRemittanceConfiguration = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetRemittanceConfigurationQuery(id);
  const [configuration, setConfiguration] = useState(null);

  if (data) {
    const { data: configuration } = data;
    return (
      <Box
        sx={{
          pt: "80px",

          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Link to={-1}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>
          <Typography variant="h5">Configuration Details</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Configuration
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Id:{" "}
                    <span style={{ opacity: 0.7 }}>{configuration?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Merchant Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.merchantId}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.accountType}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Statuses Retried:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.statusesRetried}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Enabled:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.enabled.toString()}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.account}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {configuration?.lastModifiedBy}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(configuration?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(configuration?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        ></Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleRemittanceConfiguration;
