import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";
import { PurchaseOrderStatus, updateColors } from "../presentation/helpers";

export const MerchantPurchaseOrderTable = ({ handleEditAction }) => {
 
  
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,

      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "item",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Product Name",
      valueGetter: ({ row }) => row?.item?.productName ?? "N/A",
    },
    {
      field: "merchandisePrice",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Amount",
      valueGetter: ({ row }) =>
        row?.item
          ? `${row?.item?.merchandisePrice?.currency} ${row?.item?.merchandisePrice?.amount}`
          : "N/A",
    },

    {
      field: "status",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      renderCell: ({ value }) => (
        <Button size="small" disableRipple variant="outlined" color={updateColors(value)}>
          {value}
        </Button>
      ),
    },

    {
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/merchant-purchase-order/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              disabled={[
                PurchaseOrderStatus.DELIVERED,
                PurchaseOrderStatus.CANCELED,
              ].includes(row.status)}
              color="primary"
              onClick={() =>
                handleEditAction({ prevStatus: row?.status, order: row })
              }
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
