import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { mappableOrderItemAttributes } from "../components/common/dropdownsData";
import { questionToOrderItemMap } from "../models/questionToOrderItemMap";
import {
  useCreateQuestionToOrderItemMapMutation,
  useSearchQuestionToOrderItemMapQuery,
  useUpdateQuestionToOrderItemMapMutation,
} from "../services/QuestionToOrderItemMapService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const QuestionToOrderItemMap = ({ formQuestionId }) => {
  const { data } = useSearchQuestionToOrderItemMapQuery({
    // formQuestionId: formQuestionId,
  });
  const [questionToOrderItemMapRequest, setQuestionToOrderItemMapRequest] =
    useState(questionToOrderItemMap);
  const [createQuestionToOrderItemMap] =
    useCreateQuestionToOrderItemMapMutation();
  const [updateQuestionToOrderItemMap] =
    useUpdateQuestionToOrderItemMapMutation();



  const handleAvailableVolumnChange = (event) => {
    const { name, value } = event.target;
    setQuestionToOrderItemMapRequest((prev) => ({
      ...prev,
      formQuestionId: formQuestionId,
      [name]: value,
    }));
  };
  const handleCreate = async (event) => {
    event.preventDefault();

    await createQuestionToOrderItemMap(questionToOrderItemMapRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Question-to-order-map added succesfully");
        } else {
          ErrorToast("Failed to add Question to order map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to add Question to order map");
      });
  };
  const handleUpdate = async (event) => {
    event.preventDefault();

    await updateQuestionToOrderItemMap(questionToOrderItemMapRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Question-to-order-map updated succesfully");
        } else {
          ErrorToast("Failed to update Question-to-order-map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Question-to-order-map");
      });
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="orderItemAttribute-label">
              Question to order item map
            </InputLabel>
            <Select
              labelId="orderItemAttribute-label"
              name="orderItemAttribute"
              onChange={handleAvailableVolumnChange}
              label="Question to order item map"
              value={data && data[0] && data[0]?.orderItemAttribute}
            >
              {mappableOrderItemAttributes.map(
                (mappableOrderItemAttribute, index) => (
                  <MenuItem
                    key={index}
                    disabled={mappableOrderItemAttribute === "NONE"}
                    value={mappableOrderItemAttribute}
                  >
                    {mappableOrderItemAttribute}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              onClick={data && data[0] ? handleCreate : handleUpdate}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionToOrderItemMap;
