import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { countriesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({ baseUrl: countriesUrl }),
  refetchOnReconnect: true,
  tagTypes: ["Countries"],
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => ({
        url: `/countries`,
        method: "GET",
      }),
      providesTags: ["Countries"],
      transformResponse: (response) => response.data.data,
    }),
    getCountry: builder.query({
      query: (id) => ({
        url: `/countries/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Countries"],
    }),
    searchCountries: builder.query({
      query: (filterCountryRequest) => ({
        url: `/countries?${queryString.stringify(
          filterCountryRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Countries"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),

    updateCountry: builder.mutation({
      query: (updateCountryRequest) => ({
        url: "/countries",
        method: "PUT",
        body: updateCountryRequest,
      }),
      invalidatesTags: ["Countries"],
    }),
    createCountry: builder.mutation({
      query: (createCountryRequest) => ({
        url: "/countries",
        method: "POST",
        body: createCountryRequest,
      }),
      invalidatesTags: ["Countries"],
    }),
    deleteCountry: builder.mutation({
      query: (id) => ({
        url: `/countries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Countries"],
    }),
  }),
});

export const {
  useGetAllCountriesQuery,
  useGetCountryQuery,
  useSearchCountriesQuery,
  useCreateCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countriesApi;
