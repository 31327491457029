import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMerchantQuery } from "../services/MerchantService";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MerchantCategoriesTable } from "../data/MerchantCategoriesTable";
import { MdBusiness } from "react-icons/md";
import { LiaAddressBook } from "react-icons/lia";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useGetAllWarehousesQuery } from "../services/WarehouseService";
import { WarehousesTable } from "../data/WarehousesTable";
import queryString from "query-string";
import ReactToPrint from "react-to-print";

const SingleMerchant = () => {
  const { id } = useParams();
  const { data: merchant, isLoading } = useGetMerchantQuery(id);
  const [selectedMerchantCategory, setSelectedMerchantCategory] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useGetAllWarehousesQuery(queryString.stringify({ merchantId: id }));
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedMerchantCategory(row);
  };
  const printRef = useRef();
  const columns = MerchantCategoriesTable(handleDeleteAction);
  const warehouseColumns = WarehousesTable(handleDeleteAction);

  if (merchant) {
    return (
      <Box
        sx={{
          pt: "80px",
          pb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start", pb: "20px" }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
          ref={printRef}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <Typography
              variant="h5"
              mb={3}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Merchant Details
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Merchant
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Code: <span style={{ opacity: 0.7 }}>{merchant?.code}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name: <span style={{ opacity: 0.7 }}>{merchant?.name}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Verified:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.verified ? (
                        <b styel={{ color: "blue" }}>Yes</b>
                      ) : (
                        <b style={{ color: "red" }}>No</b>
                      )}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(merchant?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(merchant?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <LiaAddressBook />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Address
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Street Address:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.address?.streetAddress}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Postal Address:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.address?.postalAddress}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    City:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.address?.city}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Region/State:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.address?.regionOrState}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Country:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {merchant?.address?.country}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">Categories Table</Typography>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              loading={isLoading}
              disableColumnSelector
              disableDensitySelector
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              rowCount={merchant?.categories?.length ?? 0}
              columns={columns}
              rows={merchant?.categories ?? []}
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
            />
          </Box>
        </Paper>

        {/* <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">Warehouses</Typography>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              disableColumnSelector
              disableDensitySelector
          slotProps={{
            panel: {
              sx: {
                top: "-120px !important",
              },
            },
          }}
              loading={isWarehouseLoading}
              rowCount={warehouseData?.length ?? 0}
              columns={warehouseColumns}
              rows={warehouseData ?? []}
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
            />
          </Box>
        </Paper> */}
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleMerchant;
