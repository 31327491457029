import React from 'react'
import { Box, FormControl, MenuItem, IconButton, InputAdornment, Select, TextField, InputLabel, } from "@mui/material"
import { weightMetrics } from './dropdownsData'
import Chip from '@mui/material/Chip';
import { FiTrash2 } from 'react-icons/fi';

const AvailableWeightComponent = ({ componentKey, setProductRequest, disabled, availableWeight }) => {

    const handleAvailableWeightChange = (event) => {
        const { name, value } = event.target;
        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableWeight: prevProduct.availableWeight.map((availableWeight, index) =>
                index === componentKey ? { ...availableWeight, [name]: value } : availableWeight
            ),
        }));
    };

    const handleRemoveAvailableWeight = () => {
        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableWeight: prevProduct.availableWeight.filter((_, index) => index !== componentKey),
        }));
    };

    return (
        <Box key={componentKey} sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="available-weight-label">Available weight</InputLabel>
                <Select
                    labelId="available-weight-label"
                    name="metric"
                    disabled={disabled}
                    value={availableWeight?.metric}
                    onChange={handleAvailableWeightChange}
                    label="Available weight"
                >
                    {weightMetrics.map(({ label, value }) => (<MenuItem value={value}>{label}</MenuItem>))}
                </Select>
            </FormControl>


            <TextField
                label="Weight Value"
                variant="outlined"
                rows={4}
                fullWidth
                disabled={disabled}
                value={availableWeight.value}
                name="value"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{weightMetrics.find((weightMetric) => weightMetric?.metric === availableWeight?.metric)?.label}</InputAdornment>
                }}
                onChange={handleAvailableWeightChange}
                size="small"
            />
            <Chip
                variant="outlined"
                color="error"
                label="Remove"
                deleteIcon={<FiTrash2 size={18} />}
                onDelete={handleRemoveAvailableWeight}
                onClick={handleRemoveAvailableWeight}
            />
        </Box>
    )
}

export default AvailableWeightComponent