import React from 'react'
import { Box, FormControl, MenuItem, IconButton, InputAdornment, Select, TextField, InputLabel, } from "@mui/material"
import { volumMetrics } from './dropdownsData'
import Chip from '@mui/material/Chip';
import { FiTrash2 } from 'react-icons/fi';

const AvailableVolumeComponent = ({ componentKey, setProductRequest, disabled, availableVolume }) => {

    const handleAvailableVolumnChange = (event) => {
        const { name, value } = event.target;
        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableVolume: prevProduct.availableVolume.map((availableVolume, index) =>
                index === componentKey ? { ...availableVolume, [name]: value } : availableVolume
            ),
        }));
    };

    const handleRemoveAvailableVolume = () => {
        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableVolume: prevProduct.availableVolume.filter((_, index) => index !== componentKey),
        }));
    };

    return (
        <Box key={componentKey} sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="available-volume-label">Available volume</InputLabel>
                <Select
                    labelId="available-volume-label"
                    name="metric"
                    value={availableVolume?.metric}
                    disabled={disabled}
                    onChange={handleAvailableVolumnChange}
                    label="Available volume"
                >
                    {volumMetrics.map(({ label, value }) => (<MenuItem value={value}>{label}</MenuItem>))}
                </Select>
            </FormControl>

            <TextField
                label="Volume Value"
                variant="outlined"
                disabled={disabled}
                rows={4}
                value={availableVolume?.value}
                fullWidth
                name="value"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{volumMetrics.find((weightMetric) => weightMetric?.value === availableVolume?.metric)?.prefix}</InputAdornment>
                }}
                onChange={handleAvailableVolumnChange}
                size="small"
            />
            <Chip
                variant="outlined"
                color="error"
                label="Remove"
                deleteIcon={<FiTrash2 size={15}/>}
                onDelete={handleRemoveAvailableVolume}
                onClick={handleRemoveAvailableVolume}
            />
        </Box>
    )
}

export default AvailableVolumeComponent