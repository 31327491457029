import { FiberManualRecord, WarningOutlined, Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const ActionDataTable = (handleDeleteAction) => {
  return [
   
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "commonName",
        flex: 1,
        headerAlign: "center",
        align: "center",
        filterable: true,
        headerName: "Common Name",
        valueGetter: ({ value }) => value ?? "N/A",
      },
    {
      field: "description",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Description",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "type",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        headerName: "Type",
        filterable: true,
        valueGetter: ({ value }) => value ?? "N/A",
      },
      {
        field: "created",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        headerName: "Date Created",
        filterable: true,
        valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
      },
      {
        field: "actionAttribute",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        headerName: "Action Attribute",
        filterable: true,
        valueGetter: ({ value }) => value ?? "N/A",
      },
    
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/* <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/loyalty/product/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip> */}
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              disabled={row?.paid}
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
