import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Grid, InputAdornment } from "@mui/material";
import { filesize } from "filesize";

const MerchantMediaModal = ({
  open,
  handleClose,
  merchantMedia,
  type,
  handleFormChange,
  handleSubmit,
}) => {
  const updaloadRef = React.useRef();

  const handleUploadClick = () => {
    updaloadRef.current.click();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle textAlign={"center"}>{`${type} MERCHANT MEDIA`}</DialogTitle>
      <DialogContent>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              ID: <span style={{ opacity: 0.7 }}>{merchantMedia?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File name:{" "}
              <span style={{ opacity: 0.7 }}>{merchantMedia?.filename}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File Url:{" "}
              <span style={{ opacity: 0.7 }}>{merchantMedia?.fileUrl}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File size:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantMedia?.size && filesize(merchantMedia?.size ?? 0)}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container padding={5}>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File name"
                name="filename"
                variant="outlined"
                value={merchantMedia?.filename}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File Url"
                name="fileUrl"
                required
                variant="outlined"
                value={merchantMedia?.fileUrl}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="MimeType"
                name="mimeType"
                variant="outlined"
                disabled
                placeholder="Example: image/png"
                value={merchantMedia?.mimeType}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File size"
                name="size"
                variant="outlined"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {merchantMedia?.size &&
                        filesize(merchantMedia?.size ?? 0)}
                    </InputAdornment>
                  ),
                }}
                value={merchantMedia?.size}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <input
              ref={updaloadRef}
              type="file"
              hidden
              name="media"
              onChange={handleFormChange}
            />
            <Button color="error" variant="contained" onClick={handleClose}>
              CANCEL
            </Button>
            <Button onClick={handleUploadClick} variant="contained">
              BROWSE
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantMediaModal;
