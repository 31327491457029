import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const LoadingPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {window.navigator.onLine ? (
        <CircularProgress color="primary" />
      ) : (
        <Typography variant={"h5"} color="red">
          Network error
        </Typography>
      )}
    </Box>
  );
};

export default LoadingPage;
