import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";


export const TransactionLimitsTable = (handleDeleteAction) => {
  return [
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "ID",
    },
    {
      field: "transactional",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Transactional",
    },
    {
      field: "daily",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Daily",
    },
    {
      field: "sendingCountryCode",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Sending Country",
    },
    {
      field: "merchantCategory",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      headerName: "Merchant Category",
    },
    {
      field: "enabled",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Enabled",
    },
    {
      field: "created",
      flex: 0.7,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/transaction/limit/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/transaction/limit/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
