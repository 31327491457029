import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useGetFormQuestionQuery } from "../../services/FormQuestionService";
import moment from "moment";

const FormAnswerModal = ({ open, onClose, formAnswer }) => {
  const { data: formQuestion } = useGetFormQuestionQuery(
    formAnswer?.formQuestionId
  );
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle>VIEW FORM ANSWER</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Id: <span style={{ opacity: 0.7 }}>{formAnswer?.id}</span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Form Question:{" "}
            <span style={{ opacity: 0.7 }}>
              {formQuestion?.hintText ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Form Answer:{" "}
            <span style={{ opacity: 0.7 }}>
              {formAnswer?.answerProvided ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Answer Bind Value:{" "}
            <span style={{ opacity: 0.7 }}>
              {formAnswer?.answerBindValue ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Path Attribute:{" "}
            <span style={{ opacity: 0.7 }}>
              {formAnswer?.pathAttribute ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Created:{" "}
            <span style={{ opacity: 0.7 }}>
              {formAnswer?.created
                ? moment(formAnswer?.created).format("LLLL")
                : "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Updated:{" "}
            <span style={{ opacity: 0.7 }}>
              {formAnswer?.updated
                ? moment(formAnswer?.updated).format("LLLL")
                : "N/A"}
            </span>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FormAnswerModal;
