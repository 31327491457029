import * as React from "react";
import {
  Button,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import {
  useCreateHttpHeaderMutation,
  useUpdateHttpHeaderMutation,
} from "../../services/HttpHeaderService";
import { ErrorToast, SuccessToast } from "../common/Toast";
const HttpHeaderDialog = ({ open, handleClose, selectedHttpHeader, type }) => {
  const [createHttpHeader] = useCreateHttpHeaderMutation();
  const [updateHttpHeader] = useUpdateHttpHeaderMutation();
  const [httpHeaderRequest, setHttpHeaderRequest] =
    React.useState(selectedHttpHeader);

  const handleHttpHeaderFormChange = (event) => {
    const { name, value } = event.target;
    setHttpHeaderRequest((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetForm = () => {
    setHttpHeaderRequest(null);
  };

  const handleCreateHttpHeader = async (event) => {
    event.preventDefault();
    await createHttpHeader(httpHeaderRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Header added succesfully");
          handleResetForm();
          handleClose();
        } else {
          ErrorToast("Failed to create header");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create header");
      });
  };
  const handleUpdateHttpHeader = async (event) => {
    event.preventDefault();
    await updateHttpHeader(httpHeaderRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Header updated succesfully");
          handleResetForm();
          handleClose();
        } else {
          ErrorToast("Failed to update header");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update header");
      });
  };

  React.useEffect(() => {
    setHttpHeaderRequest(selectedHttpHeader);
  }, [selectedHttpHeader]);

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>
          {selectedHttpHeader?.id ? "Edit Http Header" : "Add Http header"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Provide some header key and value
            </Typography>
          </DialogContentText>
          <Box sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}>
            <TextField
              fullWidth
              label="Header Key"
              variant="outlined"
              placeholder="Content-Type"
              value={httpHeaderRequest?.headerKey}
              name="headerKey"
              onChange={handleHttpHeaderFormChange}
              size="small"
            />

            <TextField
              fullWidth
              label="Header Value"
              variant="outlined"
              placeholder="application/json"
              value={httpHeaderRequest?.headerValue}
              name="headerValue"
              onChange={handleHttpHeaderFormChange}
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={
              (type === "UPDATE" && handleUpdateHttpHeader) ||
              (type === "ADD" && handleCreateHttpHeader)
            }
            variant="contained"
          >
            {type}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HttpHeaderDialog;
