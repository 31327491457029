import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Paper, Card, Button } from '@mui/material';
import { useGetOneLoyaltyTransactionQuery, useRefundLoyaltyTransactionMutation } from '../../services/loyalty/loyaltyTransaction';
import { ErrorToast, SuccessToast } from "../../components/common/Toast";

export const LoyaltyTransactionDetails = () => {
  const params = useParams()
  const id = params.id
  const navigate = useNavigate()
  const [refundLoyaltyTransaction] = useRefundLoyaltyTransactionMutation();

  const { data: loyaltyTransactionDetails, isLoading, isError } = useGetOneLoyaltyTransactionQuery(id);

  useEffect(() => {
    console.log("is id available:", id)
    console.log("data received:", loyaltyTransactionDetails)
  }, [id, loyaltyTransactionDetails])

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));



  const handleRefund = async (orderId) => {
    console.log("orderID",orderId)
    try {
      const response = await refundLoyaltyTransaction(loyaltyTransactionDetails?.order?.id);
      console.log("response", response)
      if (response.data.code == 0) {
          SuccessToast("Refund successfully ")
      } else {
          ErrorToast("Refund failed")
      }
  } catch (error) {
    console.log(error)
      ErrorToast('Error submitting form:', error);
  }
  }


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: Failed to fetch data</div>;
  }

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>

      <Box
        sx={{
          display: "inline",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >

        {isLoading ? ("loading...") : (
          <>
            <Button sx={{ borderRadius: "20px", margin: "0px 40px" }}
              variant="contained"
              color="primary" onClick={() => navigate("/pages/loyalty/transaction")}>
              Back
            </Button>

            <Card sx={{ width: "100%", py: "50px", px: "50px", background: "#f4f7fe" }}>
              <div style={{ margin: "20px 0px", fontWeight: "900" }}>Loyalty Transaction Detail</div>
              <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", alignContent: "center", mb: "20px" }}>
                <Button variant="outlined" onClick={()=>handleRefund(loyaltyTransactionDetails?.order?.id)} >Refund </Button>
              </Box>
              <Box
                key={loyaltyTransactionDetails?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                  padding: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  background: "white"
                }}
              >
                <div><span style={{ fontWeight: "900" }}>ID : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.id || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Opening Balance : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.openingBalance || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Closing Balance : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.closingBalance || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Account ID : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.accountId || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Loyalty Amount Used : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.loyaltyAmountUsed || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Type : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.type || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Type : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.id || "N/A"}</span></div>

              </Box>

              <Box
                key={loyaltyTransactionDetails?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                  padding: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  background: "white"
                }}
              >
                <div style={{ margin: "20px 0px", fontWeight: "900" }}>Order</div>

                <div><span style={{ fontWeight: "900" }}>Order Id : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.id || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order name : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.name || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order user Id : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.userId || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order  Session Id : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.orderSessionId || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order transaction Id : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.transactionId || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order active : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.active || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order paid : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.paid || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order category : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.category || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order sending Country Code : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.sendingCountryCode || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order sending Country Code : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.sendingCountryCode || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order deliveryAddress : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.deliveryAddress || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order exchange rate : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.exchangeInfo?.exchangeRate || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order exchange pair : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.exchangeInfo?.exchangePair || "N/A"}</span></div>
                <div><span style={{ fontWeight: "900" }}>Order exchange date : </span><span style={{ margin: "0px 10px" }}>{loyaltyTransactionDetails?.order?.exchangeInfo?.exchangeDate || "N/A"}</span></div>


              </Box>

              <Box
                key={loyaltyTransactionDetails?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                  padding: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  background: "white"
                }}
              >
                {loyaltyTransactionDetails?.order?.orderItems?.map((data, key) => (
                  <div key={key}>
                    <div style={{ margin: "20px 0px", fontWeight: "900" }}>Order Item</div>

                    <div><span style={{ fontWeight: "900" }}>Order Item id : </span><span style={{ margin: "0px 10px" }}>{data?.id || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item inventoryItemId : </span><span style={{ margin: "0px 10px" }}>{data?.inventoryItemId || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item productName : </span><span style={{ margin: "0px 10px" }}>{data?.productName || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item quantity : </span><span style={{ margin: "0px 10px" }}>{data?.quantity || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item amount : </span><span style={{ margin: "0px 10px" }}>{data?.merchandisePrice?.amount || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item currency : </span><span style={{ margin: "0px 10px" }}>{data?.merchandisePrice?.currency || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item exchangeInfo : </span><span style={{ margin: "0px 10px" }}>{data?.merchandisePrice?.exchangeInfo?.exchangeRate || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item exchangeInfo : </span><span style={{ margin: "0px 10px" }}>{data?.merchandisePrice?.exchangeInfo?.exchangePair || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item exchangeInfo : </span><span style={{ margin: "0px 10px" }}>{data?.merchandisePrice?.exchangeInfo?.exchangeDate || "N/A"}</span></div>

                    <div style={{ margin: "10px 0px 0px 0px" }}><span style={{ fontWeight: "900" }}>Order Item Merchandise Delivery Price  : </span><span style={{ margin: "0px 10px" }}>{data?.merchandiseDeliveryPrice?.amount || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Merchandise Delivery currency : </span><span style={{ margin: "0px 10px" }}>{data?.merchandiseDeliveryPrice?.currency || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Merchandise Delivery Exchange Rate : </span><span style={{ margin: "0px 10px" }}>{data?.merchandiseDeliveryPrice?.exchangeInfo?.exchangeRate || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Merchandise Delivery Exchange Pair: </span><span style={{ margin: "0px 10px" }}>{data?.merchandiseDeliveryPrice?.exchangeInfo?.exchangePair || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item merchandise Delivery Exchange Date : </span><span style={{ margin: "0px 10px" }}>{data?.merchandiseDeliveryPrice?.exchangeInfo?.exchangeDate || "N/A"}</span></div>

                    <div style={{ margin: "10px 0px 0px 0px" }}><span style={{ fontWeight: "900" }}>Order Item Amount Paid By Customer  : </span><span style={{ margin: "0px 10px" }}>{data?.amountPaidByCustomer?.amount || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Amount Paid currency : </span><span style={{ margin: "0px 10px" }}>{data?.amountPaidByCustomer?.currency || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Amount Paid Exchange Rate : </span><span style={{ margin: "0px 10px" }}>{data?.amountPaidByCustomer?.exchangeInfo?.exchangeRate || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Amount Paid Exchange Pair : </span><span style={{ margin: "0px 10px" }}>{data?.amountPaidByCustomer?.exchangeInfo?.exchangePair || "N/A"}</span></div>
                    <div><span style={{ fontWeight: "900" }}>Order Item Amount Paid Exchange Date : </span><span style={{ margin: "0px 10px" }}>{data?.amountPaidByCustomer?.exchangeInfo?.exchangeDate || "N/A"}</span></div>


                    <div style={{ margin: "30px" }}><span style={{ fontWeight: "900" }}>Form Answers </span></div>

                    <Box
                      key={loyaltyTransactionDetails?.id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "16px",
                        padding: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        background: "white",
                        maxHeight: '350px', overflowY: 'auto'
                      }}
                    >

                      {
                        data?.formAnswers.map((answers, key) => (
                          <div key={key} style={{ margin: "20px 0px 0px 0px" }}>
                            <div style={{ margin: "20px 0px", fontWeight: "900" }}>Form group {key + 1}</div>
                            <div><span style={{ fontWeight: "900" }}>Answer Id : </span><span style={{ margin: "0px 10px" }}>{answers?.id || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}> Answer sessionOrderItemId : </span><span style={{ margin: "0px 10px" }}>{answers?.sessionOrderItemId || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer formQuestionId : </span><span style={{ margin: "0px 10px" }}>{answers?.formQuestionId || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer answerProvided : </span><span style={{ margin: "0px 10px" }}>{answers?.answerProvided || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer formQuestionId : </span><span style={{ margin: "0px 10px" }}>{answers?.formQuestionId || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer pathAttribute : </span><span style={{ margin: "0px 10px" }}>{answers?.pathAttribute || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer created : </span><span style={{ margin: "0px 10px" }}>{answers?.created || "N/A"}</span></div>
                            <div><span style={{ fontWeight: "900" }}>Answer updated : </span><span style={{ margin: "0px 10px" }}>{answers?.updated || "N/A"}</span></div>
                          </div>
                        ))
                      }
                    </Box>
                  </div>
                ))}
              </Box>

            </Card>


          </>
        )}
      </Box>
    </Box>
  )
}
