import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";


const ViewSubCategory = ({ open, handleCloseView, selectedCategory }) => {

     return (
          <div>
               <Dialog
                    fullWidth
                    open={open}
                    onClose={handleCloseView}
                    sx={{ mx: "auto", my: "0px", }}
               >
                    <DialogTitle>View SubCategory Details</DialogTitle>
                    <DialogContent>

                         <TextField
                              disabled
                              autoFocus
                              margin="dense"
                              id="category"
                              label="Sub Category name"
                              required
                              type="text"
                              fullWidth
                              size="small"
                              name="label"
                              value={selectedCategory?.label}
                              variant="outlined"
                         />


                         <TextField
                              value={selectedCategory?.description}
                              fullWidth
                              multiline
                              size="small"
                              sx={{ my: "15px" }}
                              row={5}
                              disabled
                              name="description"
                              id={"category-description"}
                              label={"Description"}
                              type="text" />


                         <TextField
                              value={selectedCategory?.status}
                              fullWidth
                              disabled
                              multiline
                              size="small"
                              sx={{ my: "15px" }}
                              row={5}
                              name="status"
                              id={"category-status"}
                              label={"Status"}
                              type="text" />


                    </DialogContent>
                    <DialogActions>
                         <Button onClick={handleCloseView}
                         >Cancel</Button>

                    </DialogActions>
               </Dialog>
          </div>
     );
};

export default ViewSubCategory;
