import React, { useState } from "react";
import { MerchantDocumentTable } from "../data/MerchantDocumentTable";
import {
  useCreateMerchantDocumentMutation,
  useDeleteMerchantDocumentMutation,
} from "../services/MerchantDocumentService";
import { merchantDocument } from "../models/merchantDocument";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMerchantContext } from "../presentation/shared/hooks/useMerchantContext";
import { useParams } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import MerchantDocumentModal from "../components/modals/MerchantDocumentModal";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

export const EditMerchantDocument = () => {
  const dataGridService = new DataGridService("MerchantDocuments");
  const { id } = useParams();
  const { merchantData, isMerchantLoading, refetchMerchant } =
    useMerchantContext();
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] =
    useState(false);
  const [merchantDocumentRequest, setMerchantDocumentRequest] = useState({
    ...merchantDocument,
    merchantId: id,
  });
  const [type, setType] = useState("");
  const [
    createMerchantDocument,
    { isLoading: isMerchantDocumentCreateLoading },
  ] = useCreateMerchantDocumentMutation();
  const [
    deleteMerchantDocument,
    { isLoading: isMerchantDocumentDeleteLoading },
  ] = useDeleteMerchantDocumentMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleDownloadAction = (row) => {
    window.confirm("Are you sure you want to download this document file");
  };
  const handleDeleteAction = (row) => {
    setMerchantDocumentRequest(row);
    setOpenDeleteDocumentDialog(true);
  };
  const handleViewAction = (row) => {
    setType("VIEW");
    setMerchantDocumentRequest(row);
    setOpenDocumentDialog(true);
  };

  const handleShowAddMerchantDocumentDialog = () => {
    setType("ADD");
    setOpenDocumentDialog(true);
  };

  const handleCreateMerchantDocument = async (event) => {
    event.preventDefault();
    await createMerchantDocument(merchantDocumentRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Document uploaded succesfully");
          refetchMerchant();
        } else {
          ErrorToast("Failed to upload document");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to upload document");
      });
  };

  const handleDeleteMerchantDocument = async (event) => {
    event.preventDefault();
    await deleteMerchantDocument(merchantDocumentRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Document deleted succesfully");
          refetchMerchant();
        } else {
          ErrorToast("Failed to deleted document ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to deleted document");
      })
      .finally(() => {
        handleCloseDialog();
      });
  };

  const handleCloseDialog = () => {
    setOpenDocumentDialog(false);
    setMerchantDocumentRequest(merchantDocument);
  };

  const handleMerchantDocumentChange = (event) => {
    const { name, value, files } = event.target;
    if (["document"].includes(name)) {
      const selectedFile = files[0];

      setMerchantDocumentRequest((prev) => ({
        ...prev,
        media: {
          ...prev?.media,
          filename: selectedFile.name,
          fileUrl: URL.createObjectURL(selectedFile),
          mimeType: selectedFile.type,
          size: selectedFile.size,
        },
        merchantId: id,
      }));
    } else {
      if (["size", "filename", "mimeType", "fileUrl"].includes(name)) {
        setMerchantDocumentRequest((prev) => ({
          ...prev,
          media: {
            ...prev?.media,
            [name]: value,
          },
          merchantId: id,
        }));
      } else {
        setMerchantDocumentRequest((prev) => ({
          ...prev,
          [name]: value,
          merchantId: id,
        }));
      }
    }
  };

  const merchantDocumentTable = MerchantDocumentTable({
    handleDeleteAction,
    handleDownloadAction,
    handleViewAction,
  });
  return (
    <Grid item md={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Typography variant="h5">List of Merchant Documents</Typography>
        <Button
          onClick={handleShowAddMerchantDocumentDialog}
          variant="contained"
        >
          Add Document
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <DataGrid
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "gray",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slotProps={{
            panel: {
              sx: {
                top: "-120px !important",
              },
            },
          }}
          loading={isMerchantLoading}
          rowCount={merchantData?.regulatoryDocuments.length ?? 0}
          rows={merchantData?.regulatoryDocuments ?? []}
          columns={merchantDocumentTable}
          disableColumnSelector
          disableDensitySelector
          getRowId={(row) => row.id}
          slots={{ toolbar: GridToolbar }}
          sortModel={sortModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
        />
      </TableContainer>

      <MerchantDocumentModal
        open={openDocumentDialog}
        type={type}
        loading={
          isMerchantLoading ||
          isMerchantDocumentDeleteLoading ||
          isMerchantDocumentCreateLoading
        }
        merchantDocument={merchantDocumentRequest}
        handleClose={handleCloseDialog}
        handleFormChange={handleMerchantDocumentChange}
        handleSubmit={handleCreateMerchantDocument}
      />

      <CustomAlertDialog
        open={openDeleteDocumentDialog}
        title={"Delete Document"}
        icon={
          <IconButton color="error">
            <FiTrash2 />
          </IconButton>
        }
        handleClose={handleCloseDialog}
        subTitle={`Are you sure you want to delete this document 
          ${
            merchantDocumentRequest?.media?.filename ??
            merchantDocumentRequest?.media?.fileUrl
          }?`}
        fullWidth
        handleSubmit={handleDeleteMerchantDocument}
        positiveButtonText={"YES"}
        negativeButtonText={"NO"}
      />
    </Grid>
  );
};
