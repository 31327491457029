import { remittanceJobStatus } from "../components/common/dropdownsData";

export const remittanceJobColumns = [
  {
    accessorKey: "orderId",
    header: "Order Id",
  },
  {
    accessorKey: "orderItem",
    header: "Order Item",
  },
  {
    accessorKey: "dateCreated",
    header: "Request Date",
  },
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell, row }) => (
      <div>
        {row.original.status === "INIT" && (
          <span
            className="status"
            style={{ color: "#014d75", backgroundColor: "#c2eaff" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "ATTEMPTING" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fd433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "SENT" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fd433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "PENDING_RESPONSE" && (
          <span
            className="status"
            style={{ color: "#9e7703", backgroundColor: "#fad873" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "TIMED_OUT" && (
          <span
            className="status"
            style={{ color: "#ffbf0f", backgroundColor: "#ffde85" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "REQUEST_FAILED" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fe433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "FAILED_NO_RETRY_MAX_ATTEMPT_REACHED" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fe433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "FAILED_NO_RETRY_ENABLED" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fe433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "FAILED" && (
          <span
            className="status"
            style={{ color: "#fd4332", backgroundColor: "#fe433233" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
        {row.original.status === "SUCCESSFUL" && (
          <span
            className="status"
            style={{ color: "#03a329", backgroundColor: "#7ef79a" }}
          >
            {remittanceJobStatus[cell.getValue()]}
          </span>
        )}
      </div>
    ),
  },
  {
    accessorKey: "dateClosed",
    header: "Date Closed",
  },
];

export const remittanceJobs = [
  {
    dateCreated: "January 1, 2021 10:00 AM",
    dateClosed: "Febuary 1, 2022 10:00 AM",
    orderId: 12,
    orderItem: 79,
    remittanceConfigurationId: 20,
    status: "FAILED",
    closed: false,
  },
  {
    dateCreated: "January 1, 2021 10:00 AM",
    dateClosed: "Febuary 1, 2022 10:00 AM",
    orderId: 12,
    orderItem: 79,
    remittanceConfigurationId: 20,
    status: "SUCCESSFUL",
    closed: false,
  },
  {
    dateCreated: "January 1, 2021 10:00 AM",
    dateClosed: "Febuary 1, 2022 10:00 AM",
    orderId: 12,
    orderItem: 23,
    remittanceConfigurationId: 20,
    status: "INIT",
    closed: false,
  },
  {
    dateCreated: "January 1, 2021 10:00 AM",
    dateClosed: "Febuary 1, 2022 10:00 AM",
    orderId: 12,
    orderItem: 23,
    remittanceConfigurationId: 20,
    status: "PENDING_RESPONSE",
    closed: false,
  },
];
