import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Grid,
  Select,
  Typography,
} from "@mui/material";
import { useGetAllCurrencyQuery } from "../../services/CurrencyService";
import queryString from "query-string";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";

const ForexModal = ({
  open,
  handleClose,
  forex,
  type,
  handleFormChange,
  handleSubmitForex,
}) => {
  const filter = queryString.stringify({ size: 10000 });
  const printRef = React.useRef();
  const { data: currencies } = useGetAllCurrencyQuery(filter);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle> {`${type} FOREX`}</DialogTitle>
        {type === "VIEW" && (
          <ReactToPrint
            trigger={() => (
              <Button>
                <Print />
              </Button>
            )}
            content={() => printRef.current}
          />
        )}
      </Box>
      <DialogContent ref={printRef}>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Id: <span style={{ opacity: 0.7 }}>{forex?.id ?? "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Home currency:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.homeCurrency ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Foreign currency:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.foreignCurrency ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Selling rate:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.sellingRate ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Buying rate Denomination:{" "}
              <span style={{ opacity: 0.7 }}>{forex?.buyingRate ?? "N/A"}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Major Denomination:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.minorDenomination ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Created:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.created ? moment(forex?.created).format("LLLL") : "N/A"}
              </span>
            </Typography>

            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Updated:{" "}
              <span style={{ opacity: 0.7 }}>
                {forex?.updated ? moment(forex?.created).format("LLLL") : "N/A"}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={1} padding={1}>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">
                  Home Currency
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Home Currency"
                  name="homeCurrency"
                  value={forex?.homeCurrency}
                  onChange={handleFormChange}
                >
                  {currencies &&
                    currencies?.map((currency, index) => (
                      <MenuItem key={index} value={currency.initial}>
                        {`${currency.name} (${currency.initial})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-label">
                  Foreign Currency
                </InputLabel>
                <Select
                  label="Foreign Currency"
                  name="foreignCurrency"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={forex?.foreignCurrency}
                  onChange={handleFormChange}
                >
                  {currencies &&
                    currencies?.map((currency, index) => (
                      <MenuItem key={index} value={currency.initial}>
                        {`${currency.name} (${currency.initial})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                label="Selling Rate"
                name="sellingRate"
                variant="outlined"
                value={forex?.sellingRate}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                label="Buying Rate"
                name="buyingRate"
                variant="outlined"
                value={forex?.buyingRate}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            {type === "UPDATE" && (
              <Grid item md={12} xs={12} sm={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name="enabled"
                        checked={forex?.enabled}
                        onChange={handleFormChange}
                      />
                    }
                    label="Enabled"
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>
            <Button onClick={handleSubmitForex} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ForexModal;
