import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetTransactionQuery } from "../services/TransactionService";
import moment from "moment/moment";
import { Print, StorageRounded } from "@mui/icons-material";
import LoadingPage from "../components/LoadingPage";
import { RiExchangeBoxFill } from "react-icons/ri";
import { KeyboardBackspace } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  accountTypes,
  userTypes,
  transactionTypes,
} from "../components/common/dropdownsData";
import ReactToPrint from "react-to-print";

const SingleTransaction = () => {
  const { id } = useParams();
  const { data } = useGetTransactionQuery(id);
  const navigate = useNavigate();
  const printRef = useRef();

  if (data) {
    const { data: transaction } = data;
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              mb={3}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Transaction Details{" "}
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
            ref={printRef}
          >
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <RiExchangeBoxFill />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Transaction
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID: <span style={{ opacity: 0.7 }}>{transaction?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order ID: <span style={{ opacity: 0.7 }}>{transaction?.orderId}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Payment Processing ID:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.paymentProcessingId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Amount:{" "}
                    <span
                      style={{ opacity: 0.7 }}
                    >{`${transaction?.monetaryAmount?.currency} ${transaction?.monetaryAmount?.amount}`}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Transaction Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transactionTypes[transaction?.type]}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Exchange Pair:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.monetaryAmount?.exchangeInfo?.exchangePair}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Exchange Rate:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.monetaryAmount?.exchangeInfo?.exchangeRate}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Status:{" "}
                    <span style={{ opacity: 0.7 }}>{transaction?.status}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Reference:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.reference}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(transaction?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(transaction?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>{" "}
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <StorageRounded />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Sender
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.sender?.name}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Number:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.sender?.accountNumber}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {accountTypes[transaction?.sender?.accountType]}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Source:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.sender?.accountSource}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    User Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {userTypes[transaction?.sender?.userType]}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <StorageRounded />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Recipient
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.recipient?.name}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Number:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.recipient?.accountNumber}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {accountTypes[transaction?.recipient?.accountType]}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Account Source:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {transaction?.recipient?.accountSource}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    User Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {userTypes[transaction?.recipient?.userType]}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleTransaction;
