import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { merchantMediaUrl } from "../presentation/shared/ApiUrls";

export const merchantMediaApi = createApi({
  reducerPath: "merchantMediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: merchantMediaUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MerchantMedia", "Merchants"],
  endpoints: (builder) => ({
    getAllMerchantMedia: builder.query({
      query: (filterMerchantMediaRquest) => ({
        url: `/media?${filterMerchantMediaRquest}`,
        method: "GET",
      }),
      providesTags: ["MerchantMedia"],
      transformResponse: (response) => response.data.data,
    }),
    getMerchantMedia: builder.query({
      query: (id) => ({
        url: `/media/${id}`,
        method: "GET",
      }),
      providesTags: ["MerchantMedia"],
    }),
    searchMerchantMedia: builder.mutation({
      query: (filterMerchantMediaRquest) => ({
        url: `/media?${filterMerchantMediaRquest}`,
        method: "GET",
      }),
      invalidatesTags: ["MerchantMedia"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    createMerchantMedia: builder.mutation({
      query: (addMerchantMediaRequest) => ({
        url: "/media",
        method: "POST",
        body: addMerchantMediaRequest,
      }),
      invalidatesTags: ["MerchantMedia", "Merchants"],
    }),
    deleteMerchantMedia: builder.mutation({
      query: (id) => ({
        url: `/media/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MerchantMedia", "Merchants"],
    }),
  }),
});

export const {
  useGetAllMerchantMediaQuery,
  useSearchMerchantMediaMutation,
  useGetMerchantMediaQuery,
  useCreateMerchantMediaMutation,
  useDeleteMerchantMediaMutation,
} = merchantMediaApi;
