import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoyaltyUrl } from "../../presentation/shared/ApiUrls";
import { queryStringOptions } from "../../core/queryStringOptions";
import queryString from "query-string";

export const ProductApiService = createApi({
  reducerPath: "Product",
  baseQuery: fetchBaseQuery({
    baseUrl: LoyaltyUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => ({
        url: `/loyalty/products`,
        method: "GET",
      }),
      providesTags: ["Product"],
      transformResponse: (response) => response.data.data,
    }),
    getProduct: builder.query({
      query: (id) => ({
        url: `/loyalty/products/${id}`,
        method: "GET",
      }),
      providesTags: ["Product"],
      transformResponse: (response) => response.data,
    }),
    addProduct: builder.mutation({
      query: (body) => ({
        url: `/loyalty/products`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Product"],
    }),
    updateProduct: builder.mutation({
      query: (body) => ({
        url: `/loyalty/products`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Product"],
    }),

    // getMemberAccount: builder.query({
    //     query: (id) => ({
    //       url: `/loyalty/members/${id}`,
    //       method: "GET",
    //     }),
    //     providesTags: ["ProgramMembers"],
    //     transformResponse: (response) => response.data,
    //   }),
    // getOrder: builder.query({
    //   query: (id) => ({
    //     url: `/orders/${id}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Orders"],
    //   transformResponse: (response) => response.data,
    // }),
    // getTotalOrder: builder.query({
    //   query: (id) => ({
    //     url: `/orders/total`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Orders"],
    //   transformResponse: (response) => response.data,
    // }),
    // updateOrder: builder.mutation({
    //   query: (updateOrderRequest) => ({
    //     url: "/orders",
    //     method: "PUT",
    //     body: updateOrderRequest,
    //   }),
    //   invalidatesTags: ["Orders"],
    // }),
    // createOrder: builder.mutation({
    //   query: (addOrderRequest) => ({
    //     url: "/orders",
    //     method: "POST",
    //     body: addOrderRequest,
    //   }),
    //   invalidatesTags: ["Orders"],
    // }),
    // deleteProgramMember: builder.mutation({
    //   query: (id) => ({
    //     url: `/loyalty/members/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["ProgramMembers"],
    // }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/loyalty/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const {
 useGetAllProductsQuery,
 useGetProductQuery,
 useAddProductMutation,
 useDeleteProductMutation,
 useUpdateProductMutation,
} = ProductApiService;
