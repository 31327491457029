import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteTransactionLimitsMutation,
  useSearchTransactionLimitsQuery,
} from "../services/TransactionLimitService";

import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { TransactionLimitsTable } from "../data/TransactionLimitsTable";
import queryString from "query-string";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const TransactionLimits = () => {
  const dataGridService = new DataGridService("TransactionLimits");

  const [filter, setFilter] = useState({});

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useSearchTransactionLimitsQuery(filter);

  const [deleteTransactionLimit, { isSuccess: isTransactionDeleteSuccess }] =
    useDeleteTransactionLimitsMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction(null);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedTransaction(row);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleSubmit = async () => {
    await deleteTransactionLimit(selectedTransaction.id)
      .then((response) => {
        if (response?.message !== "Failed") {
          SuccessToast("Transaction Limit deleted succesfully");
          setOpen(false);
        } else {
          ErrorToast("Failed to delete Transaction Limit");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Transaction Limit");
      });
  };

  const handleSelectedRowsChange = (selection) => {
    setSelectedRows(selection);
  };

  const columns = TransactionLimitsTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isTransactionDeleteSuccess]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Transaction Limits</Typography>
          <Link
            to="/pages/transaction/limit/add"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
            >
              Add Limit
            </Button>
          </Link>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Transaction"}
          subTitle={`Are you sure you want to delete ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              paginationMode="server"
              loading={isLoading}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              rowCount={data?.data?.totalElements ?? 0}
              columns={columns}
              rows={data?.data?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
              onRowSelectionModelChange={handleSelectedRowsChange}
            />
          </TableContainer>
        </Box>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Transaction Limit"}
        subTitle={`Are you sure you want to delete ${selectedTransaction?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
    </SecuredComponent>
  );
};

export default TransactionLimits;
