import React from "react";
import {
  Box,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from "@mui/material";

const ViewAvailableWeightComponent = ({ availableWeight }) => {
  return (
    <Box sx={{ display: "flex", mt: 1, alignItems: "center", gap: 2 }}>
      <Paper>
        <Typography variant="h6">Weight</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableWeight.map((measurement, index) => (
              <TableRow hover key={index}>
                <TableCell>{measurement.metric}</TableCell>
                <TableCell>{measurement.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default ViewAvailableWeightComponent;
