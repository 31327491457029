import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";

const CustomAlertDialog = ({
  open,
  handleClose,
  handleSubmit,
  icon,
  title,
  subTitle,
  content,
  negativeButtonText,
  positiveButtonText,
  fullWidth,
}) => {

  return (
      <Dialog
        open={open}
        fullWidth={fullWidth}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>
          <IconButton
            sx={{
              color: "#049bf5",
              backgroundColor: "rgba(4,155,245, 0.2) !important",
            }}
          >
            {icon}
          </IconButton>{" "}
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              {subTitle}
            </Typography>
          </DialogContentText>
        </DialogContent>
        {content}
        <DialogActions>
          <Button
            onClick={handleClose}
            color="error"
            variant="contained"
          >
            {negativeButtonText}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {positiveButtonText}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default CustomAlertDialog;
