import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import {
  useCreateCountryMutation,
  useDeleteCountryMutation,
  useSearchCountriesQuery,
  useUpdateCountryMutation,
} from "../services/CountryService";
import { CountriesTable } from "../data/CountriesTable";
import { getEmojiFlag } from "countries-list";
import CountryModal from "../components/modals/CountryModal";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DomainType } from "../presentation/helpers";
import SecuredComponent from "../components/common/SecuredComponent";
import { DataGridService } from "../core/dataGridService";

const Countries = () => {
  const dataGridService = new DataGridService("Countries");
  const [createCountry, { isSuccess: isCountryCreateSuccess }] =
    useCreateCountryMutation();
  const [filter, setFilter] = useState({});

  const [updateCountry, { isSuccess: isCountryUpdateeSuccess }] =
    useUpdateCountryMutation();

  const { data: countryData, isLoading } = useSearchCountriesQuery(filter);
  const [deleteCountry, { isSuccess: isCountryDeletedSuccess }] =
    useDeleteCountryMutation();

  const [countryRequest, setCountryRequest] = useState(null);
  const [type, setType] = useState("");

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );

  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [openCountryModal, setOpenCountryModal] = useState(false);

  const [open, setOpen] = useState(false);

  const handleCloseCountryModal = () => {
    setOpenCountryModal(false);
    setCountryRequest(null);
  };
  const handleOpenCountryModal = () => {
    setType("ADD");
    setOpenCountryModal(true);
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["callingCodeExcludeLeadingZero"].includes(name)) {
      setCountryRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (["isoCode"].includes(name)) {
      setCountryRequest((prev) => ({ ...prev, flag: value, [name]: value }));
    } else {
      setCountryRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOpenCountryModal(false);
    setCountryRequest(null);
  };

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",

    }));
  };

  const handleSubmit = async () => {
    await deleteCountry(countryRequest.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Country deleted succesfully");
        } else {
          ErrorToast(
            "Failed to delete Country " + JSON.stringify(response.error)
          );
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Country" + JSON.stringify(error));
      })
      .finally(() => {
        setOpen(false);
        setCountryRequest(null);
      });
  };

  const handleUpdateCountry = async () => {
    await updateCountry(countryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Country updated succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to update Country ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Country");
      });
  };
  const handleDeleteAction = async (row) => {
    setCountryRequest(row);
    setOpen(true);
  };

  const handleEditAction = (row) => {
    setType("UPDATE");
    setCountryRequest(row);
    setOpenCountryModal(true);
  };
  const handleViewAction = (row) => {
    setType("VIEW");
    setCountryRequest(row);
    setOpenCountryModal(true);
  };

  const columns = CountriesTable({
    handleViewAction,
    handleEditAction,
    handleDeleteAction,
  });

  const handleSubmitCountry = async () => {
    await createCountry(countryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Country created succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to create Country");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Country");
      });
  };

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Countries</Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
            onClick={handleOpenCountryModal}
          >
            Add Country
          </Button>
        </Box>
        <CountryModal
          open={openCountryModal}
          type={type}
          country={countryRequest}
          handleClose={handleCloseCountryModal}
          handleFormChange={handleFormChange}
          handleSubmitCountry={
            type === "ADD" ? handleSubmitCountry : handleUpdateCountry
          }
        />
        <CustomAlertDialog
          open={open}
          handleClose={handleCloseDialog}
          handleSubmit={handleSubmit}
          title={"Delete Country"}
          subTitle={`Are you sure you want to delete ${
            countryRequest?.name
          } ${getEmojiFlag(countryRequest?.isoCode)}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            autoHeight
            filterMode="server"
            paginationMode="server"
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={countryData?.size ?? 0}
            columns={columns}
            rows={countryData?.data ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </Box>
      </Box>
    </SecuredComponent>
  );
};
export default Countries;
