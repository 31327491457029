import { Box } from "@mui/system";
import { IconButton, Switch, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import moment from "moment";

export const DevicesTable = (
  handleDeleteAction,
  handleViewDevice,
  handleUpdateStatus
) => {
  return [
    {
      field: "username",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Device Owner",
    },

    {
      field: "brand",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Brand",
    },
    {
      field: "model",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Model",
    },
    {
      field: "enabled",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Enabled",
    },
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Date",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton color="primary" onClick={() => handleViewDevice(row)}>
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit status">
            <Switch
              onChange={() => handleUpdateStatus(row)}
              defaultChecked={row?.enabled}
            />
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
