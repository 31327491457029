import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { disputeUrl } from "../../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../../core/queryStringOptions";

export const caseApi = createApi({
  reducerPath: "caseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: disputeUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),

  refetchOnReconnect: true,
  
  tagTypes: ["Cases"],
  endpoints: (builder) => ({
    getAllCases: builder.query({
      query: () => ({
        url: `/cases`,
        method: "GET",
      }),
      providesTags: ["Cases"],
      transformResponse: (response) => response.data.data,
    }),
    getCase: builder.query({
      query: (id) => ({
        url: `/cases/${id}`,
        method: "GET",
      }),
      providesTags: ["Cases"],
      transformResponse: (response) => response?.data,
    }),
    searchCases: builder.query({
      query: (filterCasesRequest) => ({
        url: `/cases?${queryString.stringify(
          filterCasesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Cases"],
      transformResponse: (response) => ({
        data: response?.data?.data,
        size: response?.data.totalElements,
      }),
    }),

    updateCase: builder.mutation({
      query: (updateCaseRequest) => ({
        url: "/cases",
        method: "PUT",
        body: updateCaseRequest,
      }),
      invalidatesTags: ["Cases"],
    }),
    createCase: builder.mutation({
      query: (createCaseRequest) => ({
        url: "/cases",
        method: "POST",
        body: createCaseRequest,
      }),
      invalidatesTags: ["Cases"],
    }),
    deleteCase: builder.mutation({
      query: (id) => ({
        url: `/cases/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cases"],
    }),
  }),
});

export const {
  useGetAllCasesQuery,
  useGetCaseQuery,
  useSearchCasesQuery,
  useCreateCaseMutation,
  useUpdateCaseMutation,
  useDeleteCaseMutation,
} = caseApi;
