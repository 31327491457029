import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { remittanceInstitutionTypes } from "../components/common/dropdownsData";
import moment from "moment";

export const RemittanceInstitutionTable = (
  handleDeleteAction,
  handleViewAction,
  handleEditAction
) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "ID",
    },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "label",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Label",
    },
    {
      field: "institutionType",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Institution Type",
      valueGetter: ({ row }) => remittanceInstitutionTypes[row.institutionType],
    },
    {
      field: "enabled",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Enabled",
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
