import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { accountTypes } from "../components/common/dropdownsData";
import { useCreateRemittanceConfigurationMutation } from "../services/RemittanceConfigurationService";
import { useGetAllRemittanceInstitutionsQuery } from "../services/RemittanceInstitutionService";
import { useGetAllMerchantsQuery } from "../services/MerchantService";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import queryString from "query-string";

const AddRemittanceConfiguration = () => {
  const [createRemittanceConfiguration] =
    useCreateRemittanceConfigurationMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  const navigate = useNavigate();

  const INITIAL_DATA = {
    merchantId: "",
    account: "",
    institutionId: "",
    accountType: "",
    enabled: false,
    retryEnabled: false,
    statusesRetried: "INIT",
    maxRetryAttempt: 0,
  };

  const MAXDATA = queryString.stringify({ size: 10000 });

  const [formData, setFormData] = useState(INITIAL_DATA);
  const { data: merchantsData } = useGetAllMerchantsQuery(MAXDATA);
  const [merchants, setMerchants] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const { data: institutionsData } =
    useGetAllRemittanceInstitutionsQuery(MAXDATA);
  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  useEffect(() => {
    setMerchants(merchantsData);
    setInstitutions(institutionsData?.data?.data);
  }, [merchantsData, institutionsData]);
  const handleEnableSwitch = () => {
    updateFields({ enabled: !formData.enabled });
  };

  const handleSubmitConfiguration = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    const createRemittanceConfigurationRequest = {
      merchantId: formData.merchantId,
      account: formData.account,
      institutionId: formData.institutionId,
      accountType: formData.accountType,
      enabled: formData.enabled,
      statusesRetried: formData.statusesRetried,
      maxRetryAttempt: formData.maxRetryAttempt,
      retryEnabled: formData.retryEnabled,
    };

    await createRemittanceConfiguration(createRemittanceConfigurationRequest)
      .then((response) => {
        if (response?.data?.data) {
          SuccessToast("Configuration created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Configuration");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <KeyboardBackspace /> BACK
        </Button>
        <Typography size="small" variant="h5">
          Add Configuration
        </Typography>
      </Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Merchant</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.merchantId}
              label="Merchant Category"
              name="merchantCategory"
              onChange={(e) => updateFields({ merchantId: e.target.value })}
            >
              {merchants?.map((type, i) => (
                <MenuItem key={i} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Account"
            variant="outlined"
            name="ordername"
            value={formData.account}
            onChange={(e) => updateFields({ account: e.target.value })}
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Account Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.accountType}
              label="Account Type"
              name="accountType"
              onChange={(e) => updateFields({ accountType: e.target.value })}
            >
              {Object.keys(accountTypes).map((type, i) => (
                <MenuItem key={i} value={type}>
                  {accountTypes[type]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Institutions</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.institutionId}
              label="Merchant Category"
              name="merchantCategory"
              onChange={(e) => updateFields({ institutionId: e.target.value })}
            >
              {institutions?.map((type, i) => (
                <MenuItem key={i} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mt: 4, display: "flex", gap: 4 }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Enable"
              checked={formData.enabled}
              onChange={handleEnableSwitch}
            />
          </FormGroup>
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitConfiguration}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddRemittanceConfiguration;
