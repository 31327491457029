import {
  Box,
  Grid,
  Paper,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import React, { useState } from "react";
import { useCreateProductConfigurationMutation } from "../services/ProductConfigurationService";
import { Link, useParams } from "react-router-dom";
import { createProductConfigurationRequest } from "../models/productConfiguration";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const SingleProductConfiguration = ({ productConfig }) => {
  const { id } = useParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [productConfigRequest, setProductConfigRequest] = useState(
    createProductConfigurationRequest
  );
  const [addProductConfig, { data: productResponse, isLoading }] =
    useCreateProductConfigurationMutation();

  const handleProductConfigChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (
      [
        "inputWeightRequired",
        "inputVolumeRequired",
        "inputMeasurementRequired",
        "inputDeliveryContactRequired",
        "inputDeliveryAddressRequired",
        "enabled",
      ].includes(name)
    ) {
      setProductConfigRequest((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: checked,
      }));
    } else {
      setProductConfigRequest((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: value,
      }));
    }
  };

  const handleOnCloseAddProductConfigModal = () => {
    setOpenAddModal(false);
  };
  const handleSubmitProductConfig = async (event) => {
    event.preventDefault();

    productConfigRequest.serviceId = id;
    await addProductConfig(productConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product configuration created succesfully");
        } else {
          ErrorToast("Failed to create product configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create product configuration ");
      })
      .finally(() => {
        setOpenAddModal(false);
      });
  };
  if (productConfig) {
    return (
      <>
        <Box sx={{ pb: "20px" }}>
          <Paper
            sx={{
              fontSize: "25px",
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12} md={6}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  {productConfig?.refundPolicy}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12} md={6}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  {productConfig?.retryPolicy}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputWeightRequired"
                    checked={productConfig?.inputWeightRequired}
                    disabled
                    control={<Checkbox />}
                    label="Does it input require weight?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputVolumeRequired"
                    checked={productConfig?.inputVolumeRequired}
                    control={<Checkbox />}
                    disabled
                    label="Does it input require volume?"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputMeasurementRequired"
                    checked={productConfig?.inputMeasurementRequired}
                    control={<Checkbox />}
                    disabled
                    label="Does it input require dimensions?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      name="inputDeliveryContactRequired"
                      checked={productConfig?.inputDeliveryContactRequired}
                      control={<Checkbox />}
                      disabled
                      label="Does it require delivery contact?"
                    />
                  </span>
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputDeliveryAddressRequired"
                    checked={productConfig?.inputDeliveryAddressRequired}
                    control={<Checkbox />}
                    disabled
                    label="Does it require deliry address?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="enabled"
                    checked={productConfig?.enabled}
                    disabled
                    control={<Checkbox />}
                    label="Do want to enable this service?"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Link to={`/pages/product/edit/${id}`}>
          <Button color="primary" variant="outlined">
            Add Configuration
          </Button>
        </Link>
        <Dialog
          fullWidth
          open={openAddModal}
          onClose={handleOnCloseAddProductConfigModal}
          sx={{ mx: "auto" }}
        >
          <DialogTitle>
            <IconButton color={"primary"}>
              <Settings />
            </IconButton>{" "}
            Add Configuration
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12} md={6}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2, fontWeight: "bold" }}
                >
                  Refund Policy: {productConfig?.refundPolicy}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={12} md={6}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2, fontWeight: "bold" }}
                >
                  Retry Policy:{productConfig?.retryPolicy}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputWeightRequired"
                    checked={productConfigRequest?.inputWeightRequired}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Does input require weight?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputVolumeRequired"
                    checked={productConfigRequest?.inputVolumeRequired}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Does input require volume?"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputMeasurementRequired"
                    checked={productConfigRequest?.inputMeasurementRequired}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Does input require dimensions?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      name="inputDeliveryContactRequired"
                      checked={
                        productConfigRequest?.inputDeliveryContactRequired
                      }
                      onChange={handleProductConfigChange}
                      control={<Checkbox />}
                      label="Does require delivery contact?"
                    />
                  </span>
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="inputDeliveryAddressRequired"
                    checked={productConfig?.inputDeliveryAddressRequired}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Does it require delivery address?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    name="enabled"
                    checked={productConfig?.enabled}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Do want to enable this service?"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOnCloseAddProductConfigModal}>Cancel</Button>
            <Button onClick={handleSubmitProductConfig} variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

export default SingleProductConfiguration;
