import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  useGetAllOrdersQuery,
  useGetOrderQuery,
} from "../services/OrderService";
import {
  useGetRemittanceJobQuery,
  useUpdateRemittanceJobMutation,
} from "../services/RemittanceJobService";
import { useGetAllRemittanceConfigurationsQuery } from "../services/RemittanceConfigurationService";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { remittanceJobStatus } from "../components/common/dropdownsData";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const INITIAL_DATA = {
  id: "",
  dateClosed: "",
  orderId: "",
  orderItemId: "",
  remittanceConfigurationId: "",
  status: "",
  closed: false,
  userPOId: "string",
};

const EditRemittanceJob = () => {
  const { id } = useParams();
  const [updateRemittanceJob] = useUpdateRemittanceJobMutation();
  let {
    isLoading,
    data: jobData,
    isSuccess: isMerchantSuccess,
  } = useGetRemittanceJobQuery(id);
  const { isLoading: orderLoading, data: singleOrderData } = useGetOrderQuery(
    jobData?.data?.orderId
  );

  const [jobRequest, setJobRequest] = useState(null);
  const { isLoading: configs, data: configurations } =
    useGetAllRemittanceConfigurationsQuery();
  const { isLoading: ordersLoading, data: orders } = useGetAllOrdersQuery();
  const [orderIds, setOrderIds] = useState(null);
  const [configIds, setConfigIds] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const navigate = useNavigate();

  const updateFields = (fields) => {
    setJobRequest((prev) => ({ ...prev, ...fields }));
  };

  const handleOrderIdChange = (e) => {
    updateFields({ orderId: e.target.value.id });
    setSelectedOrder(e.target.value);
  };

  const handleSubmitJob = async (event) => {
    event.preventDefault();

    await updateRemittanceJob(jobRequest)
      .then((response) => {
        if (response?.data.data) {
          SuccessToast("Remittance job updated succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to update Remittance job");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Remittance job");
      });
  };

  const setIds = () => {
    setConfigIds(configurations?.data?.data);
    setOrderIds(orders?.data?.data);
  };

  useEffect(() => {
    setIds();
  }, [configurations, orders]);

  const initJobRequest = () => {
    if (jobData) {
      setJobRequest(jobData.data);
    }
  };
  const initOrder = () => {
    if (singleOrderData) {
      setSelectedOrder(singleOrderData);
    }
  };

  useEffect(() => {
    initJobRequest();
  }, [jobData]);

  useEffect(() => {
    initOrder();
  }, [singleOrderData]);

  if (jobRequest) {
    let { remittanceConfigurationId, closed, orderItemId, status, userPOId } =
      jobRequest;

    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Typography variant="h6" sx={{ marginBottom: "14px" }}>
          Edit Remittance Job
        </Typography>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "800px",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Order</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedOrder}
                label="orderId"
                name="orderid"
                onChange={handleOrderIdChange}
              >
                {orderIds?.map((order, i) => (
                  <MenuItem key={i} value={order}>
                    {order.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Order Item Id
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={orderItemId}
                label="orderItemId"
                name="orderItemId"
                onChange={(e) => updateFields({ orderItemId: e.target.value })}
              >
                {selectedOrder?.orderItems?.map((order, i) => (
                  <MenuItem key={i} value={order.id}>
                    {order.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Remittance Configuration
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={remittanceConfigurationId}
                label="Account Type"
                name="accountType"
                onChange={(e) => {
                  updateFields({ remittanceConfigurationId: e.target.value });
                }}
              >
                {configurations?.data?.data.map((config, i) => (
                  <MenuItem key={i} value={config.id}>
                    {config.account}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Status</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={status}
                label="Status"
                name="status"
                onChange={(e) => {
                  updateFields({ status: e.target.value });
                }}
              >
                {Object.keys(remittanceJobStatus).map((status, i) => (
                  <MenuItem key={i} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <TextField
                label="UserPO Id"
                value={userPOId}
                onChange={(e) => updateFields({ userPOId: e.target.value })}
                fullWidth
                size="small"
              />
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex" }}
              required
            >
              <FormGroup className="mt-5">
                <FormControlLabel
                  control={<Switch />}
                  label="Closed"
                  checked={closed}
                  onChange={() => {
                    updateFields({ closed: !jobRequest.closed });
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "30px",
            }}
          >
            <Button
              onClick={handleSubmitJob}
              variant="contained"
              sx={{ borderRadius: "20px" }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditRemittanceJob;
