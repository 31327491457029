import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetInventoryQuery } from "../services/InventoryService";
import moment from "moment/moment";
import {
  InventoryRounded,
  KeyboardBackspace,
  Print,
  StorageRounded,
} from "@mui/icons-material";
import LoadingPage from "../components/LoadingPage";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

const SingleInventory = () => {
  const { id } = useParams();
  const { data: inventoryData } = useGetInventoryQuery(id);
  const printRef = useRef();
  const navigate = useNavigate();

  if (inventoryData) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <Typography
              variant="h5"
              mb={3}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Inventory Details
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
            ref={printRef}
          >
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <InventoryRounded />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Inventory
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Merchant ID:{" "}
                    <span style={{ opacity: 0.7 }}>{inventoryData.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Price:{" "}
                    <span
                      style={{ opacity: 0.7 }}
                    >{`${inventoryData.price.currency} ${inventoryData.price.amount}`}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Active:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {inventoryData.active ? (
                        <b style={{ color: "blue" }}>Yes</b>
                      ) : (
                        <b style={{ color: "red" }}>No</b>
                      )}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(inventoryData.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(inventoryData.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <StorageRounded />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Warehouse
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Warehouse ID:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {inventoryData.warehouse.id}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Warehouse Name:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {inventoryData.warehouse.name}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Default Warehouse:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {inventoryData.warehouse.defaultWarehouse ? (
                        <b style={{ color: "blue" }}>Yes</b>
                      ) : (
                        <b style={{ color: "red" }}>No</b>
                      )}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleInventory;
