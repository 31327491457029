import React from "react";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  FormGroup,
  InputLabel,
  Select,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import {
  formAnswerTypes,
  numericalOrders,
  questionTypes,
  validationTypes,
} from "../components/common/dropdownsData";
import { Add } from "@mui/icons-material";
import { Item } from "../components/common/Item";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FormQuestionsTable } from "../data/FormQuestionsTable";
import { createFormQuestionRequest } from "../models/formQuestion";
import {
  useCreateFormQuestionMutation,
  useDeleteFormQuestionMutation,
  useUpdateFormQuestionMutation,
} from "../services/FormQuestionService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import QuestionToOrderItemMap from "./QuestionToOrderItemMap";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";

const FormQuestion = ({ refetch, serviceTaskId, formQuestionData }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [formQuestionRequest, setFormQuestionRequest] = useState(null);
  const [
    deleteFormQuestion,
    {
      isSuccess: isFormQuestionDeleteSuccess,
      isLoading: isFormQuestionDeleteLoading,
    },
  ] = useDeleteFormQuestionMutation();
  const [addFormQuestion, { isLoading: isAddFormQuestionLoading }] =
    useCreateFormQuestionMutation();
  const [updateFormQuestion, { isLoading: isUpdateFormQuestionLoading }] =
    useUpdateFormQuestionMutation();

  const [open, setOpen] = useState(false);

  const handleUpdateFormQuestion = async (event) => {
    event.preventDefault();

    await updateFormQuestion(formQuestionRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Form question updated succesfully");
          setFormQuestionRequest(null);
          refetch();
        } else {
          ErrorToast("Failed to update Form question");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update service task");
      });
  };

  const handleDeleteFormQuestion = async (event) => {
    await deleteFormQuestion(formQuestionRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Form question deleted succesfully");
          setFormQuestionRequest(null);
          refetch();
        } else {
          ErrorToast("Failed to delete Form question");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete service task");
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleCreateFormQuestion = async (event) => {
    event.preventDefault();

    await addFormQuestion(formQuestionRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Form question created succesfully");
          setFormQuestionRequest(null);
          refetch();
        } else {
          ErrorToast("Failed to create Form question");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to created Form question");
      });
  };

  const handleAccodionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initCreateFormQuestionRequest = () => {
    setFormQuestionRequest({
      ...createFormQuestionRequest,
      serviceTaskId: serviceTaskId,
    });
  };
  const handleCancelFormQuestionRequest = () => {
    setFormQuestionRequest(null);
  };

  const handleFormQuestionChange = (event) => {
    const { name, value, checked } = event.target;

    if (["mandatory", "readOnly"].includes(name)) {
      setFormQuestionRequest((prevFormQuestionRequest) => ({
        ...prevFormQuestionRequest,
        [name]: checked,
      }));
    } else if (name === "serviceTaskId") {
      setFormQuestionRequest((prevFormQuestionRequest) => ({
        ...prevFormQuestionRequest,
        [name]: value?.id,
      }));
    } else {
      setFormQuestionRequest((prevFormQuestionRequest) => ({
        ...prevFormQuestionRequest,
        [name]: value,
      }));
    }
  };
  const handleDeleteAction = (row) => {
    setFormQuestionRequest(row);
    setOpen(true);
  };
  const handleEditAction = (row) => {
    setFormQuestionRequest(row);
  };

  const columns = FormQuestionsTable({ handleEditAction, handleDeleteAction });

  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccodionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" sx={{ width: "100%", flexShrink: 0 }}>
            Configure Form Questions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", my: 2 }}
          >
            <Typography color="primary" variant="button">
              Add Form Question
            </Typography>
            <Button
              disabled={formQuestionRequest}
              onClick={initCreateFormQuestionRequest}
              sx={{ float: "right" }}
              variant="contained"
            >
              <Add />
            </Button>
          </Box>

          <Grid container spacing={1}>
            {formQuestionRequest && (
              <Grid item xs={12} sm={12} md={12}>
                <Item>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Title"
                        variant="outlined"
                        placeholder="Example: How are you?"
                        value={formQuestionRequest?.title}
                        name="title"
                        onChange={handleFormQuestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Hint text"
                        variant="outlined"
                        value={formQuestionRequest?.hintText}
                        name="hintText"
                        onChange={handleFormQuestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">
                          Question type
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label="Question type"
                          required
                          value={formQuestionRequest?.questionType}
                          defaultValue=""
                          name="questionType"
                          onChange={handleFormQuestionChange}
                        >
                          {questionTypes.map((questionType, index) => (
                            <MenuItem key={index} value={questionType}>
                              {questionType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">
                          Path Attribute
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label="Path Attribute"
                          value={formQuestionRequest?.pathAttribute}
                          defaultValue=""
                          name="pathAttribute"
                          onChange={handleFormQuestionChange}
                        >
                          {formAnswerTypes.map((formAnswerType, index) => (
                            <MenuItem key={index} value={formAnswerType}>
                              {formAnswerType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">
                          Validation Type
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label="Validation Type"
                          value={formQuestionRequest?.validationType}
                          defaultValue=""
                          name="validationType"
                          onChange={handleFormQuestionChange}
                        >
                          {validationTypes.map((validationType, index) => (
                            <MenuItem key={index} value={validationType}>
                              {validationType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Validation regex"
                        variant="outlined"
                        value={formQuestionRequest?.validationRegex}
                        name="validationRegex"
                        onChange={handleFormQuestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Validation error message"
                        variant="outlined"
                        value={formQuestionRequest?.validationErrorMessage}
                        name="validationErrorMessage"
                        onChange={handleFormQuestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">
                          Display order
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label="Display order"
                          required
                          value={formQuestionRequest?.displayOrder}
                          name="displayOrder"
                          onChange={handleFormQuestionChange}
                        >
                          {numericalOrders.map((order, index) => (
                            <MenuItem key={index} value={order}>
                              {order}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="readOnly"
                            checked={formQuestionRequest?.readOnly}
                            onChange={handleFormQuestionChange}
                            control={<Checkbox />}
                            size="small"
                            label="ReadOnly?"
                          />
                        </span>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="mandatory"
                            checked={formQuestionRequest?.mandatory}
                            onChange={handleFormQuestionChange}
                            control={<Checkbox />}
                            size="small"
                            label="Mandatory?"
                          />
                        </span>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <CustomAlertDialog
                    open={open}
                    handleClose={handleClose}
                    handleSubmit={handleDeleteFormQuestion}
                    title={"Delete Form Question"}
                    subTitle={"Are you sure you want to delete form question?"}
                    negativeButtonText={"No"}
                    positiveButtonText={"Yes"}
                    icon={<FiTrash2 size={30} />}
                    fullWidth={true}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      mt: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      disabled={
                        isFormQuestionDeleteLoading ||
                        isAddFormQuestionLoading ||
                        isUpdateFormQuestionLoading
                      }
                      onClick={handleCancelFormQuestionRequest}
                      variant="contained"
                      color="error"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={
                        formQuestionRequest.id
                          ? handleUpdateFormQuestion
                          : handleCreateFormQuestion
                      }
                      disabled={
                        !formQuestionRequest.serviceTaskId ||
                        isAddFormQuestionLoading ||
                        isUpdateFormQuestionLoading ||
                        isFormQuestionDeleteLoading
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Item>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} sx={{ mt: 5 }}>
              <Item>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    marginBottom={2}
                  >
                    <Typography variant="h5">Form Questions</Typography>
                  </Stack>
                  <Box>
                    <TableContainer component={Paper}>
                      <DataGrid
                        autoHeight
                        sx={{
                          boxShadow: 2,
                          border: 2,
                          borderColor: "gray",
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                        }}
                        filterMode="client"
                        paginationMode="client"
                        rows={formQuestionData ?? []}
                        rowCount={formQuestionData.length || 0}
                        columns={columns}
                        disableColumnSelector
                        disableDensitySelector
                        slotProps={{
                          panel: {
                            sx: {
                              top: "-120px !important",
                            },
                          },
                        }}
                        getRowId={(row) => row.id}
                        slots={{ toolbar: GridToolbar }}
                      />
                    </TableContainer>
                  </Box>
                </Box>
              </Item>
            </Grid>
            {formQuestionRequest?.id && (
              <Grid item xs={12} sm={12} md={12} sx={{ mt: 5 }}>
                <QuestionToOrderItemMap
                  formQuestionId={formQuestionRequest?.id}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FormQuestion;
