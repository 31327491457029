import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Grid, InputAdornment } from "@mui/material";
import { filesize } from "filesize";

const MerchantDocumentModal = ({
  open,
  handleClose,
  merchantDocument,
  type,
  handleFormChange,
  handleSubmit,
}) => {
  const updaloadRef = React.useRef();

  const handleUploadClick = () => {
    updaloadRef.current.click();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle
        textAlign={"center"}
      >{`${type} MERCHANT DOCUMENT`}</DialogTitle>
      <DialogContent>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              ID: <span style={{ opacity: 0.7 }}>{merchantDocument?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Type:{" "}
              <span style={{ opacity: 0.7 }}>{merchantDocument?.type}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Reference code:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantDocument?.referenceCode}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File name:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantDocument?.media?.filename}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File URL:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantDocument?.media?.fileUrl}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File size:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantDocument?.media?.size &&
                  filesize(merchantDocument?.media?.size ?? 0)}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container padding={5}>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="Type"
                name="type"
                variant="outlined"
                value={merchantDocument?.type}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="Reference code"
                name="referenceCode"
                variant="outlined"
                value={merchantDocument?.referenceCode}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="File name"
                name="filename"
                variant="outlined"
                value={merchantDocument?.media?.filename}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="File URL"
                name="fileUrl"
                variant="outlined"
                required
                value={merchantDocument?.media?.fileUrl}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="MimeType"
                name="mimeType"
                variant="outlined"
                disabled
                value={merchantDocument?.media?.mimeType}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6}>
              <TextField
                label="File size"
                name="size"
                variant="outlined"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {merchantDocument?.media?.size &&
                        filesize(merchantDocument?.media?.size ?? 0)}
                    </InputAdornment>
                  ),
                }}
                value={merchantDocument?.media?.size}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <input
              ref={updaloadRef}
              type="file"
              hidden
              name="document"
              onChange={handleFormChange}
            />
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>
            <Button onClick={handleUploadClick} variant="contained">
              BROWSE
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantDocumentModal;
