export const ProductMangementType = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  ADD: "ADD",
};

export const remittanceJobStatus = {
  INIT: "INIT",
  ATTEMPTING: "ATTEMPTING",
  SENT: "SENT",
  PENDING_RESPONSE: "PENDING_RESPONSE",
  REQUEST_TIMED_OUT: "REQUEST_TIMED_OUT",
  REQUEST_FAILED: "REQUEST_FAILED",
  FAILED_NO_RETRY_MAX_ATTEMPT_REACHED: "FAILED_NO_RETRY_MAX_ATTEMPT_REACHED",
  FAILED_NO_RETRY_ENABLED: "FAILED_NO_RETRY_ENABLED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
};

export const remittanceAttemptStatus = {
  INIT: "INIT",
  SKIPPED: "SKIPPED",
  SENT: "SENT",
  ACK: "ACK",
  ACK_FAILED: "ACK_FAILED",
  ACK_TIMED_OUT: "ACK_TIMED_OUT",
  SUCCESS: "SUCCESS",
  UNKNOWN: "UNKNOWN",
  FAILED: "FAILED",
};

export const transactionTypes = {
  PAYMENT: "Payment",
  REMITTANCE: "Remittance",
};

export const accountTypes = {
  MOBILE_MONEY: "Mobile Money",
  BANK_ACCOUNT: "Bank Account",
  ONLINE_PAYMENT: "Online Payment",
};

export const remittanceInstitutionTypes = {
  BANK: "Bank",
  WALLET: "Wallet",
};

export const remittanceCustomMapTypes = {
  QUESTION: "Question",
  STATIC: "Static",
};

export const userTypes = {
  USER: "User",
  MERCHANT: "Merchant",
  SYSTEM: "System",
};

export const domainTypes = ["BACK_OFFICE", "SUPERMAN"];
export const merchantDomainType = ["EMPLOYEE", "MERCHANT_ADMIN"];

export const statuses = ["ACTIVE", "INACTIVE"];
export const httpMethods = ["GET", "POST"];
export const questionTypes = [
  "TEXT_QUESTION",
  "INTEGER_QUESTION",
  "DOUBLE_QUESTION",
  "DROP_DOWN_QUESTION",
];
export const formAnswerTypes = [
  "MERCHANT_SOURCING",
  "DROP_DOWN_TYPE",
  "QUANTITY",
  "NONE",
];
export const validationTypes = [
  "TEXT_ONLY",
  "TEXT_WITH_NUMBER",
  "PHONE_NUMBER",
  "AMOUNT",
  "AGE",
  "NUMBER_ONLY",
  "DATE_ONLY",
  "DATE_AND_TIME",
  "NONE",
];
export const numericalOrders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const contentTypes = ["JSON", "XML", "FORM_DATA", "URL_ENCODED"];
export const serviceTaskTypes = [
  "API_CALL",
  "USER_BILL_INFO",
  "DROP_DOWN",
  "SERVICE_FORM",
  "REVIEW_ORDER",
  "REVIEW_ORDER_BILL_INFO",
  "REVIEW_ORDER_CREATE_ORDER_BILL_INFO",
  "PAY_ORDER",
];
export const mappableOrderItemAttributes = [
  "NONE",
  "QUANTITY",
  "MERCHANDISE_AMOUNT",
  "RECIPIENT_NAME",
  "RECIPIENT_ACCOUNT",
  "RECIPIENT_BUNDLE_TYPE",
];
export const serviceTaskTypeEnums = ["CUSTOMER", "PROCUREMENT"];
export const responseCodeValueUsages = ["SUCCESS", "FAILURE"];
export const refundPolicies = [
  {
    label: "When one failed",
    value: "WHEN_ONE_FAILED",
  },
  {
    label: "When all failed",
    value: "WHEN_ALL_FAILED",
  },
  {
    label: "None",
    value: "NONE",
  },
];
export const retryPolicies = [
  "FAILURE_ONLY",
  " ALL_FAILED_SERVICES",
  "ALL_SERVICES",
  "NONE",
];

export const contactTypes = ["MOBILE", "LANDLINE", "FAX", "EMAIL"];

export const metrics = [
  {
    label: "Metre (m)",
    value: "METRE",
  },
  {
    label: "Centimetre (cm)",
    value: "CENTIMETRE",
  },
];
export const productTypes = [
  {
    label: "Physical good",
    value: "PHYSICAL_GOOD",
  },
  {
    label: "Service good",
    value: "SERVICE_GOOD",
  },
];
export const volumMetrics = [
  {
    label: "Milliliter (mL)",
    value: "MILLILITER",
  },
  {
    label: "Litter (L)",
    value: "LITER",
  },
  {
    label: "Centimetre (cm)",
    value: "CUBIC_METER",
  },
];
export const weightMetrics = [
  {
    label: "Grams (g)",
    value: "GRAMS",
  },

  {
    label: "Kilograms (kg)",
    value: "KILOGRAM",
  },
  {
    label: "Ton/Tonne (t)",
    value: "TON",
  },
];
export const measurementMetrics = [
  {
    label: "Metre (m)",
    value: "METRE",
  },
  {
    label: "Centimetre (cm)",
    value: "CENTIMETRE",
  },
];

export const purchaseOrderStatus = [
  "ACCEPTED",
  "DELIVERY_IN_PROGRESS",
  "DELIVERED",
  "CANCELED",
];

export const verificationStatus = {
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  UPDATE_REQUIRED: "UPDATE_REQUIRED",
  PENDING_REVIEW: "PENDING_REVIEW",
  ID_DECLINED: "ID_DECLINED",
  SELFIE_DECLINED: "SELFIE_DECLINED",
  KYC_DECLINED: "KYC_DECLINED",
  EXPIRED: "EXPIRED",
};

export const verificationStatuses = Object.values(verificationStatus);
