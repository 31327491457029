import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Grid,
    MenuItem,
    TextField,
    Button
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAddProductMutation } from '../../services/loyalty/productService';

import { ErrorToast, SuccessToast } from "../../components/common/Toast";
import { useGetAllProductSpecsQuery } from '../../services/loyalty/productSpecService';
import {  useNavigate } from 'react-router-dom';


const AddProduct = () => {
    const navigate = useNavigate()
    const [addProduct] = useAddProductMutation();
    const { data: productSpecData, isLoading } = useGetAllProductSpecsQuery();

    useEffect(() => {
        console.log("product spec data::", productSpecData)
    }, [productSpecData])

    const [formData, setFormData] = useState([
        { name: 'name', value: '' },
        { name: 'description', value: '' },
        { name: 'status', value: '' },
        { name: 'productSpecId', value: '' },
        { name: 'startDate', value: null },
        { name: 'endDate', value: null },
    ]);

    const handleChange = (name, value) => {
        setFormData(prevState =>
            prevState.map(field =>
                field.name === name ? { ...field, value } : field
            )
        );
    };


    const handleSubmit = async () => {
        const formDataWithDateString = formData.map(field => {
            if (field.name === 'startDate' || field.name === 'endDate') {
                return { ...field, value: field.value ? field.value.format('YYYY-MM-DD') : null };
            }
            return field;
        });

        const formDataJSON = {
            name: '',
            description: '',
            status: '',
            productSpecId: '',
            validFor: {
                startDateTime: '',
                endDateTime: ''
            },
        };

        formDataWithDateString.forEach(field => {
            switch (field.name) {
                case 'name':
                case 'description':
                case 'productSpecId':
                case 'status':
                    formDataJSON[field.name] = field.value;
                    break;
                case 'startDate':
                case 'endDate':
                    formDataJSON.validFor[field.name === 'startDate' ? 'startDateTime' : 'endDateTime'] = field.value + "T00:00:00";
                    break;
            }
        });

        console.log("formData", formDataJSON)

        try {
            const response = await addProduct(formDataJSON);
            if (response.data.code == 0) {
                SuccessToast("Product Spec successfully create")
                setFormData([
                    { name: 'name', value: '' },
                    { name: 'description', value: '' },
                    { name: 'status', value: '' },
                    { name: 'productSpecId', value: '' },
                    { name: 'startDate', value: null },
                    { name: 'endDate', value: null },

                ])
            } else {
                ErrorToast("Failed to add product spec")
            }
        } catch (error) {
            ErrorToast('Error submitting form:', error);
        }

    };

    return (
        <Box sx={{ pt: "80px", pb: "20px" }}>
            <div style={{ margin: "20px 0px", fontWeight: "900", fontSize: "25px" }}>Add Product Spec</div>

            <Paper
                sx={{
                    boxShadow: "none !important",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "divider",
                    padding: "50px 50px",
                    overflow: "hidden",
                    minHeight: "400px",
                    height: "auto"
                }}
            >
                {!isLoading ? (
                    <Grid container spacing={2}>
                        {formData.map((field, index) => (
                            <Grid key={index} item xs={6} sm={12} md={6}>
                                {field.name === 'startDate' || field.name === 'endDate' ? (
                                    <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={field.name === 'startDate' ? 'Start Date' : 'End Date'}
                                            value={field.value}
                                            onChange={(newValue) => handleChange(field.name, newValue)}
                                            fullWidth
                                            size="large"
                                            sx={{ width: "100%", margin: "5px 0px" }}
                                        />
                                    </LocalizationProvider>
                                ) : (
                                    <TextField
                                        label={field.name === 'productSpecId' ? 'Select Product Spec ' : field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                                        variant="outlined"
                                        value={field.value}
                                        onChange={(e) => handleChange(field.name, e.target.value)}
                                        fullWidth
                                        size="large"
                                        select={field.name === 'productSpecId' || field.name === 'status'}
                                        SelectProps={{ displayEmpty: true }}
                                    >
                                        {field.name === 'productSpecId' && (
                                            productSpecData?.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))
                                        )}



                                        {field.name === 'status' && (
                                            [
                                                <MenuItem key="active" value="ACTIVE">ACTIVE</MenuItem>,
                                                <MenuItem key="inactive" value="INACTIVE">INACTIVE</MenuItem>,
                                                <MenuItem key="planned" value="PLANNED">PLANNED</MenuItem>,
                                                <MenuItem key="suspended" value="SUSPENDED">SUSPENDED</MenuItem>
                                            ]
                                        )}

                                    </TextField>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : ("")}

                <Box sx={{
                    display: "flex",
                    margin: "30px",
                    padding: "16px",
                    justifyContent: "end"
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=> navigate("/pages/loyalty/product")}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ margin: "0px 10px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Add
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default AddProduct;
