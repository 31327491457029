import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittancePayloadCustomMapApi = createApi({
  reducerPath: "remittancePayloadCustomMapApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["payloadCustomMap"],
  endpoints: (builder) => ({
    getAllRemittancePayloadCustomMaps: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/custom/map/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["payloadCustomMap"],
    }),

    getRemittancePayloadCustomMap: builder.query({
      query: (id) => ({
        url: `/custom/map/${id}`,
        method: "GET",
      }),
      providesTags: ["payloadCustomMap"],
    }),

    searchRemittancePayloadCustomMaps: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/custom/map?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["payloadCustomMap"],
    }),
    updateRemittancePayloadCustomMap: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/custom/map",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["payloadCustomMap"],
    }),
    createRemittancePayloadCustomMap: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/custom/map",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["payloadCustomMap"],
    }),
    deleteRemittancePayloadCustomMap: builder.mutation({
      query: (id) => ({
        url: `/custom/map/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["payloadCustomMap"],
    }),
  }),
});

export const {
  useGetAllRemittancePayloadCustomMapsQuery,
  useGetRemittancePayloadCustomMapQuery,
  useCreateRemittancePayloadCustomMapMutation,
  useDeleteRemittancePayloadCustomMapMutation,
  useUpdateRemittancePayloadCustomMapMutation,
  useSearchRemittancePayloadCustomMapsQuery,
} = remittancePayloadCustomMapApi;
