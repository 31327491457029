import { Box, IconButton, Tooltip, Chip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";

export const MerchantContactsTable = ({
  handleDeleteAction,
  handleEditAction,
  handleViewAction,
}) => {
  return [
    {
      field: "contactType",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Contact type",
    },
    {
      field: "contact",
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerName: "Contact",
    },

    {
      field: "contactable",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Reachable",
      renderCell: ({ value }) =>
        value ? (
          <Chip size={"small"} variant="outlined" color="primary" label="YES" />
        ) : (
          <Chip size={"small"} variant="outlined" color="error" label="NO" />
        ),
    },
    {
      field: "primaryContact",
      flex: 1,
      type: "boolean",
      headerAlign: "center",
      align: "center",
      headerName: "Primary contact",
      renderCell: ({ value }) =>
        value ? (
          <Chip size={"small"} variant="outlined" color="primary" label="YES" />
        ) : (
          <Chip size={"small"} variant="outlined" color="error" label="NO" />
        ),
    },
    {
      field:"actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            placement="top"
            title="Edit"
            onClick={() => {
              handleEditAction(row);
            }}
          >
            <IconButton color="primary">
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
