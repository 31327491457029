import { Box, Typography } from '@mui/material'
import React from 'react'

export const UnAuthorizedPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography color={"red"}>You have no access to page</Typography>
    </Box>
  );
}
