import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { CaseAgentTable } from "../../data/disputes/CaseAgentTable";
import CustomAlertDialog from "../../components/modals/CustomAlertDialog";
import {
  useSearchCaseAgentsQuery,
  useDeleteCaseAgentMutation,
  useCreateCaseAgentMutation,
} from "../../services/disputeServices/CaseAgentService";
import CaseAgentModal from "../../components/modals/CaseAgentModal";
import SecuredComponent from "../../components/common/SecuredComponent";
import { DomainType } from "../../presentation/helpers";
import { DataGridService } from "../../core/dataGridService";
import { ErrorToast, SuccessToast } from "../../components/common/Toast";

const CaseAgent = () => {
  const dataGridService = new DataGridService("CaseAgent");
  const [filter, setFilter] = useState({});
  const [selectedCaseAgent, setSelectedCaseAgent] = useState(null);
  const { data: caseAgentData, isLoading: isCaseAgentLoading } =
    useSearchCaseAgentsQuery(filter);
  const [deleteCaseAgent] = useDeleteCaseAgentMutation();
  const [createCaseAgent] = useCreateCaseAgentMutation();

  const [open, setOpen] = useState(false);
  const [showCaseAgent, setShowCaseAgent] = useState(false);
  const [addCaseAgentRequest, setAddCaseAgentRequest] = useState({});

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handleClose = () => {
    setOpen(!open);
  };
  const handleShowDialog = () => {
    setOpen(!open);
  };
  const handleShowCreate = () => {
    setShowCaseAgent(!showCaseAgent);
  };
  const handleCloseCreate = () => {
    setShowCaseAgent(!showCaseAgent);
  };

  const addCaseAgent = async (event) => {
    event.preventDefault();
    const createCaseAgentRequest = {
      userId: addCaseAgentRequest.id,
      firstName: addCaseAgentRequest.firstName,
      lastName: addCaseAgentRequest.lastName,
    };

    await createCaseAgent(createCaseAgentRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Case agent created succesfully");
        } else {
          ErrorToast("Failed to create case agent");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create case agent");
      })
      .finally(() => {
        handleCloseCreate();
      });
  };

  const delCaseAgent = async () => {
    await deleteCaseAgent(selectedCaseAgent.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Case Agent deleted successfully");
        } else {
          ErrorToast("Failed to delete case agent");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete case agent");
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedCaseAgent(row);
  };
  const columns = CaseAgentTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Case Agents</Typography>
          <Button
            onClick={handleShowCreate}
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Case Agent
          </Button>
        </Box>
        <CaseAgentModal
          addCaseAgent={addCaseAgent}
          addCaseAgentRequest={addCaseAgentRequest}
          setAddCaseAgentRequest={setAddCaseAgentRequest}
          handleClose={handleCloseCreate}
          open={showCaseAgent}
        />
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={delCaseAgent}
          title={"Delete Case Agent"}
          subTitle={`Are you sure you want to delete ${selectedCaseAgent?.firstName} ${selectedCaseAgent?.lastName}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              loading={isCaseAgentLoading}
              filterMode="server"
              paginationMode="server"
              rowCount={caseAgentData?.size ?? []}
              rows={caseAgentData?.data ?? 0}
              columns={columns}
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default CaseAgent;
