import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceInstitutionApi = createApi({
  reducerPath: "remittanceInstitutionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["RemittanceInstitution"],
  endpoints: (builder) => ({
    getAllRemittanceInstitutions: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/institutions/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["RemittanceInstitution"],
    }),

    getRemittanceInstitution: builder.query({
      query: (id) => ({
        url: `/institutions/${id}`,
        method: "GET",
      }),
      providesTags: ["RemittanceInstitution"],
    }),

    searchRemittanceInstitutions: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/institutions?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["RemittanceInstitution"],
    }),
    updateRemittanceInstitution: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/institutions",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceInstitution"],
    }),
    createRemittanceInstitution: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/institutions",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceInstitution"],
    }),
    deleteRemittanceInstitution: builder.mutation({
      query: (id) => ({
        url: `/institutions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RemittanceInstitution"],
    }),
  }),
});

export const {
  useGetAllRemittanceInstitutionsQuery,
  useGetRemittanceInstitutionQuery,
  useCreateRemittanceInstitutionMutation,
  useDeleteRemittanceInstitutionMutation,
  useUpdateRemittanceInstitutionMutation,
  useSearchRemittanceInstitutionsQuery,
} = remittanceInstitutionApi;
