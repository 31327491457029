import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { contactTypes } from "../components/common/dropdownsData";
import { useResetPasswordMutation } from "../services/PasswordService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const ForgotPassword = () => {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [formData, setFormData] = useState({
    contactType: "",
    username: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await resetPassword(formData);

      if (response.data) {
        const otpId = response.data.data.otpDto.id;

        SuccessToast("OTP Sent Successfully");
        navigate(`/complete-reset`, { state: { otpId: otpId } });
      } else {
        ErrorToast("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      ErrorToast("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
          >
            <Typography variant="h5">Forgot Password</Typography>
          </Box>
        </Box>
        <FormControl fullWidth>
          <InputLabel>Contact Type</InputLabel>
          <Select
            label="Contact Type"
            name="contactType"
            value={formData.contactType}
            onChange={handleInputChange}
          >
            {contactTypes.map((contactType, index) => (
              <MenuItem key={index} value={contactType}>
                {contactType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleInputChange}
          required
          fullWidth
          margin="normal"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            sx={{ width: "50%", padding: "10px", borderRadius: "10px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                style={{ height: "20px", width: "20px" }}
                color="warning"
              />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
