import { Box } from "@mui/system";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";
import { DomainType } from "../presentation/helpers";

export const DomainUsersTable = (handleDeleteAction) => {
  const userId = sessionStorage.getItem("userId");

  const checkDomainType = (domainType) => {
    return (
      !(sessionStorage.getItem("domainType") === DomainType.SUPERMAN) &&
      DomainType.SUPERMAN === domainType
    );
  };
  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "firstName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "First Name",
    },
    {
      field: "lastName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Last Name",
    },
    {
      field: "username",
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerName: "User Name",
    },
    {
      field: "domainType",
      flex: 1,
      headerAlign: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerName: "Domain Type",
      valueGetter: ({ value }) =>
        value === DomainType.SUPERMAN ? "BORGA_ADMIN" : value,
    },
    {
      field: "status",
      filterable: false,
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      renderCell: ({ value }) =>
        value === "ACTIVE" ? (
          <Chip
            size={"small"}
            color="primary"
            label={value}
            variant="outlined"
          />
        ) : (
          <Chip
            size={"small"}
            color="error"
            label="INACTIVE"
            variant="outlined"
          />
        ),
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/domain/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              disabled={checkDomainType(row?.domainType) || row.id === userId}
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
