import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { InventoryTable } from "../data/InventoryTable";
import {
  useCreateInventoryMutation,
  useDeleteInventoryMutation,
  useSearchInventoryQuery,
  useUpdateInventoryMutation,
} from "../services/InventoryService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import InventoryModal from "../components/modals/InventoryModal";
import { InventoryRequest } from "../models/inventory";

const Inventory = ({ merchantId }) => {
  const dataGridService = new DataGridService("Inventory");
  const [filter, setFilter] = useState({});
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [open, setOpen] = useState(false);
  const [openInventoryDialog, setOpenInventoryDialog] = useState(false);
  const [type, setType] = useState("");
  const [inventoryRequest, setInventoryRequest] = useState(InventoryRequest);

  const { data: inventoryData, isLoading: isInventoryLoading } =
    useSearchInventoryQuery(filter);
  const [deleteInventory] = useDeleteInventoryMutation();
  const [createInventory] = useCreateInventoryMutation();
  const [updateInventory] = useUpdateInventoryMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenInventoryDialog(false);
    setInventoryRequest(InventoryRequest);
  };

  const handleBooleanFieldChange = (event) => {
    const { name, checked } = event.target;
    setInventoryRequest((prevInventoryRequest) => ({
      ...prevInventoryRequest,
      [name]: checked,
    }));
  };
  const handleMerchantFormChange = (event, option) => {
    setInventoryRequest((prev) => ({ ...prev, merchantId: option?.value?.id }));
  };
  const handleWarehouseFormChange = (event, option) => {
    setInventoryRequest((prev) => ({
      ...prev,
      warehouseId: option?.value?.id,
    }));
  };
  const handleProductFormChange = (event, option) => {
    setInventoryRequest((prev) => ({ ...prev, productId: option?.value?.id }));
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (
      ["active", "autoReplenishQuantity", "autoReplenishEnabled"].includes(name)
    ) {
      setInventoryRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (["amount", "currency"].includes(name)) {
      setInventoryRequest((prev) => ({
        ...prev,
        price: { ...prev?.price, [name]: value },
      }));
    } else {
      setInventoryRequest((prev) => ({ ...prev, [name]: value }));
    }
  };
  const hanldeShowInventoryDialog = () => {
    setType("ADD");
    setOpenInventoryDialog(true);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      merchantId: getIdMerchant(),
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedInventory(row);
  };

  const handleUpdateInventory = async (event) => {
    event.preventDefault();

    await updateInventory(inventoryRequest)
      .then((response) => {
        if (response.data) {
          handleClose();
          SuccessToast("Inventory updated succesfully");
        } else {
          ErrorToast("Failed to update Inventory");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Inventory");
      });
  };
  const handleCreateInventory = async (event) => {
    event.preventDefault();

    await createInventory({ ...inventoryRequest, merchantId: getIdMerchant() })
      .then((response) => {
        if (response.data) {
          handleClose();
          SuccessToast("Inventory created succesfully");
        } else {
          ErrorToast("Failed to create Inventory");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Inventory");
      });
  };
  const handleDeleteInventory = async () => {
    await deleteInventory(selectedInventory?.id)
      .then((response) => {
        if (response.data) {
          handleClose();
          SuccessToast("Inventory deleted succesfully");
        } else {
          ErrorToast("Failed to delete Inventory");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Inventory");
      })
      .finally(() => {
        handleClose();
      });
  };

  const getIdMerchant = () => {
    const user = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      [DomainType.MERCHANT_ADMIN, DomainType.EMPLOYEE].includes(user.domainType)
    ) {
      return user?.merchant;
    } else {
      return merchantId;
    }
  };

  const handleEditAction = (row) => {
    setType("UPDATE");
    setInventoryRequest(row);
    setOpenInventoryDialog(true);
  };

  const columns = InventoryTable({ handleEditAction, handleDeleteAction });

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Inventory</Typography>
        {merchantId ||
        [DomainType.MERCHANT_ADMIN, DomainType.EMPLOYEE].includes(
          sessionStorage.getItem("domainType")
        ) ? (
          <Button
            onClick={hanldeShowInventoryDialog}
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Inventory
          </Button>
        ) : null}
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleDeleteInventory}
        title={"Delete Inventory"}
        subTitle={`Are you sure you want to delete ${selectedInventory?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sortingMode="server"
            paginationMode="server"
            filterMode="server"
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "gray",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            slots={{ toolbar: GridToolbar, filterPanelDeleteIcon: FiTrash2 }}
            loading={isInventoryLoading}
            rowCount={inventoryData?.size ?? 0}
            columns={columns}
            rows={inventoryData?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
        <InventoryModal
          open={openInventoryDialog}
          type={type}
          inventory={inventoryRequest}
          handleClose={handleClose}
          formChanges={{
            handleFormChange,
            handleMerchantFormChange,
            handleProductFormChange,
            handleWarehouseFormChange,
            handleBooleanFieldChange,
          }}
          handleSubmit={
            type === "ADD" ? handleCreateInventory : handleUpdateInventory
          }
        />
      </Box>
    </Box>
  );
};

export default Inventory;
