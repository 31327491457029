export const sendChatMessageRequest = {
     "chatRoomId": "",
     "message": "",
     "userRefId": null,
     "automatedResponse": false,
     "media": [
          {
               "id": "",
               "chatMessageId": "",
               "url": "",
               "type": "IMAGE",
               "created": "",
               "updated": ""
          }
     ]
}