import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { merchantContactUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const merchantContactsApi = createApi({
  reducerPath: "merchantContactsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: merchantContactUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MerchantContacts", "Merchants"],
  endpoints: (builder) => ({
    getAllMerchantContacts: builder.query({
      query: (filterMerchantContactRequest) => ({
        url: `/contacts?${filterMerchantContactRequest}`,
        method: "GET",
      }),
      providesTags: ["MerchantContacts", "Merchants"],
      transformResponse: (response) => response.data.data,
    }),
    getMerchantContact: builder.query({
      query: (id) => ({
        url: `/contacts/${id}`,
        method: "GET",
      }),
      providesTags: ["MerchantContacts", "Merchants"],
    }),
    searchMerchantContacts: builder.query({
      query: (filterMerchantContactRquest) => ({
        url: `/contacts?${queryString.stringify(
          filterMerchantContactRquest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: (result, error, request) => [
        { type: "Merchants", id: "LIST" },
      ],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateMerchantContact: builder.mutation({
      query: (updateMerchantContactRequest) => ({
        url: "/contacts",
        method: "PUT",
        body: updateMerchantContactRequest,
      }),
      invalidatesTags: (result, error, request) => [
        { type: "Merchants", id: "LIST" },
      ],
    }),
    createMerchantContact: builder.mutation({
      query: (addMerchantContactRequest) => ({
        url: "/contacts",
        method: "POST",
        body: addMerchantContactRequest,
      }),
      invalidatesTags: ["Merchants"],
    }),
    deleteMerchantContact: builder.mutation({
      query: (id) => ({
        url: `/contacts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MerchantContacts", "Merchants"],
    }),
  }),
});

export const {
  useGetAllMerchantContactsQuery,
  useSearchMerchantContactsQuery,
  useGetMerchantContactQuery,
  useCreateMerchantContactMutation,
  useUpdateMerchantContactMutation,
  useDeleteMerchantContactMutation,
} = merchantContactsApi;
