import { FiHome, FiSettings, FiShoppingCart } from "react-icons/fi";
import {
  MdAttachMoney,
  MdBusiness,
  MdOutlineInventory, MdPublic
} from "react-icons/md";
import { AiOutlineTransaction, AiOutlineUser } from "react-icons/ai";
import { LiaProductHunt, LiaUserFriendsSolid } from "react-icons/lia";
import { RiAdminLine } from "react-icons/ri";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BiTransfer, BiDevices } from "react-icons/bi";
import { LiaWarehouseSolid } from "react-icons/lia";
import { GiPayMoney } from "react-icons/gi";

export const adminLinks = [
  {
    name: "Dashboard",
    icon: <FiHome size={20} />,
    url: "/pages/dashboard",
  },
  {
    name: "Platform Users",
    icon: <RiAdminLine size={20} />,
    subLinks: [
      {
        name: "Admins",
        url: "/pages/domain-users",
      },
      {
        name: "Users",
        url: "/pages/mobile-users",
      },
    ],
  },
  {
    name: "Platform Customers",
    icon: <AiOutlineUser size={20} />,
    url: "/pages/merchant-admin",
  },
  {
    name: "Merchants",
    icon: <MdBusiness size={20} />,
    url: "/pages/merchants",
    subLinks: [
      {
        name: "All Merchants",
        url: "/pages/merchants",
      },
      {
        name: "Merchant Category",
        url: "/pages/merchants/categories",
      },
    ],
  },
  {
    name: "Loyalty",
    icon: <MdBusiness size={20} />,
    url: "/pages/loyalty/program-members",
    subLinks: [
      {
        name: "Program Member",
        url: "/pages/loyalty/program-members",
      },
      {
        name: "Product Spec",
        url: "/pages/loyalty/product-spec",
      },
      {
        name: "Loyalty Transaction",
        url: "/pages/loyalty/transaction",
      },
      // {
      //   name: "Product ",
      //   url: "/pages/loyalty/product",
      // },
      // {
      //   name: "Actions ",
      //   url: "/pages/loyalty/actions",
      // },
      // {
      //   name: "Event Type",
      //   url: "/pages/loyalty/event-type",
      // },
    ],
  },
  {
    name: "Inventory",
    icon: <MdOutlineInventory size={20} />,
    url: "/pages/inventory",
  },
  {
    name: "Products",
    icon: <LiaProductHunt size={20} />,
    url: "/pages/products",
  },
  {
    name: "Orders",
    icon: <FiShoppingCart size={20} />,
    url: "/pages/orders",
    subLinks: [
      {
        name: "All Orders",
        url: "/pages/orders",
      },
      {
        name: "Merchant Purchase Orders",
        url: "/pages/merchant-purchase-order",
      },
    ],
  },
  {
    name: "Disputes",
    icon: <LiaUserFriendsSolid size={20} />,
    url: "/pages/disputes",
    subLinks: [
      {
        name: "Cases",
        url: "/pages/cases",
      },
      {
        name: "Case Agents",
        url: "/pages/case/agents",
      },
    ],
  },
  {
    name: "Countries",
    icon: <MdPublic size={20} />,
    url: "/pages/countries",
    subLinks: [
      {
        name: "All countries",
        url: "/pages/countries",
      },
      {
        name: "All currencies",
        url: "/pages/currencies",
      },
    ],
  },
  {
    name: "Forex",
    icon: <MdAttachMoney size={20} />,
    url: "/pages/forex",
  },
  {
    name: "Transactions",
    icon: <AiOutlineTransaction size={20} />,
    url: "/pages/transactions",
    subLinks: [
      {
        name: "All Transactions",
        url: "/pages/transactions",
      },
      { name: "Limits", url: "/pages/transaction/limits" },
      { name: "Retrigger Transaction", url: "/pages/transaction/callback" },
    ],
  },
  {
    name: "Payments",
    icon: <GiPayMoney size={20} />,
    url: "/pages/payments",
    subLinks: [
      {
        name: "Payments",
        url: "/pages/payments",
      },
      {
        name: "Payment Providers",
        url: "/pages/payment/providers",
      },
    ],
  },
  {
    name: "Remittance",
    icon: <BiTransfer size={20} />,
    url: "/pages/remittance/jobs",
    subLinks: [
      {
        name: "Remittance Jobs",
        url: "/pages/remittance/jobs",
      },
      {
        name: "Default Maps",
        url: "/pages/remittance/maps",
      },
      {
        name: "Configurations",
        url: "/pages/remittance/configurations",
      },
      {
        name: "Institutions",
        url: "/pages/remittance/institutions",
      },
      {
        name: "Payload Custom Map",
        url: "/pages/remittance/custom-maps",
      },
      {
        name: "Remittance Attempts",
        url: "/pages/remittance/attempts",
      },
    ],
  },

  {
    name: "Activities",
    icon: <HiOutlineDocumentText size={20} />,
    url: "/pages/audit-trials",
    subLinks: [
      {
        name: "Transaction Activity",
        url: "/pages/transaction/activities",
      },
      {
        name: "Order Activity",
        url: "/pages/order/activities",
      },
      {
        name: "Remittance Activity",
        url: "/pages/remittance/activities",
      },
      {
        name: "Procurement Activity",
        url: "/pages/procurement-activities",
      },
      {
        name: "Dispute Activity Log",
        url: "/pages/disputes-activity-log",
      },
    ],
  },
  {
    name: "Devices",
    icon: <BiDevices size={20} />,
    url: "/pages/devices",
  },
  {
    name: "Settings",
    icon: <FiSettings size={20} />,
    url: "/pages/settings",
  },
];

export const backOfficeLinks = [
  
  {
    name: "Orders",
    icon: <FiShoppingCart size={20} />,
    url: "/pages/orders",
    subLinks: [
      {
        name: "All Orders",
        url: "/pages/orders",
      },
      {
        name: "Merchant Purchase Orders",
        url: "/pages/merchant-purchase-order",
      },
    ],
  },
  {
    name: "Transactions",
    icon: <AiOutlineTransaction size={20} />,
    url: "/pages/transactions",
  },
  {
    name: "Payments",
    icon: <GiPayMoney size={20} />,
    url: "/pages/payments",
  },
  {
    name: "Remittance",
    icon: <BiTransfer size={20} />,
    url: "/pages/remittance/jobs",
    subLinks: [
      {
        name: "Remittance Jobs",
        url: "/pages/remittance/jobs",
      },
      {
        name: "Payload Default Maps",
        url: "/pages/remittance/maps",
      },
      {
        name: "Configurations",
        url: "/pages/remittance/configurations",
      },
      {
        name: "Institutions",
        url: "/pages/remittance/institutions",
      },
      {
        name: "Payload Custom Map",
        url: "/pages/remittance/custom-maps",
      },
      {
        name: "Remittance Attempts",
        url: "/pages/remittance/attempts",
      },
    ],
  },
  {
    name: "Audit trial",
    icon: <HiOutlineDocumentText size={20} />,
    url: "/pages/audit-trials",
    subLinks: [
      {
        name: "Transaction Activity",
        url: "/pages/transaction/activities",
      },
      {
        name: "Order Activity",
        url: "/pages/order/activities",
      },
      {
        name: "Remittance Activity",
        url: "/pages/remittance/activities",
      },
      {
        name: "Procurement Activity",
        url: "/pages/procurement-activities",
      },
      {
        name: "Dispute Activity Log",
        url: "/pages/disputes-activity-log",
      },
    ],
  },
  
  {
    name: "Settings",
    icon: <FiSettings size={20} />,
    url: "/pages/settings",
  },
];

export const merchantLinks = [
  {
    name: "Dashboard",
    icon: <FiHome size={20} />,
    url: "/pages/dashboard",
  },
  {
    name: "Inventory",
    icon: <LiaProductHunt size={20} />,
    url: "/pages/inventory",
  },
  {
    name: "Orders",
    icon: <FiShoppingCart size={20} />,
    url: "/pages/merchant-purchase-order",
  },
  {
    name: "Warehouse",
    icon: <LiaWarehouseSolid size={20} />,
    url: "/pages/warehouses",
  },
  {
    name: "Settings",
    icon: <FiSettings size={20} />,
    url: "/pages/settings",
  },
];
