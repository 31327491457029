import { Box } from "@mui/system";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const CaseTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "orderCategory",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Order Category",
      valueGetter: ({ value }) => (value === "" || null ? "N/A" : value),
    },
    {
      field: "assignedAgent",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Assigned Agent",
      valueGetter: ({ value }) =>
        value ? `${value.firstName} ${value.lastName}` : "N/A",
    },
    {
      field: "opened",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Status",
      renderCell: ({ value }) =>
        value ? (
          <Chip
            size={"small"}
            color="error"
            label="OPENED"
            variant="outlined"
          />
        ) : (
          <Chip
            size={"small"}
            color="success"
            label="CLOSED"
            variant="outlined"
          />
        ),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/case/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
