import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceMapApi = createApi({
  reducerPath: "remittanceMapApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["RemittanceMaps"],
  endpoints: (builder) => ({
    getAllRemittanceMaps: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/default/map/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["RemittanceMaps"],
    }),

    getRemittanceMap: builder.query({
      query: (id) => ({
        url: `/default/map/${id}`,
        method: "GET",
      }),
      providesTags: ["RemittanceMaps"],
    }),

    searchRemittanceMaps: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/default/map?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["RemittanceMaps"],
    }),
    updateRemittanceMap: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/default/map",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceMaps"],
    }),
    createRemittanceMap: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/default/map",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceMaps"],
    }),
    deleteRemittanceMap: builder.mutation({
      query: (id) => ({
        url: `/default/map/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RemittanceMaps"],
    }),
  }),
});

export const {
  useGetAllRemittanceMapsQuery,
  useGetRemittanceMapQuery,
  useCreateRemittanceMapMutation,
  useDeleteRemittanceMapMutation,
  useUpdateRemittanceMapMutation,
  useSearchRemittanceMapsQuery,
} = remittanceMapApi;
