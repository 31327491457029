import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import AddCategory from "../components/modals/AddCategory";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useCreateMerchantCategoryMutation,
  useDeleteMerchantCategoryMutation,
  useSearchMerchantCategoriesQuery,
} from "../services/MerchantCategoryService";
import { MerchantCategoriesTable } from "../data/MerchantCategoriesTable";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const MerchantCategories = () => {
  const dataGridService = new DataGridService("MerchantCategories");
  const [filter, setFilter] = useState({});
  const [open, setOpen] = React.useState(false);
  const { data: merchantCategoryData, isLoading: isMerchantCategoryLoading } =
    useSearchMerchantCategoriesQuery(filter);
  const [createMerchantCategory] = useCreateMerchantCategoryMutation();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [deleteCategory] = useDeleteMerchantCategoryMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [categoryRequest, setCategoryRequest] = useState(null);

  const handleSubmitCategory = async (event) => {
    event.preventDefault();
    await createMerchantCategory(categoryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Category created succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to create category");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create category");
      });
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["requiresNotificationOnOrderPlaced"].includes(name)) {
      setCategoryRequest((prev) => ({ ...prev, [name]: checked }));
    } else {
      setCategoryRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDeleteDailog = () => {
    setOpenDelete(false);
  };
  const handleDeleteAction = async (row) => {
    setOpenDelete(true);
    setSelectedCategory(row);
  };
  const handleSubmit = async () => {
    await deleteCategory(selectedCategory?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Category deleted succesfully");
        } else {
          ErrorToast("Failed to delete Category");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Category");
      })
      .finally(() => {
        setOpenDelete(false);
        setSelectedCategory(null);
      });
  };
  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  const columns = MerchantCategoriesTable(handleDeleteAction);
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Categories</Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          sx={{ borderRadius: "20px" }}
          onClick={handleClickOpen}
        >
          Add Category
        </Button>
      </Box>
      <AddCategory
        open={open}
        handleClose={handleClose}
        handleSubmitCategory={handleSubmitCategory}
        handleFormChange={handleFormChange}
      />
      <CustomAlertDialog
        open={openDelete}
        handleClose={handleCloseDeleteDailog}
        handleSubmit={handleSubmit}
        title={"Delete Categories"}
        subTitle={`Are you sure you want to delete ${selectedCategory?.label}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            filterMode="server"
            paginationMode="server"
            loading={isMerchantCategoryLoading}
            rowCount={merchantCategoryData?.size ?? 0}
            columns={columns}
            rows={merchantCategoryData?.data ?? []}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default MerchantCategories;
