import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const CurrenciesTable = ({
  handleViewCurrency,
  handleEditAction,
  handleDeleteAction,
}) => {
  return [
    {
      field: "name",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "initial",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Initials",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "majorDenomination",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Major Denomination",
      valueGetter: ({ value }) => value ?? "-",
    },
    {
      field: "minorDenomination",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Minor Denomination",
      valueGetter: ({ value }) => value ?? "-",
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewCurrency(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
