import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import {
  useGetTransactionLimitsQuery,
  useUpdateTransactionLimitsMutation,
} from "../services/TransactionLimitService";

import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { useGetAllCountriesQuery } from "../services/CountryService";
import { useGetAllMerchantCategoriesQuery } from "../services/MerchantCategoryService";
import { useGetAllTransactionsQuery } from "../services/TransactionService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditTransactionLimit = () => {
  const { id } = useParams();

  const [updateTransactionLimit] = useUpdateTransactionLimitsMutation();

  let {
    isLoading,
    data: transactionLimitData,
    isSuccess,
  } = useGetTransactionLimitsQuery(id);

  const [transactionLimitRequest, setTransactionLimitRequest] = useState(null);

  const { isLoading: countryLoading, data: countries } =
    useGetAllCountriesQuery();
  const { isLoading: merchantLoading, data: categories } =
    useGetAllMerchantCategoriesQuery();
  const { isLoading: transactionLoading, data: transactions } =
    useGetAllTransactionsQuery();

  const navigate = useNavigate();

  const updateFields = (fields) => {
    setTransactionLimitRequest((prev) => ({ ...prev, ...fields }));
  };

  const handleSubmitTransactionLimitRequest = async (event) => {
    event.preventDefault();

    let updateRequestBody = {
      id: transactionLimitRequest.id,
      transactional: transactionLimitRequest.transactional,
      daily: transactionLimitRequest.daily,
      transactionId: transactionLimitRequest.transactionId,
      enabled: transactionLimitRequest.enabled,
      merchantCategory: transactionLimitRequest.merchantCategory,
      sendingCountryCode: transactionLimitRequest.sendingCountryCode,
    };

    await updateTransactionLimit(updateRequestBody)
      .then((response) => {
        if (response?.data) {
          SuccessToast("Transaction Limit updated succesfully");
        } else {
          ErrorToast("Failed to update Transaction Limit");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Transaction Limit");
      });
  };

  const initTransactionLimitRequest = () => {
    if (transactionLimitData) {
      setTransactionLimitRequest(transactionLimitData.data);
    }
  };

  React.useEffect(() => {
    initTransactionLimitRequest();
  }, [transactionLimitData]);

  if (transactionLimitRequest) {
    let {
      transactional,
      daily,
      enabled,
      merchantCategory,
      sendingCountryCode,
      transactionId,
    } = transactionLimitRequest;

    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
          <Typography variant="h5">Edit Transaction Limit</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "70%",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Sending Country
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={sendingCountryCode}
                label="Sending Country"
                name="sendingCountry"
                onChange={(e) =>
                  updateFields({ sendingCountryCode: e.target.value })
                }
              >
                {countries?.map((country, i) => (
                  <MenuItem key={i} value={country.isoCode}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Merchant Category
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={merchantCategory}
                label="Merchant Category"
                name="merchantCategory"
                onChange={(e) =>
                  updateFields({ merchantCategory: e.target.value })
                }
              >
                {categories?.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <TextField
              label="Transactional"
              variant="outlined"
              type="number"
              name="transactional"
              value={transactional}
              onChange={(e) => {
                updateFields({ transactional: e.target.value });
              }}
              fullWidth
              size="small"
            />
            <TextField
              label="Daily"
              variant="outlined"
              type="number"
              name="daily"
              value={daily}
              onChange={(e) => updateFields({ daily: e.target.value })}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ mt: 4, display: "flex", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <FormControlLabel
                control={<Switch />}
                label="Enable"
                checked={enabled}
                onChange={() => {
                  updateFields({ enabled: !enabled });
                }}
              />
            </FormControl>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Transaction Id
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={transactionId}
                label="Transaction Id"
                name="transactionId"
                onChange={(e) =>
                  updateFields({ transactionId: e.target.value })
                }
              >
                {transactions?.data?.data.map((transaction, index) => (
                  <MenuItem key={index} value={transaction.id}>
                    {transaction.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            onClick={handleSubmitTransactionLimitRequest}
            variant="contained"
            sx={{ borderRadius: "20px" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditTransactionLimit;
