import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  useGetMerchantBillCategoryQuery,
  useGetMerchantConstructionCategoryQuery,
  useGetMerchantFundCategoryQuery,
  useGetMerchantLabCategoryQuery,
  useGetMerchantMedicineCategoryQuery,
  useGetMerchantSchoolCategoryQuery,
} from "../../services/MerchantService";

const TopCountries = () => {
  // Define a function to calculate the count for each category
  const getCountForCategory = (data) => {
    return data ? data.length : 0;
  };

  const billCategoryData = useGetMerchantBillCategoryQuery();
  const constructionCategoryData = useGetMerchantConstructionCategoryQuery();
  const fundCategoryData = useGetMerchantFundCategoryQuery();
  const labCategoryData = useGetMerchantLabCategoryQuery();
  const medicineCategoryData = useGetMerchantMedicineCategoryQuery();
  const schoolCategoryData = useGetMerchantSchoolCategoryQuery();

  const categories = [
    { name: "Bill", count: getCountForCategory(billCategoryData.data) },
    {
      name: "Construction",
      count: getCountForCategory(constructionCategoryData.data),
    },
    { name: "Fund", count: getCountForCategory(fundCategoryData.data) },
    { name: "Lab", count: getCountForCategory(labCategoryData.data) },
    { name: "Medicine", count: getCountForCategory(medicineCategoryData.data) },
    { name: "School", count: getCountForCategory(schoolCategoryData.data) },
  ];

  return (
    <Box sx={{ padding: "15px" }}>
      <Typography variant="h5">Merchants Category</Typography>
      {/* <Typography sx={{ fontSize: "12px", opacity: 0.7 }}>
        Total number of merchants in the various categories
      </Typography> */}
      <Box sx={{ my: 2 }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          sx={{ my: 3 }}
        >
          <Stack direction={"row"} alignItems="center" spacing={1}>
            <Typography>Category</Typography>
          </Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
            Total Count
          </Typography>
        </Stack>
        {/* Map through the categories and display counts */}
        {categories.map((category, index) => (
          <Stack
            key={index}
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
            sx={{ my: 1 }}
          >
            <Stack direction={"row"} alignItems="center" spacing={1}>
              <Typography>{category.name}</Typography>
            </Stack>
            <Typography>{category.count}</Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default TopCountries;
