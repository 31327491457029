import React from 'react'
import { Box, FormControl, MenuItem, IconButton, InputAdornment, Select, TextField, InputLabel } from "@mui/material"
import { measurementMetrics } from './dropdownsData'
import { FiTrash2 } from 'react-icons/fi';
import Chip from '@mui/material/Chip';


const AvailableMeasurementComponent = ({ componentKey, setProductRequest, disabled, availableMeasurement }) => {

    const handleAvailableMeasurementChange = (event) => {
        const { name, value } = event.target;

        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableMeasurement: prevProduct.availableMeasurement.map((availableMeasurement, index) =>
                index === componentKey ? { ...availableMeasurement, [name]: value } : availableMeasurement
            ),
        }));
    };

    const handleRemoveAvailableMeasurement = () => {
        setProductRequest((prevProduct) => ({
            ...prevProduct,
            availableMeasurement: prevProduct.availableMeasurement.filter((_, index) => index !== componentKey),
        }));
    };

    return (
        <Box key={componentKey} sx={{ display: "flex", alignItems: "center", mt: 1, gap: 1 }}>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="available-measurement-label">Available Dimension</InputLabel>
                <Select
                    labelId="available-measurement-label"
                    name="metric"
                    disabled={disabled}
                    value={availableMeasurement.metric}
                    onChange={handleAvailableMeasurementChange}
                    label="Available dimension"
                >
                    {measurementMetrics.map(({ label, value }) => (<MenuItem value={value}>{label}</MenuItem>))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                label="Length"
                variant="outlined"
                disabled={disabled}
                rows={4}
                value={availableMeasurement.length}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{measurementMetrics.find((measurement) => measurement?.value === availableMeasurement?.metric)?.prefix}</InputAdornment>
                }}
                name="length"
                onChange={handleAvailableMeasurementChange}
                size="small"
            />
            <TextField
                fullWidth
                label="Width"
                variant="outlined"
                value={availableMeasurement.width}
                disabled={disabled}
                rows={4}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{measurementMetrics.find((measurement) => measurement?.value === availableMeasurement?.metric)?.prefix}</InputAdornment>
                }}
                name="width"
                onChange={handleAvailableMeasurementChange}
                size="small"
            />
            <Chip
                variant="outlined"
                color="error"
                label="Remove"
                deleteIcon={<FiTrash2 size={15}/>}
                onDelete={handleRemoveAvailableMeasurement}
                onClick={handleRemoveAvailableMeasurement}
            />
        </Box>

    )
}

export default AvailableMeasurementComponent