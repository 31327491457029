import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { FiTrash2 } from "react-icons/fi";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { DevicesTable } from "../data/devicesTable";
import {
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
  useSearchDevicesQuery,
} from "../services/DevicesService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const Devices = () => {
  const dataGridService = new DataGridService("Devices");

  const [filter, setFilter] = useState({});
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { data: deviceData, isLoading: isDeviceLoading } =
    useSearchDevicesQuery(filter);

  const [deleteDevice, { isSuccess: isDeviceDeleteSuccess }] =
    useDeleteDeviceMutation();
  const [updateStatus, { isSuccess: isDeviceEnabled }] =
    useUpdateDeviceMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [deviceRequest, setDeviceRequest] = useState(null);
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const printRef = useRef();

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handleClose = () => {
    setOpen(false);
    setOpenView(false);
    setOpenUpdate(false);
  };

  const handleSubmit = async () => {
    await deleteDevice(selectedDevice.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Device deleted succesfully");
        } else {
          ErrorToast("Failed to delete Device");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Device");
      })
      .finally(() => {
        setOpen(false);
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedDevice(row);
  };

  const handleViewDevice = async (row) => {
    setOpenView(true);
    setSelectedDevice(row);
  };
  const handleUpdateStatus = async (row) => {
    setSelectedDevice(row);

    selectedDevice?.enabled ? setDeviceRequest(false) : setDeviceRequest(true);

    await updateStatus(deviceRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast(
            `Device ${selectedDevice?.enabled ? "Disabled" : "Enabled"}`
          );
        } else {
          ErrorToast("Failed to update Device");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Device");
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const columns = DevicesTable(
    handleDeleteAction,
    handleViewDevice,
    handleUpdateStatus
  );

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Devices</Typography>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Merchant"}
          subTitle={`Are you sure you want to delete ${selectedDevice?.brand}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Dialog
          open={openView}
          fullWidth={true}
          onClose={handleClose}
          sx={{ mx: "auto", my: "0px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Device Details </DialogTitle>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <DialogContent ref={printRef}>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Id: <span style={{ opacity: 0.7 }}>{selectedDevice?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Username:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedDevice?.username ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              User Id:{" "}
              <span style={{ opacity: 0.7 }}>{selectedDevice?.userdId}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Manufacturer:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedDevice?.manufacturer}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Brand:{" "}
              <span style={{ opacity: 0.7 }}>{selectedDevice?.brand}</span>
            </Typography>

            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Model:{" "}
              <span style={{ opacity: 0.7 }}>{selectedDevice?.model}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Enabled:{" "}
              <span style={{ opacity: 0.7 }}>{selectedDevice?.enabled}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Date:{" "}
              <span style={{ opacity: 0.7 }}>
                {" "}
                {moment(selectedDevice?.created).format("LLL") ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Updated:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedDevice?.updated).format("LLL") ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Created by:{" "}
              <span style={{ opacity: 0.7 }}>{selectedDevice?.createdBy}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Last Modified by:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedDevice?.lastModifiedBy}
              </span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              loading={isDeviceLoading}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              rowCount={deviceData?.size ?? 0}
              rows={deviceData?.data ?? []}
              columns={columns}
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default Devices;
