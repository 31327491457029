import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllPaymentTasksQuery } from "../services/PaymentTaskService";
import { useGetAllCountriesQuery } from "../services/CountryService";
import { useGetAllPaymentMediaQuery } from "../services/PaymentMediaService";
import { useCreatePaymentProviderMutation } from "../services/PaymentProviderService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddPaymentProvider = () => {
  const [createPaymentProvider] = useCreatePaymentProviderMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const { isLoading: taskLoading, data: paymentTasksData } =
    useGetAllPaymentTasksQuery();
  const { isLoading: countriesLoading, data: countriesData } =
    useGetAllCountriesQuery();
  const { isLoading: mediaLoading, data: mediaData } =
    useGetAllPaymentMediaQuery();
  const [paymentTasks, setPaymentTasks] = useState([]);
  const [countries, setCountries] = useState([]);
  const [media, setMedia] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const navigate = useNavigate();

  const INITIAL_DATA = {
    countriesAvailable: [],
    configuration: [],
    media: [],
    enabled: false,
    name: "",
  };

  const [formData, setFormData] = useState(INITIAL_DATA);

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const setDropDowns = () => {
    setPaymentTasks(paymentTasksData?.data?.data);
    setCountries(countriesData);
    setMedia(mediaData?.data?.data);
  };

  useEffect(() => {
    setDropDowns();
  }, [countriesData, paymentTasksData, mediaData]);

  const handleSubmitPaymentProvider = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    await createPaymentProvider(formData)
      .then((response) => {
        if (response?.data) {
          SuccessToast("Payment Provider created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Payment Provider");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Payment Provider");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <KeyboardBackspace /> BACK
        </Button>
        <Typography size="small" variant="h5">
          Add Payment Provider
        </Typography>
      </Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <TextField
              label="Name"
              value={formData.name}
              onChange={(e) => updateFields({ name: e.target.value })}
              fullWidth
              size="small"
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="paymenttasks">Payment Tasks</InputLabel>
            <Select
              labelId="paymenttasks"
              name="paymenttasks"
              fullWidth
              multiple
              value={formData.configuration}
              onChange={(e) => {
                updateFields({ configuration: e.target.value });
              }}
              renderValue={(selected) => {
                return selected.map((select) => select.name).join(", ");
              }}
              label="paymenttasks"
            >
              {paymentTasks &&
                paymentTasks.map((subCategory, index) => (
                  <MenuItem key={index} value={subCategory}>
                    {subCategory?.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="availablecountries">Available Countries</InputLabel>
            <Select
              labelId="availablecountries"
              name="availablecountries"
              fullWidth
              multiple
              value={selectedCountries}
              renderValue={(selected) => {
                return selected.map((select) => select.isoCode).join(", ");
              }}
              onChange={(e) => {
                setSelectedCountries(e.target.value);

                const newArray = selectedCountries.map((arr) => {
                  let { id, currency, ...newObj } = arr;
                  return newObj;
                });
                updateFields({ countriesAvailable: newArray });
              }}
              label="Available Countries"
            >
              {countries &&
                countries.map((country, index) => (
                  <MenuItem key={index} value={country}>
                    {country?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="media">Media</InputLabel>
            <Select
              labelId="media"
              name="media"
              fullWidth
              multiple
              value={formData.media}
              renderValue={(selected) => {
                return selected.map((select) => select.filename).join(", ");
              }}
              onChange={(e) => {
                updateFields({ media: e.target.value });
              }}
              label="Media"
            >
              {media &&
                media.map((med, index) => (
                  <MenuItem key={index} value={med}>
                    {med?.filename}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControlLabel
            control={<Switch />}
            label="Enable"
            checked={formData.enabled}
            onChange={() => {
              updateFields({ enabled: !formData.enabled });
            }}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitPaymentProvider}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddPaymentProvider;
