import React from "react";
import {
  Box,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Divider,
  Chip,
} from "@mui/material";
import { Item } from "../components/common/Item";
import { contentTypes, httpMethods } from "../components/common/dropdownsData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useProductContext } from "../presentation/shared/hooks/useProductContextHook";
import queryString from "query-string";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { Add } from "@mui/icons-material";
import { FiTrash2 } from "react-icons/fi";
import {
  useCreateServiceTaskConfigurationMutation,
  useDeleteServiceTaskConfigurationMutation,
  useSearchServiceTaskConfigurationsMutation,
  useUpdateServiceTaskConfigurationMutation,
} from "../services/ServiceTaskConfigurationService";
import { createServiceTaskConfigurationRequest } from "../models/serviceTaskConfiguration";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetAllServiceTasksQuery } from "../services/ServiceTaskService";
import { ServiceTaskConfigurationsTable } from "../data/ServiceTaskConfigurationsTable";
import EditServiceTaskResponseConfiguration from "./EditServiceTaskResponseConfiguration";
import EditHttpHeader from "./EditHttpHeader";
import PaperWrapper from "../components/common/PaperWrapper";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditServiceTaskConfiguration = () => {
  const { id: productId } = useParams();
  const filter = queryString.stringify({ page: 10000 });

  const {
    refetchProduct,
    setTab,
    productData: { configuration },
    isProductLoading,
  } = useProductContext();
  const [updateServiceTaskConfig, { isLoading: isServiceTaskUpdateLoading }] =
    useUpdateServiceTaskConfigurationMutation();
  const [
    createServiceTaskConfig,
    { isLoading: isCreateServiceTaskConfigLoading },
  ] = useCreateServiceTaskConfigurationMutation();
  const [
    deleteServiceTaskConfiguration,
    { isLoading: isDeleteServiceTaskConfigurationLoading },
  ] = useDeleteServiceTaskConfigurationMutation();
  const { data: serviceTasks } = useGetAllServiceTasksQuery(
    queryString.stringify({
      productConfigurationId: configuration?.id,
      size: 10000,
    })
  );
  const [serviceTaskConfigRequest, setServiceTaskConfigRequest] =
    useState(null);
  const [
    showDeleteServiceTaskConfigurationDialog,
    setShowDeleteServiceTaskConfigurationDialog,
  ] = useState(false);
  const [open, setOpen] = useState(false);
  const defaultServiceTask = { id: null, name: "All  service task" };
  const [
    searchSearviceTaskConfig,
    { data: serviceTaskConfigData, isLoading: isServiceTaskConfigLoading },
  ] = useSearchServiceTaskConfigurationsMutation();
  const [
    selectedServiceTaskConfigurationToDelete,
    setSelectedTaskConfigurationToDelete,
  ] = useState(null);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [selectedServiceTaskForFilter, setSelectedServiceTaskForFilter] =
    useState(null);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const updateNestedProperty = (obj, path, value) => {
      const [current, ...rest] = path.split(".");
      if (path === "serviceTaskId") {
        setServiceTaskConfigRequest((prevServiceTaskConfigurationRequest) => ({
          ...prevServiceTaskConfigurationRequest,
          [path]: value.id,
        }));
      } else if (rest.length === 0) {
        return { ...obj, [current]: value };
      } else {
        return {
          ...obj,
          [current]: updateNestedProperty(obj[current], rest.join("."), value),
        };
      }
    };

    setServiceTaskConfigRequest((prevServiceTaskConfig) =>
      updateNestedProperty(prevServiceTaskConfig, name, value)
    );
  };

  const initCreateServiceTaskConfigRequest = () => {
    setServiceTaskConfigRequest((prevServiceTaskConfig) => ({
      ...createServiceTaskConfigurationRequest,
    }));
  };
  const handleSwitchTab = () => {
    setTab(2);
  };
  const handleShowServiceTaskConfigurationDialogOnNoServiceConfigurationTask =
    () => {
      setOpen(true);
    };
  const handleCloseDeleteServiceTaskConfigurationDialog = () => {
    setShowDeleteServiceTaskConfigurationDialog(false);
  };
  const handleCloseServiceTaskConfigurationDialog = () => {
    setOpen(false);
  };
  const handleCancelServiceTaskConfigRequest = () => {
    setServiceTaskConfigRequest(null);
  };

  const handleDeleteServiceTaskConfiguration = async (event) => {
    event.preventDefault();
    setShowDeleteServiceTaskConfigurationDialog(false);

    await deleteServiceTaskConfiguration(
      selectedServiceTaskConfigurationToDelete.id
    )
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task configuration deleted successfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to delete service task configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete service task configuration ");
      });
  };

  const handleDeleteAction = (row) => {};
  const handleEditAction = (row) => {
    setServiceTaskConfigRequest(row);
  };
  const handleSelectedServiceTaskForFilter = (event) => {
    const { value } = event.target;
    setSelectedServiceTaskForFilter(value);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel);
  };

  const handleUpdateServiceTaskConfiguration = async (event) => {
    event.preventDefault();

    await updateServiceTaskConfig(serviceTaskConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task configuration updated succesfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to update service task configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update service task configuration");
      });
  };

  const handleCreateServiceTaskConfiguration = async (event) => {
    event.preventDefault();

    await createServiceTaskConfig(serviceTaskConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Service task configuration created succesfully");
        } else {
          ErrorToast("Failed to create service task configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create service task configuration ");
      });
  };

  const handleGetServiceTaskConfiguration = async () => {
    if (!selectedServiceTaskForFilter && serviceTasks?.[0]) {
      setSelectedServiceTaskForFilter(serviceTasks[0]);
    }

    const params = {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      serviceTaskId: selectedServiceTaskForFilter?.id,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortBy: sortModel[0]?.field,
    };

    const filterServiceTaskConfigurationRequest = queryString.stringify(
      params,
      {
        skipNull: true,
        strict: true,
        skipEmptyString: true,
      }
    );

    await searchSearviceTaskConfig(filterServiceTaskConfigurationRequest);
  };

  const columns = ServiceTaskConfigurationsTable({
    handleEditAction,
    handleDeleteAction,
  });

  useEffect(() => {
    handleGetServiceTaskConfiguration();
  }, [
    paginationModel,
    filterModel,
    sortModel,
    selectedServiceTaskForFilter,
    serviceTasks,
  ]);

  return (
    <Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "90%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={serviceTaskConfigRequest}
            onClick={initCreateServiceTaskConfigRequest}
            startIcon={<Add />}
            sx={{ float: "right" }}
            variant="contained"
          >
            Add Service Task Configuration
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={12} md={12}>
            <TextField
              fullWidth
              label="Endpoint"
              variant="outlined"
              name="endpoint"
              value={serviceTaskConfigRequest?.endpoint}
              onChange={handleFormChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={12} md={12}>
            <TextField
              fullWidth
              label="Sample Body"
              variant="outlined"
              placeholder="Hello world..."
              value={serviceTaskConfigRequest?.sampleRequestBody}
              rows={5}
              multiline
              name="sampleRequestBody"
              onChange={handleFormChange}
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">HTTP Method</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Http method"
                value={serviceTaskConfigRequest?.method}
                name="method"
                onChange={handleFormChange}
              >
                {httpMethods.map((method) => (
                  <MenuItem value={method}>{method}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">Content type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Content Type"
                defaultValue={""}
                value={serviceTaskConfigRequest?.contentType}
                name="contentType"
                onChange={handleFormChange}
              >
                {contentTypes.map((contentType) => (
                  <MenuItem value={contentType}>{contentType}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">
                Service tasks
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Service tasks"
                defaultValue=""
                name="serviceTaskId"
                onChange={handleFormChange}
              >
                {configuration &&
                  configuration.customerTasks.map((serviceTask) => (
                    <MenuItem value={serviceTask}>{serviceTask.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                display: "flex",
                mt: 10,
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelServiceTaskConfigRequest}
              >
                {(isProductLoading ||
                  isCreateServiceTaskConfigLoading ||
                  isServiceTaskUpdateLoading) && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                    color="secondary"
                  />
                )}
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={
                  serviceTaskConfigRequest?.id
                    ? handleUpdateServiceTaskConfiguration
                    : handleCreateServiceTaskConfiguration
                }
              >
                {(isServiceTaskConfigLoading ||
                  isCreateServiceTaskConfigLoading ||
                  isServiceTaskUpdateLoading) && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                    color="secondary"
                  />
                )}
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} sx={{ mt: 5 }}>
            <Item>
              <Box>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  marginBottom={2}
                >
                  <FormControl sx={{ minWidth: 250 }} size="small">
                    <InputLabel id="demo-select-small-label">
                      Service Task
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Service task"
                      required
                      placeholder="Select Service task"
                      value={selectedServiceTaskForFilter?.name}
                      name="serviceTaskId"
                      onChange={handleSelectedServiceTaskForFilter}
                    >
                      {serviceTasks &&
                        serviceTasks.map((serviceTask, index) => (
                          <MenuItem key={index} value={serviceTask}>
                            {serviceTask.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <Typography variant="h5">
                    {selectedServiceTaskForFilter &&
                      `${selectedServiceTaskForFilter?.name} -`}{" "}
                    Service Task Configuration
                  </Typography>
                </Stack>
                <Box>
                  <TableContainer component={Paper}>
                    <DataGrid
                      autoHeight
                      loading={isServiceTaskConfigLoading}
                      sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: "gray",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      filterMode="server"
                      paginationMode="server"
                      rows={serviceTaskConfigData?.data ?? []}
                      rowCount={serviceTaskConfigData?.size || 0}
                      columns={columns}
                      disableColumnSelector
                      disableDensitySelector
                      slotProps={{
                        panel: {
                          sx: {
                            top: "-120px !important",
                          },
                        },
                      }}
                      getRowId={(row) => row.id}
                      slots={{ toolbar: GridToolbar }}
                      paginationModel={paginationModel}
                      onPaginationModelChange={handlePaginationModelChange}
                      onSortModelChange={handleSortModelChange}
                      onFilterModelChange={handleFilterModelChange}
                    />
                  </TableContainer>
                </Box>
              </Box>
            </Item>
          </Grid>{" "}
          <Grid item xs={6} sm={12} md={12}>
            {serviceTaskConfigRequest?.id && (
              <>
                <Divider>
                  <Chip
                    label="Advanced Configuration"
                    variant="outlined"
                    size="sm"
                  />
                </Divider>

                <PaperWrapper>
                  <Item>
                    {serviceTaskConfigRequest?.id && (
                      <EditHttpHeader
                        serviceTaskConfigurationId={serviceTaskConfigRequest.id}
                      />
                    )}
                  </Item>
                  <Divider />
                  <Item>
                    {serviceTaskConfigRequest?.id && (
                      <EditServiceTaskResponseConfiguration
                        serviceTaskConfigurationId={serviceTaskConfigRequest.id}
                        id={serviceTaskConfigRequest?.responseConfiguration?.id}
                      />
                    )}
                  </Item>
                </PaperWrapper>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>

      <CustomAlertDialog
        open={showDeleteServiceTaskConfigurationDialog}
        handleClose={handleCloseDeleteServiceTaskConfigurationDialog}
        handleSubmit={handleDeleteServiceTaskConfiguration}
        title={"Delete Service task configuration"}
        subTitle={`Are you sure you want to delete service task configuration: ${selectedServiceTaskConfigurationToDelete?.name}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
    </Box>
  );
};

export default EditServiceTaskConfiguration;
