import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceConfigurationApi = createApi({
  reducerPath: "remittanceConfigurationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["RemittanceConfiguration"],
  endpoints: (builder) => ({
    getAllRemittanceConfigurations: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/configurations/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["RemittanceConfiguration"],
    }),

    getRemittanceConfiguration: builder.query({
      query: (id) => ({
        url: `/configurations/${id}`,
        method: "GET",
      }),
      providesTags: ["RemittanceConfiguration"],
    }),

    searchRemittanceConfigurations: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/configurations?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["RemittanceConfiguration"],
    }),
    updateRemittanceConfiguration: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/configurations",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceConfiguration"],
    }),
    createRemittanceConfiguration: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/configurations",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceConfiguration"],
    }),
    deleteRemittanceConfiguration: builder.mutation({
      query: (id) => ({
        url: `/configurations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RemittanceConfiguration"],
    }),
  }),
});

export const {
  useGetAllRemittanceConfigurationsQuery,
  useGetRemittanceConfigurationQuery,
  useCreateRemittanceConfigurationMutation,
  useDeleteRemittanceConfigurationMutation,
  useUpdateRemittanceConfigurationMutation,
  useSearchRemittanceConfigurationsQuery,
} = remittanceConfigurationApi;
