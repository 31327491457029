import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import { useGetAllDomainAccountsQuery } from "../../services/DomainAcountService";
import queryString from "query-string";
import { DomainType } from "../../presentation/helpers";

const CaseAgentModal = ({
  open,
  handleClose,
  addCaseAgent,
  setAddCaseAgentRequest,
  addCaseAgentRequest,
}) => {
  const [admins, setAdmins] = React.useState([]);
  const { data: AllDomainAccounts, isSuccess } = useGetAllDomainAccountsQuery(
    queryString.stringify({ size: 10000 })
  );

  function filterData(data = []) {
    return data.filter(
      (admin) =>
        admin.domainType === DomainType.SUPERMAN ||
        admin.domainType === DomainType.BACK_OFFICE
    );
  }

  React.useEffect(() => {
    setAdmins(filterData(AllDomainAccounts) ?? []);
  }, [AllDomainAccounts]);

  const handleChange = (user) => {
    setAddCaseAgentRequest(user);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>Add A Case Agent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Select The User You Want Add As An Agent
            </Typography>
          </DialogContentText>

          <FormControl fullWidth size="small" required>
            <InputLabel label="demo-simple-select-label">Users</InputLabel>
            <Select
              id="demo-simple-select"
              label={"User"}
              size="small"
              labelId="demo-simple-select-label"
            >
              {admins?.map((User) => (
                <MenuItem
                  value={User}
                  key={User?.id}
                  onClick={() => handleChange(User)}
                >
                  {User.firstName} {User.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addCaseAgent} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CaseAgentModal;
