import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { loginUrl } from "../presentation/shared/ApiUrls";

export const logoutApi = createApi({
  reducerPath: "logoutApi",
  baseQuery: fetchBaseQuery({
    baseUrl: loginUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    logoutDomainUser: builder.mutation({
      query: (token) => ({
        url: `/token/revoke?${token}`,
        method: "GET",
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const { useLogoutDomainUserMutation } = logoutApi;
