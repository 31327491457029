import * as React from "react";
import {
  Button,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  Dialog,
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,
  Checkbox,
  TextField,
  FormGroup,
} from "@mui/material";
import { contactTypes } from "../common/dropdownsData";

const MerchantContactModal = ({
  open,
  handleClose,
  merchantContact,
  type,
  handleFormChange,
  handleSubmit,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle
        textAlign={"center"}
      >{`${type} MERCHANT CONTACT`}</DialogTitle>
      <DialogContent>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              ID: <span style={{ opacity: 0.7 }}>{merchantContact?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Contact type:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantContact?.contactType}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Contact:{" "}
              <span style={{ opacity: 0.7 }}>{merchantContact?.contact}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Reachable:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantContact?.contactable ? "YES" : "NO"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Primary contact:{" "}
              <span style={{ opacity: 0.7 }}>
                {merchantContact?.primaryContact ? "YES" : "NO"}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container padding={5}>
            <Grid item sm={12} xs={12} md={6}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">
                  Contact type
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Contact type"
                  name="contactType"
                  placeholder="Select Contact type"
                  value={merchantContact?.contactType}
                  onChange={handleFormChange}
                >
                  {contactTypes.map((contactType) => (
                    <MenuItem value={contactType}>
                      {contactType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="Contact"
                name="contact"
                variant="outlined"
                value={merchantContact?.contact}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            {type === "UPDATE" && (
              <Grid item sm={12} xs={12} md={12}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Are you reachable?"
                      name="contactable"
                      checked={merchantContact?.contactable}
                      size="small"
                      onChange={handleFormChange}
                    />
                  </span>
                </FormGroup>
              </Grid>
            )}
            {type === "UPDATE" && (
              <Grid item sm={12} xs={12} md={12}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Primary contact?"
                      name="primaryContact"
                      checked={merchantContact?.primaryContact}
                      size="small"
                      onChange={handleFormChange}
                    />
                  </span>
                </FormGroup>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>

            <Button onClick={handleSubmit} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MerchantContactModal;
