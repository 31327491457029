import { Box, Typography } from "@mui/material";
import React from "react";
import Table from "../components/Table";
import { remittanceJobColumns, remittanceJobs } from "../data/remittanceJob";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";

const Remittance = () => {
  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Typography variant="h6" sx={{ marginBottom: "14px" }}>
          Remittance
        </Typography>
        <Table
          data={remittanceJobs}
          fields={remittanceJobColumns}
          numberOfRows={remittanceJobs.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
        />
      </Box>
    </SecuredComponent>
  );
};

export default Remittance;
