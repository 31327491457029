import { Box, Paper, TableContainer, Typography } from "@mui/material";
import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteForexActivityLogMutation,
  useSearchForexActivityLogsMutation,
} from "../services/ForexActivityLogService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { ForexActivityLogTable } from "../data/ForexActivityLogTable";
import queryString from "query-string";
import { useEffect } from "react";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const ForexActivityLog = () => {
  const [selectedForexActivityLog, setSelectedForexActivityLog] =
    useState(null);
  const [open, setOpen] = useState(false);
  const [getForexActivityLog, { data, isLoading }] =
    useSearchForexActivityLogsMutation();
  const [deleteForexActivityLog] = useDeleteForexActivityLogMutation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setSelectedForexActivityLog(null);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedForexActivityLog(row);
  };

  const handleSearchForexActivityLog = async () => {
    const filterForexActivityLogRequest = queryString.stringify(
      {
        page: paginationModel.page,
        size: paginationModel.pageSize,
        [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
        sortOrder: sortModel[0]?.sort.toUpperCase(),
        sortBy: sortModel[0]?.field,
      },
      { skipNull: true, strict: true, skipEmptyString: true }
    );

    await getForexActivityLog(filterForexActivityLogRequest);
  };

  const handleSubmit = async () => {
    await deleteForexActivityLog(selectedForexActivityLog.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("ForexActivityLog deleted succesfully");
        } else {
          ErrorToast("Failed to delete ForexActivityLog");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete ForexActivityLog");
      });
  };
  const columns = ForexActivityLogTable(handleDeleteAction);
  useEffect(() => {
    handleSearchForexActivityLog();
  }, [paginationModel, filterModel, sortModel]);
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Forex Activity Log</Typography>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete ForexActivityLog"}
        subTitle={`Are you sure you want to delete ${selectedForexActivityLog?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            filterMode="server"
            paginationMode="server"
            loading={isLoading}
            rowCount={data?.size ?? 0}
            columns={columns}
            git
            add
            rows={data?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ForexActivityLog;
