import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceTaskUrl } from "../presentation/shared/ApiUrls";

export const serviceTaskApi = createApi({
  reducerPath: "serviceTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceTaskUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["ServiceTasks"],
  endpoints: (builder) => ({
    getAllServiceTasks: builder.query({
      query: (filterServiceTaskRequest) => ({
        url: `/service-tasks?${filterServiceTaskRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ["ServiceTasks"],
    }),
    searchServiceTasks: builder.mutation({
      query: (filterServiceTaskRequest) => ({
        url: `/service-tasks?${filterServiceTaskRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTasks"],
    }),
    getServiceTask: builder.query({
      query: (id) => ({
        url: `/service-tasks/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTasks"],
    }),
    updateServiceTask: builder.mutation({
      query: (updateServiceTaskRequest) => ({
        url: "/service-tasks",
        method: "PUT",
        body: updateServiceTaskRequest,
      }),
      invalidatesTags: ["ServiceTasks"],
    }),
    createServiceTask: builder.mutation({
      query: (serviceTaskRequest) => ({
        url: "/service-tasks",
        method: "POST",
        body: serviceTaskRequest,
      }),
      invalidatesTags: ["ServiceTasks"],
    }),
    deleteServiceTask: builder.mutation({
      query: (id) => ({
        url: `/service-tasks/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceTasks"],
    }),
  }),
});

export const {
  useGetAllServiceTasksQuery,
  useSearchServiceTasksMutation,
  useGetServiceTaskQuery,
  useCreateServiceTaskMutation,
  useUpdateServiceTaskMutation,
  useDeleteServiceTaskMutation,
} = serviceTaskApi;
