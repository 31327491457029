import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DomainType } from "../../presentation/helpers";

const Profile = () => {
  let userDetails = {};

  if (sessionStorage.getItem("userDetails")) {
    userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  }

  return (
    <Box>
      <Typography variant="subtitle1">Profile</Typography>
      <Divider />
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <TextField
            label="First Name"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={userDetails.firstName}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={userDetails.lastName}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Username"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={userDetails.username}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={userDetails.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={userDetails.phone}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Domain Type"
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={DomainType.SUPERMAN === userDetails.domainType ? "BORGA_ADMIN" :  userDetails.domainType}
            disabled
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
