import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useLoginClientMutation } from "./services/ClientAuthService";
import { clientToken } from "./pages";

export default function AuthLayout() {
  const [loginClient] = useLoginClientMutation();

  const clientLogin = async () => {
    await loginClient(clientToken).then((response) => {
      if (response) {
        sessionStorage.setItem(
          "clientToken",
          response?.data?.data?.access_token
        );
      }
    });
  };

  useEffect(() => {
    clientLogin();
  }, []);

  return <Outlet />;
}
