import {
  Box,
  Divider,
  Grid,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { FaTruck } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { FiCalendar, FiMap, FiUser } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { useGetOrderQuery } from "../services/OrderService";
import LoadingPage from "../components/LoadingPage";
import { useState } from "react";
import { OrderItemsTable } from "../data/OrderItemsTable";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { DataGridService } from "../core/dataGridService";

const SingleOrder = () => {
  const { id } = useParams();
  const dataGridService = new DataGridService("SingleOrder");
  const { data: order, isLoading } = useGetOrderQuery(id);
  const printRef = useRef();

  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const handlePaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel);
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedOrder(row);
  };
  const columns = OrderItemsTable({ handleDeleteAction });

  if (order) {
    return (
      <>
        <Box sx={{ pt: "80px", pb: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pb: "20px",
            }}
          >
            <Link to={-1}>
              <Button variant="outlined">
                <KeyboardBackspace /> BACK
              </Button>
            </Link>

            <Typography variant="h5"> Order Details</Typography>
          </Box>

          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ my: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FiCalendar />
                  <Typography variant="h5">Order</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between" }}
                ref={printRef}
              >
                <ReactToPrint
                  trigger={() => (
                    <Button>
                      <Print />
                    </Button>
                  )}
                  content={() => printRef.current}
                />
              </Box>
            </Box>
            <Divider />
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FiUser />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Customer
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Name:{" "}
                      <span style={{ opacity: 0.7 }}>{order?.createdBy}</span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Active:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.active ? "YES" : "NO"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      User ID:{" "}
                      <span style={{ opacity: 0.7 }}>{order?.userId} </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Exchange Rate: {order?.exchangeInfo?.exchangeRate}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Exchange Pair:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {" "}
                        {order?.exchangeInfo?.exchangePair}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Exchange Date:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {moment(order?.exchangeInfo?.exchangeDate).format(
                          "LLLL"
                        )}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FaTruck />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Order Info
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Order ID:{" "}
                      <span style={{ opacity: 0.7 }}>{order?.id}</span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Name: <span style={{ opacity: 0.7 }}>{order?.name}</span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Transaction ID:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.transactionId ? order?.transactionId : "None"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Paid:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.paid ? order?.paid : "No info"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Category:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.category ? order?.category : "None"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FiMap />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Delivery
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Country:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {`${order?.deliverAddress?.city ?? "N/A"}, ${
                          order?.deliverAddress?.country ?? "N/A"
                        }`}
                      </span>
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Address:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliveryAddress?.addressLocation
                          ? order?.deliveryAddress?.addressLocation
                          : "N/A"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Region or State:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliverAddress?.regionOrState ?? "N/A"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Street Address:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliverAddress?.streetAddress ?? "N/A"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Typography variant="h6" sx={{ mt: 5 }}>
              Order Items Details
            </Typography>
            <Box sx={{ mt: 2 }}>
              <DataGrid
                autoHeight
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: "gray",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                }}
                slotProps={{
                  panel: {
                    sx: {
                      top: "-120px !important",
                    },
                  },
                }}
                loading={isLoading}
                rowCount={order.orderItems.length ?? 0}
                columns={columns}
                rows={order.orderItems ?? []}
                getRowId={(row) => row.id}
                slots={{ toolbar: GridToolbar }}
                disableColumnSelector
                disableDensitySelector
                sortModel={sortModel}
                paginationModel={paginationModel}
                filterModel={filterModel}
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={handleFilterModelChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                mt: 2,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 3,
                    width: "200px",
                  }}
                >
                  <Typography variant="h6">Total</Typography>
                  <Typography variant="h6" sx={{ opacity: 0.8 }}>
                    $8,532
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Grid container spacing={14} sx={{ py: 3, px: 5 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Created:
                    </span>{" "}
                    {order?.created
                      ? moment(order?.created).format("LLLL")
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Updated:
                    </span>{" "}
                    {order?.updated
                      ? moment(order?.updated).format("LLLL")
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleOrder;
