export const updateProductConfigurationRequest = {
  id: "",
  serviceId: "",
  refundPolicy: "",
  retryPolicy: "",
  inputWeightRequired: false,
  inputVolumeRequired: false,
  inputMeasurementRequired: false,
  inputDeliveryContactRequired: false,
  inputDeliveryAddressRequired: false,
  enabled: false,
};
export const createProductConfigurationRequest = {
  serviceId: "",
  refundPolicy: "",
  retryPolicy: "",
  inputWeightRequired: false,
  inputVolumeRequired: false,
  inputMeasurementRequired: false,
  inputDeliveryContactRequired: false,
  inputDeliveryAddressRequired: false,
  enabled: false,
};
