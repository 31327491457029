import { Box, Paper, Tab, Tabs, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { useGetProductQuery } from "../services/ProductService";
import LoadingPage from "../components/LoadingPage";
import SingleProductDescription from "./SingleProductDescription";
import SingleProductConfiguration from "./SingleProductConfiguration";
import ViewServiceTaskConfiguration from "./ViewServiceTaskConfiguration";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewProductLayout = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const {
    data: productData,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
  } = useGetProductQuery(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/pages/products"}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5">Product Details</Typography>
        </Box>

        <Box sx={{ width: "100%", mt: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Product Description" {...a11yProps(0)} />
              <Tab label="Product Configuration" {...a11yProps(1)} />
              <Tab label="Service Task configuration" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {isProductLoading ? (
              <LoadingPage />
            ) : (
              <SingleProductDescription product={productData} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {isProductLoading ? (
              <LoadingPage />
            ) : (
              <SingleProductConfiguration
                productConfig={productData?.configuration}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {isProductLoading ? (
              <LoadingPage />
            ) : (
              <ViewServiceTaskConfiguration
                productConfig={productData?.configuration}
              />
            )}
          </TabPanel>
        </Box>
      </Paper>
    </Box>
  );
};

export default ViewProductLayout;
