import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { transactionsActivitiesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const transactionsActivityApi = createApi({
  reducerPath: "transactionsActivitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: transactionsActivitiesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Transactions_Activities"],
  endpoints: (builder) => ({
    getAllTransactionsActivities: builder.query({
      query: (filterTransactionRequest) => ({
        url: `/transactions/activities?${filterTransactionRequest}`,
        method: "GET",
      }),
      providesTags: ["Transactions_Activities"],
    }),

    searchTransactionActivities: builder.query({
      query: (filterTransactionActivitiesRequest) => ({
        url: `/transactions/activities?${queryString.stringify(
          filterTransactionActivitiesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Transactions_Activities"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
  }),
});

export const {
  useGetAllTransactionsActivitiesQuery,
  useSearchTransactionActivitiesQuery,
} = transactionsActivityApi;
