import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreateMerchantContactMutation,
  useDeleteMerchantContactMutation,
  useUpdateMerchantContactMutation,
} from "../services/MerchantContactService";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import MerchantContactModal from "../components/modals/MerchantContactModal";
import { FiTrash2 } from "react-icons/fi";
import { merchantContact } from "../models/merchantContact";
import { MerchantContactsTable } from "../data/MerchantContactsTable";
import { useMerchantContext } from "../presentation/shared/hooks/useMerchantContext";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

export const EditMerchantContact = () => {
  const dataGridService = new DataGridService("MerchantContacts");
  const { id } = useParams();
  const { merchantData, isMerchantLoading, refetchMerchant } =
    useMerchantContext();
  const [type, setType] = useState("");
  const [merchantContactRequest, setMerchantContactRequest] =
    useState(merchantContact);
  const [openDeleteContactDialog, setOpenDeleteContactDialog] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [createMerchantContact] = useCreateMerchantContactMutation();
  const [updateMerchantContact] = useUpdateMerchantContactMutation();
  const [deleteMerchantContact] = useDeleteMerchantContactMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleShowAddMerchantContactDialog = () => {
    setType("ADD");
    setOpenContactDialog(true);
  };
  const handleCreateMerchantContact = async (event) => {
    event.preventDefault();
    await createMerchantContact({ ...merchantContactRequest, merchantId: id })
      .then((response) => {
        if (response.data) {
          SuccessToast("Contact added successfully");
          refetchMerchant();
        } else {
          ErrorToast("Failed to upload contact");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to upload contact");
      })
      .finally(() => {
        handleCloseDialog();
      });
  };
  const handleUpdateMerchantContact = async (event) => {
    event.preventDefault();
    await updateMerchantContact(merchantContactRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Contact updated successfully");
          handleCloseDialog();
          refetchMerchant();
        } else {
          ErrorToast("Failed to update contact");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update contact");
      })
      .finally(() => {
        handleCloseDialog();
      });
  };

  const handleDeleteMerchantContact = async (event) => {
    event.preventDefault();
    await deleteMerchantContact(merchantContactRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Contact deleted successfully");
          handleCloseDialog();
          refetchMerchant();
        } else {
          ErrorToast("Failed to deleted contact");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to deleted contact");
      }).finally(() => {
        handleCloseDialog();
      });
  };
  const handleMerchantContactChange = (event) => {
    const { name, value, checked } = event.target;
    if (["contactable", "primaryContact"].includes(name)) {
      setMerchantContactRequest((prev) => ({
        ...prev,
        [name]: checked,
        merchantId: id,
      }));
    } else
      setMerchantContactRequest((prev) => ({
        ...prev,
        [name]: value,
        merchantId: id,
      }));
  };

  const handleCloseDialog = () => {
    setOpenDeleteContactDialog(false);
    setOpenContactDialog(false);
    setMerchantContactRequest(merchantContact);
  };

  const handleEditAction = (row) => {
    setType("UPDATE");
    setMerchantContactRequest(row);
    setOpenContactDialog(true);
  };
  const handleDeleteAction = (row) => {
    setMerchantContactRequest(row);
    setOpenDeleteContactDialog(true);
  };
  const handleViewAction = (row) => {
    setType("VIEW");
    setMerchantContactRequest(row);
    setOpenContactDialog(true);
  };

  const merchantContactTable = MerchantContactsTable({
    handleDeleteAction,
    handleEditAction,
    handleViewAction,
  });
  return (
    <Grid item md={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Typography variant="h5">List of Merchant Contacts</Typography>
        <Button
          variant="contained"
          onClick={handleShowAddMerchantContactDialog}
        >
          Add Contact
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <DataGrid
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "gray",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slotProps={{
            panel: {
              sx: {
                top: "-120px !important",
              },
            },
          }}
          loading={isMerchantLoading}
          rowCount={merchantData.contacts?.length ?? 0}
          rows={merchantData?.contacts ?? []}
          columns={merchantContactTable}
          disableColumnSelector
          disableDensitySelector
          getRowId={(row) => row.id}
          slots={{ toolbar: GridToolbar }}
          sortModel={sortModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
        />
      </TableContainer>
      <MerchantContactModal
        open={openContactDialog}
        type={type}
        isMerchantDocumentUpdateLoading
        merchantContact={merchantContactRequest}
        handleClose={handleCloseDialog}
        handleFormChange={handleMerchantContactChange}
        handleSubmit={
          type === "ADD"
            ? handleCreateMerchantContact
            : handleUpdateMerchantContact
        }
      />
      <CustomAlertDialog
        open={openDeleteContactDialog}
        title={"Delete contact"}
        icon={
          <IconButton color="error">
            <FiTrash2 />
          </IconButton>
        }
        handleClose={handleCloseDialog}
        subTitle={`Are you sure you want to delete this contact
                        ${merchantContactRequest?.contact}?`}
        fullWidth
        handleSubmit={handleDeleteMerchantContact}
        positiveButtonText={"YES"}
        negativeButtonText={"NO"}
      />
    </Grid>
  );
};
