import {
  Box,
  Paper,
  Typography,
  IconButton,
  GlobalStyles,
  Button,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { KeyboardBackspace, StorageRounded } from "@mui/icons-material";
import { useGetDomainAccountQuery } from "../services/DomainAcountService";
import moment from "moment";
import LoadingPage from "../components/LoadingPage";
import { FaUser } from "react-icons/fa";

const SingleDomainUser = () => {
  const { id } = useParams();
  const { data } = useGetDomainAccountQuery(id);

  if (data) {
    const { data: domainUser } = data;

    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/pages/domain-users"}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5">Domain User Details</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            mt: "10px",
          }}
        >
          <Box
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly", display: "flex" }}
          >
            <Box item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <FaUser />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>{domainUser.firstName}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Name:{" "}
                    <span style={{ opacity: 0.7 }}>{domainUser.lastName}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Domain Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {domainUser.domainType}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Phone:{" "}
                    <span style={{ opacity: 0.7 }}>{domainUser.phone}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    User Name{" "}
                    <span style={{ opacity: 0.7 }}>{domainUser.username}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Seen{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(domainUser.lastSeen).format("LLLL")}
                    </span>
                  </Typography>
                  {domainUser.roles && domainUser.roles.length > 0 && (
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Roles:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {domainUser.roles.map((role, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && ", "}
                            {role.name}
                          </React.Fragment>
                        ))}
                      </span>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <GlobalStyles
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "15px", mb: 2 }}
                      >
                        Email:{domainUser.email}
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "15px", mb: 2 }}
                      >
                        Status:{domainUser.status}
                      </Typography>
                    </Box>
                    <StorageRounded />
                  </GlobalStyles>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleDomainUser;
