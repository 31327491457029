import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Item } from "../components/common/Item";
import {
  useCreateServiceTaskResponseConfigurationMutation,
  useGetServiceTaskResponseConfigurationQuery,
  useUpdateServiceTaskResponseConfigurationMutation,
} from "../services/ServiceTaskResponseConfigurationService";
import { useEffect } from "react";
import TaskResponseBiilInfoMapComponent from "../components/common/TaskResponseBiilInfoMapComponent";
import PaperWrapper from "../components/common/PaperWrapper";
import MapForDropDownQuestionComponent from "../components/common/MapsForDropDownQuestionComponent";
import ResponseCodeMapComponent from "../components/common/ResponseCodeMapComponent";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditServiceTaskResponseConfiguration = ({
  serviceTaskConfigurationId,
  id,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [createServiceTaskResponseConfig, { isLoading: isCreateLoading }] =
    useCreateServiceTaskResponseConfigurationMutation();
  const [updateServiceTaskResponseConfig, { isLoading: isUpdateLoading }] =
    useUpdateServiceTaskResponseConfigurationMutation();
  const {
    data: serviceTaskResponseConfiguration,
    isLoading: isServiceTaskResponseLoading,
    isSuccess: isServiceTaskResponseSuccess,
  } = useGetServiceTaskResponseConfigurationQuery(id);
  const [responseConfigRequest, setResponseConfigRequest] = useState(
    serviceTaskResponseConfiguration
  );

  const handleInitServiceTaskResponseConfigRequest = () => {
    setResponseConfigRequest({});
  };
  const handleCancelServiceTaskResponseConfigRequest = () => {
    setResponseConfigRequest(null);
  };

  const handleInitResponseBillInfoMap = () => {
    setResponseConfigRequest((prev) => ({
      ...prev,
      mapForCustomerBillInfo: {},
    }));
  };
  const handleInitMapForDropDownSuggestion = () => {
    setResponseConfigRequest((prev) => ({
      ...prev,
      mapForDropDownSuggestion: {},
    }));
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const updateNestedProperty = (obj, path, value) => {
      const [current, ...rest] = path.split(".");
      if (rest.length === 0) {
        return { ...obj, [current]: value };
      } else {
        return {
          ...obj,
          [current]: updateNestedProperty(obj[current], rest.join("."), value),
        };
      }
    };
    setResponseConfigRequest((prevResponseConfig) =>
      updateNestedProperty(prevResponseConfig, name, value)
    );
  };

  const handleAccodionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCreateServiceTaskResponseConfig = async (event) => {
    event.preventDefault();
    setResponseConfigRequest((prevResponseConfig) => ({
      ...prevResponseConfig,
      serviceTaskConfigurationId: serviceTaskConfigurationId,
    }));
    await createServiceTaskResponseConfig(responseConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast(
            "Service task response configuration created succesfully"
          );
        } else {
          ErrorToast("Failed to created service task response configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to created service task response configuration ");
      });
  };

  const handleUpdateServiceTaskResponseConfig = async (event) => {
    event.preventDefault();
    await updateServiceTaskResponseConfig(responseConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast(
            "Service task response configuration updated succesfully"
          );
        } else {
          ErrorToast("Failed to update service task response configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update service task response configuration ");
      });
  };

  useEffect(() => {
    setResponseConfigRequest(serviceTaskResponseConfiguration);
  }, [serviceTaskResponseConfiguration]);

  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccodionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" sx={{ width: "100%", flexShrink: 0 }}>
            Configure service task response configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isServiceTaskResponseLoading && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {isServiceTaskResponseSuccess && responseConfigRequest && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Item>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Attribute for response code"
                        variant="outlined"
                        placeholder="Example: How are you?"
                        value={responseConfigRequest?.attributeForResponseCode}
                        name="attributeForResponseCode"
                        onChange={handleFormChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Attribute for Response Message"
                        variant="outlined"
                        value={
                          responseConfigRequest?.attributeForResponseMessage
                        }
                        name="attributeForResponseMessage"
                        onChange={handleFormChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <PaperWrapper>
                        {responseConfigRequest?.mapForCustomerBillInfo ? (
                          <>
                            <Typography
                              variant="h5"
                              sx={{ width: "100%", flexShrink: 0 }}
                            >
                              Configure Task response bill info map
                            </Typography>
                            <TaskResponseBiilInfoMapComponent
                              handleFormChange={handleFormChange}
                              responseConfigRequest={responseConfigRequest}
                            />
                          </>
                        ) : (
                          <Button
                            sx={{ float: "right" }}
                            variant="contained"
                            onClick={handleInitResponseBillInfoMap}
                          >
                            Add Task Bill Map Info
                          </Button>
                        )}
                      </PaperWrapper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <PaperWrapper>
                        {responseConfigRequest?.mapForDropDownSuggestion ? (
                          <>
                            <Typography
                              variant="h5"
                              sx={{ width: "100%", flexShrink: 0 }}
                            >
                              Configure Task Map for dropdown suggestion
                            </Typography>
                            <MapForDropDownQuestionComponent
                              handleFormChange={handleFormChange}
                              responseConfigRequest={responseConfigRequest}
                            />
                          </>
                        ) : (
                          <Button
                            sx={{ float: "right" }}
                            variant="contained"
                            onClick={handleInitMapForDropDownSuggestion}
                          >
                            Add Task Map for dropdown suggestion
                          </Button>
                        )}
                      </PaperWrapper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {serviceTaskResponseConfiguration?.id && (
                        <ResponseCodeMapComponent
                          serviceTaskResponseConfigurationId={
                            serviceTaskResponseConfiguration?.id
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    mt: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    disabled={
                      isServiceTaskResponseLoading ||
                      isCreateLoading ||
                      isUpdateLoading
                    }
                    onClick={handleCancelServiceTaskResponseConfigRequest}
                  >
                    {(isServiceTaskResponseLoading ||
                      isCreateLoading ||
                      isUpdateLoading) && (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                        color="secondary"
                      />
                    )}
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    disabled={
                      isServiceTaskResponseLoading ||
                      isCreateLoading ||
                      isUpdateLoading
                    }
                    onClick={
                      responseConfigRequest?.id
                        ? handleUpdateServiceTaskResponseConfig
                        : handleCreateServiceTaskResponseConfig
                    }
                  >
                    {(isServiceTaskResponseLoading ||
                      isCreateLoading ||
                      isUpdateLoading) && (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                        color="secondary"
                      />
                    )}
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
          {responseConfigRequest === null && (
            <Button
              sx={{ float: "right" }}
              onClick={handleInitServiceTaskResponseConfigRequest}
              variant="contained"
            >
              Add Task response configuration
            </Button>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default EditServiceTaskResponseConfiguration;
