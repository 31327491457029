import { merchantsApi } from "../services/MerchantService";
import { inventoryApi } from "../services/InventoryService";
import { merchantCategoryApi } from "../services/MerchantCategoryService";
import { combineReducers } from "@reduxjs/toolkit";
import { warehousesApi } from "../services/WarehouseService";
import { merchantMediaApi } from "../services/MerchantMediaService";
import { ordersApi } from "../services/OrderService";
import { productsApi } from "../services/ProductService";
import { countriesApi } from "../services/CountryService";
import { merchantSubCategoryApi } from "../services/MerchantSubCategoryService";
import { productConfigurationsApi } from "../services/ProductConfigurationService";
import { transactionsApi } from "../services/TransactionService";
import { serviceTaskApi } from "../services/ServiceTaskService";
import { httpHeadersApi } from "../services/HttpHeaderService";
import { serviceTaskResponseConfigurationsApi } from "../services/ServiceTaskResponseConfigurationService";
import { serviceTaskConfigurationsApi } from "../services/ServiceTaskConfigurationService";
import { dropDownSuggestionsApi } from "../services/DropDownSuggestionService";
import { formQuestionsApi } from "../services/FormQuestionService";
import { rolesApi } from "../services/RoleService";
import { domainAccountApi } from "../services/DomainAcountService";
import { transactionsActivityApi } from "../services/TransactionActivityService";
import { forexApi } from "../services/ForexService";
import { currenciesApi } from "../services/CurrencyService";
import { remittanceJobApi } from "../services/RemittanceJobService";
import { remittanceMapApi } from "../services/RemittanceMapsService";
import { remittanceConfigurationApi } from "../services/RemittanceConfigurationService";
import { transactionLimitsApi } from "../services/TransactionLimitService";
import { paymentsApi } from "../services/PaymentsService";
import { forexActivityLogApi } from "../services/ForexActivityLogService";
import { disputesActivityLogApi } from "../services/DisputesActivityLogService";
import { disputesApi } from "../services/DisputesService";
import { caseAgentApi } from "../services/disputeServices/CaseAgentService";
import { caseApi } from "../services/disputeServices/CasesServices";
import { chatMessageApi } from "../services/disputeServices/ChatMessageService";
import { chatRoomApi } from "../services/disputeServices/ChatRoomService";
import { rulingApi } from "../services/disputeServices/RullingService";
import { authApi } from "../services/AuthService";
import { responseCodeMapsApi } from "../services/ResponseCodeMapService";
import { merchantPurchaseOrderApi } from "../services/MerchantPurchaseOrderService";
import { mobileUsersApi } from "../services/MobileUsersService";
import { clientApi } from "../services/ClientAuthService";
import { orderActivityApi } from "../services/OrderActivityService";
import { procurementActivityApi } from "../services/ProcurementActivityService";
import { remittanceActivityApi } from "../services/RemittanceActivityService";
import { inventoryMediaApi } from "../services/InventoryMediaService";
import { merchantDocumentsApi } from "../services/MerchantDocumentService";
import { merchantContactsApi } from "../services/MerchantContactService";
import { paymentTaskApi } from "../services/PaymentTaskService";
import { paymentMediaApi } from "../services/PaymentMediaService";
import { paymentProviderApi } from "../services/PaymentProviderService";
import { notificationsApi } from "../services/NotificationService";
import { devicesApi } from "../services/DevicesService";
import { remittanceInstitutionApi } from "../services/RemittanceInstitutionService";
import { remittancePayloadCustomMapApi } from "../services/RemittancePayloadCustomMapService";
import { remittanceAttemptApi } from "../services/RemittanceAttemptService";
import { remittanceProviderApi } from "../services/RemittanceProviderService";
import { logoutApi } from "../services/LogoutService";
import { kycApi } from "../services/KYCService";
import { questionToOrderItemMapApi } from "../services/QuestionToOrderItemMapService";
import { ProgramMembersApiService } from "../services/loyalty/programMembersService";
import { ProductSpecApiService } from "../services/loyalty/productSpecService";
import { LoyaltyTransactionApiService } from "../services/loyalty/loyaltyTransaction";
import { ProductApiService } from "../services/loyalty/productService";
import { ActionApiService } from "../services/loyalty/actionService";

export const rootReducers = combineReducers({
  [merchantsApi.reducerPath]: merchantsApi.reducer,
  [merchantContactsApi.reducerPath]: merchantContactsApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [merchantCategoryApi.reducerPath]: merchantCategoryApi.reducer,
  [merchantSubCategoryApi.reducerPath]: merchantSubCategoryApi.reducer,
  [warehousesApi.reducerPath]: warehousesApi.reducer,
  [merchantMediaApi.reducerPath]: merchantMediaApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [productConfigurationsApi.reducerPath]: productConfigurationsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [serviceTaskApi.reducerPath]: serviceTaskApi.reducer,
  [questionToOrderItemMapApi.reducerPath]: questionToOrderItemMapApi.reducer,
  [serviceTaskConfigurationsApi.reducerPath]:
    serviceTaskConfigurationsApi.reducer,
  [serviceTaskResponseConfigurationsApi.reducerPath]:
    serviceTaskResponseConfigurationsApi.reducer,
  [httpHeadersApi.reducerPath]: httpHeadersApi.reducer,
  [formQuestionsApi.reducerPath]: formQuestionsApi.reducer,
  [dropDownSuggestionsApi.reducerPath]: dropDownSuggestionsApi.reducer,
  [domainAccountApi.reducerPath]: domainAccountApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [transactionsActivityApi.reducerPath]: transactionsActivityApi.reducer,
  [orderActivityApi.reducerPath]: orderActivityApi.reducer,
  [remittanceActivityApi.reducerPath]: remittanceActivityApi.reducer,
  [responseCodeMapsApi.reducerPath]: responseCodeMapsApi.reducer,
  [procurementActivityApi.reducerPath]: procurementActivityApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [forexApi.reducerPath]: forexApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [forexActivityLogApi.reducerPath]: forexActivityLogApi.reducer,
  [caseAgentApi.reducerPath]: caseAgentApi.reducer,
  [caseApi.reducerPath]: caseApi.reducer,
  [chatMessageApi.reducerPath]: chatMessageApi.reducer,
  [chatRoomApi.reducerPath]: chatRoomApi.reducer,
  [rulingApi.reducerPath]: rulingApi.reducer,
  [disputesActivityLogApi.reducerPath]: disputesActivityLogApi.reducer,
  [disputesApi.reducerPath]: disputesApi.reducer,
  [merchantDocumentsApi.reducerPath]: merchantDocumentsApi.reducer,
  [inventoryMediaApi.reducerPath]: inventoryMediaApi.reducer,
  [merchantPurchaseOrderApi.reducerPath]: merchantPurchaseOrderApi.reducer,
  [mobileUsersApi.reducerPath]: mobileUsersApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [remittanceJobApi.reducerPath]: remittanceJobApi.reducer,
  [remittanceMapApi.reducerPath]: remittanceMapApi.reducer,
  [remittanceConfigurationApi.reducerPath]: remittanceConfigurationApi.reducer,
  [transactionLimitsApi.reducerPath]: transactionLimitsApi.reducer,
  [paymentTaskApi.reducerPath]: paymentTaskApi.reducer,
  [paymentMediaApi.reducerPath]: paymentMediaApi.reducer,
  [paymentProviderApi.reducerPath]: paymentProviderApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [remittanceInstitutionApi.reducerPath]: remittanceInstitutionApi.reducer,
  [remittancePayloadCustomMapApi.reducerPath]:
    remittancePayloadCustomMapApi.reducer,
  [remittanceAttemptApi.reducerPath]: remittanceAttemptApi.reducer,
  [remittanceProviderApi.reducerPath]: remittanceProviderApi.reducer,
  [logoutApi.reducerPath]: logoutApi.reducer,
  [kycApi.reducerPath]: kycApi.reducer,
  [ProgramMembersApiService.reducerPath] : ProgramMembersApiService.reducer,
  [ProductSpecApiService.reducerPath] : ProductSpecApiService.reducer,
  [LoyaltyTransactionApiService.reducerPath] : LoyaltyTransactionApiService.reducer,
  [ProductApiService.reducerPath] : ProductApiService.reducer,
  [ActionApiService.reducerPath] : ActionApiService.reducer,
});
