import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { kycUrl } from "../presentation/shared/ApiUrls";

export const kycApi = createApi({
  reducerPath: "kycApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
    baseUrl: kycUrl,
  }),
  refetchOnReconnect: true,

  tagTypes: ["KYC"],
  endpoints: (builder) => ({
    verifyKyc: builder.mutation({
      query: (kycRequest) => ({
        url: "/kyc/verify",
        method: "PUT",
        body: kycRequest,
      }),
      invalidatesTags: ["KYC"],
    }),
    getUserCount: builder.query({
      query: () => ({
        url: "/users/count",
        method: "GET",
      }),
      providesTags: ["KYC"],
      transformResponse: (response) => response.data,
    }),
    getUserProfileImage: builder.query({
      query: (id) => ({
        url: `/kyc/docs/download/${id}`,
        method: "GET",
      }),
      providesTags: ["KYC"],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useVerifyKycMutation,
  useGetUserCountQuery,
  useGetUserProfileImageQuery,
} = kycApi;
