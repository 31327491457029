import { Box, Paper, Button, TableContainer, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteRemittancePayloadCustomMapMutation,
  useSearchRemittancePayloadCustomMapsQuery,
} from "../services/RemittancePayloadCustomMapService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { RemittancePayloadCustomMapTable } from "../data/RemittancePayloadCustomMapTable";
import { Link } from "react-router-dom";

import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const RemittancePayloadCustomMap = () => {
  const dataGridService = new DataGridService("RemittancePayloadCustomMap");

  const [filter, setFilter] = useState({});

  const [selectedRemittanceMap, setSelectedRemittanceMap] = useState(null);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useSearchRemittancePayloadCustomMapsQuery(filter);
  const [
    deleteRemittancePayloadCustomMap,
    { isSuccess: isRemittanceMapDeleteSuccess },
  ] = useDeleteRemittancePayloadCustomMapMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedRemittanceMap(null);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedRemittanceMap(row);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleSubmit = async () => {
    await deleteRemittancePayloadCustomMap(selectedRemittanceMap.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Remittance Map deleted succesfully");
        } else {
          ErrorToast("Failed to delete Remittance Map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Remittance Map");
      });
  };

  const columns = RemittancePayloadCustomMapTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isRemittanceMapDeleteSuccess]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Remittance Payload Custom Maps</Typography>
        <Link
          to="/pages/remittance/custom-map/add"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Payload Custom Map
          </Button>
        </Link>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Remittance map"}
        subTitle={`Are you sure you want to delete ${selectedRemittanceMap?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={data?.data?.totalElements ?? 0}
            columns={columns}
            rows={data?.data?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RemittancePayloadCustomMap;
