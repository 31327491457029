import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serviceTaskConfigurationUrl } from "../presentation/shared/ApiUrls";

export const serviceTaskConfigurationsApi = createApi({
  reducerPath: "serviceTaskConfigurationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceTaskConfigurationUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["ServiceTaskConfigurations"],
  endpoints: (builder) => ({
    getAllServiceTaskConfigurations: builder.query({
      query: () => ({
        url: `/task-configurations`,
        method: "GET",
      }),
      providesTags: ["ServiceTaskConfigurations"],
    }),
    searchServiceTaskConfigurations: builder.mutation({
      query: (filterServiceTaskConfgurationRequest) => ({
        url: `/task-configurations?${filterServiceTaskConfgurationRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTaskConfigurations"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    getServiceTaskConfiguration: builder.query({
      query: (id) => ({
        url: `/task-configurations/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTaskConfigurations"],
      transformResponse: (response) => response.data,
    }),
    updateServiceTaskConfiguration: builder.mutation({
      query: (updateProductConfigurationRequest) => ({
        url: "/task-configurations",
        method: "PUT",
        body: updateProductConfigurationRequest,
      }),
      invalidatesTags: ["ServiceTaskConfigurations"],
    }),
    createServiceTaskConfiguration: builder.mutation({
      query: (createServiceTaskConfigurationRequest) => ({
        url: "/task-configurations",
        method: "POST",
        body: createServiceTaskConfigurationRequest,
      }),
      invalidatesTags: ["ServiceTaskConfigurations"],
    }),
    deleteServiceTaskConfiguration: builder.mutation({
      query: (id) => ({
        url: `/task-configurations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceTaskConfigurations"],
    }),
  }),
});

export const {
  useGetAllServiceTaskConfigurationsQuery,
  useSearchServiceTaskConfigurationsMutation,
  useGetServiceTaskConfigurationQuery,
  useCreateServiceTaskConfigurationMutation,
  useUpdateServiceTaskConfigurationMutation,
  useDeleteServiceTaskConfigurationMutation,
} = serviceTaskConfigurationsApi;
