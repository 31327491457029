import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { ProductsTable } from "../data/ProductsTable";
import {
  useDeleteProductMutation,
  useSearchProductsQuery,
} from "../services/ProductService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const Products = () => {
  const dataGridService = new DataGridService("Products");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filter, setFilter] = useState({});
  const { data: productData, isLoading: isProductLoading } =
    useSearchProductsQuery(filter);

  const [deleteProduct] = useDeleteProductMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [open, setOpen] = useState(false);

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await deleteProduct(selectedProduct.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product deleted succesfully");
        } else {
          ErrorToast("Failed to delete Product");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Product");
      })
      .finally(() => {
        setOpen(false);
        setSelectedProduct(null);
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedProduct(row);
  };

  const columns = ProductsTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type={"BUTTON"}
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Products</Typography>

          <Link to="/pages/product/add" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
            >
              Add Product
            </Button>
          </Link>
        </Box>
      </Box>

      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Product"}
        subTitle={`Are you sure you want to delete ${selectedProduct?.name}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <DataGrid
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "gray",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slotProps={{
            panel: {
              sx: {
                top: "-120px !important",
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
            filterPanelDeleteIcon: FiTrash2,
          }}
          loading={isProductLoading}
          rowCount={productData?.size ?? 0}
          columns={columns}
          rows={productData?.data ?? []}
          getRowId={(row) => row.id}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          disableColumnSelector
          disableDensitySelector
          sortModel={sortModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
        />
      </Box>
    </SecuredComponent>
  );
};

export default Products;
