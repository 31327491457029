import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { procurementActivitiesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const procurementActivityApi = createApi({
  reducerPath: "procurementActivitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: procurementActivitiesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["Procurement"],
  endpoints: (builder) => ({
    getAllProcurementActivities: builder.query({
      query: (filterProcurementRequest) => ({
        url: `/procurement-activity?${filterProcurementRequest}`,
        method: "GET",
      }),
      providesTags: ["Procurement_Activities"],
    }),

    searchProcurementActivities: builder.query({
      query: (filterProcurementActivitiesRequest) => ({
        url: `/procurement-activity?${queryString.stringify(
          filterProcurementActivitiesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Procurement_Activity"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
  }),
});

export const {
  useGetAllProcurementActivitiesQuery,
  useSearchProcurementActivitiesQuery,
} = procurementActivityApi;
