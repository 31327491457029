import React, { useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  Paper,
} from "@mui/material";

import { useCreateProductMutation } from "../services/ProductService";
import { Link, useNavigate } from "react-router-dom";
import ProductDescription from "./ProductDescription";
import ProductConfiguration from "./ProductConfiguration";
import AddProductStep3 from "./AddProductStep3";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useCreateProductConfigurationMutation } from "../services/ProductConfigurationService";
import { createProductConfigurationRequest } from "../models/productConfiguration";
import { createProductRequest } from "../models/product";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddProductOnboarding = () => {
  const steps = ["Step 1", "Step 2", "Step 3"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [product, setProduct] = useState(createProductRequest);
  const [productConfig, setProductConfig] = useState(
    createProductConfigurationRequest
  );
  const [addProduct, { data: productResponse, isLoading }] =
    useCreateProductMutation();
  const [
    addProductConfig,
    { data: productConfigResponse, isLoading: isProductConfigLoading },
  ] = useCreateProductConfigurationMutation();
  const navigate = useNavigate();
  const [productId, setProductId] = useState(null);

  const handleSubmitProduct = async (event) => {
    event.preventDefault();

    await addProduct(product)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product created succesfully");
          setProductConfig((prevProductConfig) => ({
            ...prevProductConfig,
            ["serviceId"]: response.data.data.id,
          }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          ErrorToast("Failed to create Product");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Product");
      });
  };
  const handleSubmitProductConfig = async (event) => {
    event.preventDefault();

    await addProductConfig(productConfig)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product configuration created succesfully");
          setProductConfig((prevProductConfig) => ({
            ...prevProductConfig,
            ["serviceId"]: response.data.data.id,
          }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          ErrorToast("Failed to create product configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create product configuration");
      });
  };

  const handleNext = () => {
    if (activeStep !== 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleFinish = () => {
    navigate("/pages/products");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Product
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "100%",
          margin: "0 auto",

          overflow: "hidden",
        }}
      >
        <div>
          <Typography variant="h4" gutterBottom>
            Product onboarding
          </Typography>

          <Stepper variant="outlined" activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            <Typography variant="h6" gutterBottom>
              Step {activeStep + 1}
            </Typography>
            {activeStep === 0 && (
              <div>
                <ProductDescription product={product} setProduct={setProduct} />
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <ProductConfiguration
                  productConfig={productConfig}
                  setProductConfig={setProductConfig}
                />
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <Typography variant="body1" gutterBottom>
                  <AddProductStep3 />
                </Typography>
              </div>
            )}

            <Box
              sx={{ display: "flex", mt: 3, justifyContent: "space-evenly" }}
            >
              <Button
                startIcon={!(0 === activeStep) && <KeyboardArrowLeft />}
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="primary"
                disabled={
                  (activeStep === 0 ?? steps.length - 1) ||
                  productConfig?.serviceId
                }
                onClick={handleBack}
              >
                Back
              </Button>
              <Link onClick={handleNext} hidden={activeStep !== 1}>
                <Button variant="contained" sx={{ borderRadius: "20px" }}>
                  Skip
                </Button>
              </Link>
              <Button
                endIcon={
                  !(steps.length - 1 === activeStep) && <KeyboardArrowRight />
                }
                sx={{ borderRadius: "20px" }}
                variant="contained"
                color="primary"
                onClick={
                  (activeStep === 0 && handleSubmitProduct) ||
                  (activeStep === 1 && handleSubmitProductConfig) ||
                  (activeStep === steps.length - 1 && handleFinish)
                }
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default AddProductOnboarding;
