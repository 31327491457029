import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Badge,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { FiMenu, FiMoon, FiSun } from "react-icons/fi";
import { useColorTheme } from "../../contexts/ThemeContext";
import ProfileMenu from "./ProfileMenu";
import { BsBell } from "react-icons/bs";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { useTotalNotificationsQuery } from "../../services/NotificationService";
import SecuredComponent from "./SecuredComponent";
import { DomainType } from "../../presentation/helpers";

const Navbar = ({ sideBarWidth, handleDrawerToggle }) => {
  const colorMode = useColorTheme();
  const theme = useTheme();
  const filter = queryString.stringify({ size: 1000 });

  const { data: notificationCount } = useTotalNotificationsQuery(filter);
  let userDetails = {};

  if (sessionStorage.getItem("userDetails")) {
    userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  }

  const currentTheme = theme.palette.mode;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${sideBarWidth}px)` },
        ml: { md: `${sideBarWidth}px` },
        boxShadow: "unset",
        backgroundColor: "background.paper",
        color: "text.primary",
        borderBottomWidth: 1,
        borderBottomColor: "divider",
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Tooltip title="Menu" arrow>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <FiMenu />
              </IconButton>
            </Tooltip>

            <Typography
              variant="h5"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              {/* Dashboard */}
            </Typography>
          </Box>

          <Stack direction="row" spacing={1} alignItems="center">
            <SecuredComponent
              type={"BUTTON"}
              requiredDomainTypes={[
                DomainType.BACK_OFFICE,
                DomainType.SUPERMAN,
              ]}
            >
              <Tooltip title="Notifications" arrow>
                <Link to={`/pages/notifications`}>
                  <IconButton sx={{ fontSize: "20px", color: "text.primary" }}>
                    <Badge
                      color="error"
                      showZero={true}
                      variant="standard"
                      badgeContent={notificationCount ?? 0}
                    >
                      <BsBell />
                    </Badge>
                  </IconButton>
                </Link>
              </Tooltip>
            </SecuredComponent>
            <Tooltip title="Toggle Theme" arrow>
              <IconButton
                onClick={colorMode.toggleColorMode}
                sx={{ fontSize: "20px", color: "text.primary" }}
              >
                {currentTheme === "light" ? <FiMoon /> : <FiSun />}
              </IconButton>
            </Tooltip>
            <ProfileMenu />
          </Stack>
        </Box>
        <Typography>{userDetails.lastName} </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
