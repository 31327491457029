import { Box, Paper, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MerchantSubCategoriesTable } from "../data/MerchantSubCategoriesTable";
import LoadingPage from "../components/LoadingPage";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AddSubCategory from "../components/modals/AddSubCategory";
import ViewSubCategory from "../components/modals/viewSubCategory";
import { useDeleteMerchantSubCategoryMutation } from "../services/MerchantSubCategoryService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { useGetMerchantCategoryQuery } from "../services/MerchantCategoryService";
import { KeyboardBackspace } from "@mui/icons-material";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const SingleMerchantCategory = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetMerchantCategoryQuery(id);
  const [deleteMerchantSubCategory] = useDeleteMerchantSubCategoryMutation();
  const [selectedMerchantCategory, setSelectedMerchantCategory] = useState();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const handleCloseDeleteDailog = () => {
    setOpenDelete(false);
  };

  const handleDeleteAction = async (row) => {
    setOpenDelete(true);
    setSelectedCategory(row);
  };
  const handleClickOpen = (row) => {
    setSelectedCategory(row);
    setOpen(true);
  };

  const handleOpenView = (row) => {
    setSelectedCategory(row);
    setOpenView(true);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMerchantCategory(null);
  };

  const handleCloseView = () => {
    setOpenView(false);
    setSelectedMerchantCategory(null);
  };

  const handleDeleteSubCategory = async () => {
    await deleteMerchantSubCategory(selectedMerchantCategory?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Subcategory deleted succesfully");
          refetch();
        } else {
          ErrorToast("Failed to delete Subcategory");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Subcategory");
      })
      .finally(() => {
        setOpen(false);
        setSelectedMerchantCategory(null);
        handleCloseDeleteDailog();
      });
  };

  const columns = MerchantSubCategoriesTable({
    handleDeleteAction,
    handleClickOpen,
    handleOpenView,
  });

  if (data) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            {data.label}
          </Typography>
          <Typography textAlign={"center"}>{data.description}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h6"> SubCategories</Typography>

            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
              onClick={handleClickOpen}
            >
              Add SubCategory
            </Button>
          </Box>

          <Box>
            <ViewSubCategory
              open={openView}
              handleCloseView={handleCloseView}
              selectedCategory={selectedCategory}
              sx={{ m: "2rem" }}
            />
            <AddSubCategory
              refetch={refetch}
              open={open}
              handleClose={handleClose}
              categoryId={id}
              sx={{ m: "2rem" }}
              selectedCategory={selectedCategory}
            />
            <CustomAlertDialog
              open={openDelete}
              handleClose={handleCloseDeleteDailog}
              handleSubmit={handleDeleteSubCategory}
              title={"Delete SubCategory"}
              subTitle={`Are you sure you want to delete ${selectedCategory?.label}?`}
              negativeButtonText={"No"}
              positiveButtonText={"Yes"}
              icon={<FiTrash2 size={30} />}
              fullWidth={true}
            />

            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              loading={isLoading}
              disableColumnSelector
              disableDensitySelector
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              rowCount={data?.subCategories.length ?? 0}
              columns={columns}
              rows={data?.subCategories ?? []}
              getRowId={(row) => row.id}
              slots={{ toolbar: GridToolbar }}
              onFilterModelChange={handleFilterModelChange}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
            />
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleMerchantCategory;
