import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { remittanceInstitutionTypes } from "../components/common/dropdownsData";
import {
  useUpdateRemittanceInstitutionMutation,
  useGetRemittanceInstitutionQuery,
} from "../services/RemittanceInstitutionService";
import { useGetAllRemittanceProvidersQuery } from "../services/RemittanceProviderService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddRemittanceInstitution = () => {
  const { id } = useParams();

  const INITIAL_DATA = {
    label: "",
    name: "",
    institutionType: "",
    enabled: false,
  };

  let {
    isLoading: institutionLoading,
    data: institutionData,
    isSuccess,
  } = useGetRemittanceInstitutionQuery(id);
  let { data: providersData } = useGetAllRemittanceProvidersQuery();
  const [updateRemittanceInstitution] =
    useUpdateRemittanceInstitutionMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [formData, setFormData] = useState(null);

  const navigate = useNavigate();

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const handleSubmitInstitution = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    const remittanceInstitutionRequest = {
      id: formData.id,
      name: formData.name,
      institutionType: formData.institutionType,
      enabled: formData.enabled,
      label: formData.label,
      providerId: formData.providerId,
    };

    await updateRemittanceInstitution(remittanceInstitutionRequest)
      .then((response) => {
        if (response?.data?.data) {
          SuccessToast("Configuration created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Configuration ");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  const initRemittanceInstitutionRequest = () => {
    if (institutionData) {
      setFormData(institutionData.data);
    }
  };

  useEffect(() => {
    initRemittanceInstitutionRequest();
  }, [institutionData]);

  if (formData) {
    let { name, label, institutionType, enabled, providerId } = formData;

    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
          <Typography size="small" variant="h5">
            Edit Institution
          </Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "70%",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              value={name}
              onChange={(e) => updateFields({ name: e.target.value })}
              fullWidth
              size="small"
            />

            <TextField
              label="Label"
              variant="outlined"
              name="label"
              value={label}
              onChange={(e) => updateFields({ label: e.target.value })}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Institution Type
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={institutionType}
                label="Institution Type"
                name="institutiontype"
                onChange={(e) =>
                  updateFields({ institutionType: e.target.value })
                }
              >
                {Object.keys(remittanceInstitutionTypes).map((type, i) => (
                  <MenuItem key={i} value={type}>
                    {remittanceInstitutionTypes[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" required>
              <FormControlLabel
                control={<Switch />}
                label="Enable"
                checked={enabled}
                onChange={() => {
                  updateFields({ enabled: !formData.enabled });
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Provider</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={providerId}
                label="Provider id"
                name="provider"
                onChange={(e) => updateFields({ providerId: e.target.value })}
              >
                {providersData?.data?.data?.map((provider, i) => (
                  <MenuItem key={i} value={provider.id}>
                    {provider.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" required></FormControl>
          </Box>
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            onClick={handleSubmitInstitution}
            variant="contained"
            disabled={isFieldsDisabled}
            sx={{ borderRadius: "20px" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  } else {
    <LoadingPage />;
  }
};

export default AddRemittanceInstitution;
