import {
  Box,
  Divider,
  Grid,
  Button,
  IconButton,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import moment from "moment";

import { FiMap, FiUser } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import { useGetMerchantPurchaseOrderQuery } from "../services/MerchantPurchaseOrderService";
import { KeyboardBackspace } from "@mui/icons-material";
import LoadingPage from "../components/LoadingPage";
import { useGetMerchantQuery } from "../services/MerchantService";
import { updateColors } from "../presentation/helpers";

const SingleMerchantPurchaseOrder = () => {
  const { id } = useParams();

  const { data: order } = useGetMerchantPurchaseOrderQuery(id);

  const { data: merchant } = useGetMerchantQuery(order?.merchantId);

  const navigate = useNavigate();

  if (order) {
    return (
      <>
        <Box sx={{ pt: "80px", pb: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Button variant="outlined" onClick={() => navigate(-1)}>
              <KeyboardBackspace /> BACK
            </Button>
            <Typography variant="h5">Merchant Purchase Order</Typography>
          </Box>
          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <span
                style={{
                  padding: "3px 10px",
                  borderRadius: "5px",
                  textDecoration: "none",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "15px", mb: 2 }}
                >
                  Status:{" "}
                  <span style={{ opacity: 0.7 }}>
                    {
                      <Chip
                        label={order?.status}
                        variant="outlined"
                        color={updateColors(order?.status)}
                      />
                    }
                  </span>
                </Typography>
              </span>
            </Box>

            <Divider />
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FiUser />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Order Details
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Product Name:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.item?.productName ?? "N/A"}
                      </span>
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Quantity:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.item?.quantity ?? "N/A"}{" "}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Merchant Name:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {merchant?.name ?? "N/A"}{" "}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FiMap />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Delivery{" "}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Address:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliveryAddress?.addressLocation
                          ? order?.deliveryAddress?.addressLocation
                          : "N/A"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Region or State:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliverAddress?.regionOrState ?? "N/A"}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Street Address:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {order?.deliverAddress?.streetAddress ?? "N/A"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Grid container spacing={14} sx={{ py: 3, px: 5 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Created:
                    </span>{" "}
                    {order.created
                      ? moment(order?.created).format("LLLL")
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Updated:
                    </span>{" "}
                    {order.updated
                      ? moment(order?.updated).format("LLLL")
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleMerchantPurchaseOrder;
