import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LoadingPage from "../components/LoadingPage";
import { statuses } from "../components/common/dropdownsData";
import {
  useUpdateMerchantCategoryMutation,
  useGetMerchantCategoryQuery,
} from "../services/MerchantCategoryService";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditMerchantCategory = () => {
  const { id } = useParams();

  const [updateMerchantCategory] = useUpdateMerchantCategoryMutation();
  const { data } = useGetMerchantCategoryQuery(id);
  const navigate = useNavigate();

  const [merchantCategoryRequest, setMerchantCategoryRequest] = useState(null);

  useEffect(() => {
    setMerchantCategoryRequest(data);
  }, [data]);

  const handleEditCategory = async (event) => {
    event.preventDefault();

    await updateMerchantCategory(merchantCategoryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Category Edited succesfully");
        } else {
          ErrorToast("Failed to edit category");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to edit category");
      });
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setMerchantCategoryRequest((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (merchantCategoryRequest) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", pb: "20px" }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "800px",
            margin: "0 auto",

            overflow: "hidden",
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginBottom: "14px", textAlign: "center" }}
          >
            Edit Merchant Category
          </Typography>
          <Box sx={{ mt: 4, alignItems: "center", gap: 4 }}>
            <TextField
              autoFocus
              margin="dense"
              id="category"
              label="Category name"
              required
              type="text"
              fullWidth
              size="small"
              name="label"
              value={merchantCategoryRequest?.label}
              onChange={handleFormChange}
              variant="outlined"
            />

            <TextField
              value={merchantCategoryRequest?.description}
              fullWidth
              multiline
              size="small"
              sx={{ my: "15px" }}
              row={5}
              name="description"
              id={"category-description"}
              label={"Description"}
              onChange={handleFormChange}
              type="text"
            />

            <FormControl sx={{ my: "10px", width: "50%" }} required>
              <InputLabel label="demo-simple-select-label">Status</InputLabel>
              <Select
                value={merchantCategoryRequest?.status}
                id="demo-simple-select"
                label={"Status"}
                size="small"
                name="status"
                labelId="demo-simple-select-label"
                onChange={handleFormChange}
              >
                {statuses?.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "30px",
            }}
          >
            <Button
              onClick={handleEditCategory}
              variant="contained"
              sx={{ borderRadius: "20px" }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};
export default EditMerchantCategory;
