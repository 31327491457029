import { FiberManualRecord, WarningOutlined, Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const LoyaltyTransactionDataTable = (handleDeleteAction) => {
  return [
    {
      field: "id",
      flex: 1,
      headerAlign: "Type",
      align: "center",
      headerName: "ID",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "recipientName",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Recipient Name",
      filterable: true,
      valueGetter: ({ row }) =>
        row?.order?.recipientDetails?.recipientName ?? "N/A",
    },
    {
      field: "accountId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: true,
      headerName: "Account Id",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "openingBalance",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Opening Balance",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "closingBalance",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Closing Balance",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "loyaltyAmountUsed",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Loyalty Amount Used",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "order",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Date",
      filterable: true,
      valueGetter: ({ value }) => moment(value?.created).format("LL") ?? "N/A",
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/loyalty/transaction/detail/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
