import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import {
  useDeleteWarehouseMutation,
  useSearchWarehousesQuery,
} from "../services/WarehouseService";
import { WarehousesTable } from "../data/WarehousesTable";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const Warehouses = () => {
  const dataGridService = new DataGridService("Warehouses");
  const { id } = useParams();
  const [filter, setFilter] = useState({});
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [deleteWarehouse] = useDeleteWarehouseMutation();
  const { data, isLoading } = useSearchWarehousesQuery(filter);
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const [open, setOpen] = useState(false);

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const getIdMerchant = () => {
    const user = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      [DomainType.MERCHANT_ADMIN, DomainType.EMPLOYEE].includes(user.domainType)
    )
      return user?.merchant;
    else {
      return id;
    }
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      merchantId: getIdMerchant(),
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort?.toUpperCase(),
      sortBy: sortModel[0]?.field,
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    await deleteWarehouse(selectedWarehouse.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Warehouse deleted succesfully");
        } else {
          ErrorToast("Failed to delete Warehouse");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Warehouse");
      })
      .finally(() => {
        setOpen(false);
        setSelectedWarehouse(null);
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedWarehouse(row);
  };
  const columns = WarehousesTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Warehouse</Typography>
        <Link
          to={`/pages/warehouse/add?merchantId=${id}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Warehouse
          </Button>
        </Link>
      </Box>

      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Warehouse"}
        subTitle={`Are you sure you want to delete ${selectedWarehouse?.name}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />

      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={data?.data?.totalElements ?? 0}
            disableRowSelectionOnClick
            columns={columns}
            rows={data?.data?.data ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            disableColumnSelector
            disableDensitySelector
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Warehouses;
