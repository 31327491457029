export const InventoryRequest = {
  id: "",
  price: {
    amount: 0,
    currency: "",
  },
  quantity: 0,
  minStockLevel: 0,
  warehouseId: "",
  autoReplenishQuantity: false,
  autoReplenishEnabled: false,
  merchantId: "",
  productId: "",
  active: false,
};
