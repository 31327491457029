import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useUpdateMerchantSubCategoryMutation,
  useCreateMerchantSubCategoryMutation,
} from "../../services/MerchantSubCategoryService";
import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import { statuses } from "../common/dropdownsData";
import { ErrorToast, SuccessToast } from "../common/Toast";

const AddSubCategory = ({
  open,
  handleClose,
  categoryId,
  selectedCategory,
  refetch,
}) => {
  const [createMerchantSubCategory] = useCreateMerchantSubCategoryMutation();
  const [updateMerchantSubCategory] = useUpdateMerchantSubCategoryMutation();

  const [label, setLabel] = React.useState();
  const [description, setDescription] = React.useState();
  const [status, setStatus] = React.useState();

  const handleLabelChanged = (event) => {
    setLabel(event.target.value);
  };

  const handleDescriptionChanged = (event) =>
    setDescription(event.target.value);
  const handleStatusClicked = (status) => setStatus(status);

  const handleResetForm = () => {
    setLabel("");
    setDescription("");
    setStatus("ACTIVE");
  };

  const handleAddSubCategory = async (event) => {
    event.preventDefault();
    const createMerchantSubCategoryRequest = {
      label: label,
      description: description,
      categoryId: categoryId,
      status: status,
    };
    await createMerchantSubCategory(createMerchantSubCategoryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("SubCategory created succesfully");
          handleResetForm();
          handleClose();
          refetch();
        } else {
          ErrorToast("Failed to create category");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create category");
      });
  };

  const handleEditSubCategory = async (event) => {
    event.preventDefault();
    const updateMerchantSubCategoryRequest = {
      id: selectedCategory?.id,
      label: label,
      description: description,
      categoryId: categoryId,
      status: status,
    };
    await updateMerchantSubCategory(updateMerchantSubCategoryRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("SubCategory Edited succesfully");
          handleResetForm();
          handleClose();
          refetch();
        } else {
          ErrorToast("Failed to edit category");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to edit category");
      });
  };
  React.useEffect(() => {
    setLabel(selectedCategory?.id ? selectedCategory?.label : "");
    setDescription(selectedCategory?.id ? selectedCategory?.description : "");
    setStatus(selectedCategory?.id ? selectedCategory?.status : "ACTIVE");
  }, [selectedCategory]);

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>Add SubCategory</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Provide SubCategory name and description
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="category"
            label="Sub Category name"
            required
            type="text"
            fullWidth
            size="small"
            name="label"
            value={label}
            onChange={handleLabelChanged}
            variant="outlined"
          />

          <TextField
            value={description}
            fullWidth
            multiline
            size="small"
            sx={{ my: "15px" }}
            row={5}
            name="description"
            id={"category-description"}
            label={"Description"}
            onChange={handleDescriptionChanged}
            type="text"
          />

          <FormControl sx={{ my: "10px", width: "50%" }} required>
            <InputLabel label="demo-simple-select-label">Status</InputLabel>
            <Select
              value={status}
              selected
              id="demo-simple-select"
              label={"Status"}
              size="small"
              labelId="demo-simple-select-label"
              // autoWidth
            >
              {statuses.map((status, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleStatusClicked(status)}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleResetForm();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={
              selectedCategory?.id
                ? handleEditSubCategory
                : handleAddSubCategory
            }
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddSubCategory;
