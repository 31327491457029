import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { paymentProviderUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const paymentProviderApi = createApi({
  reducerPath: "paymentProviderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: paymentProviderUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Payment Provider"],
  endpoints: (builder) => ({
    getAllPaymentProviders: builder.query({
      query: (filterRequest) => ({
        url: `/?${filterRequest}`,
        method: "GET",
      }),
      providesTags: ["Payment Provider"],
    }),

    getPaymentProvider: builder.query({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
      providesTags: ["Payment Provider"],
    }),

    searchPaymentProvider: builder.query({
      query: (filterRequest) => ({
        url: `/?${queryString.stringify(filterRequest, queryStringOptions)}`,
        method: "GET",
      }),
      providesTags: ["Payment Provider"],
    }),
    updatePaymentProvider: builder.mutation({
      query: (updateRequest) => ({
        url: "/",
        method: "PUT",
        body: updateRequest,
      }),
      invalidatesTags: ["Payment Provider"],
    }),
    createPaymentProvider: builder.mutation({
      query: (addRequest) => ({
        url: "/",
        method: "POST",
        body: addRequest,
      }),
      invalidatesTags: ["Payment Provider"],
    }),
    deletePaymentProvider: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment Provider"],
    }),
  }),
});

export const {
  useGetAllPaymentProvidersQuery,
  useGetPaymentProviderQuery,
  useSearchPaymentProviderQuery,
  useCreatePaymentProviderMutation,
  useUpdatePaymentProviderMutation,
  useDeletePaymentProviderMutation,
} = paymentProviderApi;
