import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { statuses } from "../common/dropdownsData";

const AddCategory = ({
  open,
  handleClose,
  handleSubmitCategory,
  handleFormChange,
}) => {
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Provide some category name and description
            </Typography>
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                id="category"
                label="Category label"
                required
                name="label"
                fullWidth
                size="small"
                onChange={handleFormChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                multiline
                size="small"
                sx={{ my: "15px" }}
                row={5}
                name="description"
                id={"category-description"}
                label={"Description"}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel labelId="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  label={"Status"}
                  size="small"
                  labelId="demo-simple-select-label"
                  onChange={handleFormChange}
                >
                  {statuses.map((status) => (
                    <MenuItem value={status}>{status} </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <FormGroup>
                <span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleFormChange}
                        name="requiresNotificationOnOrderPlaced"
                      />
                    }
                    label="Require notification on order placed?"
                  />
                </span>
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitCategory} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCategory;
