import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
  InputAdornment,
} from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";

import React, { useState } from "react";
import { useGetAllCountriesQuery } from "../services/CountryService";
import { useGetAllMerchantCategoriesQuery } from "../services/MerchantCategoryService";
import { useNavigate } from "react-router-dom";
import { useCreateTransactionLimitsMutation } from "../services/TransactionLimitService";
import queryString from "query-string";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddTransactionLimit = () => {
  const [createTransactionLimit] = useCreateTransactionLimitsMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});

  const navigate = useNavigate();

  const INITIAL_DATA = {
    transactional: "",
    daily: "",
    sendingCountryCode: "",
    merchantCategory: "",
    enabled: false,
  };

  const [formData, setFormData] = useState(INITIAL_DATA);
  const { isLoading: countryLoading, data: countries } =
    useGetAllCountriesQuery();
  const { isLoading: merchantLoading, data: categories } =
    useGetAllMerchantCategoriesQuery(queryString.stringify({ size: 10000 }));

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const handleSelectSendingCountry = (e) => {
    let country = e.target.value;
    setSelectedCountry(country);
    updateFields({ sendingCountryCode: country.isoCode });
  };

  const handleSubmitLimit = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    await createTransactionLimit(formData)
      .then((response) => {
        if (response.data) {
          SuccessToast("Transaction limit created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Transaction Limit");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Transaction Limit");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <KeyboardBackspace /> BACK
        </Button>
        <Typography size="small" variant="h5">
          Add Transaction Limit
        </Typography>
      </Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">
              Sending Country
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedCountry}
              label="Sending Country"
              name="sendingCountry"
              onChange={handleSelectSendingCountry}
            >
              {countries?.map((country, i) => (
                <MenuItem key={i} value={country}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">
              Merchant Category
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.merchantCategory}
              label="Merchant Category"
              name="merchantCategory"
              onChange={(e) =>
                updateFields({ merchantCategory: e.target.value })
              }
            >
              {categories?.map((category, index) => (
                <MenuItem key={index} value={category.id}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Transactional"
            variant="outlined"
            type="number"
            name="transactional"
            value={formData.transactional}
            onChange={(e) => {
              updateFields({ transactional: e.target.value });
            }}
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedCountry?.currency?.initial}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Daily"
            variant="outlined"
            name="daily"
            type="number"
            value={formData.daily}
            onChange={(e) => updateFields({ daily: e.target.value })}
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {selectedCountry?.currency?.initial}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", gap: 4 }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Enable"
              checked={formData.enabled}
              onChange={() => {
                updateFields({ enabled: !formData.enabled });
              }}
            />
          </FormGroup>
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitLimit}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddTransactionLimit;
