import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from "./components/common/Sidebar";
import Navbar from "./components/common/Navbar";

export default function MainLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const sideBarWidth = 260;
  const navigate = useNavigate();
  const [session, setSession] = React.useState(
    sessionStorage.getItem("accessToken")
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (!session) {
      navigate("/login");
    }
  }, [session]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar
        sideBarWidth={sideBarWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        sideBarWidth={sideBarWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 1, md: 2 },
          width: { xs: "100%", md: `calc(100% - ${sideBarWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
