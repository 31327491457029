import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useLoginDomainUserMutation } from "../services/AuthService";
import borgaLogo from "../assets/borgalogo.png";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const LoginPage = () => {
  const [loginDomainUser, { isLoading: isLoginLoading }] =
    useLoginDomainUserMutation();

  const [formData, setFormData] = useState({
    id: "",
    secret: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async () => {
    try {
      const response = await loginDomainUser(formData);

      if (response.data) {
        const domainType = response.data?.data.domainAccount.domainType;
        sessionStorage.setItem("domainType", domainType);

        const userId = response.data?.data.domainAccount.id;
        sessionStorage.setItem("userId", userId);

        const userDetails = response.data?.data.domainAccount;
        sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

        const accessToken = response.data?.data.access_token;
        sessionStorage.setItem("accessToken", accessToken);

        SuccessToast("Login successfully");
        navigate("/pages/dashboard");
      } else {
        const errorCode = response.error.data.error.errorCode;
        if (errorCode === 401) {
          ErrorToast("Invalid user credential");
        } else if (errorCode === 400) {
          ErrorToast("username and password required");
        } else {
          ErrorToast("Failed to Login");
        }
      }
    } catch (error) {
      ErrorToast("Failed to Login");
    }
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Typography>
            <img src={borgaLogo} alt="Logo" width="80%" />
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Username"
              name="id"
              value={formData.id}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="secret"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              type={showPassword ? "text" : "password"}
              value={formData.secret}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ width: "100%", padding: "10px", borderRadius: "10px" }}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isLoginLoading}
          >
            {isLoginLoading ? (
              <CircularProgress
                style={{ height: "20px", width: "20px" }}
                color="warning"
              />
            ) : (
              "Login"
            )}
          </Button>
          <Link
            to="/forgot-password"
            style={{
              textDecoration: "none",
              borderBottom: "none",
              marginTop: "10px",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Forgot Password?
            </Typography>
          </Link>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;
