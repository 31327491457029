import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { remittanceCustomMapTypes } from "../components/common/dropdownsData";
import { useCreateRemittanceInstitutionMutation } from "../services/RemittanceInstitutionService";
import { useCreateRemittancePayloadCustomMapMutation } from "../services/RemittancePayloadCustomMapService";
import { useGetAllMerchantsQuery } from "../services/MerchantService";
import { useGetAllInventoryQuery } from "../services/InventoryService";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddRemittancePayloadCustomMap = () => {
  const INITIAL_DATA = {
    remittanceAttribute: "",
    remittanceAttributeValue: "",
    type: "",
    merchantId: "",
    inventoryId: "",
    enabled: false,
  };

  const [createRemittanceInstitution] =
    useCreateRemittanceInstitutionMutation();
  const [createRemittancePayloadCustomMap] =
    useCreateRemittancePayloadCustomMapMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState(INITIAL_DATA);
  const { isLoading: merhcantsLoading, data: merchantsData } =
    useGetAllMerchantsQuery();
  const { isLoading: inventoryLoading, data: inventoryData } =
    useGetAllInventoryQuery();

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const handleSubmitInstitution = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    await createRemittancePayloadCustomMap(formData)
      .then((response) => {
        if (response?.data?.data) {
          SuccessToast("Payload Custom Map created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Payload Custom Map ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Payload Custom Map");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <KeyboardBackspace /> BACK
        </Button>
        <Typography size="small" variant="h5">
          Add Payload Custom Map
        </Typography>
      </Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Attribute"
            variant="outlined"
            name="name"
            value={formData.remittanceAttribute}
            onChange={(e) =>
              updateFields({ remittanceAttribute: e.target.value })
            }
            fullWidth
            size="small"
          />

          <TextField
            label="Attribute Value"
            variant="outlined"
            name="label"
            value={formData.remittanceAttributeValue}
            onChange={(e) =>
              updateFields({ remittanceAttributeValue: e.target.value })
            }
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.type}
              label="Type"
              name="type"
              onChange={(e) => updateFields({ type: e.target.value })}
            >
              {Object.keys(remittanceCustomMapTypes).map((type, i) => (
                <MenuItem key={i} value={type}>
                  {remittanceCustomMapTypes[type]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Merchant</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.merchantId}
              label="Merchant"
              name="merchantId"
              onChange={(e) => updateFields({ merchantId: e.target.value })}
            >
              {merchantsData?.map((merchant, i) => (
                <MenuItem key={i} value={merchant?.id}>
                  {merchant?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Inventory</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.inventoryId}
              label="Inventory"
              name="inventoryId"
              onChange={(e) => updateFields({ inventoryId: e.target.value })}
            >
              {inventoryData?.map((inventory, i) => (
                <MenuItem key={i} value={inventory?.id}>
                  {inventory?.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" required></FormControl>
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitInstitution}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddRemittancePayloadCustomMap;
