import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import {
  useGetWarehouseQuery,
  useUpdateWarehouseMutation,
} from "../services/WarehouseService";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { useGetAllCountriesQuery } from "../services/CountryService";
import queryString from "query-string";
import Marker from "../components/common/Marker";
import GoogleMapReact from "google-map-react";
import { getCities } from "countries-cities";
import { GOOGLE_MAP_API_KEY } from "../core/api-key";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditWarehouse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateWarehouse] = useUpdateWarehouseMutation();
  const { data: warehouseData } = useGetWarehouseQuery(id);
  const { data: countries } = useGetAllCountriesQuery(
    queryString.stringify({ size: 1000 })
  );
  const [warehouseRequest, setWarehouseRequest] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["active", "defaultWarehouse"].includes(name)) {
      setWarehouseRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (["country"].includes(name)) {
      setSelectedCountry(value);
      setWarehouseRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value },
      }));
    } else if (
      ["streetAddress", "postalAddress", "city", "regionOrState"].includes(name)
    ) {
      setWarehouseRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value },
      }));
    } else {
      setWarehouseRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleGPSChange = (event) => {
    const { lat, lng } = event.center;
    setWarehouseRequest((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        addressLocation: {
          ...prev?.address?.addressLocation,
          longitude: lng,
          latitude: lat,
        },
      },
    }));
  };

  const handleCancelInventory = () => {
    navigate("/pages/warehouses");
  };
  const handleSubmitWarehouse = async (event) => {
    event.preventDefault();

    await updateWarehouse(warehouseRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Warehouse updated succesfully");
          navigate("/pages/warehouses");
        } else {
          ErrorToast("Failed to update Warehouse");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Warehouse");
      });
  };

  const initWarehouseRequest = () => {
    if (warehouseData) {
      setWarehouseRequest(warehouseData);
    }
  };

  React.useEffect(() => {
    initWarehouseRequest();
  }, [warehouseData]);

  if (warehouseRequest) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Typography variant="h6" sx={{ marginBottom: "14px" }}>
          Edit Warehouse
        </Typography>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "800px",
            margin: "0 auto",

            overflow: "hidden",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                value={warehouseRequest?.name}
                onChange={handleFormChange}
                size="small"
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                label="Street Address"
                name="streetAddress"
                variant="outlined"
                value={warehouseRequest?.address?.streetAddress}
                fullWidth
                size="small"
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Postal Address"
                name="postalAddress"
                variant="outlined"
                value={warehouseRequest?.address?.postalAddress}
                fullWidth
                size="small"
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Region or State"
                name="regionOrState"
                variant="outlined"
                fullWidth
                value={warehouseRequest?.regionOrState}
                size="small"
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">Country</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Country"
                  name="country"
                  onChange={handleFormChange}
                  value={warehouseRequest?.address?.country}
                >
                  {countries &&
                    countries?.map((country) => (
                      <MenuItem value={country?.name}>{country?.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  name="city"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="City"
                  onChange={handleFormChange}
                  value={warehouseRequest?.address?.city}
                >
                  {warehouseRequest?.address?.country &&
                    getCities(warehouseRequest?.address?.country)?.map(
                      (city) => <MenuItem value={city}>{city}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <Box sx={{ mt: 4, height: "400px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                  center={{
                    lat:
                      warehouseRequest?.address?.addressLocaion?.latitude ??
                      5.612218041361714,
                    lng:
                      warehouseRequest?.address?.addressLocaion?.longitude ??
                      -0.20050925221460147,
                  }}
                  defaultZoom={11}
                  onChange={handleGPSChange}
                >
                  <Marker
                    lat={warehouseRequest?.address?.addressLocation?.latitude}
                    lng={warehouseRequest?.address?.addressLocation?.longitude}
                  />
                </GoogleMapReact>
              </Box>
            </Grid>

            <Grid item md={12}>
              <FormGroup>
                <span>
                  <FormControlLabel
                    onChange={handleFormChange}
                    checked={warehouseRequest?.active}
                    control={<Checkbox />}
                    label="Enabled?"
                    name="active"
                  />
                </span>
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <FormGroup>
                <span>
                  <FormControlLabel
                    onChange={handleFormChange}
                    control={<Checkbox />}
                    checked={warehouseRequest?.defaultWarehouse}
                    label="Default Warehouse?"
                    name="defaultWarehouse"
                  />
                </span>
              </FormGroup>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              mt: "30px",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{ borderRadius: "20px" }}
              onClick={handleCancelInventory}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: "20px" }}
              onClick={handleSubmitWarehouse}
            >
              Submit
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditWarehouse;
