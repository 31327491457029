export const merchantMedia = {
  id: "",
  type: "",
  referenceCode: "",
  media: {
    filename: "",
    fileUrl: "",
    mimeType: "",
    size: 0,
  },
  merchantId: "",
};
