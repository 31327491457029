import { Routes, Route, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Dashboard from "./pages/Dashboard";
import {
  AddMerchant,
  Orders,
  MerchantCategories,
  SingleMerchantCategory,
  Settings,
  SingleMerchantPurchaseOrder,
  Merchants,
  SingleOrder,
  SingleMerchant,
  Countries,
  Products,
  Inventory,
  Transactions,
  SingleInventory,
  Warehouses,
  AddOrder,
  AddProductOnboarding,
  AddCountry,
  SingleCountry,
  EditCountry,
  ViewProductLayout,
  EditProductLayout,
  Remittance,
  TransactionActivity,
  RemittanceJob,
  Payments,
  PaymentProviders,
  RemittanceConfiguration,
  AddRemittanceConfiguration,
  SingleRemittanceConfiguration,
  SingleRemittanceJob,
  RemittanceMap,
  EditRemittanceConfiguration,
  EditRemittanceJob,
  AddRemittanceJob,
  TransactionLimits,
  AddTransactionLimit,
  EditTransactionLimit,
  SingleTransactionLimit,
  EditMerchantCategory,
  Cases,
  CaseAgent,
  ChatRoom,
  SingleCase,
  AddPaymentProvider,
  Devices,
  RemittanceInstitutions,
  AddRemittanceInstitution,
  SingleRemittanceInstitution,
  EditRemittanceInstitution,
  RemittancePayloadCustomMap,
  SingleRemittancePayloadCustomMap,
  RemittanceAttempt,
  SingleRemittanceAttempt,
  AddRemittancePayloadCustomMap,
  EditRemittancePayloadCustomMap,
  NotFound,
} from "./pages";
import SingleTransaction from "./pages/SingleTransaction";
import SingleOrderItem from "./pages/SingleOrderItem";
import DomainUsers from "./pages/DomainUsers";
import SingleDomainUser from "./pages/SingleDomainUser";
import UpdatePassword from "./pages/UpdatePassword";
import ForgotPassword from "./pages/ForgotPassword";
import LoginPage from "./pages/LoginPage";
import OrderActivity from "./pages/OrderActivity";
import RemittanceActivity from "./pages/RemittanceActivity";
import ProcurementActivity from "./pages/ProcurementActivity";
import SinglePayment from "./pages/SinglePayment";
import Forex from "./pages/Forex";
import SingleForex from "./pages/SingleForex";
import ForexActivityLog from "./pages/ForexActivityLog";
import MainLayout from "./Layout";
import CompleteForgotPassword from "./pages/CompleteForgotPassword";
import DisputesActivityLog from "./pages/DisputesActivityLog";
import Currencies from "./pages/Currencies";
import MerchantPurchaseOrders from "./pages/MerchantPurchaseOrders";
import Users from "./pages/Users";
import EditWarehouse from "./pages/EditWarehouse";
import SingleWarehouse from "./pages/SingleWarehouse";
import AddDomainUser from "./pages/AddDomainUser";
import Notifications from "./pages/Notifications";
import AddWarehouse from "./pages/AddWarehouse";
import CreatePassword from "./pages/CreatePassword";
import AuthLayout from "./AuthLayout";
import EditMerchantLayout from "./pages/EditMerchantLayout";
import AddMerchantAdmin from "./pages/AddMerchantAdmin";
import MerchantAdmins from "./pages/MerchantAdmins";
import SingleMerchantAdmin from "./pages/SingleMerchantAdmin";
import SingleForexPage from "./pages/SingleForexPage"
import  ProgramMembers  from "./pages/loyalty/programMembers";
import  ProductSpec  from "./pages/loyalty/productSpec";
import  Product  from "./pages/loyalty/Product";
import  Actions  from "./pages/loyalty/actions";
import  EventType  from "./pages/loyalty/eventType";
import MemberAccounts from "./pages/loyalty/memberAccount";
import { ProductSpecDetails } from "./pages/loyalty/productSpecDetails";
import LoyaltyTransaction from "./pages/loyalty/loyaltyTransaction";
import { LoyaltyTransactionDetails } from "./pages/loyalty/loyaltyTransactionDetails";
import AddProductSpec from "./pages/loyalty/addProductSpec";
import UpdateProductSpec from "./pages/loyalty/updateProductSpec";
import { ProductDetails } from "./pages/loyalty/productDetails";
import AddProduct from "./pages/loyalty/addProduct";
import AddProductSpecRule from "./pages/loyalty/addProductSpecRule";
import TransactionCallBack from "./pages/transactionCallBack";

function App() {
  return (
    <Box>
      {/* Routes */}

      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Navigate replace to={"/login"} />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="complete-reset" element={<CompleteForgotPassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="user-reset-password" element={<CreatePassword />} />
        </Route>
        <Route path="/pages" element={<MainLayout />}>
          <Route index element={<Navigate replace to={"/pages/dashboard"} />} />
          <Route path="dashboard" element={<Dashboard />} />
          {/* Merchant  */}
          <Route path="merchants" element={<Merchants />} />
          <Route path="merchant/add" element={<AddMerchant />} />
          <Route path="merchant/edit/:id" element={<EditMerchantLayout />} />
          <Route path="merchant/:id" element={<SingleMerchant />} />
          <Route path="merchants/categories" element={<MerchantCategories />} />
          <Route
            path="merchant/category/:id"
            element={<SingleMerchantCategory />}
          />
          <Route
            path="merchant/category/edit/:id"
            element={<EditMerchantCategory />}
          />
          <Route path="warehouses" element={<Warehouses />} />
          <Route path="warehouse/add" element={<AddWarehouse />} />
          <Route path="warehouse/edit/:id" element={<EditWarehouse />} />
          <Route path="warehouse/:id" element={<SingleWarehouse />} />
          <Route
            path="merchant-purchase-order"
            element={<MerchantPurchaseOrders />}
          />
          <Route
            path="merchant-purchase-order/:id"
            element={<SingleMerchantPurchaseOrder />}
          />
          <Route path="products" element={<Products />} />
          <Route path="product/:id" element={<ViewProductLayout />} />
          <Route path="product/add" element={<AddProductOnboarding />} />
          <Route path="product/edit/:id" element={<EditProductLayout />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="inventory/:id" element={<SingleInventory />} />

          {/* Country */}
          <Route path="countries" element={<Countries />} />
          <Route path="forex" element={<Forex />} />
          {/* <Route path="forex/:id" element={<SingleForexPage />} /> */}
          <Route path="forex-activity-log" element={<ForexActivityLog />} />
          <Route path="currencies" element={<Currencies />} />

          {/* Order */}
          <Route path="orders" element={<Orders />} />
          <Route path="order/add" element={<AddOrder />} />
          <Route path="order/:id" element={<SingleOrder />} />
          <Route path="order/view-more" element={<SingleOrderItem />} />
          <Route path="order/activities" element={<OrderActivity />} />

          <Route
            path="disputes-activity-log"
            element={<DisputesActivityLog />}
          />

          {/* Transaction */}
          <Route path="transactions" element={<Transactions />} />
          <Route path="transaction/:id" element={<SingleTransaction />} />
          <Route
            path="transaction/activities"
            element={<TransactionActivity />}
          />

          {/* Remittance */}
          <Route path="remittance" element={<Remittance />} />
          <Route path="remittance-jobs" element={<RemittanceJob />} />
          <Route
            path="remittance-job/:id"
            element={<SingleRemittanceJob />}
          />
          <Route
            path="remittance/activities"
            element={<RemittanceActivity />}
          />
          <Route path="remittance/institutions" element={<RemittanceInstitutions />} />
          <Route path="remittance/institution/add" element={<AddRemittanceInstitution />} />
          <Route path="remittance/institution/:id" element={<SingleRemittanceInstitution />} />
          <Route path="remittance/institution/edit/:id" element={<EditRemittanceInstitution />} />
          <Route path="remittance/custom-maps" element={<RemittancePayloadCustomMap />} />
          <Route path="remittance/custom-map/:id" element={<SingleRemittancePayloadCustomMap />} />
          <Route path="remittance/custom-map/edit/:id" element={<EditRemittancePayloadCustomMap />} />
          <Route path="remittance/custom-map/add" element={<AddRemittancePayloadCustomMap />} />
          <Route path="remittance/attempts" element={<RemittanceAttempt />} />
          <Route path="remittance/attempt/:id" element={<SingleRemittanceAttempt />} />

          {/* Payments */}
          <Route path="payments" element={<Payments />} />
          <Route path="payments/:id" element={<SinglePayment />} />
          <Route path="payment/providers" element={<PaymentProviders />} />
          <Route path="payment/provider/add" element={<AddPaymentProvider />} />


          {/* loyalty */}
        
          <Route path="loyalty/program-members" element={<ProgramMembers/>} />
          <Route path="loyalty/member/:userId" element={<MemberAccounts/>} />
          <Route path="loyalty/product-spec" element={<ProductSpec />} />
          <Route path="loyalty/product-spec/:id" element={<ProductSpecDetails />} />
          <Route path="loyalty/add-product-spec" element={<AddProductSpec />} />
          <Route path="loyalty/add-product-spec-rule" element={<AddProductSpecRule />} />
          <Route path="loyalty/update-product-spec/:id" element={<UpdateProductSpec />} />
          <Route path="loyalty/product" element={<Product />} />
          <Route path="loyalty/actions" element={<Actions />} />
          <Route path="loyalty/event-type" element={<EventType />} />
          <Route path="loyalty/transaction" element={<LoyaltyTransaction />} /> 
          <Route path="loyalty/transaction/detail/:id" element={<LoyaltyTransactionDetails />} /> 
          <Route path="loyalty/product/:id" element={<ProductDetails/>} /> 
          <Route path="loyalty/add-product" element={<AddProduct />} />


     
          {/* User */}
          <Route path="settings" element={<Settings />} />
          <Route path="add-domain-user" element={<AddDomainUser />} />
          <Route path="domain/:id" element={<SingleDomainUser />} />
          <Route path="admin/:id" element={<SingleMerchantAdmin />} />
          <Route path="domain-users" element={<DomainUsers />} />
          <Route path="merchant-admin" element={<MerchantAdmins />} />
          <Route path="add-merchant-admin" element={<AddMerchantAdmin />} />
          <Route path="domain/:id" element={<SingleDomainUser />} />
          <Route path="update-password" element={<UpdatePassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="mobile-users" element={<Users />} />

          {/**Disputes */}
          <Route path="cases" element={<Cases />} />
          <Route path="case/agents" element={<CaseAgent />} />
          <Route path="case/:id" element={<SingleCase />} />
          <Route path="chat/:id/:caseId" element={<ChatRoom />} />
          <Route
            path="disputes-activity-log"
            element={<DisputesActivityLog />}
          />

          {/**Notification */}
          <Route path="notifications" element={<Notifications />} />

          <Route path="forex" element={<Forex />} />
          <Route path="forex/:id" element={<SingleForex />} />

          <Route path="transactions" element={<Transactions />} />
          <Route path="transaction/callback" element={<TransactionCallBack />} />

          <Route path="transaction/:id" element={<SingleTransaction />} />
          <Route path="transaction/limits" element={<TransactionLimits />} />
          <Route
            path="transaction/limit/:id"
            element={<SingleTransactionLimit />}
          />
          <Route
            path="transaction/limit/add"
            element={<AddTransactionLimit />}
          />
          <Route
            path="transaction/limit/edit/:id"
            element={<EditTransactionLimit />}
          />

          <Route path="remittance/jobs" element={<RemittanceJob />} />
          <Route path="remittance/job/:id" element={<SingleRemittanceJob />} />
          <Route path="remittance/job/add" element={<AddRemittanceJob />} />
          <Route
            path="remittance/job/edit/:id"
            element={<EditRemittanceJob />}
          />

          <Route path="remittance/maps" element={<RemittanceMap />} />
          <Route
            path="remittance/configurations"
            element={<RemittanceConfiguration />}
          />
          <Route
            path="remittance/configuration/add"
            element={<AddRemittanceConfiguration />}
          />
          <Route
            path="procurement-activities"
            element={<ProcurementActivity />}
          />
          <Route
            path="remittance/configuration/:id"
            element={<SingleRemittanceConfiguration />}
          />
          <Route
            path="remittance/configuration/edit/:id"
            element={<EditRemittanceConfiguration />}
          />
          <Route path="payments" element={<Payments />} />
          <Route path="payments/:id" element={<SinglePayment />} />
          {/* <Route path="/transaction/add" element={<AddTransaction/>}/> */}
          <Route path="devices" element={<Devices />} />
          {/* Not Found */}
          <Route path="*" element={<NotFound />} />
          <Route
            path="procurement-activities"
            element={<ProcurementActivity />}
          />
        </Route>
        {/* Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <Footer /> */}
    </Box>
  );
}

export default App;
