import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye } from "react-icons/fi";
import moment from "moment";

export const ProcurementActivityTable = (handleViewActivity) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => moment(value).format("LLL") ?? "N/A",
    },
    {
      field: "updated",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: " Updated",
      valueGetter: ({ value }) => moment(value).format("LLL") ?? "N/A",
    },

    {
      field: "taskId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Task ID",
      valueGetter: ({ row }) => {
        return row?.taskId ?? "N/A";
      },
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton color="primary" onClick={() => handleViewActivity(row)}>
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
