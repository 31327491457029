export const merchantDocument = {
  id: "",
  type: "",
  referenceCode: "",
  media: {
    id: "",
    filename: "",
    fileUrl: "",
    mimeType: "",
    size: 0,
  },
  created: "",
  updated: "",
};
