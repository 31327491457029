import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { accountTypes } from "../components/common/dropdownsData";
import { useUpdateMerchantMutation } from "../services/MerchantService";
import {
  useGetRemittanceConfigurationQuery,
  useUpdateRemittanceConfigurationMutation,
} from "../services/RemittanceConfigurationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { KeyboardBackspace } from "@mui/icons-material";

const EditRemittanceConfiguration = () => {
  const { id } = useParams();
  const [updateMerchant] = useUpdateMerchantMutation();
  const [updateRemittanceConfiguration] =
    useUpdateRemittanceConfigurationMutation();
  let {
    isLoading,
    data: configurationData,
    isSuccess: isMerchantSuccess,
  } = useGetRemittanceConfigurationQuery(id);
  const [configurationRequest, setconfigurationRequest] = useState(null);

  const navigate = useNavigate();

  const updateFields = (fields) => {
    setconfigurationRequest((prev) => ({ ...prev, ...fields }));
  };

  const handleEnabledChange = () => {
    updateFields({ enabled: !configurationRequest.enabled });
  };

  const handleSubmitConfiguration = async (event) => {
    event.preventDefault();

    await updateRemittanceConfiguration(configurationRequest)
      .then((response) => {
        if (response?.data.data) {
          SuccessToast("Configuration updated succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to update Configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Configuration");
      });
  };

  const initconfigurationRequest = () => {
    if (configurationData) {
      setconfigurationRequest(configurationData.data);
    }
  };

  React.useEffect(() => {
    initconfigurationRequest();
  }, [configurationData]);

  if (configurationRequest) {
    let {
      retryEnabled,
      maxRetryAttempt,
      statusesRetried,
      account,
      enabled,
      accountType,
    } = configurationRequest;

    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={-1}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5"> Edit Configuration</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "800px",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <TextField
              label="Account"
              variant="outlined"
              rows={4}
              name="account"
              value={account}
              onChange={(e) => {
                updateFields({ account: e.target.value });
              }}
              fullWidth
              size="small"
            />
            <TextField
              label="Max Retry Attempt"
              variant="outlined"
              rows={4}
              type="number"
              name="maxRetryAttempt"
              value={maxRetryAttempt}
              onChange={(e) => {
                updateFields({ maxRetryAttempt: e.target.value });
              }}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Account Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={accountType}
                label="Account Type"
                name="accountType"
                onChange={(e) => {
                  updateFields({ accountType: e.target.value });
                }}
              >
                {Object.keys(accountTypes).map((type, i) => (
                  <MenuItem key={i} value={type}>
                    {accountTypes[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex" }}
              required
            >
              <FormGroup className="mt-5">
                <FormControlLabel
                  control={<Switch />}
                  label="Enabled"
                  checked={enabled}
                  onChange={() => {
                    updateFields({ enabled: !configurationRequest.enabled });
                  }}
                />
              </FormGroup>
              <FormGroup className="mt-5">
                <FormControlLabel
                  control={<Switch />}
                  label="Retry Enabled"
                  checked={retryEnabled}
                  onChange={() => {
                    updateFields({
                      retryEnabled: !configurationRequest.retryEnabled,
                    });
                  }}
                />
              </FormGroup>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "30px",
            }}
          >
            <Button
              onClick={handleSubmitConfiguration}
              variant="contained"
              sx={{ borderRadius: "20px" }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditRemittanceConfiguration;
