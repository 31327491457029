import React, { useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUpdatePasswordMutation } from "../services/PasswordService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const UpdatePassword = ({ isOpen, onClose }) => {
  const [updatePassword] = useUpdatePasswordMutation();

  let userId = "";

  if (sessionStorage.getItem("userId")) {
    userId = sessionStorage.getItem("userId");
  }

  const [passwords, setPasswords] = useState({
    id: userId,
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    if (passwords.password.length < 8) {
      ErrorToast("Password must be at least 8 characters long.");
      return;
    }

    if (!/[A-Z]/.test(passwords.password)) {
      ErrorToast("Password must contain at least one uppercase letter.");
      return;
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(passwords.password)) {
      ErrorToast("Password must contain at least one special symbol.");
      return;
    }

    try {
      const response = await updatePassword(passwords);

      if (response.data) {
        SuccessToast("Password update successfully");

        onClose();
      } else {
        ErrorToast("Failed to update password. Please try again.");
      }
    } catch (error) {
      ErrorToast("An error occurred. Please try again later.");
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Update Password
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          value={passwords.password}
          onChange={handlePasswordChange}
          required
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          value={passwords.confirmPassword}
          onChange={handlePasswordChange}
          required
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePassword;
