import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetRemittanceInstitutionQuery } from "../services/RemittanceInstitutionService";
import moment from "moment";
import { RemittanceInstitutionConfigurationsTable } from "../data/RemittanceInstitutionConfigurationsTable";
import { MdBusiness } from "react-icons/md";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { remittanceInstitutionTypes } from "../components/common/dropdownsData";

const SingleRemittanceInstitution = () => {
  const { id } = useParams();
  const { data: institutionData, isLoading } =
    useGetRemittanceInstitutionQuery(id);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleDeleteAction = async (row) => {
    setOpen(true);
    // setSelectedMerchantCategory(row);
  };
  const printRef = useRef();
  const columns = RemittanceInstitutionConfigurationsTable(handleDeleteAction);

  if (institutionData) {
    let institution = institutionData.data;
    return (
      <Box
        sx={{
          pt: "80px",
          pb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start", pb: "20px" }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
          ref={printRef}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <Typography
              variant="h5"
              mb={3}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Institutions Details
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID: <span style={{ opacity: 0.7 }}>{institution?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>{institution?.name}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Label:{" "}
                    <span style={{ opacity: 0.7 }}>{institution?.label}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Institution Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceInstitutionTypes[institution?.institutionType]}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Provider Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {institution?.providerId}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Enabled:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {institution?.enabled?.toString()}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Created By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {institution?.createdBy}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {institution?.lastModifiedBy}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(institution?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(institution?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleRemittanceInstitution;
