import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dropDownSuggestionsUrl } from "../presentation/shared/ApiUrls";

export const dropDownSuggestionsApi = createApi({
  reducerPath: "dropDownSuggestionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: dropDownSuggestionsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["DropDownSuggestions"],
  endpoints: (builder) => ({
    getAllDropDownSuggestions: builder.query({
      query: (filterDropDownSuggestionRequest) => ({
        url: `/suggestions?${filterDropDownSuggestionRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ["DropDownSuggestions"],
    }),

    getDropDownSuggestion: builder.query({
      query: (id) => ({
        url: `/suggestions/${id}`,
        method: "GET",
      }),
      providesTags: ["DropDownSuggestions"],
    }),

    searchDropDownSuggestions: builder.mutation({
      query: (filterDropDownSuggestionRequest) => ({
        url: `/suggestions?${filterDropDownSuggestionRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["DropDownSuggestions"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateDropDownSuggestion: builder.mutation({
      query: (updateDropDownSuggestionRequest) => ({
        url: "/suggestions",
        method: "PUT",
        body: updateDropDownSuggestionRequest,
      }),
      invalidatesTags: ["DropDownSuggestions"],
    }),
    createDropDownSuggestion: builder.mutation({
      query: (addDropDownSuggestionRequest) => ({
        url: "/suggestions",
        method: "POST",
        body: addDropDownSuggestionRequest,
      }),
      invalidatesTags: ["DropDownSuggestions"],
    }),
    deleteDropDownSuggestion: builder.mutation({
      query: (id) => ({
        url: `/suggestions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DropDownSuggestions"],
    }),
  }),
});

export const {
  useGetAllDropDownSuggestionsQuery,
  useGetDropDownSuggestionQuery,
  useSearchDropDownSuggestionsMutation,
  useCreateDropDownSuggestionMutation,
  useUpdateDropDownSuggestionMutation,
  useDeleteDropDownSuggestionMutation,
} = dropDownSuggestionsApi;
