import React, { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Button,
  DialogActions,
  Box,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import UserProfileImage from "../common/UserProfileImage";
import OtherImageView from "../common/OtherImageView";

const UserProfileModal = ({ open, handleClose, user }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const url =
    "https://charlie-02.s3.amazonaws.com/resteasy-reactive17621021768198010772upload";
  const pathAfterDotCom = url.replace(/^.*\.com\//, "");

  console.log("Path after .com/:", pathAfterDotCom);

  const handleConvertUrl = (url) => url.replace(/^.*\.com\//, "");

  if (!user) return <h1>Users</h1>;

  const { kyc } = user;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth={kyc?.kycDocument?.length > 0 ? "lg" : "sm"}
    >
      <DialogContent>
        <DialogContentText>
          <Typography sx={{ color: "grey", pb: "30px", textAlign: "center" }}>
            User Profile
          </Typography>
        </DialogContentText>

        {kyc?.kycDocument.length > 0 ? (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {kyc?.kycDocument?.map((item, key) => (
                      <Tab label={item.documentType} value={key} />
                    ))}
                  </TabList>
                </Box>
                {kyc?.kycDocument?.map((item, key) => {
                  const convertedImageUrl = handleConvertUrl(
                    item.data["imageUrlFront"]
                  );

                  return (
                    <TabPanel value={key}>
                      <Box sx={{ textAlign: "center" }}>
                        {item ? (
                          ["PASSPORT", "SELFIE"].includes(item.documentType) ? (
                            <UserProfileImage imageUrl={convertedImageUrl} />
                          ) : (
                            <>
                              <UserProfileImage imageUrl={convertedImageUrl} />

                              <UserProfileImage
                                imageUrl={handleConvertUrl(
                                  item.data["imageUrlBack"]
                                )}
                              />
                            </>
                          )
                        ) : (
                          <label htmlFor="image-upload">
                            <img alt={"User Profile Not Uploaded"} />
                          </label>
                        )}
                      </Box>
                    </TabPanel>
                  );
                })}
              </>
            </TabContext>
          </Box>
        ) : (
          <div>User Profile not uploaded</div>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button onClick={handleClose} variant="contained">
            OK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UserProfileModal;
