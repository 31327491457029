import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiberManualRecord } from "@mui/icons-material";
import moment from "moment";

export const WarehousesTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => moment(value).format("LLL"),
    },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "active",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Active",
      renderCell: ({ value }) => (
        <>
          {value ? (
            <FiberManualRecord fontSize={"15px"} color="success" />
          ) : (
            <FiberManualRecord fontSize={"15px"} color="error" />
          )}
        </>
      ),
    },
    {
      field: "defaultWarehouse",
      flex: 1,
      headerAlign: "center",
      type: "boolean",
      align: "center",
      headerName: "Default warehouse",
    },
    {
      field: "address",
      filterable: false,

      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Location",
      valueGetter: ({ value }) =>
        `${value?.city ?? "N/A"} - ${value?.country ?? "N/A"}`,
    },
    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/warehouse/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/warehouse/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
