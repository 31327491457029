import {
  Box,
  Button,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { productTypes } from "../components/common/dropdownsData";
import queryString from "query-string";
import { Add } from "@mui/icons-material";
import AvailableWeightComponent from "../components/common/AvailableWeightComponent";
import AvailableVolumeComponent from "../components/common/AvailableVolumeComponent";
import AvailableMeasurementComponent from "../components/common/AvailableMeasurementComponent";
import { addProductDescription_Step_1 } from "../components/common/descriptions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetAllMerchantSubCategoriesQuery } from "../services/MerchantSubCategoryService";
import { availableVolumeRequest } from "../models/availableVolume";
import { availableDimensionRequest } from "../models/availableDimension";
import { availableWeightRequest } from "../models/availableWeight";
import Chip from "@mui/material/Chip";
import { Divider } from "@mui/material";

const ProductDescription = ({ product, setProduct }) => {
  const filter = queryString.stringify({ size: 10000 });
  const { data: subCategoryData } = useGetAllMerchantSubCategoriesQuery(filter);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleProductTypeChange = (event) => {
    const { name, value } = event.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };
  const handleProductNameChange = (event) => {
    const { name, value } = event.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleBooleanFildsChange = (event) => {
    const { name, checked } = event.target;
    setProduct((prev) => ({ ...prev, [name]: checked }));
  };

  const handleAvailableWeightComponent = () => {
    setProduct((prev) => ({
      ...prev,
      availableWeight: [...prev?.availableWeight, availableWeightRequest],
    }));
  };
  const handleAvailableVolumeComponent = () => {
    setProduct((prev) => ({
      ...prev,
      availableVolume: [...prev?.availableVolume, availableVolumeRequest],
    }));
  };
  const handleAvailableMeasurementComponent = () => {
    setProduct((prev) => ({
      ...prev,
      availableMeasurement: [
        ...prev?.availableMeasurement,
        availableDimensionRequest,
      ],
    }));
  };
  const handleCustomerAmountAcceptedChange = (event) => {
    const { name, checked } = event.target;
    setProduct((prev) => ({ ...prev, [name]: checked }));
  };

  const handleMerchantCategoriesChange = (event) => {
    const { name, value } = event.target;
    setProduct((prev) => ({ ...prev, [name]: [value] }));
  };
  const handleLicensingDocumentRequiredChange = (event) => {
    const { name, checked } = event.target;
    setProduct((prev) => ({ ...prev, [name]: checked }));
  };

  return (
    <Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "60%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Typography
          color={"gray"}
          sx={{ fontStyle: "italic", fontFamily: "sans-serif", mb: 10 }}
        >
          {addProductDescription_Step_1}
        </Typography>

        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  gap: 2,
                }}
              >
                <TextField
                  label="Product name"
                  variant="outlined"
                  required
                  value={product?.name}
                  name="name"
                  fullWidth
                  onChange={handleProductNameChange}
                  size="small"
                />

                <FormControl fullWidth size="small" required>
                  <InputLabel id="demo-select-small-label">
                    Product type
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Product type"
                    required
                    value={product?.type}
                    name="type"
                    onChange={handleProductTypeChange}
                  >
                    {productTypes.map(({ value, label }) => (
                      <MenuItem value={value}>{label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {product.type === "PHYSICAL_GOOD" && (
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  <Paper
                    sx={{
                      boxShadow: "none !important",
                      borderRadius: "12px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "divider",
                      p: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Typography color="primary" variant="button">
                        Add Available weights
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleAvailableWeightComponent}
                      >
                        <Add />
                      </Button>
                    </Box>

                    {product &&
                      product?.availableWeight.map((availableWeight, key) => (
                        <AvailableWeightComponent
                          componentKey={key}
                          availableWeight={availableWeight}
                          setProductRequest={setProduct}
                        />
                      ))}
                  </Paper>
                  <Paper
                    sx={{
                      boxShadow: "none !important",
                      borderRadius: "12px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "divider",
                      p: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Typography color="primary" variant="button">
                        Add Available volumes
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleAvailableVolumeComponent}
                      >
                        <Add />
                      </Button>
                    </Box>
                    {product &&
                      product?.availableVolume.map((availableVolume, key) => (
                        <AvailableVolumeComponent
                          componentKey={key}
                          availableWeight={availableVolume}
                          setProductRequest={setProduct}
                        />
                      ))}
                  </Paper>
                  <Paper
                    sx={{
                      boxShadow: "none !important",
                      borderRadius: "12px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "divider",
                      p: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Typography color="primary" variant="button">
                        Add Available dimensions
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleAvailableMeasurementComponent}
                      >
                        <Add />
                      </Button>
                    </Box>
                    {product &&
                      product?.availableMeasurement.map(
                        (availableMeasurement, key) => (
                          <AvailableMeasurementComponent
                            componentKey={key}
                            availableMeasurement={availableMeasurement}
                            setProductRequest={setProduct}
                          />
                        )
                      )}
                  </Paper>
                </Box>
              )}
              <Box
                sx={{ mt: 1, display: "flex", alignItems: "center", gap: 4 }}
              >
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="categories-label">Categories</InputLabel>
                  <Select
                    labelId="categories-label"
                    name="categories"
                    fullWidth
                    value={product?.categories?.at(0)}
                    onChange={handleMerchantCategoriesChange}
                    label="Categories"
                  >
                    {subCategoryData &&
                      subCategoryData?.map((subCategory, index) => (
                        <MenuItem key={index} value={subCategory}>
                          {subCategory?.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Divider variant="middle">
                <Chip variant="outlined" label="Advanced" />
              </Divider>

              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Configurations
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ mt: 1, alignItems: "center", gap: 5 }}>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="enabled"
                          checked={product?.enabled}
                          onChange={handleBooleanFildsChange}
                          control={<Checkbox />}
                          label="Enable product after creation"
                        />
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="platformSKU"
                          checked={product?.platformSKU}
                          onChange={handleBooleanFildsChange}
                          control={<Checkbox />}
                          label="Platform stock keeping unit?"
                        />
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="service"
                          checked={product?.service}
                          onChange={handleBooleanFildsChange}
                          control={<Checkbox />}
                          label="Service?"
                        />
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="customerAmountAccepted"
                          checked={product.customerAmountAccepted}
                          onChange={handleCustomerAmountAcceptedChange}
                          control={<Checkbox />}
                          label="Customer amount accepted?"
                        />
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <span>
                        <FormControlLabel
                          name="licensingDocumentRequired"
                          checked={product?.licensingDocumentRequired}
                          onChange={handleLicensingDocumentRequiredChange}
                          control={<Checkbox />}
                          label="Licensing document required?"
                        />
                      </span>
                    </FormGroup>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProductDescription;
