export const createdropDownSuggestionRequest = {
  formQuestionId: "",
  dropDownLabel: "",
  dropDownValue: "",
  bindValue: "",
};
export const updateDropDownSuggestionRequest = {
  id: "",
  formQuestionId: "",
  dropDownLabel: "",
  dropDownValue: "",
  bindValue: "",
};
