import { Box } from "@mui/system";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { HiOutlineDownload } from "react-icons/hi";
import { filesize } from "filesize";

export const InventoryMediaTable = ({
  handleDeleteAction,
  handleDownloadAction,
  handleViewAction,
}) => {
  return [
    {
      field: "filename",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "fileUrl",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Url",
    },

    {
      field: "mimeType",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Mimetype",
    },
    {
      field: "size",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Size",
      valueGetter: ({ value }) => (value ? filesize(value) : filesize(0)),
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            placement="top"
            title="Download"
            onClick={() => {
              handleDownloadAction(row);
            }}
          >
            <IconButton color="primary">
              <HiOutlineDownload size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
