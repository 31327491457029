import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { paymentMediaUrl } from "../presentation/shared/ApiUrls";

export const paymentMediaApi = createApi({
  reducerPath: "paymentMediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: paymentMediaUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect:true,refetchOnMountOrArgChange: true, 
  tagTypes: ["Payment Media"],
  endpoints: (builder) => ({
    getAllPaymentMedia: builder.query({
      query: (filterRequest) => ({
        url: `/?${filterRequest}`,
        method: "GET",
      }),
      providesTags: ["Payment Media"],
    }),

    getPaymentMedia: builder.query({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
      providesTags: ["Payment Media"],
    }),

    searchPaymentMedia: builder.mutation({
      query: (filterRequest) => ({
        url: `/?${filterRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["Payment Media"],
    }),
    updatePaymentMedia: builder.mutation({
      query: (updateRequest) => ({
        url: "/",
        method: "PUT",
        body: updateRequest,
      }),
      invalidatesTags: ["Payment Media"],
    }),
    createPaymentMedia: builder.mutation({
      query: (addRequest) => ({
        url: "/",
        method: "POST",
        body: addRequest,
      }),
      invalidatesTags: ["Payment Media"],
    }),
    deletePaymentMedia: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment Media"],
    }),
  }),
});

export const {
  useGetAllPaymentMediaQuery,
  useGetPaymentMediaQuery,
  useSearchPaymentMediaMutation,
  useCreatePaymentMediaMutation,
  useUpdatePaymentMediaMutation,
  useDeletePaymentMediaMutation,
} = paymentMediaApi;
