import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

export const ForexTable = ({
  // handleViewAction,
  handleEditAction,
  handleDeleteAction,
}) => {
  return [
    {
      field: "created",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "homeCurrency",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Home Currency",
    },

    {
      field: "foreignCurrency",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Foreign Currency",
    },
    {
      field: "sellingRate",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Selling Rate",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "buyingRate",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Buying Rate",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/forex/${row.id}`}>
              <IconButton
                color="primary"
                // onClick={() => {
                //   handleViewAction(row);
                // }}
              >
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
