import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getEmojiFlag } from "countries-list";
import moment from "moment";

export const MerchantsTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "code",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Code",
    },
    {
      field: "active",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Active",
      renderCell: ({ value }) =>
        value ? (
          <FiberManualRecord fontSize={"15px"} color="success" />
        ) : (
          <WarningOutlined fontSize={"15px"} color="error" />
        ),
    },
    {
      field: "verified",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Verified",
      renderCell: ({ value }) =>
        value ? (
          <VerifiedIcon fontSize={"15px"} color="primary" />
        ) : (
          <VerifiedIcon fontSize={"15px"} color="error" />
        ),
    },
    {
      field: "address",
      flex: 1,
      headerAlign: "center",
      filterable: false,
      sortable: false,
      align: "center",
      headerName: "Location",
      valueGetter: ({ row }) =>
        `${getEmojiFlag(row.countryCode)} ${row?.address?.city ?? "N/A"}-${
          row?.address?.country ?? "N/A"
        }`,
    },

    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/* <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/merchant/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip> */}
          <Tooltip arrow placement="top" title="Edit">
            <Link to={`/pages/merchant/edit/${row.id}`}>
              <IconButton color="primary">
                <FiEdit size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
