// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { merchantPurchaseOrderUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

// Define a service using a base URL and expected endpoints
export const merchantPurchaseOrderApi = createApi({
  reducerPath: "merchantPurchaseOrderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: merchantPurchaseOrderUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MerchantPurchaseOrder"],
  endpoints: (builder) => ({
    getAllMerchantPurchaseOrders: builder.query({
      query: (filterMerchantPurchaseOrderRequest) => ({
        url: `/merchant/orders?${filterMerchantPurchaseOrderRequest}`,
        method: "GET",
      }),
      providesTags: ["MerchantOrder"],
      transformResponse: (response) => response.data.data,
    }),
    searchMerchantPurchaseOrder: builder.query({
      query: (filterMerchantPurchaseOrderRequest) => ({
        url: `/merchant/orders?${queryString.stringify(
          filterMerchantPurchaseOrderRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["MerchantOrder"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    getMerchantPurchaseOrder: builder.query({
      query: (id) => ({
        url: `/merchant/orders/${id}`,
        method: "GET",
      }),
      providesTags: ["MerchantOrder"],
      transformResponse: (response) => response.data,
    }),
    updateMerchantPurchaseOrder: builder.mutation({
      query: (updateMerchantPurchaseOrderRequest) => ({
        url: "/merchant/orders",
        method: "PUT",
        body: updateMerchantPurchaseOrderRequest,
      }),
      invalidatesTags: ["MerchantOrder"],
    }),
    updateMerchantPurchaseOrderPatch: builder.mutation({
      query: ({ purchaseOrderId, merchantId }) => ({
        url: `/merchant/orders/${purchaseOrderId}/${merchantId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["MerchantOrder"],
    }),
    createMerchantPurchaseOrder: builder.mutation({
      query: (createMerchantPurchaseOrderRequest) => ({
        url: "/merchant/orders",
        method: "POST",
        body: createMerchantPurchaseOrderRequest,
      }),
      invalidatesTags: ["MerchantOrder"],
    }),
    deleteMerchantPurchaseOrder: builder.mutation({
      query: (id) => ({
        url: `/merchant/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MerchantOrder"],
    }),
  }),
});

export const {
  useGetAllMerchantPurchaseOrdersQuery,
  useSearchMerchantPurchaseOrderQuery,
  useGetMerchantPurchaseOrderQuery,
  useCreateMerchantPurchaseOrderMutation,
  useUpdateMerchantPurchaseOrderMutation,
  useUpdateMerchantPurchaseOrderPatchMutation,
  useDeleteMerchantPurchaseOrderMutation,
} = merchantPurchaseOrderApi;
