import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { merchantsUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

export const merchantsApi = createApi({
  reducerPath: "merchantsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: merchantsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Merchants", "MerchantContacts"],
  endpoints: (builder) => ({
    getAllMerchants: builder.query({
      query: (filterMerchantRequest) => ({
        url: `/merchants?${filterMerchantRequest}`,
        method: "GET",
      }),
      providesTags: ["Merchants"],
      transformResponse: (response) => response.data.data,
    }),

    getMerchant: builder.query({
      query: (id) => ({
        url: `/merchants/${id}`,
        method: "GET",
      }),
      providesTags: ["Merchants"],
      transformResponse: (response) => response.data,
    }),

    searchMerchants: builder.query({
      query: (filterMerchantRequest) => ({
        url: `/merchants?${queryString.stringify(
          filterMerchantRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Merchants"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateMerchant: builder.mutation({
      query: (updateMerchantRequest) => ({
        url: "/merchants",
        method: "PUT",
        body: updateMerchantRequest,
      }),
      invalidatesTags: ["Merchants"],
    }),
    createMerchant: builder.mutation({
      query: (addMerchantRequest) => ({
        url: "/merchants",
        method: "POST",
        body: addMerchantRequest,
      }),
      invalidatesTags: ["Merchants"],
    }),
    deleteMerchant: builder.mutation({
      query: (id) => ({
        url: `/merchants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Merchants"],
    }),
    getMerchantCount: builder.query({
      query: () => ({
        url: "/merchants/count",
        method: "GET",
      }),
      providesTags: ["MerchantCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantLabCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=lab",
        method: "GET",
      }),
      providesTags: ["MerchantLabCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantConstructionCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=construction",
        method: "GET",
      }),
      providesTags: ["MerchantConstructionCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantSchoolCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=school",
        method: "GET",
      }),
      providesTags: ["MerchantSchoolCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantFundCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=fund",
        method: "GET",
      }),
      providesTags: ["MerchantFundCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantBillCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=bill",
        method: "GET",
      }),
      providesTags: ["MerchantBillCount"],
      transformResponse: (response) => response.data,
    }),
    getMerchantMedicineCategory: builder.query({
      query: () => ({
        url: "/merchants/list?merchantCategory=medicine",
        method: "GET",
      }),
      providesTags: ["MerchantMedicineCount"],
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllMerchantsQuery,
  useGetMerchantQuery,
  useSearchMerchantsQuery,
  useCreateMerchantMutation,
  useUpdateMerchantMutation,
  useDeleteMerchantMutation,
  useGetMerchantCountQuery,
  useGetMerchantBillCategoryQuery,
  useGetMerchantConstructionCategoryQuery,
  useGetMerchantFundCategoryQuery,
  useGetMerchantLabCategoryQuery,
  useGetMerchantMedicineCategoryQuery,
  useGetMerchantSchoolCategoryQuery,
} = merchantsApi;
