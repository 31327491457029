import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { PaymentProviderTable } from "../data/PaymentProviderTable";
import {
  useDeletePaymentProviderMutation,
  useSearchPaymentProviderQuery,
} from "../services/PaymentProviderService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const PaymentProviders = () => {
  const dataGridService = new DataGridService("PaymentProviders");

  const [filter, setFilter] = useState({});

  const [selectPaymentProvider, setselectPaymentProvider] = useState(null);

  const { data, isLoading } = useSearchPaymentProviderQuery(filter);

  const [deletePaymentProvider, { isSuccess: isPaymentProviderDeleteSuccess }] =
    useDeletePaymentProviderMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await deletePaymentProvider(selectPaymentProvider.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Payment Provider deleted succesfully");
        } else {
          ErrorToast("Failed to delete Payment Provider");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Payment Provider");
      })
      .finally(() => {
        setOpen(false);
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setselectPaymentProvider(row);
  };
  const handleSelectedRowsChange = (selection) => {
    setSelectedRows(selection);
  };
  const columns = PaymentProviderTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isPaymentProviderDeleteSuccess]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Payment Providers</Typography>
        <Link
          to="/pages/payment/provider/add"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Payment Provider
          </Button>
        </Link>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Payment Provider"}
        subTitle={`Are you sure you want to delete ${selectPaymentProvider?.name}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            loading={isLoading}
            filterMode="server"
            paginationMode="server"
            rowCount={data?.data?.data.totalElements ?? 0}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            rows={data?.data?.data ?? []}
            getRowId={(row) => row.name}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PaymentProviders;
