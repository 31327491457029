import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetTransactionQuery } from "../services/TransactionService";
import { useGetTransactionLimitsQuery } from "../services/TransactionLimitService";
import moment from "moment/moment";
import { StorageRounded } from "@mui/icons-material";
import LoadingPage from "../components/LoadingPage";
import { RiExchangeBoxFill } from "react-icons/ri";
import { KeyboardBackspace } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  accountTypes,
  userTypes,
  transactionTypes,
} from "../components/common/dropdownsData";

const SingleTransactionLimit = () => {
  const { id } = useParams();
  const { data } = useGetTransactionLimitsQuery(id);
  const navigate = useNavigate();

  if (data) {
    const { data: limit } = data;
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Button variant="outlined" onClick={() => navigate(-1)}>
            <KeyboardBackspace /> BACK
          </Button>
          <Typography variant="h5">Transaction Limit Details</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={2}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <RiExchangeBoxFill />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Transaction Limit
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID: <span style={{ opacity: 0.7 }}>{limit?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Transaction Id:{" "}
                    <span
                      style={{ opacity: 0.7 }}
                    >{`${limit?.transactionId}`}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Sending Country Codee:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {limit?.sendingCountryCode}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Merchant Category:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {limit?.merchantCategory}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Enabled:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {limit?.enabled.toString()}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>{" "}
            <Grid item xs={12} sm={2}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Transactional:{" "}
                    <span style={{ opacity: 0.7 }}>{limit?.transactional}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Daily: <span style={{ opacity: 0.7 }}>{limit?.daily}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {limit?.lastModifiedBy}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(limit?.updated).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(limit?.created).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleTransactionLimit;
