import { Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

export const OrderItemsTable = ({ handleDeleteAction }) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable:false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? new Date(value) : "N/A"),
    },
    {
      field: "quantity",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Quantity",
    },
    {
      field: "paid",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Paid",
      renderCell: ({ value }) =>
        value ? <Paid color="success" /> : <Paid color="error" />,
    },
    {
      field: "deliveryAddress",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Location",
      valueGetter: ({ value }) =>
        value ? `${value?.city}/${value?.country}` : "N/A",
    },
    {
      field: "updated",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable:false,
      headerName: "Date Updated",
      valueGetter: ({ value }) => (value ? new Date(value) : "N/A"),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link
              onClick={localStorage.setItem("view-more", JSON.stringify(row))}
              to={`/pages/order/view-more`}
            >
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>

          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
