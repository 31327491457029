import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";

export const MerchantSubCategoriesTable = ({
  handleDeleteAction,
  handleClickOpen,
  handleOpenView,
}) => {
  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "label",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
    },
    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton color="primary" onClick={() => handleOpenView(row)}>
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton color="primary" onClick={() => handleClickOpen(row)}>
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
