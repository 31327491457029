import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const AddProductStep3 = () => {
  return (
    <Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          textAlign: "center",
          maxWidth: "70%",
          margin: "0 auto",
          
          overflow: "hidden",
        }}
      >
        <Typography variant="h5">Click Finish to complete process</Typography>
      </Paper>
    </Box>
  );
};

export default AddProductStep3;
