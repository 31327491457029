import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LoadingPage from "../components/LoadingPage";
import moment from "moment";

const ViewServiceTaskConfiguration = ({ productConfig }) => {
  if (productConfig) {
    return (
      <Box>
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            {productConfig.customerTasks.map((serviceTask) => (
              <Grid item xs={6} sm={12} md={6}>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    ID :
                  </span>
                  {serviceTask.id}
                </Typography>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Service Name :{" "}
                  </span>
                  {serviceTask.name}
                </Typography>

                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Date Created:{" "}
                  </span>{" "}
                  {serviceTask.created}
                </Typography>

                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Service Task Type:{" "}
                  </span>
                  {serviceTask.taskType}
                </Typography>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Service Task Description:{" "}
                  </span>{" "}
                  {serviceTask.description}
                </Typography>
                <Typography variant="subtitle2">
                  <span
                    style={{
                      fontSize: "15px",
                      margin: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Date Updated:{" "}
                  </span>{" "}
                  {serviceTask?.updated ?
                    moment(serviceTask?.updated).format("LLLL"):"N/A"}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default ViewServiceTaskConfiguration;
