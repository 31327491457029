import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { disputesUrl } from "../presentation/shared/ApiUrls";

export const disputesApi = createApi({
  reducerPath: "disputesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: disputesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Disputes"],
  endpoints: (builder) => ({
    getAllDisputes: builder.query({
      query: (filterDisputeRequest) => ({
        url: `/disputes?${filterDisputeRequest}`,
        method: "GET",
      }),
      providesTags: ["Disputes"],
    }),

    getDispute: builder.query({
      query: (id) => ({
        url: `/disputes/${id}`,
        method: "GET",
      }),
      providesTags: ["Disputes"],
    }),
    searchDisputes: builder.mutation({
      query: (filterDisputeRequest) => ({
        url: `/disputes?${filterDisputeRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["Disputes"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateDispute: builder.mutation({
      query: (updateDisputeRequest) => ({
        url: "/disputes",
        method: "PUT",
        body: updateDisputeRequest,
      }),
      invalidatesTags: ["Disputes"],
    }),
    createDispute: builder.mutation({
      query: (addDisputeRequest) => ({
        url: "/disputes",
        method: "POST",
        body: addDisputeRequest,
      }),
      invalidatesTags: ["Disputes"],
    }),
    deleteDispute: builder.mutation({
      query: (id) => ({
        url: `/disputes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Disputes"],
    }),
  }),
});

export const {
  useGetAllDisputesQuery,
  useGetDisputeQuery,
  useSearchDisputesMutation,
  useCreateDisputeMutation,
  useUpdateDisputeMutation,
  useDeleteDisputeMutation,
} = disputesApi;
