import React, { useState } from 'react';
import {
    Box,
    Paper,
    Grid,
    TextField,
    Button
} from "@mui/material";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { useRetriggerTransactionMutation } from '../services/TransactionService';
import {  useNavigate } from 'react-router-dom';


const TransactionCallBack = () => {

    const navigate = useNavigate()
    const [retriggerTransaction] = useRetriggerTransactionMutation();

    const [formData, setFormData] = useState([
        { name: 'orderId', value: '' },
        { name: 'transactionId', value: '' },
        { name: 'siteReference', value: '' },
        { name: 'settleStatus', value: '' },
        { name: 'transactionReference', value: '' },
    ]);

    const handleChange = (name, value) => {
        setFormData(prevState =>
            prevState.map(field =>
                field.name === name ? { ...field, value } : field
            )
        );
    };

    const handleSubmit = async () => {
        const formDataJSON = {};
    
        formData.forEach(field => {
            formDataJSON[field.name] = field.value;
        });
    
        console.log("formData", formDataJSON);
    
        try {
            const response = await retriggerTransaction(formDataJSON);
            if (response.data.code === 0) {
                SuccessToast("Transaction call back trigger sucessful");
                setFormData([
                    { name: 'orderId', value: '' },
                    { name: 'transactionId', value: '' },
                    { name: 'siteReference', value: '' },
                    { name: 'settleStatus', value: '' },
                    { name: 'transactionReference', value: '' },
                ]);
            } else {
                ErrorToast("Failed to trigger transaction");
            }
        } catch (error) {
            ErrorToast('Error submitting form:', error);
        }
    };

    return (
        <Box sx={{ pt: "80px", pb: "20px" }}>
            <div style={{ margin: "20px 0px", fontWeight: "900", fontSize: "25px" }}>Add Product Spec</div>

            <Paper
                sx={{
                    boxShadow: "none !important",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "divider",
                    padding: "50px 50px",
                    overflow: "hidden",
                    minHeight: "400px",
                    height: "auto"
                }}
            >
                <Grid container spacing={2}>
                    {formData.map((field, index) => (
                        <Grid key={index} item xs={6} sm={12} md={6}>

                                <TextField
                                    label={field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                                    variant="outlined"
                                    value={field.value}
                                    onChange={(e) => handleChange(field.name, e.target.value)}
                                    fullWidth
                                    size="large"
                                >
                                </TextField>
                           
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{
                    display: "flex",
                    margin: "30px",
                    padding: "16px",
                    justifyContent: "end"
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                    onClick={()=> navigate("/pages/transactions")}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ margin: "0px 10px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Trigger
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default TransactionCallBack;
