import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceActivitiesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceActivityApi = createApi({
  reducerPath: "RemittanceActivitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceActivitiesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["Remittance"],
  endpoints: (builder) => ({
    getAllRemittanceActivities: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/remittance/activities?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["Remittance_Activities"],
    }),

    searchRemittanceActivities: builder.query({
      query: (filterRemittanceActivitiesRequest) => ({
        url: `/remittance/activities?${queryString.stringify(
          filterRemittanceActivitiesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Remittance_Activities"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
  }),
});

export const {
  useGetAllRemittanceActivitiesQuery,
  useSearchRemittanceActivitiesQuery,
} = remittanceActivityApi;
