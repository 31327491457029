import React from "react";
import { UnAuthorizedPage } from "../../presentation/UnAuthorizedPage";

const SecuredComponent = ({ children, requiredDomainTypes, type }) => {
  const domainType = sessionStorage.getItem("domainType");

  switch (type) {
    case "PAGE":
      if (requiredDomainTypes.includes(domainType)) {
        return children;
      } else {
        return <UnAuthorizedPage />;
      }
    case "BUTTON":
      if (requiredDomainTypes.includes(domainType)) {
        return children;
      } else {
        return null;
      }
    default:
      return null;
  }
};

export default SecuredComponent;
