import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { DomainUsersTable } from "../data/DomainUsersTable";
import {
  useDeleteDomainMutation,
  useSearchDomainAccountQuery,
} from "../services/DomainAcountService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const DomainUsers = () => {
  const dataGridService = new DataGridService("DomainUsers");
  const [filter, setFilter] = useState({});
  const [selectedDomainUser, setSelectedDomainUser] = useState(null);

  const [deleteDomainUser, { isSuccess: isDomainUserDeleteSuccess }] =
    useDeleteDomainMutation();
  const { data: domainUsers, isLoading } = useSearchDomainAccountQuery(filter);

  const [open, setOpen] = useState(false);

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  // const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await deleteDomainUser(selectedDomainUser.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Domain User deleted succesfully");
        } else {
          ErrorToast("Failed to delete DomainUsers");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete DomainUsers");
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleDeleteAction = async (row) => {
    setSelectedDomainUser(row);
    setOpen(true);
  };
  const columns = DomainUsersTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Domain Users</Typography>
          <Link to="/pages/add-domain-user" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
            >
              Add Domain User
            </Button>
          </Link>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Domain User"}
          subTitle={`Are you sure you want to delete ${selectedDomainUser?.firstName} ${selectedDomainUser?.lastName}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            autoHeight
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            disableColumnSelector
            disableDensitySelector
            loading={isLoading}
            rowCount={domainUsers?.size ?? 0}
            columns={columns}
            rows={domainUsers?.data ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </Box>
      </Box>
    </SecuredComponent>
  );
};
export default DomainUsers;
