import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import { KeyboardBackspace } from "@mui/icons-material";
import { remittanceInstitutionTypes } from "../components/common/dropdownsData";
import { useCreateRemittanceInstitutionMutation } from "../services/RemittanceInstitutionService";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddRemittanceInstitution = () => {
  const INITIAL_DATA = {
    label: "",
    name: "",
    institutionType: "",
    enabled: false,
  };

  const [createRemittanceInstitution] =
    useCreateRemittanceInstitutionMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState(INITIAL_DATA);
  const [configs, setConfigs] = useState([]);
  const [selectedConfigurations, setSelectedConfigurations] = useState([]);

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const handleSubmitInstitution = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    await createRemittanceInstitution(formData)
      .then((response) => {
        if (response?.data?.data) {
          SuccessToast("Configuration created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Configuration");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          <KeyboardBackspace /> BACK
        </Button>
        <Typography size="small" variant="h5">
          Add Institution
        </Typography>
      </Box>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={(e) => updateFields({ name: e.target.value })}
            fullWidth
            size="small"
          />

          <TextField
            label="Label"
            variant="outlined"
            name="label"
            value={formData.label}
            onChange={(e) => updateFields({ label: e.target.value })}
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">
              Institution Type
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.institutionType}
              label="institutiontype"
              name="institutiontype"
              onChange={(e) =>
                updateFields({ institutionType: e.target.value })
              }
            >
              {Object.keys(remittanceInstitutionTypes).map((type, i) => (
                <MenuItem key={i} value={type}>
                  {remittanceInstitutionTypes[type]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" required>
            <FormControlLabel
              control={<Switch />}
              label="Enable"
              checked={formData.enabled}
              onChange={() => {
                updateFields({ enabled: !formData.enabled });
              }}
            />
          </FormControl>
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitInstitution}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddRemittanceInstitution;
