import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Container,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useCreatePasswordMutation } from "../services/PasswordService";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const CreatePassword = () => {
  const [createPassword, { data }] = useCreatePasswordMutation();

  const navigate = useNavigate();

  const { id, token } = queryString.parseUrl(window.location.href).query;

  const [passwords, setPasswords] = useState({
    id: id,
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async () => {
    if (passwords.password.length < 8) {
      ErrorToast("Password must be at least 8 characters long.");
      return;
    }

    if (!/[A-Z]/.test(passwords.password)) {
      ErrorToast("Password must contain at least one uppercase letter.");
      return;
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(passwords.password)) {
      ErrorToast("Password must contain at least one special symbol.");
      return;
    }

    try {
      const response = await createPassword(passwords);

      if (response.data) {
        SuccessToast("Password created successfully");
        navigate("/login");
      } else {
        ErrorToast("Failed to create password. Please try again.");
      }
    } catch (error) {
      ErrorToast("An error occurred. Please try again later.");
    }
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <Typography variant="h5">Create Password</Typography>
        </Box>

        <TextField
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          value={passwords.password}
          onChange={handlePasswordChange}
          required
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          value={passwords.confirmPassword}
          onChange={handlePasswordChange}
          required
          fullWidth
          margin="normal"
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2, width: "100%" }}
        >
          Submit
        </Button>
      </Paper>
    </Container>
  );
};

export default CreatePassword;
