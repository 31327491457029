export const createFormQuestionRequest = {
  serviceTaskId: "",
  displayOrder: 0,
  title: "",
  hintText: "",
  questionType: "",
  readOnly: false,
  pathAttribute: "",
  validationType: "",
  validationRegex: "",
  validationErrorMessage: "",
  mandatory: false,
};
export const updateFormQuestionRequest = {
  id: "",
  serviceTaskId: "",
  displayOrder: 0,
  title: "",
  hintText: "",
  questionType: "",
  readOnly: false,
  pathAttribute: "",
  validationType: "",
  validationRegex: "",
  validationErrorMessage: "",
  mandatory: false,
};
