import { FiberManualRecord, WarningOutlined, Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const ProgramMembersDataTable = (handleDeleteAction) => {
  return [
   
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
      filterable: true,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "created",
        flex: 1,
        headerAlign: "center",
        align: "center",
        filterable: false,
        headerName: "Created",
        valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
      },
    {
      field: "active",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Active",
      filterable: false,
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
        field: "productName", // Assuming you want to display account details
        flex: 1.5, 
        headerAlign: "center",
        align: "center",
        filterable: true,
        headerName: "Product Name",
        valueGetter: ({ row }) => {
          // Extract account details
          const accounts = row.accounts.map(account => {
            return `${account?.productName ?? "N/A"}`;
          });
          return accounts.join(", ");
        },
    },
      {
        field: "balance",
        flex: 1.5,
        headerAlign: "center",
        align: "center",
        filterable: false,
        headerName: "Balance",
        valueGetter: ({ row }) => {
            // Extract account details
            const accounts = row.accounts.map(account => {
              return `${account.balance} ${account.unit} `;
            });
            return accounts.join(", ");
          },
      },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/loyalty/member/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              disabled={row?.paid}
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
