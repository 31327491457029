import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetWarehouseQuery } from "../services/WarehouseService";
import moment from "moment";
import { MdBusiness } from "react-icons/md";
import { LiaAddressBook } from "react-icons/lia";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";

const SingleWarehouse = () => {
  const { id } = useParams();
  const printRef = useRef();

  const { data: warehouse } = useGetWarehouseQuery(id);
  const navigate = useNavigate();

  if (warehouse) {
    return (
      <Box
        sx={{
          pt: "80px",
          pb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/pages/warehouses"}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <Typography
              variant="h5"
              mb={3}
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Warehouse Details{" "}
            </Typography>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <Link
            to={`/pages/warehouse/edit/${id}`}
            onClick={() => {
              localStorage.setItem("edit-warehouse", JSON.stringify(warehouse));
            }}
            style={{
              padding: "5px 10px",
              float: "right",
              color: "#049bf5",
              backgroundColor: "rgba(4, 155, 245,0.2)",
              borderRadius: "5px",
              textDecoration: "none",
            }}
          >
            Edit Warehouse
          </Link>
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={4} ref={printRef}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Warehouse
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Id: <span style={{ opacity: 0.7 }}>{id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Name:{" "}
                    <span style={{ opacity: 0.7 }}>{warehouse?.name}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(warehouse?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(warehouse?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <LiaAddressBook />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Address
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Street Address:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {warehouse?.address.streetAddress}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Postal Address:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {warehouse?.address.postalAddress}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    City:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {warehouse?.address.city}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Region/State:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {warehouse?.address.regionOrState}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Country:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {warehouse?.address.country}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleWarehouse;
