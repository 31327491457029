import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { categoryUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const merchantCategoryApi = createApi({
  reducerPath: "merchantCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: categoryUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MerchantCategories"],
  endpoints: (builder) => ({
    getAllMerchantCategories: builder.query({
      query: (filterMerchantCategoriesRequest) => ({
        url: `/categories?${filterMerchantCategoriesRequest}`,
        method: "GET",
      }),
      providesTags: ["MerchantCategories"],
      transformResponse: (response) => response.data.data,
    }),
    searchMerchantCategories: builder.query({
      query: (filterMerchantCategoriesRequest) => ({
        url: `/categories?${queryString.stringify(
          filterMerchantCategoriesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["MerchantCategories"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    getMerchantCategory: builder.query({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["MerchantCategories"],
      transformResponse: (response) => response.data,
    }),
    updateMerchantCategory: builder.mutation({
      query: (updateCategoryRequest) => ({
        url: "/categories",
        method: "PUT",
        body: updateCategoryRequest,
      }),
      invalidatesTags: ["MerchantCategories"],
    }),
    createMerchantCategory: builder.mutation({
      query: (createCategoryRequest) => ({
        url: "/categories",
        method: "POST",
        body: createCategoryRequest,
      }),
      invalidatesTags: ["MerchantCategories"],
    }),
    deleteMerchantCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MerchantCategories"],
    }),
  }),
});

export const {
  useGetAllMerchantCategoriesQuery,
  useGetMerchantCategoryQuery,
  useSearchMerchantCategoriesQuery,
  useCreateMerchantCategoryMutation,
  useUpdateMerchantCategoryMutation,
  useDeleteMerchantCategoryMutation,
} = merchantCategoryApi;
