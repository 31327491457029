import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, Select, Typography } from "@mui/material";
import queryString from "query-string";

import {
  useGetAllCaseAgentsQuery,
  useAssignCaseAgentMutation,
} from "../../services/disputeServices/CaseAgentService";
import { useParams } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../common/Toast";

const AssignCaseAgentModal = ({ open, handleClose, refetchCase }) => {
  const { id: caseId } = useParams();
  const [agentId, setAgentId] = React.useState();

  const { data: caseAgents } = useGetAllCaseAgentsQuery(
    queryString.stringify({ size: 10000 })
  );
  const [assign, { isLoading: isAssignLoading }] = useAssignCaseAgentMutation();

  const assignAgent = async (event) => {
    event.preventDefault();
    await assign({ caseId, agentId })
      .then((response) => {
        if (response.data) {
          SuccessToast("Case Agent Assigned Succesfully");
          handleClose();
          refetchCase();
        } else {
          ErrorToast("Failed to assign agent");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to assign agent");
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>Assign A Case Agent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Select an agent to assign this case
            </Typography>
          </DialogContentText>

          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">Users</InputLabel>
            <Select
              id="demo-simple-select"
              label="User"
              size="small"
              labelId="demo-simple-select-label"
            >
              {caseAgents?.map((agent) => (
                <MenuItem
                  value={agent?.id}
                  key={agent?.id}
                  onClick={() => setAgentId(agent?.id)}
                >
                  {agent?.firstName} {agent?.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isAssignLoading}
            onClick={assignAgent}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignCaseAgentModal;
