import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ordersUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ordersUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Orders"],
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => ({
        url: `/orders`,
        method: "GET",
      }),
      providesTags: ["Orders"],
      transformResponse: (response) => response.data.data,
    }),
    searchOrders: builder.query({
      query: (filterOrderRequest) => ({
        url: `/orders?${queryString.stringify(
          filterOrderRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Orders"],
    }),
    getOrder: builder.query({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "GET",
      }),
      providesTags: ["Orders"],
      transformResponse: (response) => response.data,
    }),
    getTotalOrder: builder.query({
      query: (id) => ({
        url: `/orders/total`,
        method: "GET",
      }),
      providesTags: ["Orders"],
      transformResponse: (response) => response.data,
    }),
    updateOrder: builder.mutation({
      query: (updateOrderRequest) => ({
        url: "/orders",
        method: "PUT",
        body: updateOrderRequest,
      }),
      invalidatesTags: ["Orders"],
    }),
    createOrder: builder.mutation({
      query: (addOrderRequest) => ({
        url: "/orders",
        method: "POST",
        body: addOrderRequest,
      }),
      invalidatesTags: ["Orders"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetOrderQuery,
  useGetTotalOrderQuery,
  useSearchOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
} = ordersApi;
