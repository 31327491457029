import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Grid,
  Select,
  Typography,
  Switch,
} from "@mui/material";
import queryString from "query-string";
import moment from "moment";
import { remittanceInstitutionTypes } from "../../components/common/dropdownsData";
import { useGetAllRemittanceProvidersQuery } from "../../services/RemittanceProviderService";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";

const RemittanceInstitutionModal = ({
  open,
  handleClose,
  institution,
  type,
  updateFields,
  handleSubmitRemittanceInstitution,
}) => {
  const MAXSIZE = queryString.stringify({ size: 10000 });
  const printRef = React.useRef();
  let { data: providersData } = useGetAllRemittanceProvidersQuery();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle> {`${type} REMITTANCE INSTITUTION`}</DialogTitle>
        {type === "VIEW" && (
          <ReactToPrint
            trigger={() => (
              <Button>
                <Print />
              </Button>
            )}
            content={() => printRef.current}
          />
        )}
      </Box>
      <DialogContent ref={printRef}>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              ID: <span style={{ opacity: 0.7 }}>{institution?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Name: <span style={{ opacity: 0.7 }}>{institution?.name}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Label: <span style={{ opacity: 0.7 }}>{institution?.label}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Institution Type:{" "}
              <span style={{ opacity: 0.7 }}>
                {remittanceInstitutionTypes[institution?.institutionType]}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Provider Id:{" "}
              <span style={{ opacity: 0.7 }}>{institution?.providerId}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Enabled:{" "}
              <span style={{ opacity: 0.7 }}>
                {institution?.enabled.toString()}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Created By:{" "}
              <span style={{ opacity: 0.7 }}>{institution?.createdBy}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Last Modified By:{" "}
              <span style={{ opacity: 0.7 }}>
                {institution?.lastModifiedBy}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Date Created:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(institution?.created).format("LLL")}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Last Updated:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(institution?.updated).format("LLL")}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={1} padding={2}>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={institution?.name}
                onChange={(e) => updateFields({ name: e.target.value })}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                label="Label"
                variant="outlined"
                name="label"
                value={institution?.label}
                onChange={(e) => updateFields({ label: e.target.value })}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">
                  Institution Type
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={institution?.institutionType}
                  label="Institution Type"
                  name="institutiontype"
                  onChange={(e) =>
                    updateFields({ institutionType: e.target.value })
                  }
                >
                  {Object.keys(remittanceInstitutionTypes).map((type, i) => (
                    <MenuItem key={i} value={type}>
                      {remittanceInstitutionTypes[type]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">Provider</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={institution?.providerId}
                  label="Provider id"
                  name="provider"
                  onChange={(e) => updateFields({ providerId: e.target.value })}
                >
                  {providersData?.data?.data?.map((provider, i) => (
                    <MenuItem key={i} value={provider.id}>
                      {provider.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <FormControl fullWidth size="small" required>
                <FormControlLabel
                  control={<Switch />}
                  label="Enable"
                  checked={institution?.enabled}
                  onChange={() => {
                    updateFields({ enabled: !institution.enabled });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>
            <Button
              onClick={handleSubmitRemittanceInstitution}
              variant="contained"
            >
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RemittanceInstitutionModal;
