import React from "react";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Item } from "../components/common/Item";
import { DropDownSuggestionsTable } from "../data/DropDownSuggestionsTable";
import { useGetAllFormQuestionsQuery } from "../services/FormQuestionService";
import queryString from "query-string";
import {
  useCreateDropDownSuggestionMutation,
  useDeleteDropDownSuggestionMutation,
  useSearchDropDownSuggestionsMutation,
  useUpdateDropDownSuggestionMutation,
} from "../services/DropDownSuggestionService";
import { useEffect } from "react";
import {
  createdropDownSuggestionRequest,
  updateDropDownSuggestionRequest,
} from "../models/dropDownSuggestion";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";

const DropDownSuggestion = ({formQuestionData}) => {
  const filter = queryString.stringify({ size: 10000 });
  const defaultFormQuestion = { id: null, label: "All  form question" };
  const [dropDownSuggestionRequest, setDropDownSuggestionRequest] =
    useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const [selectedFormQuestion, setSelectedFormQuestion] = useState(null);

  // const { data: formQuestionData } = useGetAllFormQuestionsQuery(filter);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDropdownDialog = () => {
    setOpenDialog(false);
  };

  const [addDropdownSuggestion] = useCreateDropDownSuggestionMutation();
  const [updateDropdownSuggestion] = useUpdateDropDownSuggestionMutation();
  const [
    deleteDropDownSuggestion,
    { isLoading: isDropDownSuggestionDeleteSuccess },
  ] = useDeleteDropDownSuggestionMutation();
  const [selectedFormQuestionForFilter, setSelectedFormQuestionForFilter] =
    useState(defaultFormQuestion);
  const [
    searchDropDownSuggestion,
    { data: dropDownSuggestionData, isLoading: isDropDownSuggestionLoading },
  ] = useSearchDropDownSuggestionsMutation();
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  

  const handleAccodionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel);
  };

  const handleUpdateDropDownSuggestion = async (event) => {
    event.preventDefault();

    await updateDropdownSuggestion(dropDownSuggestionRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Dropdown suggestion updated succesfully");
        } else {
          ErrorToast("Failed to update Dropdown suggestion");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Dropdown suggestion");
      });
  };

  const handleCreateDropdownSuggestion = async (event) => {
    event.preventDefault();

    await addDropdownSuggestion(dropDownSuggestionRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Dropdown suggestion created succesfully");
          setDropDownSuggestionRequest(null);
        } else {
          ErrorToast("Failed to created dropdown suggestion ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to created dropdown suggestion");
      });
  };

  const handleGetDropdownSuggestions = async () => {
    const filterDropDownSuggestionRequest = queryString.stringify(
      {
        page: paginationModel.page,
        size: paginationModel.pageSize,
        formQuestionId: selectedFormQuestionForFilter.id,
        [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
        sortBy: sortModel[0]?.field,
      },
      { skipNull: true, strict: true, skipEmptyString: true }
    );
    await searchDropDownSuggestion(filterDropDownSuggestionRequest);
  };

  const handleDropdownSuggestionChange = (event) => {
    const { name, value } = event.target;
    if (name === "formQuestionId") {
      setDropDownSuggestionRequest((prevDropdownSuggestionResquest) => ({
        ...prevDropdownSuggestionResquest,
        [name]: value?.id,
      }));
    } else {
      setDropDownSuggestionRequest((prevDropdownSuggestionResquest) => ({
        ...prevDropdownSuggestionResquest,
        [name]: value,
      }));
    }
  };

  const handleDeleteDropdownQuestion = async () => {
    await deleteDropDownSuggestion(selectedFormQuestion.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Dropdown Suggestion deleted succesfully");
        } else {
          ErrorToast("Failed to delete Dropdown Suggestion");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Dropdown Suggestion");
      })
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleDeleteAction = async (row) => {
    setSelectedFormQuestion(row);
    setOpenDialog(true);
  };

  const handleEditAction = (row) => {
    const toUpdateDropDownSuggestionRequest = updateDropDownSuggestionRequest;
    toUpdateDropDownSuggestionRequest.id = row?.id;
    toUpdateDropDownSuggestionRequest.dropDownLabel = row?.dropDownLabel;
    toUpdateDropDownSuggestionRequest.dropDownValue = row?.dropDownValue;
    toUpdateDropDownSuggestionRequest.formQuestionId = row?.formQuestionId;
    toUpdateDropDownSuggestionRequest.bindValue = row?.bindValue;

    setDropDownSuggestionRequest({ ...toUpdateDropDownSuggestionRequest });
  };

  const handleSelectedFormQuestionForFilter = (event) => {
    const { value } = event.target;
    setSelectedFormQuestionForFilter(value);
  };

  const initDropDownSuggestionRequest = () => {
    setDropDownSuggestionRequest({ ...createdropDownSuggestionRequest });
  };
  const handleCancelDropDownSuggestionRequest = () => {
    setDropDownSuggestionRequest(null);
  };

  const columns = DropDownSuggestionsTable({
    handleEditAction,
    handleDeleteAction,
  });
  useEffect(() => {
    handleGetDropdownSuggestions();
  }, [
    paginationModel,
    filterModel,
    sortModel,
    isDropDownSuggestionDeleteSuccess,
    selectedFormQuestionForFilter,
  ]);

  return (
    <Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccodionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" sx={{ width: "100%", flexShrink: 0 }}>
            Configure Drop down suggestions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", my: 2 }}
          >
            <Typography color="primary" variant="button">
              Add Drop down suggestion
            </Typography>
            <Button
              onClick={initDropDownSuggestionRequest}
              disabled={dropDownSuggestionRequest}
              sx={{ float: "right" }}
              variant="contained"
            >
              <Add />
            </Button>
          </Box>

          <Grid container spacing={1}>
            {dropDownSuggestionRequest && (
              <Grid item xs={12} sm={12} md={12}>
                <Item>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Drop down Label"
                        variant="outlined"
                        placeholder="Example: Label 1"
                        value={dropDownSuggestionRequest?.dropDownLabel}
                        name="dropDownLabel"
                        onChange={handleDropdownSuggestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Drop down Value"
                        variant="outlined"
                        value={dropDownSuggestionRequest?.dropDownValue}
                        name="dropDownValue"
                        onChange={handleDropdownSuggestionChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">
                          Form question
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          label="Form question"
                          required
                          placeholder="Selected form question"
                          value={selectedFormQuestion?.title}
                          defaultValue=""
                          name="formQuestionId"
                          onChange={handleDropdownSuggestionChange}
                        >
                          {formQuestionData &&
                            [defaultFormQuestion]
                              .concat(formQuestionData)
                              .map((formQuestion, index) => (
                                <MenuItem key={index} value={formQuestion}>
                                  {formQuestion.title}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Bind Value"
                        variant="outlined"
                        value={dropDownSuggestionRequest?.bindValue}
                        name="bindValue"
                        onChange={handleDropdownSuggestionChange}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={handleCancelDropDownSuggestionRequest}
                      variant="contained"
                      color="error"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={
                        dropDownSuggestionRequest?.id
                          ? handleUpdateDropDownSuggestion
                          : handleCreateDropdownSuggestion
                      }
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Item>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} sx={{ mt: 5 }}>
              <Item>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    marginBottom={2}
                  >
                    <FormControl sx={{ minWidth: 160 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Form question
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Form question"
                        required
                        placeholder="Selected form question"
                        value={selectedFormQuestion?.title}
                        defaultValue=""
                        name="formQuestionId"
                        onChange={handleSelectedFormQuestionForFilter}
                      >
                        {formQuestionData &&
                          formQuestionData.map((formQuestion, index) => (
                            <MenuItem key={index} value={formQuestion}>
                              {formQuestion.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <Typography variant="h5">Dropdown suggesstions</Typography>
                  </Stack>

                  <Box>
                    <CustomAlertDialog
                      open={openDialog}
                      handleClose={handleCloseDropdownDialog}
                      handleSubmit={handleDeleteDropdownQuestion}
                      title={"Delete Dropdown Suggestion"}
                      subTitle={`Are you sure you want to delete Dropdown Suggestion?`}
                      negativeButtonText={"No"}
                      positiveButtonText={"Yes"}
                      icon={<FiTrash2 size={30} />}
                      fullWidth={true}
                    />
                    <TableContainer component={Paper}>
                      <DataGrid
                        autoHeight
                        loading={isDropDownSuggestionLoading}
                        sx={{
                          boxShadow: 2,
                          border: 2,
                          borderColor: "gray",
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                        }}
                        filterMode="server"
                        paginationMode="server"
                        rows={dropDownSuggestionData?.data || []}
                        rowCount={dropDownSuggestionData?.size || 0}
                        columns={columns}
                        disableColumnSelector
                        disableDensitySelector
                        slotProps={{
                          panel: {
                            sx: {
                              top: "-120px !important",
                            },
                          },
                        }}
                        getRowId={(row) => row.id}
                        slots={{ toolbar: GridToolbar }}
                        onPaginationModelChange={handlePaginationModelChange}
                        onSortModelChange={handleSortModelChange}
                        onFilterModelChange={handleFilterModelChange}
                      />
                    </TableContainer>
                  </Box>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default DropDownSuggestion;
