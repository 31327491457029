import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { merchantMediaUrl } from "../presentation/shared/ApiUrls";

export const merchantDocumentsApi = createApi({
  reducerPath: "merchantDocumentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: merchantMediaUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MerchantDocuments","Merchants"],
  endpoints: (builder) => ({
    getAllMerchantDocuments: builder.query({
      query: (filterMerchantDocumentRequest) => ({
        url: `/documents?${filterMerchantDocumentRequest}`,
        method: "GET",
      }),
      providesTags: ["MerchantDocuments"],
      transformResponse: (response) => response.data.data,
    }),
    getMerchantDocument: builder.query({
      query: (id) => ({
        url: `/documents/${id}`,
        method: "GET",
      }),
      providesTags: ["MerchantDocuments"],
    }),
    searchMerchantDocuments: builder.mutation({
      query: (filterMerchantDocumentRquest) => ({
        url: `/documents?${filterMerchantDocumentRquest}`,
        method: "GET",
      }),
      invalidatesTags: ["MerchantDocuments","Merchants"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    createMerchantDocument: builder.mutation({
      query: (addMerchantDocumentRequest) => ({
        url: "/documents",
        method: "POST",
        body: addMerchantDocumentRequest,
      }),
      invalidatesTags: ["MerchantDocuments","Merchants"],
    }),
    deleteMerchantDocument: builder.mutation({
      query: (id) => ({
        url: `/documents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MerchantDocuments","Merchants"],
    }),
  }),
});

export const {
  useGetAllMerchantDocumentsQuery,
  useSearchMerchantDocumentsMutation,
  useGetMerchantDocumentQuery,
  useCreateMerchantDocumentMutation,
  useDeleteMerchantDocumentMutation,
} = merchantDocumentsApi;
