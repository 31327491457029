import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { loginUrl } from "../presentation/shared/ApiUrls";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: loginUrl,
    prepareHeaders: (headers) => {
      let clientToken = sessionStorage.getItem("clientToken");
      headers.append("Authorization", `Bearer ${clientToken}`);
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    loginDomainUser: builder.mutation({
      query: (loginRequest) => ({
        url: "/domain",
        method: "POST",
        body: loginRequest,
      }),
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const { useLoginDomainUserMutation } = authApi;
