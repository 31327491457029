import { Box, Typography, Paper, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { OrdersTable } from "../../data/OrdersTable";
import {
  useDeleteOrderMutation,
  useSearchOrdersQuery,
} from "../../services/OrderService";
import CustomAlertDialog from "../../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";
import { DataGridService } from "../../core/dataGridService";
import { ErrorToast, SuccessToast } from "../../components/common/Toast";
import {
  useDeleteProgramMemberMutation,
  useGetAllProgramMembersQuery,
} from "../../services/loyalty/programMembersService";
import { ProgramMembersDataTable } from "../../data/loyalty/programMembersDataTable";

const ProgramMembers = () => {
  const dataGridService = new DataGridService("ProgramMember");
  const [filter, setFilter] = useState({});
  const { data: membersData, isLoading } = useGetAllProgramMembersQuery();
  const { programMembersResponseData, setProgramMembersResponseData } =
    useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [deleteProgramMember] = useDeleteProgramMemberMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  // const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedOrder(row);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    await deleteProgramMember(selectedOrder?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Member deleted succesfully");
          setOpen(false);
        } else {
          ErrorToast("Failed to delete member");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete member");
      });
  };

  const columns = ProgramMembersDataTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
    console.log("program members data::", membersData);
  }, [paginationModel, filterModel, sortModel, membersData]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Program Members</Typography>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Program Member"}
        subTitle={`Are you sure you want to delete ${selectedOrder?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={membersData?.length ?? 0}
            columns={columns}
            rows={membersData ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            disableColumnSelector
            disableDensitySelector
            paginationModel={paginationModel}
            filterModel={filterModel}
            sortModel={sortModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ProgramMembers;
