import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useCreateMerchantMutation } from "../services/MerchantService";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useGetAllMerchantCategoriesQuery } from "../services/MerchantCategoryService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddOrder = () => {
  const MAXDATA = queryString.stringify({ size: 10000 });
  const [createMerchant] = useCreateMerchantMutation();
  const { data, isLoading } = useGetAllMerchantCategoriesQuery(MAXDATA);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  const imageInput = useRef(null);
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [regionOrState, setRegionOrState] = useState("");
  const [contact, setContact] = useState("");
  const [verified, setVerified] = useState(false);
  const [active, setActive] = useState(true);
  const [categories, setCategories] = useState([]);
  const [streetAddress, setStreetAddress] = useState("");
  const [longitude, setLongitude] = useState(0.0);
  const [latitude, setLatitude] = useState(0.0);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };
  const handleContactChange = (event) => {
    setContact(event.target.value);
  };
  const handleStreetAddress = (event) => {
    setStreetAddress(event.target.value);
  };
  const handleVerifiedChange = (event) => {
    setVerified(event.target.checked);
  };
  const handleActiveChange = (event) => {
    setActive(event.target.checked);
  };
  const handleRegionOrStateChange = (event) => {
    setRegionOrState(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const handlePostalAddressChange = (event) => {
    setPostalAddress(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleCategoryChange = (event, value) => {
    setCategories(value);
  };

  const handleSubmitMerchant = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    const createMerchantRequest = {
      code: code,
      name: name,
      address: {
        city: city,
        country: country,
        postalAddress: postalAddress,
        regionOrState: regionOrState,
        streetAddress: streetAddress,
        gpsLocation: {
          latitude: latitude,
          longitude: longitude,
        },
      },
      categories: categories,
      verified: verified,
      active: active,
    };

    await createMerchant(createMerchantRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Merchant created succesfully");
          navigate("/pages/merchants");
        } else {
          ErrorToast("Failed to create Merchant");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Merchant");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  function getAddressLocation() {
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLongitude(longitude);
      setLatitude(latitude);
    }
  }

  useEffect(() => {
    getAddressLocation();
  }, []);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Order
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",

          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Name"
            variant="outlined"
            onChange={handleNameChange}
            fullWidth
            size="small"
          />
          <TextField
            label="Merchant Code"
            variant="outlined"
            onChange={handleCodeChange}
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="City"
            variant="outlined"
            onChange={handleCityChange}
            fullWidth
            size="small"
          />
          <TextField
            label="Country "
            variant="outlined"
            onChange={handleCountryChange}
            fullWidth
            size="small"
          />
        </Box>

        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Postal Address"
            variant="outlined"
            onChange={handlePostalAddressChange}
            fullWidth
            size="small"
          />
          <TextField
            label="Region/ State"
            variant="outlined"
            onChange={handleRegionOrStateChange}
            fullWidth
            size="small"
          />
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Street Address"
            variant="outlined"
            onChange={handleStreetAddress}
            fullWidth
            size="small"
          />
          <TextField
            label="Contact"
            variant="outlined"
            onChange={handleContactChange}
            fullWidth
            size="small"
          />
        </Box>

        <Box>
          <Autocomplete
            sx={{ mt: 4 }}
            multiple
            loading={isLoading}
            id="tags-filled"
            options={data ? data.data.data : []}
            onChange={handleCategoryChange}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="standard"
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                helperText="Select a category or type any category name and press enter"
                variant="outlined"
                label="Merchant Categories"
                placeholder="Merchant Categories"
              />
            )}
          />
        </Box>

        <Box sx={{ mt: 4, alignItems: "center", gap: 5 }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Active"
              onChange={handleActiveChange}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Verified"
              onChange={handleVerifiedChange}
            />
          </FormGroup>
        </Box>

        <input
          type="file"
          hidden
          ref={imageInput}
          onChange={(e) => setImage(e.target.files[0])}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            onClick={handleSubmitMerchant}
            variant="contained"
            sx={{ borderRadius: "20px" }}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddOrder;
