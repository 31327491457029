import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { paymentTaskUrl } from "../presentation/shared/ApiUrls";

export const paymentTaskApi = createApi({
  reducerPath: "paymentTaskApi",
  baseQuery: fetchBaseQuery({
    baseUrl: paymentTaskUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect:true,refetchOnMountOrArgChange: true, 
  tagTypes: ["Payment Task"],
  endpoints: (builder) => ({
    getAllPaymentTasks: builder.query({
      query: (filterRequest) => ({
        url: `/?${filterRequest}`,
        method: "GET",
      }),
      providesTags: ["Payment Task"],
    }),

    getPaymentTask: builder.query({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
      providesTags: ["Payment Task"],
    }),

    searchPaymentTask: builder.mutation({
      query: (filterRequest) => ({
        url: `/?${filterRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["Payment Task"],
    }),
    updatePaymentTask: builder.mutation({
      query: (updateRequest) => ({
        url: "/",
        method: "PUT",
        body: updateRequest,
      }),
      invalidatesTags: ["Payment Task"],
    }),
    createPaymentTask: builder.mutation({
      query: (addRequest) => ({
        url: "/",
        method: "POST",
        body: addRequest,
      }),
      invalidatesTags: ["Payment Task"],
    }),
    deletePaymentTask: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment Task"],
    }),
  }),
});

export const {
  useGetAllPaymentTasksQuery,
  useGetPaymentTaskQuery,
  useSearchPaymentTaskMutation,
  useCreatePaymentTaskMutation,
  useUpdatePaymentTaskMutation,
  useDeletePaymentTaskMutation,
} = paymentTaskApi;
