import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { responseCodeMapUrl } from "../presentation/shared/ApiUrls";

export const responseCodeMapsApi = createApi({
  reducerPath: "responseCodeMapsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: responseCodeMapUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  tagTypes: ["ResponseCodeMaps"],
  endpoints: (builder) => ({
    getAllResponseCodeMaps: builder.query({
      query: (filterResponseCodeMapRequest) => ({
        url: `/code-maps?${filterResponseCodeMapRequest}`,
        method: "GET",
      }),
      providesTags: ["ResponseCodeMaps"],
      transformResponse: (response) => response.data.data,
    }),
    getResponseCodeMap: builder.query({
      query: (id) => ({
        url: `/code-maps/${id}`,
        method: "GET",
      }),
      provideTags: ["ResponseCodeMaps"],
      transformResponse: (response) => response.data,
    }),
    searchResponseCodeMaps: builder.mutation({
      query: (filterResponseCodeMapRequest) => ({
        url: `/code-maps?${filterResponseCodeMapRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["ResponseCodeMaps"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateResponseCodeMap: builder.mutation({
      query: (updateResponseCodeMapRequest) => ({
        url: "/code-maps",
        method: "PUT",
        body: updateResponseCodeMapRequest,
      }),
      invalidatesTags: ["ResponseCodeMaps"],
    }),
    createResponseCodeMap: builder.mutation({
      query: (createProductRequest) => ({
        url: "/code-maps",
        method: "POST",
        body: createProductRequest,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["ResponseCodeMaps"],
    }),
    deleteResponseCodeMap: builder.mutation({
      query: (id) => ({
        url: `/code-maps/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ResponseCodeMaps"],
    }),
  }),
});

export const {
  useGetAllResponseCodeMapsQuery,
  useSearchResponseCodeMapsMutation,
  useGetResponseCodeMapQuery,
  useCreateResponseCodeMapMutation,
  useUpdateResponseCodeMapMutation,
  useDeleteResponseCodeMapMutation,
} = responseCodeMapsApi;
