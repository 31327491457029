import { toast } from "react-toastify";

export const SuccessToast = (message) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    type: "success",
  });
};
export const ErrorToast = (message) => {
  return toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    type: "error",
  });
};
