// import styled from "@emotion/styled";
// import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
// import React from "react";
// import { lineChartData, lineChartOptions } from "../../../data/chartData";
// import { stats } from "../../../data/stats";
// import LineChart from "../charts/LineChart";
// import {useGetForexQuery} from "../../../services/DashboardService";

// const Stats = () => {
//   const Item = styled(Paper)({
//     padding: "5px 10px",
//     borderRadius: "12px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//   });

//   return (
//     <Grid container spacing={2}>
//       {stats.map(
//         ({ id, title, amount, icon, iconBg, iconColor, isMoney }, i) => (
//           <Grid
//             item
//             xs={12}
//             sm={i === stats.length - 1 ? 12 : 6}
//             lg={4}
//             key={id}
//           >
//             <Item
//               sx={{
//                 borderStyle: "solid",
//                 borderWidth: "1px",
//                 borderColor: "divider",
//               }}
//             >
//               <Box sx={{ flex: 1 }}>
//                 <IconButton
//                   sx={{ background: `${iconBg} !important`, color: iconColor }}
//                 >
//                   {icon}
//                 </IconButton>
//                 <Typography variant="h4" sx={{ my: 2 }}>
//                   {`${isMoney ? "$" + amount : amount}`}
//                 </Typography>
//                 <Typography sx={{ opacity: 0.7 }}>{title}</Typography>
//               </Box>
//               <Box sx={{ flex: 1 }}>
//                 <LineChart
//                   chartOptions={lineChartOptions}
//                   chartData={lineChartData}
//                 />
//               </Box>
//             </Item>
//           </Grid>
//         )
//       )}
//     </Grid>
//   );
// };

// export default Stats;
import styled from "@emotion/styled";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { useGetDashboardForexDataQuery } from "../../../services/ForexService";
import { useGetUserCountQuery } from "../../../services/KYCService";
import { useGetMerchantCountQuery } from "../../../services/MerchantService";


const Stats = () => {
  const Item = styled(Paper)({
    padding: "5px 10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });


  const { data: forexData, isError: forexError, isLoading: forexLoading } = useGetDashboardForexDataQuery();
  const { data: userCount, isError: userCountError, isLoading: userCountLoading } = useGetUserCountQuery();
  const { data: merchantCount, isError: merchantCountError, isLoading: merchantCountLoading } = useGetMerchantCountQuery();


  if (forexLoading || userCountLoading || merchantCountLoading) {
    return <div>Loading...</div>;
  }

  if (forexError || userCountError || merchantCountError) {
    return <div>Error loading data</div>;
  }

  const sellingRate = forexData?.[0]?.sellingRate;
  const homeCurrency = forexData?.[0]?.homeCurrency;
  const foreignCurrency = forexData?.[0]?.foreignCurrency;

  return (
    <Grid container spacing={2}>
      {/* Your other stats mapping */}

      {/* Selling rate */}
      <Grid item xs={12} sm={6} lg={4}>
        <Item
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <IconButton>
              <span role="img" aria-label="Selling Rate">
                💵
              </span>
            </IconButton>
            <Typography variant="h5" sx={{ my: 2 }}>
              {`Selling Rate: ${foreignCurrency}/${homeCurrency} ${sellingRate}`}
            </Typography>
            <Typography sx={{ opacity: 0.7 }}>Rates for the day</Typography>
          </Box>
        </Item>
      </Grid>

      {/* Total number of users */}
      <Grid item xs={12} sm={6} lg={4}>
        <Item
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <IconButton>
              <span role="img" aria-label="Selling Rate">
                💵
              </span>
            </IconButton>
            <Typography variant="h5" sx={{ my: 2 }}>
              {`Total Users: ${userCount}`}
            </Typography>
            <Typography sx={{ opacity: 0.7 }}>Total number of users</Typography>
          </Box>
        </Item>
      </Grid>

       {/* Total number of Merchants */}
       <Grid item xs={12} sm={6} lg={4}>
        <Item
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <IconButton>
              <span role="img" aria-label="Selling Rate">
                💵
              </span>
            </IconButton>
            <Typography variant="h5" sx={{ my: 2 }}>
              {`Total Merchants: ${merchantCount}`}
            </Typography>
            <Typography sx={{ opacity: 0.7 }}>Total number of merchants</Typography>
          </Box>
        </Item>
      </Grid>
    </Grid>
  );
};

export default Stats;
