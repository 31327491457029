import { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";
import { inventoryMedia } from "../models/inventoryMedia";
import { InventoryMediaTable } from "../data/InventoryMediaTable";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import {
  useCreateInventoryMediaMutation,
  useDeleteInventoryMediaMutation,
} from "../services/InventoryMediaService";
import InventoryMediaModal from "../components/modals/InventoryMediaModal";
import { useProductContext } from "../presentation/shared/hooks/useProductContextHook";

export const EditInventoryMedia = () => {
  const dataGridService = new DataGridService("IneventoryMedia");
  const { id } = useParams();
  const [type, setType] = useState("");
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [openDeleteMediaDialog, setOpenDeleteMediaDialog] = useState(false);
  const [inventoryMediaRequest, setInventoryMediaRequest] = useState({
    ...inventoryMedia,
    productId: id,
  });
  const { productData, isProductLoading ,refetchProduct} = useProductContext();
  const [createInventoryMedia] = useCreateInventoryMediaMutation();
  const [deleteInventoryMedia] = useDeleteInventoryMediaMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleShowAddInventoryMediaDialog = () => {
    setType("ADD");
    setOpenMediaDialog(true);
  };

  const handleInventoryMediaChange = (event) => {
    const { name, value, files } = event.target;

    if (["media"].includes(name)) {
      const selectedFile = files[0];
      setInventoryMediaRequest((prev) => ({
        ...prev,
        filename: selectedFile.name,
        fileUrl: URL.createObjectURL(selectedFile),
        mimeType: selectedFile.type,
        size: selectedFile.size,
        productId: id,
      }));
    } else {
      setInventoryMediaRequest((prev) => ({
        ...prev,
        [name]: value,
        productId: id,
      }));
    }
  };

  const handleCreateInventoryMedia = async (event) => {
    event.preventDefault();
    await createInventoryMedia(inventoryMediaRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Media uploaded succesfully");
          refetchProduct()
          handleClose();
        } else {
          ErrorToast("Failed to upload media");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to upload media");
      });
  };

  const handleDeleteMerchantMedia = async (event) => {
    event.preventDefault();
    await deleteInventoryMedia(inventoryMediaRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Media deleted succesfully");
          refetchProduct()
          handleClose();
        } else {
          ErrorToast("Failed to deleted media ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to deleted media ");
      });
  };

  const handleClose = () => {
    setOpenDeleteMediaDialog(false);
    setOpenMediaDialog(false);
    setInventoryMediaRequest(inventoryMedia);
  };

  const handleDownloadAction = (row) => {
    window.confirm("Are you sure you want to download this media file");
  };
  const handleDeleteAction = (row) => {
    setInventoryMediaRequest(row);
    setOpenDeleteMediaDialog(true);
  };
  const handleViewAction = (row) => {
    setType("VIEW");
    setInventoryMediaRequest(row);
    setOpenMediaDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenMediaDialog(false);
    setOpenDeleteMediaDialog(false);
    setInventoryMediaRequest(inventoryMedia);
  };

  const inventoryMediaTable = InventoryMediaTable({
    handleDeleteAction,
    handleDownloadAction,
    handleViewAction,
  });

  return (
    <Grid item md={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Typography variant="h5">List of Inventory Media</Typography>

        <Button variant="contained" onClick={handleShowAddInventoryMediaDialog}>
          Add media
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <DataGrid
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "gray",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          slotProps={{
            panel: {
              sx: {
                top: "-120px !important",
              },
            },
          }}
          loading={isProductLoading}
          rowCount={productData?.media.length ?? 0}
          rows={productData.media ?? []}
          columns={inventoryMediaTable}
          disableColumnSelector
          disableDensitySelector
          getRowId={(row) => row.id}
          slots={{ toolbar: GridToolbar }}
          sortModel={sortModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          onPaginationModelChange={handlePaginationModelChange}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
        />
      </TableContainer>
      <InventoryMediaModal
        open={openMediaDialog}
        type={type}
        inventoryMedia={inventoryMediaRequest}
        handleClose={handleCloseDialog}
        handleFormChange={handleInventoryMediaChange}
        handleSubmit={handleCreateInventoryMedia}
      />
      <CustomAlertDialog
        open={openDeleteMediaDialog}
        title={"Delete media"}
        icon={
          <IconButton color="error">
            <FiTrash2 />
          </IconButton>
        }
        handleClose={handleCloseDialog}
        subTitle={`Are you sure you want to delete this media
                        ${
                          inventoryMediaRequest?.filename ??
                          inventoryMediaRequest?.fileUrl
                        }?`}
        fullWidth
        handleSubmit={handleDeleteMerchantMedia}
        positiveButtonText={"YES"}
        negativeButtonText={"NO"}
      />
    </Grid>
  );
};
