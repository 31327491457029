import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";

export const NotificationsTable = ({handleViewActivity,
}) => {
  return [
    {
      field: "created",
      flex: 1.5,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "type",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Type",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "createdBy",
      flex: 1.5,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created By",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "userName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Username ",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "title",
      flex: 1.5,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Title",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "sender",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Sender ",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "receiver",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Receiver",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton
              color="primary"
              onClick={() => {
                handleViewActivity(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
