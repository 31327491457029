import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { devicesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const devicesApi = createApi({
  reducerPath: "devicesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: devicesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Devices"],
  endpoints: (builder) => ({
    getAllDevices: builder.query({
      query: (filterDeviceRequest) => ({
        url: `/devices?${filterDeviceRequest}`,
        method: "GET",
      }),
      providesTags: ["Devices"],
    }),

    getDevice: builder.query({
      query: (id) => ({
        url: `/devices/${id}`,
        method: "GET",
      }),
      providesTags: ["Devices"],
    }),
    searchDevices: builder.query({
      query: (filterDeviceRequest) => ({
        url: `/devices?${queryString.stringify(
          filterDeviceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Devices"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateDevice: builder.mutation({
      query: (updateDeviceRequest) => ({
        url: "/devices",
        method: "PUT",
        body: updateDeviceRequest,
      }),
      invalidatesTags: ["Devices"],
    }),
    createDevice: builder.mutation({
      query: (addDeviceRequest) => ({
        url: "/devices",
        method: "POST",
        body: addDeviceRequest,
      }),
      invalidatesTags: ["Devices"],
    }),
    deleteDevice: builder.mutation({
      query: (id) => ({
        url: `/devices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Devices"],
    }),
  }),
});

export const {
  useGetAllDevicesQuery,
  useGetDeviceQuery,
  useSearchDevicesQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
} = devicesApi;
