import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import {
  useDeleteResponseCodeMapMutation,
  useGetAllResponseCodeMapsQuery,
} from "../../services/ResponseCodeMapService";
import queryString from "query-string";
import ResponseCodeMapDialog from "../modals/ResponseCodeMapDialog";
import { Add } from "@mui/icons-material";
import { ErrorToast, SuccessToast } from "./Toast";
const ResponseCodeMapComponent = ({ serviceTaskResponseConfigurationId }) => {
  const { data: codeMaps } = useGetAllResponseCodeMapsQuery(
    queryString.stringify({
      serviceTaskResponseConfigurationId: serviceTaskResponseConfigurationId,
    })
  );
  const [deleteResponseCodeMap] = useDeleteResponseCodeMapMutation();
  const [selectedResponseCodeMap, setSeletedResponseCodeMap] =
    React.useState(null);
  const [dialogType, setDialogType] = React.useState("");
  const [showDialog, setShowDialog] = useState(false);

  const handleShowDialog = (responseCodeMap) => {
    if (responseCodeMap?.id) {
      setDialogType("UPDATE");
      setSeletedResponseCodeMap(responseCodeMap);
    } else {
      setSeletedResponseCodeMap((prev) => ({
        ...prev,
        serviceTaskResponseConfigurationId: serviceTaskResponseConfigurationId,
      }));
      setDialogType("ADD");
    }
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setSeletedResponseCodeMap(null);
    setShowDialog(false);
  };

  const handleDeleteResponseCodeMap = async (responseCodeMap) => {
    await deleteResponseCodeMap(responseCodeMap?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Response code map deleted succesfully");
        } else {
          ErrorToast("Failed to delete response code map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete response code map");
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography color="primary" variant="button">
          Add map for dropdown question
        </Typography>
        <Button variant="contained" onClick={handleShowDialog}>
          <Add />
        </Button>
      </Box>
      {codeMaps &&
        codeMaps.map((codeMap, index) => (
          <Box
            key={index}
            sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}
          >
            <TextField
              label="Response Value"
              variant="outlined"
              disabled={true}
              rows={4}
              value={codeMap?.responseValue}
              fullWidth
              size="small"
            />
            <TextField
              label="Response code usage"
              variant="outlined"
              disabled={true}
              rows={4}
              value={codeMap?.responseCodeUsage}
              fullWidth
              size="small"
            />

            <Chip
              variant="outlined"
              color="primary"
              label="Edit"
              onClick={() => {
                handleShowDialog(codeMap);
              }}
            />

            <Chip
              variant="outlined"
              color="error"
              label="Remove"
              onClick={handleDeleteResponseCodeMap}
            />
          </Box>
        ))}
      <ResponseCodeMapDialog
        type={dialogType}
        open={showDialog}
        handleClose={handleCloseDialog}
        selectedResponseCodeMap={selectedResponseCodeMap}
      />
    </>
  );
};

export default ResponseCodeMapComponent;
