import moment from "moment";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { DomainType, kycStatusColor } from "../presentation/helpers";
import { FiEdit, FiEye, FiUser } from "react-icons/fi";

export const MobileUsersTable = ({
  handleViewAction,
  handleEditAction,
  handleViewProfileAction,
}) => {
  const checkDomainType = (domainType) => {
    return (
      (sessionStorage.getItem("domainType") === DomainType.SUPERMAN) ===
      domainType
    );
  };

  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "email",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Email",
    },

    {
      field: "username",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "User Phone",
    },
    {
      field: "fullName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Username",
      filterable: false,
      // renderCell: ({ value }) => (
      //   <Chip
      //     size={"small"}
      //     color={kycStatusColor(value?.firstName)}
      //     label={value?.verificationStatus ?? "NOT SUBMITTED"}
      //     variant="outlined"
      //   />
      // ),
      valueGetter: ({ row }) => (row?.kyc ? `${row?.kyc?.fullName}` : "N/A"),
    },
    {
      field: "kyc",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "KYC Status",
      filterable: false,
      renderCell: ({ value }) => (
        <Chip
          size={"small"}
          color={kycStatusColor(value?.verificationStatus)}
          label={value?.verificationStatus ?? "NOT SUBMITTED"}
          variant="outlined"
        />
      ),
    },
    {
      field: "lastLoggedIn",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Last Logged In",
      filterable: false,
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "lastSeen",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Last Seen",
      filterable: false,
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Tooltip arrow placement="right" title="Edit">
            <IconButton
              color="primary"
              disabled={row?.kyc === null}
              onClick={() =>
                handleEditAction({
                  prevStatus: row?.kyc?.verificationStatus,
                  user: row,
                })
              }
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="right" title="View">
            <IconButton
              disabled={checkDomainType(row?.domainType)}
              color="primary"
              onClick={() => {
                handleViewAction(row);
              }}
            >
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="right" title="View profile">
            <IconButton
              disabled={checkDomainType(row?.domainType)}
              color="primary"
              onClick={() => {
                handleViewProfileAction(row);
              }}
            >
              <FiUser size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
