import { Box, Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import LoadingPage from "../components/LoadingPage";
import { useGetMerchantQuery } from "../services/MerchantService";
import EditMerchantDetails from "./EditMerchantDetails";
import Warehouses from "./Warehouses";
import { EditMerchantContact } from "./EditMerchantContact";
import { EditMerchantMedia } from "./EditMerchantMedia";
import { EditMerchantDocument } from "./EditMerchantDocument";
import Inventory from "./Inventory";

export const MerchantContext = createContext({});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditMerchantLayout = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const {
    refetch:refetchMerchant,
    data: merchantData,
    isLoading: isMerchantLoading,
    isSuccess: isMerchantSuccess,
  } = useGetMerchantQuery(id);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {}, [merchantData]);
  return (
    <MerchantContext.Provider
      value={{
        merchantData,
        refetchMerchant,
        isMerchantLoading,
        isMerchantSuccess,
        setTab,
      }}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={-1}>
              <Button variant="outlined">
                <KeyboardBackspace /> BACK
              </Button>
            </Link>

            <Typography variant="h5">Edit Merchant</Typography>
          </Box>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Merchant Details" {...a11yProps(0)} />
                <Tab label="Inventories" {...a11yProps(1)} />
                <Tab label="Contacts" {...a11yProps(2)} />
                <Tab label="Media" {...a11yProps(3)} />
                <Tab label="Documents" {...a11yProps(4)} />
                <Tab label="Warehouses" {...a11yProps(5)} />
              </Tabs>
            </Box>
            {isMerchantSuccess && merchantData ? (
              <Box>
                <TabPanel value={tab} index={0}>
                  <EditMerchantDetails />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <Inventory merchantId={id} pageType={"ADD_INVENTORY"} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <EditMerchantContact />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <EditMerchantMedia />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                  <EditMerchantDocument />
                </TabPanel>
                <TabPanel value={tab} index={5}>
                  <Warehouses />
                </TabPanel>
              </Box>
            ) : (
              <LoadingPage />
            )}
          </Box>
        </Paper>
      </Box>
    </MerchantContext.Provider>
  );
};

export default EditMerchantLayout;
