import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEdit, FiTrash2 } from "react-icons/fi";

export const ServiceTaskConfigurationsTable = ({
  handleEditAction,
  handleDeleteAction,
}) => {
  return [
    {
      field: "created",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "updated",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date updated",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "endpoint",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "End Point",
    },
    {
      field: "method",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Method",
    },

    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
