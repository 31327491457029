import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { serviceTaskResponseConfigurationUrl } from "../presentation/shared/ApiUrls";

export const serviceTaskResponseConfigurationsApi = createApi({
  reducerPath: "serviceTaskResponseConfigurationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: serviceTaskResponseConfigurationUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["ServiceTaskResponseConfigurations"],
  endpoints: (builder) => ({
    getAllServiceTaskResponseConfigurations: builder.query({
      query: () => ({
        url: `/response-configurations`,
        method: "GET",
      }),
      providesTags: ["ServiceTaskResponseConfigurations"],
    }),
    searchServiceTaskResponseConfigurations: builder.query({
      query: (filterServiceTaskResponseConfigurationsRequest) => ({
        url: `/response-configurations?${filterServiceTaskResponseConfigurationsRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTaskResponseConfigurations"],
    }),
    getServiceTaskResponseConfiguration: builder.query({
      query: (id) => ({
        url: `/response-configurations/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["ServiceTaskResponseConfigurations"],
      transformResponse: (response) => response.data,
    }),
    updateServiceTaskResponseConfiguration: builder.mutation({
      query: (updateServiceTaskResponseConfigurationRequest) => ({
        url: "/response-configurations",
        method: "PUT",
        body: updateServiceTaskResponseConfigurationRequest,
      }),
      invalidatesTags: ["ServiceTaskResponseConfigurations"],
    }),
    createServiceTaskResponseConfiguration: builder.mutation({
      query: (createServiceTaskResponseConfigurationRequest) => ({
        url: "/response-configurations",
        method: "POST",
        body: createServiceTaskResponseConfigurationRequest,
      }),
      invalidatesTags: ["ServiceTaskResponseConfigurations"],
    }),
    deleteServiceTaskResponseConfiguration: builder.mutation({
      query: (id) => ({
        url: `/response-configurations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceTaskResponseConfigurations"],
    }),
  }),
});

export const {
  useGetAllServiceTaskResponseConfigurationsQuery,
  useSearchServiceTaskResponseConfigurationsQuery,
  useGetServiceTaskResponseConfigurationQuery,
  useCreateServiceTaskResponseConfigurationMutation,
  useUpdateServiceTaskResponseConfigurationMutation,
  useDeleteServiceTaskResponseConfigurationMutation,
} = serviceTaskResponseConfigurationsApi;
