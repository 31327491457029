export const createServiceTaskRequest = {
  productConfigurationId: "",
  executionOrder: "",
  taskType: "",
  name: "",
  useResponseAsDropDownQuestion: false,
  description: "",
  labelActionButton: "",
  labelNextTask: "",
};
export const updateServiceTaskRequest = {
  id: "",
  productConfigurationId: "",
  executionOrder: "",
  taskType: "",
  name: "",
  useResponseAsDropDownQuestion: false,
  description: "",
  labelActionButton: "",
  labelNextTask: "",
};
