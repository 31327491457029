import { useGetUserProfileImageQuery } from "../../services/KYCService";
import { kycUrl } from "../../presentation/shared/ApiUrls";
const UserProfileImage = ({ imageUrl }) => {
  console.log("imageUrl : >>", imageUrl);
  const response = useGetUserProfileImageQuery(imageUrl);

  console.log("loading state", response);

  const blob = new Blob([response.data], { type: "application/octet-stream" });

  const url = URL.createObjectURL(blob);
  console.log("this is the url we have", url);
  console.log(response.data);

  return (
    <img
      className="imageItem"
      alt={"User Profile Not Uploaded"}
      src={`${kycUrl}/kyc/docs/download/${imageUrl}`}
      style={{ height: "100vh", width: "40vw" }}
    />
  );
};

export default UserProfileImage;
