import React from 'react'
import { Box, Grid, TextField } from "@mui/material"


const MapsForDropDownQuestionComponent = ({ responseConfigRequest, handleFormChange }) => {
    


    return (
        <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={6} >
                <TextField
                    label="Root attribute for list"
                    variant="outlined"
                    value={responseConfigRequest?.mapsForDropDownQuestion?.rootAttributeForList}
                    fullWidth
                    name="rootAttributeForList"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>


            <Grid item xs={6} sm={12} md={6} >

                <TextField
                    label="Root attribute for key"
                    variant="outlined"
                    value={responseConfigRequest?.mapsForDropDownQuestion?.rootAttributeForKey}
                    fullWidth
                    name="mapForDropDownSuggestion.rootAttributeForKey"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>

            <Grid item xs={6} sm={12} md={6} >

                <TextField
                    label="Root attribute for label"
                    variant="outlined"
                    value={responseConfigRequest?.mapsForDropDownQuestion?.rootAttributeForLabel}
                    fullWidth
                    name="mapForDropDownSuggestion.rootAttributeForLabel"
                    onChange={handleFormChange}
                    size="small"
                />

            </Grid>

            <Grid item xs={6} sm={12} md={6} >
                <TextField
                    label="Root attribute for value"
                    variant="outlined"
                    value={responseConfigRequest?.mapsForDropDownQuestion?.rootAttributeForValue}
                    fullWidth
                    name="mapForDropDownSuggestion.rootAttributeForValue"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={6} sm={12} md={6} >
                <TextField
                    label="Root attribute for bind value"
                    variant="outlined"
                    value={responseConfigRequest?.mapsForDropDownQuestion?.rootAttributeForBindValue}
                    fullWidth
                    name="mapForDropDownSuggestion.rootAttributeForBindValue"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>

        </Grid>

    )

}

export default MapsForDropDownQuestionComponent