import { Box, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";

export const DisputesActivityLogTable = ({handleViewActivity}) => {
  return [
    {
      field: "created",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "component",
      flex: 1,

      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Component",
    },

    {
      field: "activity",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Activity ",
    },
    {
      field: "buyingRate",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Buying Rate",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "updated",
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
      headerName: "Date Upload",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton color="primary" onClick={() => handleViewActivity(row)}>
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
