import { Box, Button, Paper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { createContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetProductQuery } from "../services/ProductService";
import EditProductDescription from "./EditProductDescription";
import EditProductConfiguration from "./EditProductConfiguration";
import { KeyboardBackspace } from "@mui/icons-material";
import EditServiceTask from "./EditServiceTask";
import EditServiceTaskConfiguration from "./EditServiceTaskConfiguration";
import LoadingPage from "../components/LoadingPage";
import { EditInventoryMedia } from "./EditInventoryMedia";

export const ProductContext = createContext({});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditProductLayout = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const {
    refetch: refetchProduct,
    data: productData,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
  } = useGetProductQuery(id);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <ProductContext.Provider
      value={{
        productData,
        isProductLoading,
        refetchProduct,
        isProductSuccess,
        setTab,
      }}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={"/pages/products"}>
              <Button variant="outlined">
                <KeyboardBackspace /> BACK
              </Button>
            </Link>

            <Typography variant="h5">Edit Product</Typography>
          </Box>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}     
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Product Description" {...a11yProps(0)} />
                <Tab label="Media" {...a11yProps(1)} />
                <Tab label="Product Configuration" {...a11yProps(2)} />
                <Tab label="Service Task" {...a11yProps(3)} />
                <Tab label="Service Task Configuration" {...a11yProps(4)} />
              </Tabs>
            </Box>
            {isProductSuccess && productData ? (
              <Box>
                <TabPanel value={tab} index={0}>
                  <EditProductDescription />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <EditInventoryMedia />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <EditProductConfiguration />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <EditServiceTask />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                  <EditServiceTaskConfiguration />
                </TabPanel>
              </Box>
            ) : (
              <LoadingPage />
            )}
          </Box>
        </Paper>
      </Box>
    </ProductContext.Provider>
  );
};

export default EditProductLayout;
