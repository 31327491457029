import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { domainAccountUrl } from "../presentation/shared/ApiUrls";

export const passwordApi = createApi({
  reducerPath: "passwordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: domainAccountUrl,
    prepareHeaders: (headers) => {
      let clientToken = sessionStorage.getItem("clientToken");
      headers.append("Authorization", `Bearer ${clientToken}`);
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Domain_Password"],
  endpoints: (builder) => ({
    createPassword: builder.mutation({
      query: (addPasswordRequest) => ({
        url: "/accounts/password",
        method: "POST",
        body: addPasswordRequest,
      }),
      invalidatesTags: ["Domain_Password"],
    }),
    resetPassword: builder.mutation({
      query: (resetPassword) => ({
        url: "/accounts/forgot/password",
        method: "PUT",
        body: resetPassword,
      }),
      invalidatesTags: ["Domain_Password"],
    }),

    updatePassword: builder.mutation({
      query: (updatePassword) => ({
        url: "/accounts/password",
        method: "PUT",
        body: updatePassword,
      }),
      invalidatesTags: ["Domain_Password"],
    }),

    completeForgotPassword: builder.mutation({
      query: (completeForgotPassword) => ({
        url: "/accounts/complete/forgot/password",
        method: "PUT",
        body: completeForgotPassword,
      }),
      invalidatesTags: ["Domain_Password"],
    }),
  }),
});

export const {
  useCreatePasswordMutation,
  useUpdatePasswordMutation,
  useResetPasswordMutation,
  useCompleteForgotPasswordMutation,
} = passwordApi;
