import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useCreateMerchantMutation } from "../services/MerchantService";
import { useNavigate } from "react-router-dom";
import { useGetAllMerchantCategoriesQuery } from "../services/MerchantCategoryService";
import { useGetAllCountriesQuery } from "../services/CountryService";
import { getEmojiFlag } from "countries-list";
import queryString from "query-string";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAP_API_KEY } from "../core/api-key";
import Marker from "../components/common/Marker";
import { KeyboardBackspace } from "@mui/icons-material";
import { City } from "country-state-city";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddMerchant = () => {
  const filter = queryString.stringify({ size: 10000 });
  const [createMerchant, { isLoading: isCreateLoading }] =
    useCreateMerchantMutation();
  const { data: categories, isCategoryLoading } =
    useGetAllMerchantCategoriesQuery(filter);
  const { data: countries } = useGetAllCountriesQuery(filter);
  const navigate = useNavigate();
  const [merchantRequest, setMerchantRequest] = useState({
    verified: true,
    enabled: true,
  });
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 5.612218041361714,
      lng: -0.20050925221460147,
    },
    zoom: 11,
  });
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCategoryChange = (event, value) => {
    setMerchantRequest((prev) => ({ ...prev, categories: value }));
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["verified", "active"].includes(name)) {
      setMerchantRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (
      ["streetAddress", "postalAddress", "regionOrState"].includes(name)
    ) {
      setMerchantRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value },
      }));
    } else if (["city"].includes(name)) {
      setMerchantRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value?.name },
      }));
    } else if (["countryCode"].includes(name)) {
      setSelectedCountry(value);
      setMerchantRequest((prev) => ({
        ...prev,
        [name]: value.isoCode,
        address: { ...prev?.address, country: value.name },
      }));
    } else {
      setMerchantRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const hanldeContactPersonChange = (event) => {
    const { name, value } = event.target;
    if (["name", "position"].includes(name)) {
      setMerchantRequest((prev) => ({
        ...prev,
        contactPerson: { [name]: value },
      }));
    }
  };

  const handleGPSChange = (event) => {
    const { lat, lng } = event.center;
    const { zoom } = event;
    setDefaultProps((prev) => ({
      ...prev,
      center: { lat: lat, lng: lng },
      zoom: zoom,
    }));
    setMerchantRequest((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        gpsLocation: {
          ...prev?.address?.gpsLocation,
          latitude: lat,
          longitude: lng,
        },
      },
    }));
  };

  const handleCancel = () => {
    navigate("/pages/merchants");
  };

  const handleSubmitMerchant = async (event) => {
    event.preventDefault();

    await createMerchant(merchantRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Merchant created succesfully");
          navigate("/pages/merchants");
        } else {
          ErrorToast("Failed to create Merchant");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Merchant");
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
      >
        <Button variant="outlined" onClick={handleCancel}>
          <KeyboardBackspace /> BACK
        </Button>{" "}
        <Typography
          variant="h6"
          sx={{
            marginBottom: "14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Add Merchant
        </Typography>
      </Box>

      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={1}>
          <Grid sm={12} xs={12} item md={6}>
            <TextField
              variant="outlined"
              name="name"
              label="Business name"
              disabled={isCreateLoading}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid sm={12} xs={12} item md={6}>
            <TextField
              label="Contact Person Name"
              variant="outlined"
              disabled={isCreateLoading}
              name="name"
              onChange={hanldeContactPersonChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            <TextField
              disabled={isCreateLoading}
              label="Position"
              variant="outlined"
              name="position"
              onChange={hanldeContactPersonChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            <TextField
              label="Postal Address"
              variant="outlined"
              name="postalAddress"
              disabled={isCreateLoading}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            <TextField
              label="Region/State"
              variant="outlined"
              name="regionOrState"
              disabled={isCreateLoading}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid sm={12} xs={12} item md={6}>
            <TextField
              label="Street Address"
              variant="outlined"
              name="streetAddress"
              disabled={isCreateLoading}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Country"
                name="countryCode"
                disabled={isCreateLoading}
                onChange={handleFormChange}
              >
                {countries?.map((country, index) => (
                  <MenuItem value={country} key={index}>{`${getEmojiFlag(
                    country?.isoCode
                  )} ${country?.name}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                disabled={!selectedCountry || isCreateLoading}
                name="city"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="City"
                defaultValue=""
                onChange={handleFormChange}
              >
                {merchantRequest?.countryCode &&
                  City.getCitiesOfCountry(merchantRequest?.countryCode)?.map(
                    (city) => <MenuItem value={city}>{city.name}</MenuItem>
                  )}
              </Select>
            </FormControl>
          </Grid>

          <Grid sm={12} xs={12} item md={6}>
            <Autocomplete
              size="small"
              multiple
              fullWidth
              loading={isCategoryLoading}
              id="tags-filled"
              options={categories ?? []}
              onChange={handleCategoryChange}
              freeSolo
              disabled={isCreateLoading}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="standard"
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Merchant Categories"
                  placeholder="Merchant Categories"
                />
              )}
            />
          </Grid>
          <Grid sm={12} xs={12} item md={12}>
            <Box sx={{ mt: 4, height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                onChange={handleGPSChange}
              >
                <Marker
                  lat={defaultProps.center.lat}
                  lng={defaultProps?.center?.lng}
                />
              </GoogleMapReact>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleCancel}
          variant="contained"
          disabled={isCreateLoading}
          sx={{ borderRadius: "20px" }}
          color="error"
        >
          Cancel
        </Button>
        <Button
          disabled={isCreateLoading}
          onClick={handleSubmitMerchant}
          variant="contained"
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddMerchant;
