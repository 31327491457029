import React from 'react'
import { Grid, TextField } from "@mui/material"

function TaskResponseBiilInfoMapComponent({ responseConfigRequest, handleFormChange }) {

    return (
        <Grid spacing={2} container>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute For First name"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForFirstName}
                    name="mapForCustomerBillInfo.attributeForFirstName"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute for Last name"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForLastName}
                    name="mapForCustomerBillInfo.attributeForLastName"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} >
                <TextField
                    fullWidth
                    label="Attribute for full name"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForFullName}
                    name="mapForCustomerBillInfo.attributeForFullName"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute for customer number"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForCustomerNumber}
                    name="mapForCustomerBillInfo.attributeForCustomerNumber"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute for invoice ID"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForInvoiceId}
                    name="mapForCustomerBillInfo.attributeForInvoiceId"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute for invoice amount"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForInvoiceAmount}
                    name="mapForCustomerBillInfo.attributeForInvoiceAmount"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <TextField
                    fullWidth
                    label="Attribute for invoice deadline"
                    variant="outlined"
                    value={responseConfigRequest?.mapForCustomerBillInfo?.attributeForInvoiceDeadline}
                    name="mapForCustomerBillInfo.attributeForInvoiceDeadline"
                    onChange={handleFormChange}
                    size="small"
                />
            </Grid>
        </Grid>
    )
}

export default TaskResponseBiilInfoMapComponent