import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  Container,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCompleteForgotPasswordMutation } from "../services/PasswordService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const CompleteForgotPassword = () => {
  const [resetPassword, { data }] = useCompleteForgotPasswordMutation();
  const { state } = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [passwords, setPasswords] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    otp: {
      id: state.otpId,
      value: "",
    },
  });

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "otp") {
      setPasswords((prevPasswords) => ({
        ...prevPasswords,
        otp: {
          ...prevPasswords.otp,
          value,
        },
      }));
    } else {
      setPasswords((prevPasswords) => ({
        ...prevPasswords,
        [name]: value,
      }));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    try {
      const response = await resetPassword(passwords);

      if (response.data) {
        SuccessToast("Password updated successfully");
        navigate("/login");
      } else {
        ErrorToast("Failed to update password. Please try again.");
      }
    } catch (error) {
      ErrorToast("Failed to update password. Please try again.");
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, borderRadius: "10px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <Typography variant="h5">Reset Password</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          ></Box>
          <TextField
            label="Username"
            name="username"
            value={passwords.username}
            onChange={handlePasswordChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={passwords.password}
            onChange={handlePasswordChange}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={showPassword ? "text" : "password"}
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="OTP"
            name="otp"
            onChange={handlePasswordChange}
            required
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              sx={{ width: "50%", padding: "10px", borderRadius: "10px" }}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default CompleteForgotPassword;
