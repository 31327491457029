import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { mobileUsersUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const mobileUsersApi = createApi({
  reducerPath: "mobileUsersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: mobileUsersUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["MobileUsers"],
  endpoints: (builder) => ({
    searchUsers: builder.query({
      query: (filterMerchantRequest) => ({
        url: `/users/all?${queryString.stringify(
          filterMerchantRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["MobileUsers"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),

    getSingleUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      providesTags: ["MobileUsers"],
      transformResponse: (response) => response.data,
    }),

    deleteMobileUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MobileUsers"],
    }),
  }),
});

export const {
  useSearchUsersQuery,
  useGetSingleUserQuery,
  useDeleteMobileUserMutation,
} = mobileUsersApi;
