export const createProductRequest = {
  type: "",
  name: "",
  service: false,
  media: [],
  platformSKU: false,
  enabled: false,
  customerAmountAccepted: false,
  availableWeight: [],
  availableVolume: [],
  availableMeasurement: [],
  licensingDocumentRequired: false,
};

export const updateProductRequest = {
  id: "",
  type: "",
  name: "",
  merchantId: "",
  service: false,
  media: [],
  platformSKU: false,
  enabled: false,
  customerAmountAccepted: false,
  availableWeight: [],
  availableVolume: [],
  availableMeasurement: [],
  categories: [],
  licensingDocumentRequired: false,
};
