import { Box, Paper, Typography, Button } from "@mui/material";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import Chat from "../../components/inbox/Chat";
import { useCreateChatMessageMutation } from "../../services/disputeServices/ChatMessageService";
import { sendChatMessageRequest } from "../../models/sendMessageRequest";
import { useGetCaseQuery } from "../../services/disputeServices/CasesServices";
import { useCreateRulingMutation } from "../../services/disputeServices/RullingService";
import { KeyboardBackspace } from "@mui/icons-material";
import { ErrorToast, SuccessToast } from "../../components/common/Toast";

const ChatRoom = () => {
  const { caseId } = useParams();
  const {
    data: caseData,
    isLoading: isCaseDataLoading,
    status,
    isSuccess,
  } = useGetCaseQuery(caseId);
  const [createMessageRequest] = useCreateChatMessageMutation();
  const [sendMessageRequest, setSendMessageRequest] = useState(
    sendChatMessageRequest
  );

  const [createRule, { isLoading: isRuleLoading }] = useCreateRulingMutation();
  const agentId = caseData?.assignedAgent?.id;

  const createRuling = async (event) => {
    event.preventDefault();

    const ruleFor = event.target.value;
    const rulingRequest = {
      caseId: caseId,
      agentId: agentId,
      status: ruleFor,
    };

    await createRule(rulingRequest)
      .then((response) => {
        if (response?.data) {
          SuccessToast(`Case judged ${ruleFor}`);
        } else {
          ErrorToast("Failed to judge case ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to judge case ");
      })
      .finally(() => {});
  };

  if (caseData) {
    return (
      <Box
        sx={{
          pt: "80px",
          pb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"/pages/cases"}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginRight: "2.5em" }}
          >
            Rule In Favour{" "}
          </Typography>
          <Box>
            <Button
              disabled={isRuleLoading}
              variant="contained"
              sx={{ borderRadius: "20px", marginRight: "10px" }}
              value={"IN_FAVOR_OF_CUSTOMER"}
              onClick={createRuling}
            >
              Customer
            </Button>
            <Button
              disabled={isRuleLoading}
              variant="contained"
              value={"IN_FAVOR_OF_MERCHANT"}
              sx={{ borderRadius: "20px" }}
              onClick={createRuling}
            >
              Merchant
            </Button>
          </Box>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Chat caseId={caseId} />
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default ChatRoom;
