import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { filesize } from "filesize";

const InventoryMediaModal = ({
  open,
  handleClose,
  inventoryMedia,
  type,
  isFormValid,
  handleFormChange,
  handleSubmit,
  loading,
}) => {
  const updaloadRef = React.useRef();

  const handleUploadClick = () => {
    updaloadRef.current.click();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle
        textAlign={"center"}
      >{`${type} INVENTORY MEDIA`}</DialogTitle>
      <DialogContent>
        {type === "VIEW" ? (
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              ID: <span style={{ opacity: 0.7 }}>{inventoryMedia?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File name:{" "}
              <span style={{ opacity: 0.7 }}>{inventoryMedia?.filename}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File Url:{" "}
              <span style={{ opacity: 0.7 }}>{inventoryMedia?.fileUrl}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              File size:{" "}
              <span style={{ opacity: 0.7 }}>
                {inventoryMedia?.size && filesize(inventoryMedia?.size ?? 0)}
              </span>
            </Typography>
          </Box>
        ) : (
          <Grid spacing={2} container padding={5}>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File name"
                name="filename"
                variant="outlined"
                disabled
                value={inventoryMedia?.filename}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File Url"
                name="fileUrl"
                variant="outlined"
                required
                disabled={type === "UPDATE"}
                value={inventoryMedia?.fileUrl}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="MimeType"
                name="mimeType"
                variant="outlined"
                disabled
                placeholder="Example: image/png"
                value={inventoryMedia?.mimeType}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid sm={12} xs={12} item md={6}>
              <TextField
                label="File size"
                name="size"
                variant="outlined"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {inventoryMedia?.size && filesize(inventoryMedia?.size)}
                    </InputAdornment>
                  ),
                }}
                value={inventoryMedia?.size}
                onChange={handleFormChange}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <input
              ref={updaloadRef}
              type="file"
              hidden
              name="media"
              onChange={handleFormChange}
            />
            <Button
              color="error"
              onClick={handleClose}
              variant="contained"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              CANCEL
            </Button>
            <Button
              onClick={handleUploadClick}
              variant="contained"
              disabled={loading}
            >
              BROWSE
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={handleClose}
              variant="contained"
              disabled={loading || isFormValid}
            >
              {loading && (
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InventoryMediaModal;
