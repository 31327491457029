import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const TransactionsActivityTable = (handleViewActivity) => {
  return [
    {
      field: "created",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "createdby",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: " Created By",
      valueGetter: ({ value }) => value ?? "N/A",
    },

    {
      field: "activity",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Activity",
    },
    {
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
    },

    {
      field: "lastModifiedby",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Last Modified",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <IconButton color="primary" onClick={() => handleViewActivity(row)}>
              <FiEye size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
