import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { domainAccountUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const domainAccountApi = createApi({
  reducerPath: "domainAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: domainAccountUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Domain_Accounts"],
  endpoints: (builder) => ({
    getAllDomainAccounts: builder.query({
      query: (filterDomainAccountRequest) => ({
        url: `/accounts?${filterDomainAccountRequest}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ["Domain_Accounts"],
    }),

    getDomainAccount: builder.query({
      query: (id) => ({
        url: `/accounts/${id}`,
        method: "GET",
      }),
      providesTags: ["Domain_Accounts"],
    }),
    loginDomainUser: builder.mutation({
      query: (loginRequest) => ({
        url: "/accounts",
        method: "POST",
        body: loginRequest,
      }),
      invalidatesTags: ["Domain_Accounts"],
    }),
    searchDomainAccount: builder.query({
      query: (filterDomainAccountRequest) => ({
        url: `/accounts?${queryString.stringify(
          filterDomainAccountRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Domain_Accounts"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateDomain: builder.mutation({
      query: (updateDomainRequest) => ({
        url: "/accounts",
        method: "PUT",
        body: updateDomainRequest,
      }),
      invalidatesTags: ["Domain_Accounts"],
    }),
    createDomainAccount: builder.mutation({
      query: (addDomainRequest) => ({
        url: "/accounts",
        method: "POST",
        body: addDomainRequest,
      }),
      invalidatesTags: ["Domain_Accounts"],
    }),
    deleteDomain: builder.mutation({
      query: (id) => ({
        url: `/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Domain_Accounts"],
    }),
  }),
});

export const {
  useGetAllDomainAccountsQuery,
  useSearchDomainAccountQuery,
  useCreateDomainAccountMutation,
  useGetDomainAccountQuery,
  useDeleteDomainMutation,
  useUpdateDomainMutation,
} = domainAccountApi;
