import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import moment from "moment";

export const ServiceTasksTable = ({ handleDeleteAction, handleEditAction }) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Date created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "updated",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,

      headerName: "Date updated",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
    },
    {
      field: "taskType",
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerName: "Task Type",
    },
    {
      field: "executionOrder",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Execution Order",
    },
    {
      field: "labelNextTask",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Next Button Label",
    },
    {
      field: "labelActionButton",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Action Button Label",
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
