import * as React from "react";
import { getEmojiFlag } from "countries-list";
import { FiDelete, FiTrash2 } from "react-icons/fi";

import {
  MenuItem,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Button,
  DialogActions,
  Box,
  Chip,
} from "@mui/material";

import { useDeleteMobileUserMutation } from "../../services/MobileUsersService";
import { verificationStatuses } from "../common/dropdownsData";
import CustomAlertDialog from "./CustomAlertDialog";
import { kycStatusColor } from "../../presentation/helpers";
import { ErrorToast, SuccessToast } from "../common/Toast";
import { useNavigate } from "react-router-dom";

const KycModal = ({
  open,
  handleClose,
  user,
  type,
  handleFormChange,
  handleSubmit,
}) => {
  const [deleteForex] = useDeleteMobileUserMutation();
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleDelete = async () => {
    await deleteForex(user?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("User deleted succesfully");
          handleClose();
          navigate(0);
        } else {
          ErrorToast("Failed to delete User");
          handleClose();
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete User");
        handleClose();
      });
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  return (
    user && (
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              {type} USER
            </Typography>
          </DialogContentText>
          {type === "UPDATE" ? (
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">
                Verification Status
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Verification Status"
                value={user?.user?.kyc?.verificationStatus}
                name="verificationStatus"
                onChange={handleFormChange}
              >
                {verificationStatuses.map((status, index) => (
                  <MenuItem
                    disabled={status === user?.prevStatus}
                    key={index}
                    value={status}
                  >
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box>
              <CustomAlertDialog
                open={openDelete}
                handleClose={handleCloseDelete}
                handleSubmit={handleDelete}
                title={"Delete User"}
                subTitle={`Are you sure you want to delete ${
                  user?.id ?? "N/A"
                }?`}
                negativeButtonText={"No"}
                positiveButtonText={"Yes"}
                icon={<FiDelete size={30} />}
                fullWidth={true}
              />
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Id: <span style={{ opacity: 0.7 }}>{user?.id ?? "N/A"}</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Email:{" "}
                <span style={{ opacity: 0.7 }}>{user?.email ?? "N/A"}</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Phone:{" "}
                <span style={{ opacity: 0.7 }}>{user?.phone ?? "N/A"}</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Country:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.countryCode &&
                    user?.countryCode + getEmojiFlag(user?.countryCode)}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Username:{" "}
                <span style={{ opacity: 0.7 }}>
                  {`${user?.kyc?.firstName ?? "N/A"}  ${
                    user?.kyc?.lastName ?? "N/A"
                  }`}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Nationality:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.nationality ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Date of Birth:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.dateOfBirth ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Residential Address:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.residentialAddress ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Employment Status:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.employmentStatus ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Source of Funds:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.sourceOfFunds ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Income Range:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc?.incomeRange ?? "N/A"}
                </span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Verification Status:{" "}
                <span style={{ opacity: 0.7 }}>
                  {user?.kyc ? (
                    <Chip
                      variant="outlined"
                      label={user.kyc.verificationStatus}
                      color={kycStatusColor(user.kyc.verificationStatus)}
                    />
                  ) : (
                    <Chip
                      variant="outlined"
                      label={"NOT_SUBMITTED"}
                      color="default"
                    />
                  )}
                </span>
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {type !== "VIEW" ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button color="error" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} variant="contained">
                Update
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={handleOpenDelete}
                variant="contained"
                color="error"
              >
                Delete
              </Button>

              <Button onClick={handleClose} variant="contained">
                OK
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};

export default KycModal;
