import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoyaltyUrl } from "../../presentation/shared/ApiUrls";
import { queryStringOptions } from "../../core/queryStringOptions";
import queryString from "query-string";

export const ProgramMembersApiService = createApi({
  reducerPath: "programMembersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: LoyaltyUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["ProgramMembers"],
  endpoints: (builder) => ({
    getAllProgramMembers: builder.query({
      query: () => ({
        url: `/loyalty/members`,
        method: "GET",
      }),
      providesTags: ["ProgramMembers"],
      transformResponse: (response) => response.data.data,
    }),
    searchProgramMember: builder.query({
      query: (filterOrderRequest) => ({
        url: `/loyalty/members?${queryString.stringify(
          filterOrderRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["ProgramMembers"],
    }),
    getMemberAccount: builder.query({
        query: (id) => ({
          url: `/loyalty/members/${id}`,
          method: "GET",
        }),
        providesTags: ["ProgramMembers"],
        transformResponse: (response) => response.data,
      }),
    // getOrder: builder.query({
    //   query: (id) => ({
    //     url: `/orders/${id}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Orders"],
    //   transformResponse: (response) => response.data,
    // }),
    // getTotalOrder: builder.query({
    //   query: (id) => ({
    //     url: `/orders/total`,
    //     method: "GET",
    //   }),
    //   providesTags: ["Orders"],
    //   transformResponse: (response) => response.data,
    // }),
    // updateOrder: builder.mutation({
    //   query: (updateOrderRequest) => ({
    //     url: "/orders",
    //     method: "PUT",
    //     body: updateOrderRequest,
    //   }),
    //   invalidatesTags: ["Orders"],
    // }),
    // createOrder: builder.mutation({
    //   query: (addOrderRequest) => ({
    //     url: "/orders",
    //     method: "POST",
    //     body: addOrderRequest,
    //   }),
    //   invalidatesTags: ["Orders"],
    // }),
    deleteProgramMember: builder.mutation({
      query: (id) => ({
        url: `/loyalty/members/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProgramMembers"],
    }),
    deleteMemberAccount: builder.mutation({
      query: (id) => ({
        url: `/loyalty/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProgramMembers"],
    }),
  }),
});

export const {
    useGetAllProgramMembersQuery,
//   useGetOrderQuery,
//   useGetTotalOrderQuery,
//   useSearchOrdersQuery,
//   useCreateOrderMutation,
//   useUpdateOrderMutation,
  useDeleteProgramMemberMutation,
  useDeleteMemberAccountMutation,
  useGetMemberAccountQuery,
  useSearchProgramMemberQuery,
} = ProgramMembersApiService;
