import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { productsUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: productsUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  tagTypes: ["Products", "Merchants"],
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (filterProductRequest) => ({
        url: `/products?${filterProductRequest}`,
        method: "GET",
      }),
      providesTags: ["Products"],
      transformResponse: (response) => response.data.data,
    }),
    getProduct: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
        method: "GET",
      }),
      provideTags: ["Products"],
      transformResponse: (response) => response.data,
    }),
    searchProducts: builder.query({
      query: (filterProductRequest) => ({
        url: `/products?${queryString.stringify(
          filterProductRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Products", "Merchant"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
    updateProduct: builder.mutation({
      query: (updateProductRequest) => ({
        url: "/products",
        method: "PUT",
        body: updateProductRequest,
      }),
      invalidatesTags: ["Products", "Merchants"],
    }),
    createProduct: builder.mutation({
      query: (createProductRequest) => ({
        url: "/products",
        method: "POST",
        body: createProductRequest,
      }),
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useSearchProductsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productsApi;
