import { Box } from "@mui/system";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { updateAttemptColor } from "../presentation/helpers";
import moment from "moment";

export const RemittanceAttemptTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "id",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "ID",
    },

    {
      field: "orderId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Order Id",
    },

    {
      field: "externalId",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "External Id",
    },
    {
      field: "status",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      renderCell: ({ value }) => (
        <Chip
          size="small"
          variant="outlined"
          color={updateAttemptColor(value)}
          label={value}
        />
      ),
    },

    {
      field: "action",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/remittance/attempt/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
