import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Marker = ({ lat, lng }) => {
  return (
    <div style={{ color: "red", fontWeight: "bold" }}>
      <LocationOnIcon />
      <div>lat:{lat}</div>
      <div>lng:{lng}</div>
    </div>
  );
};

export default Marker;
