import {
  Box,
  Divider,
  Grid,
  Button,
  IconButton,
  Paper,
  Typography,
  TableContainer,
} from "@mui/material";
import { FiCalendar, FiUser } from "react-icons/fi";
import { useParams } from "react-router-dom";
import {
  useGetForexQuery,
  useGetForexDetailsQuery,
} from "../services/ForexService";
import "../styles/singleForex.css";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {} from "@mui/material";

// import { ForexActivitiesTable } from "../data/ForexActivitiesTable";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const SingleForex = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: forex } = useGetForexQuery(id);
  const activityResponse = useGetForexDetailsQuery(id);
  console.log("forex activity response :: ", activityResponse);
  // const dataGridService = new DataGridService("Forex");

  // const [paginationModel, setPaginationModel] = useState(
  //   dataGridService.getPaginationModel()
  // );
  const activityData = activityResponse.data;
  console.log("activities :: ", activityData);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  // const theme = createTheme({
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: ({ ownerState }) => ({
  //           ...(ownerState.variant === "contained" &&
  //             ownerState.color === "primary" && {
  //               backgroundColor: "#fffffff",
  //               color: "#000000",
  //             }),
  //         }),
  //       },
  //     },
  //   },
  // });
  // const handlePaginationModelChange = (paginationModel) => {
  //   dataGridService.setPaginationModel(paginationModel);
  //   setPaginationModel(paginationModel);
  // };

  if (forex) {
    return (
      <>
        <Box sx={{ pb: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pb: "20px",
            }}
          >
            <Button variant="outlined" onClick={() => navigate(-1)}>
              <KeyboardBackspace /> BACK
            </Button>
            <Typography
              variant="h5"
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              Forex details
            </Typography>
          </Box>

          <Paper
            sx={{
              boxShadow: "none !important",
              borderRadius: "12px",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "divider",
              p: "20px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ my: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FiCalendar />
                  <Typography variant="h5">Forex</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#049bf5",
                        backgroundColor: "rgba(4,155,245, 0.2) !important",
                      }}
                    >
                      <FiUser />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                      Forex Info
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      ID: <span style={{ opacity: 0.7 }}>{forex?.id}</span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Home Currency:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {forex?.homeCurrency}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Foreign Currency:{" "}
                      <span style={{ opacity: 0.7 }}>
                        {forex?.foreignCurrency}{" "}
                      </span>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Selling Rate: {forex?.sellingRate}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "15px", mb: 2 }}
                    >
                      Buying Rate:{" "}
                      <span style={{ opacity: 0.7 }}> {forex?.buyingRate}</span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Grid container spacing={14} sx={{ py: 3, px: 5 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Created:
                    </span>{" "}
                    {forex?.created}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Typography variant="subtitle2">
                    <span style={{ opacity: 0.7, pb: 1, my: 2 }}>
                      {" "}
                      Date Updated:
                    </span>{" "}
                    {forex?.updated}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: "white" }}>
              <TableRow style={{ justifyContent: "center" }}>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell align="">Previous Buying Rate</StyledTableCell>
                <StyledTableCell align="">Current Selling Rate</StyledTableCell>
                <StyledTableCell align="">Currency Pair</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityData?.data?.data?.map((row) => (
                <StyledTableRow key={row.username}>
                  <StyledTableCell component="th" scope="row">
                    {row.username}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.previousBuyingRate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currentSellingRate}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currencyPair}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleForex;
