import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useGetProductSpecQuery } from '../../services/loyalty/productSpecService'
import { Card, Typography, IconButton, Tooltip, Button } from '@mui/material';
import { IoAddCircle } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";


export const ProductSpecDetails = () => {
    const params = useParams()
    const id = params.id
    const navigate = useNavigate();
    const { data: ProductSpecDetails, isLoading } = useGetProductSpecQuery(id);

    useEffect(() => {
        console.log("is id available:", id)
        console.log("data received:", ProductSpecDetails)
    }, [ProductSpecDetails])

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    const bracesOpened = `{`
    const bracesClosed = `}`

    return (
        <Box sx={{ pt: "80px", pb: "20px" }}>
            <Box
                sx={{
                    display: "inline",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                }}
            >
                 <Button sx={{ borderRadius: "20px" , margin:"0px 20px"}}
              variant="contained"
              color="primary" onClick={() => navigate("/pages/loyalty/product-spec")}>
              Back
            </Button>
                <Card sx={{ width: "100%", py: "50px", px: "50px", background: "#f4f7fe" }}>
                    {!isLoading ? (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignContent: "center", mb: "20px" }}>
                                <Typography sx={{ fontWeight: "900", }}> Product Spec</Typography>
                                <Button variant="outlined" onClick={() => navigate(`/pages/loyalty/update-product-spec/${ProductSpecDetails?.id}`)} >Update Product Spec  </Button>
                            </Box>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} sx={{ background: "white", py: "5px", px: "5px" }}>
                         
                                <Grid item xs={6}>
                                    
                                    <Item>
                                        <div><span style={{ fontWeight: "900" }}>ID : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.id || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Name : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.name || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Description : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.description || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Product Number : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.productNumber || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Needs Loyalty : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.needsLoyaltyAccount || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Brand : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.brand || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Status : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.status || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Country code : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.countryCode || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Balance unite : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.balanceUnit || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Background color : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.bgcolor || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Label color : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.labelcolor || "N/A"}</span></div>
                                    </Item>
                                </Grid>
                                <Grid item xs={6}>
                                    <Item>
                                        <div><span style={{ fontWeight: "900" }}>Valid for ID : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.validFor.id || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Start Date Time : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.validFor.startDateTime || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>End Date Time : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.validFor.endDateTime || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Create : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.created || "N/A"}</span></div>
                                        <div><span style={{ fontWeight: "900" }}>Updated : </span><span style={{ margin: "0px 10px" }}>{ProductSpecDetails?.updated || "N/A"}</span></div>
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} sx={{ width: "100%", my: "10px", background: "#f4f7fe", px: "0", py: "0" }}>

                                <Grid item xs={12} sx={{ background: "#f4f7fe", px: "0", py: "0" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignContent: "center", mb: "20px" }}>
                                        <Typography sx={{ fontWeight: "900", }}> Product Spec Rules</Typography>
                                        <Button variant="outlined" onClick={()=> navigate('/pages/loyalty/add-product-spec-rule')}>Add Product Spec Rule </Button>
                                    </Box>
                                    <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
                                        <Item sx={{ background: "#f4f7fe" }}>
                                            {ProductSpecDetails?.specRules?.map((data, key) => (
                                                <Card sx={{ width: "100%", background: "white", px: "10px", py: "20px", my: "10px" }}>

                                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignContent: "center", mb: "20px" }}>
                                                        <Typography sx={{ fontWeight: "900", }}> Spec Rule {key + 1}</Typography>
                                                        <Button variant="outlined">Edit Product Spec Rule </Button>
                                                    </Box>

                                                    <div><span style={{ fontWeight: "900" }}>Product Spec ID : </span><span style={{ margin: "0px 10px" }}>{data.productSpecId || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Common name: </span><span style={{ margin: "0px 10px" }}>{data.commonName || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Description : </span><span style={{ margin: "0px 10px" }}>{data.description || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Cnf : </span><span style={{ margin: "0px 10px" }}>{data.cnf || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Has Sub Rules : </span><span style={{ margin: "0px 10px" }}>{data.hasSubRules || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Mandatory Evalution : </span><span style={{ margin: "0px 10px" }}>{data.mandatoryEvaluation || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>usage : </span><span style={{ margin: "0px 10px" }}>{data.usage || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Keywords : </span><span style={{ margin: "0px 10px" }}>{data.keywords || "N/A"}</span></div>
                                                    <div><span style={{ fontWeight: "900" }}>Policy Name : </span><span style={{ margin: "0px 10px" }}>{data.policyName || "N/A"}</span></div>

                                                    <div style={{ margin: "10px 0px" }}>
                                                        <div><span style={{ fontWeight: "900" }}>Event Type ID :</span><span style={{ margin: "0px 10px" }}>{data?.eventType?.id || "N/A"}</span></div>
                                                        <div><span style={{ fontWeight: "900" }}>Event  :</span><span style={{ margin: "0px 10px" }}>{data?.eventType?.eventType || "N/A"}</span></div>
                                                        <div><span style={{ fontWeight: "900" }}>Event Type created :</span><span style={{ margin: "0px 10px" }}>{data?.eventType?.created || "N/A"}</span></div>
                                                        <div><span style={{ fontWeight: "900" }}>Event Type updated :</span><span style={{ margin: "0px 10px" }}>{data?.eventType?.updated || "N/A"}</span></div>
                                                    </div>

                                                    <div style={{ margin: "10px 0px" }}>
                                                        {data?.conditions?.map((data, key) => (
                                                            <>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions ID :</span><span style={{ margin: "0px 10px" }}>{data?.id || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions attribute :</span><span style={{ margin: "0px 10px" }}>{data?.attribute || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions :</span><span style={{ margin: "0px 10px" }}>{data?.condition || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions value :</span><span style={{ margin: "0px 10px" }}>{data?.conditionValue || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions model :</span><span style={{ margin: "0px 10px" }}>{data?.model || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions created :</span><span style={{ margin: "0px 10px" }}>{data?.created || "N/A"}</span></div>
                                                                <div><span style={{ fontWeight: "900" }}> Conditions updated :</span><span style={{ margin: "0px 10px" }}>{data?.updated || "N/A"}</span></div>
                                                            </>
                                                        ))}
                                                    </div>

                                                    <Typography sx={{ fontWeight: "900" }}>Action </Typography>
                                                    < Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} sx={{ width: "100%", my: "10px", background: "#f4f7fe", px: "0", py: "0" }}>
                                                        <Grid xs={12}>
                                                            <Item>
                                                                <Box sx={{ width: "100%", px: "20px", py: "20px", background: "black" }}>
                                                                    <>
                                                                        {bracesOpened}
                                                                        <div style={{ margin: "10px 30px", width: "100%" }}>
                                                                            <div><span style={{ color: "red" }}>"id" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.id}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"type" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.type}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"actionAttribute" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.actionAttribute}"</span></div>
                                                                            <Box style={{ display: 'flex', width: "100% !important" }}><div style={{ color: "red", }}>"header":</div><div style={{ color: "green", padding: "0px 10px", flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{data?.action?.header}</div></Box>
                                                                            <div><span style={{ color: "red" }}>"body" :</span><span style={{ color: "green", padding: "0px 10px" }}>{data?.action?.body}</span></div>
                                                                            <div><span style={{ color: "red" }}>"commonName" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.commonName}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"description" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.description}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"httpMethod" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.httpMethod}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"endpoint" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.endpoint}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"created" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.created}"</span></div>
                                                                            <div><span style={{ color: "red" }}>"updated" :</span><span style={{ color: "green", padding: "0px 10px" }}>"{data?.action?.updated}"</span></div>
                                                                        </div>
                                                                        {bracesClosed}
                                                                    </>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            ))}
                                        </Item>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ) : (<></>)}
                </Card>
            </Box>
        </Box>
    )
}
