export const createServiceTaskConfigurationRequest = {
  serviceTaskId: "",
  endpoint: "",
  method: "",
  contentType: "",
  additionalHeaders: [],
  sampleRequestBody: "",
  responseConfiguration: {
    serviceTaskConfigurationId: "",
    attributeForResponseCode: "",
    attributeForResponseMessage: "",
    mapsForResponseCodeValues: [],
    mapsForDropDownQuestion: {
      rootAttributeForList: "",
      attributeForKey: "",
      attributeForLabel: "",
      attributeForValue: "",
      attributeForBindValue: "",
    },
    mapForCustomerBillInfo: {
      attributeForFirstName: "",
      attributeForLastName: "",
      attributeForFullName: "",
      attributeForCustomerNumber: "",
      attributeForInvoiceId: "",
      attributeForInvoiceAmount: "",
      attributeForInvoiceDeadline: "",
    },
  },
};

export const updateServiceTaskConfigurationRequest = {
  id: "",
  serviceTaskId: "",
  endpoint: "",
  method: "",
  contentType: "",
  additionalHeaders: [],
  sampleRequestBody: "",
  responseConfiguration: {
    id: "",
    serviceTaskConfigurationId: "",
    attributeForResponseCode: "",
    attributeForResponseMessage: "",
    mapsForResponseCodeValues: [],
    mapsForDropDownQuestion: {
      rootAttributeForList: "",
      attributeForKey: "",
      attributeForLabel: "",
      attributeForValue: "",
      attributeForBindValue: "",
    },
    mapForCustomerBillInfo: {
      attributeForFirstName: "",
      attributeForLastName: "",
      attributeForFullName: "",
      attributeForCustomerNumber: "",
      attributeForInvoiceId: "",
      attributeForInvoiceAmount: "",
      attributeForInvoiceDeadline: "",
    },
  },
};
