import { FiberManualRecord, WarningOutlined } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/system";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { getEmojiFlag } from "countries-list";
import moment from "moment";

export const CaseAgentTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LLL") : "N/A"),
    },
    {
      field: "firstName",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "First Name",
    },
    {
      field: "lastName",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Last Name",
    },
    {
      field: "active",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Active",
      renderCell: ({ value }) =>
      value ? (
        <FiberManualRecord fontSize={"small"} color="success" />
      ) : (
        <FiberManualRecord fontSize={"small"} color="error" />
      ),
    },
    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {/*<Tooltip arrow placement="top" title="View">
                              <Link to={`/pages/merchant/${row.id}`}>
                                   <IconButton color="primary" >
                                        <FiEye size={18} />
                                   </IconButton>
                              </Link>
                         </Tooltip>
                         <Tooltip arrow placement="top" title="Edit">
                              <Link to={`/pages/merchant/edit/${row.id}`}>
                                   <IconButton color="primary" >
                                        <FiEdit size={18} />
                                   </IconButton>
                              </Link>
               </Tooltip>*/}
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
