import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orderActivitiesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const orderActivityApi = createApi({
  reducerPath: "orderActivitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: orderActivitiesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getAllOrderActivities: builder.query({
      query: (filterOrderRequest) => ({
        url: `/orders/activity?${filterOrderRequest}`,
        method: "GET",
      }),
      providesTags: ["Order_Activities"],
    }),

    searchOrderActivities: builder.query({
      query: (filterOrderActivitiesRequest) => ({
        url: `/orders/activity?${queryString.stringify(
          filterOrderActivitiesRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Order_Activities"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),
  }),
});

export const {
  useGetAllOrderActivitiesQuery,
  useSearchOrderActivitiesQuery,
} = orderActivityApi;
