import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { NotificationsTable } from "../data/NotificationsTable";
import { useSearchNotificationsQuery } from "../services/NotificationService";
import moment from "moment";
import { Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Notifications = () => {
  const dataGridService = new DataGridService("Notifications");
  const [filter, setFilter] = useState({});
  const [open, setOpen] = useState(false);
  const { data: notificationsData, isLoading } =
    useSearchNotificationsQuery(filter);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const printRef = useRef();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedNotification(null);
  };
  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleViewActivity = async (row) => {
    setOpen(true);
    setSelectedNotification(row);
  };

  const handleDeleteAction = (row) => {
    setSelectedNotification(row);
    setOpen(true);
  };

  const columns = NotificationsTable({
    handleViewActivity,
    handleDeleteAction,
  });
  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Notifications</Typography>
        </Box>

        <Dialog
          open={open}
          fullWidth={true}
          onClose={handleClose}
          sx={{ mx: "auto", my: "0px" }}
        >
          {" "}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Notification Details </DialogTitle>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>
          <DialogContent ref={printRef}>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Id:{" "}
              <span style={{ opacity: 0.7 }}>{selectedNotification?.id}</span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              User ID:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.orderId ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Sender:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.sender ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Receiver:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.receiver ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Title :{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.title ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Message:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.message}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Created By:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.createdBy}
              </span>
            </Typography>

            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Last Updated By:{" "}
              <span style={{ opacity: 0.7 }}>
                {selectedNotification?.orderId ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Date Created:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedNotification?.created).format("LLL") ?? "N/A"}
              </span>
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
              Date Updated:{" "}
              <span style={{ opacity: 0.7 }}>
                {moment(selectedNotification?.updated).format("LLL") ?? "N/A"}
              </span>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              loading={isLoading}
              rowCount={notificationsData?.size ?? 0}
              columns={columns}
              rows={notificationsData?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default Notifications;
