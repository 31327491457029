import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
import { useCreateDomainAccountMutation } from "../services/DomainAcountService";
import { useGetAllRolesQuery } from "../services/RoleService";
import { merchantDomainType } from "../components/common/dropdownsData";
import { useGetAllCountriesQuery } from "../services/CountryService";
import queryString from "query-string";
import { getEmojiFlag } from "countries-list";
import { useGetAllMerchantsQuery } from "../services/MerchantService";
import { checkDomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddMerchantAdmin = () => {
  const MAXDATA = queryString.stringify({ size: 10000 });
  const { data: roleData, status } = useGetAllRolesQuery(MAXDATA);

  const [createDomainAccount] = useCreateDomainAccountMutation();

  const [selectedRoles, setSelectedRoles] = useState([]);

  const isComponentSelected = (role) => {
    return selectedRoles.includes(role);
  };

  const { data: countries } = useGetAllCountriesQuery(MAXDATA);
  const { data: merchants } = useGetAllMerchantsQuery(MAXDATA);

  const navigate = useNavigate();
  const [domainAccount, setDomainAccount] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (["domainCode"].includes(name)) {
      setDomainAccount((prev) => ({
        ...prev,
        [name]: value.code,
        merchant: value?.id,
      }));
    } else if (["phone"].includes(name)) {
      setDomainAccount((prev) => ({
        ...prev,
        [name]: value.replace(/[^0-9]/g, ""),
      }));
    } else {
      setDomainAccount((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectedRoles = (event) => {
    const { name, value } = event.target;
    if (value.includes("all")) {
      setSelectedRoles((prevSelectedRoles) => {
        setDomainAccount((prev) => ({ ...prev, [name]: roleData }));
        return prevSelectedRoles.length === roleData?.length ? [] : roleData;
      });
    } else {
      setSelectedRoles(value);
      setDomainAccount((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    await createDomainAccount(domainAccount)
      .then((response) => {
        if (response.data) {
          SuccessToast("Account created successfully");
          navigate("/pages/merchant-admin");
        } else {
          ErrorToast("Failed to create Account");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Account");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, width: "55%", borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h4" align="center">
            Add Merchant Admin
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                name="firstName"
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="email"
                label="Email"
                name="email"
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                selected
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={domainAccount?.phone}
                label="Phone"
                name="phone"
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country Code"
                  name="countryCode"
                  value={domainAccount?.countryCode}
                  onChange={handleChange}
                  required
                >
                  {countries &&
                    countries.map((country, index) => (
                      <MenuItem key={index} value={country.isoCode}>
                        {`${getEmojiFlag(country?.isoCode)} ${country?.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Merchant</InputLabel>
                <Select
                  label="Domain Code"
                  name="domainCode"
                  onChange={handleChange}
                  required
                >
                  {merchants &&
                    merchants.map((merchant, index) => (
                      <MenuItem key={index} value={merchant}>
                        {merchant?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Domain Type</InputLabel>
                <Select
                  label="Domain Type"
                  name="domainType"
                  onChange={handleChange}
                  required
                >
                  {merchantDomainType.map((domainType, index) => (
                    <MenuItem
                      disabled={!checkDomainType().includes(domainType)}
                      key={index}
                      value={domainType}
                    >
                      {domainType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Role</InputLabel>
                <Select
                  label="Role"
                  name="roles"
                  multiple
                  value={selectedRoles}
                  onChange={handleSelectedRoles}
                  renderValue={(selected) => (
                    <div>
                      {selected.includes("all")
                        ? "Select All"
                        : selected.length > 2
                        ? `${selected.length} roles selected`
                        : selected.map((role) => (
                            <span key={role.id}>{` ${role.name}, `}</span>
                          ))}
                    </div>
                  )}
                >
                  <MenuItem value="all">
                    <Checkbox
                      indeterminate={
                        selectedRoles.length > 0 &&
                        selectedRoles.length < roleData?.length
                      }
                      checked={selectedRoles.length === roleData?.length}
                    />
                    Select All
                  </MenuItem>
                  {roleData &&
                    roleData.map((role) => (
                      <MenuItem key={role.id} value={role}>
                        <Checkbox checked={isComponentSelected(role)} />
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  sx={{ mt: 3, width: 300 }}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Add User
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box></Box>
        </form>
      </Paper>
    </Box>
  );
};

export default AddMerchantAdmin;
