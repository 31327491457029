import { Box, Typography,Button, Paper, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomAlertDialog from "../../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";
import { DataGridService } from "../../core/dataGridService";
import { ErrorToast, SuccessToast } from "../../components/common/Toast";
import { useDeleteMemberAccountMutation, useGetMemberAccountQuery } from "../../services/loyalty/programMembersService";
import {MemberAccountDatatable} from "../../data/loyalty/memberAccountDataTable";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'


const MemberAccounts = () => {
  const dataGridService = new DataGridService("MemberAccounts");
  const navigate = useNavigate();
  const params = useParams()
  const userID = params.userId
  const [filter, setFilter] = useState({});
  const { data:membersData, isLoading } = useGetMemberAccountQuery(userID);
  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deleteMemberAccount] = useDeleteMemberAccountMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };
  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort?.toUpperCase(),
      sortBy: sortModel[0]?.field,
    }));
  };
  

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedAccount(row);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    await deleteMemberAccount(selectedAccount?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Account deleted succesfully");
          setOpen(false);
          
        } else {
          ErrorToast("Failed to delete account");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete account");
      });

  };

  const columns = MemberAccountDatatable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();      
  }, [paginationModel, filterModel, sortModel, membersData, ]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "inline",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
         <Button sx={{ borderRadius: "20px" , margin:"20px 10px"}}
              variant="contained"
              color="primary" onClick={() => navigate("/pages/loyalty/program-members")}>
              Back
            </Button>
        <Typography variant="h6" sx={{marginBottom:"10px"}}>Member Accounts</Typography>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Member Account"}
        subTitle={`Are you sure you want to delete ${selectedAccount?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
     
      {
        isLoading ? (<>loading....</>): (<>
        <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={membersData?.accounts?.length ?? 0}
            columns={columns}
            rows={membersData?.accounts ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            disableColumnSelector
            disableDensitySelector
            paginationModel={paginationModel}
            filterModel={filterModel}
            sortModel={sortModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box></>)
      }
    </Box>
  );
};

export default MemberAccounts;
