import {
  Box,
  Button, FormControl, Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import Marker from "../components/common/Marker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCities } from "countries-cities";

import {
  useCreateWarehouseMutation
} from "../services/WarehouseService";
import GoogleMapReact from "google-map-react";

import { useGetAllMerchantsQuery } from "../services/MerchantService";
import { useGetAllCountriesQuery } from "../services/CountryService";
import { getEmojiFlag } from "countries-list";
import queryString from "query-string";
import { GOOGLE_MAP_API_KEY } from "../core/api-key";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddWarehouse = () => {
  const location = useLocation();
  const { merchantId } = queryString.parseUrl(location.search).query;
  const { data: merchantData } = useGetAllMerchantsQuery(
    queryString.stringify({ size: 1000 })
  );
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [createWarehouse] = useCreateWarehouseMutation();
  const [warehouseRequest, setWarehouseRequest] = useState({
    defaultWarehouse: false,
    active: true,
  });
  const { data: countries } = useGetAllCountriesQuery(
    queryString.stringify({ size: 1000 })
  );
  const defaultProps = {
    center: {
      lat: 5.612218041361714,
      lng: -0.20050925221460147,
    },
    zoom: 11,
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["active", "defaultWarehouse"].includes(name)) {
      setWarehouseRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (["country"].includes(name)) {
      setSelectedCountry(value);
      setWarehouseRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value?.name },
      }));
    } else if (
      ["streetAddress", "postalAddress", "city", "regionOrState"].includes(name)
    ) {
      setWarehouseRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value },
      }));
    } else {
      setWarehouseRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleGPSChange = (event) => {
    const { lat, lng } = event.center;
    setWarehouseRequest((prev) => ({
      ...prev,
      addressLocaion: {
        ...prev?.addressLocation,
        longitude: lng,
        latitude: lat,
      },
    }));
  };

  const getIdMerchant = () => {
    const user = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      [DomainType.MERCHANT_ADMIN, DomainType.EMPLOYEE].includes(user.domainType)
    )
      return user?.merchant;
      else{
        return merchantId
      }
  };

  const handleSubmitWarehouse = async () => {
    await createWarehouse({ ...warehouseRequest, merchantId: getIdMerchant() })
      .then((response) => {
        if (response.data) {
          SuccessToast("Warehouse created succesfully");
          navigate(-1);
        } else {
          ErrorToast("Failed to create Warehouse");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Warehouse");
      });
  };
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Warehouse
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",

          overflow: "hidden",
        }}
      >
        {" "}
        <Grid container spacing={2}>
          <Grid item md={6}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              onChange={handleFormChange}
              size="small"
            />
          </Grid>

          <Grid item md={6}>
            <TextField
              label="Street Address"
              name="streetAddress"
              variant="outlined"
              rows={4}
              fullWidth
              size="small"
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Postal Address"
              name="postalAddress"
              variant="outlined"
              rows={4}
              fullWidth
              size="small"
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="State"
              name="regionOrState"
              variant="outlined"
              rows={4}
              fullWidth
              size="small"
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Country"
                name="country"
                onChange={handleFormChange}
              >
                {countries &&
                  countries?.map((country) => (
                    <MenuItem value={country}>{`${getEmojiFlag(
                      country?.isoCode
                    )} ${country?.name}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item md={6}>
            <SecuredComponent
              type={"BUTTON"}
              requiredDomainTypes={[
                DomainType.BACK_OFFICE,
                DomainType.SUPERMAN,
              ]}
            >
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-select-small-label">Merchant</InputLabel>
                <Select
                  label="Merchant"
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  required
                  name="merchantId"
                  onChange={handleFormChange}
                >
                  {merchantData &&
                    merchantData.map((merchant) => (
                      <MenuItem value={merchant?.id}>{merchant.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </SecuredComponent>
          </Grid> */}
          <Grid item md={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                name="city"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="City"
                onChange={handleFormChange}
              >
                {selectedCountry &&
                  getCities(selectedCountry?.name)?.map((city) => (
                    <MenuItem value={city}>{city}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Region/State"
              name="regionOrState"
              variant="outlined"
              fullWidth
              size="small"
              onChange={handleFormChange}
            />
          </Grid>

          <Grid item md={12}>
            <Box sx={{ mt: 4, height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                onChange={handleGPSChange}
              >
                <Marker
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                />
              </GoogleMapReact>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
            gap: 2,
          }}
        >
          <Link to={-1}>
            <Button variant="contained" sx={{ borderRadius: "20px" }}>
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            sx={{ borderRadius: "20px" }}
            onClick={handleSubmitWarehouse}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddWarehouse;
