import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { loginUrl } from "../presentation/shared/ApiUrls";

export const clientApi = createApi({
  reducerPath: "clientAPi",
  baseQuery: fetchBaseQuery({
    baseUrl: loginUrl,
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    loginClient: builder.mutation({
      query: (lgoinClient) => ({
        url: "/client",
        method: "POST",
        body: lgoinClient,
      }),
      invalidatesTags: ["Client_Account"],
    }),
  }),
});

export const { useLoginClientMutation } = clientApi;
