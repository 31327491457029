import { Box, Paper, TableContainer, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteRemittanceMapMutation,
  useSearchRemittanceMapsQuery,
} from "../services/RemittanceMapsService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { RemittanceMapsTable } from "../data/RemittanceMapsTable";
import queryString from "query-string";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const RemittanceMap = () => {
  const dataGridService = new DataGridService("RemittanceMap");
  const [filter, setFilter] = useState({});
  const [selectedRemittanceMap, setSelectedRemittanceMap] = useState(null);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useSearchRemittanceMapsQuery(filter);
  const [deleteRemittanceMap, { isSuccess: isRemittanceMapDeleteSuccess }] =
    useDeleteRemittanceMapMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRemittanceMap(null);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedRemittanceMap(row);
  };
  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleSubmit = async () => {
    await deleteRemittanceMap(selectedRemittanceMap.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Remittance Map deleted succesfully");
        } else {
          ErrorToast("Failed to delete Remittance Map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Remittance Map");
      });
  };

  const columns = RemittanceMapsTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isRemittanceMapDeleteSuccess]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Remittance Default Maps</Typography>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Remittance map"}
          subTitle={`Are you sure you want to delete ${selectedRemittanceMap?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              loading={isLoading}
              rowCount={data?.data?.totalElements ?? 0}
              columns={columns}
              rows={data?.data?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default RemittanceMap;
