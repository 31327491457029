import { Box, Typography, Paper, TableContainer , Button} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomAlertDialog from "../../components/modals/CustomAlertDialog";
import { FiTrash2 } from "react-icons/fi";
import { DataGridService } from "../../core/dataGridService";
import { ErrorToast, SuccessToast } from "../../components/common/Toast";
import { useDeleteActionMutation,useGetAllActionsQuery } from "../../services/loyalty/actionService";
import { ActionDataTable } from "../../data/loyalty/actionDataTable";
import { useNavigate } from 'react-router-dom'

const Actions = () => {

  const navigate = useNavigate();
  const [deleteAction] = useDeleteActionMutation();


  const dataGridService = new DataGridService("Action");
  const [filter, setFilter] = useState({});
  const { data: actionData, isLoading } = useGetAllActionsQuery();
  const [open, setOpen] = useState(false);
  const [selectAction, setSelectAction] = useState(null);
 
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort?.toUpperCase(),
      sortBy: sortModel[0]?.field,
    }));
  };


  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectAction(row);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    await deleteAction(selectAction?.id)
      .then((response) => {
        console.log("delete",response)
        if (response.data) {
          SuccessToast("Action Spec deleted succesfully");
          setOpen(false);
        } else {
          ErrorToast("Failed to delete action spec");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete action spec");
      });
  };


  const columns = ActionDataTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
    console.log("action spec data::", actionData)

  }, [paginationModel, filterModel, sortModel, actionData,]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Action </Typography>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", alignContent: "center",  }}>
          <Button variant="outlined" onClick={() => navigate("/pages/loyalty/add-action-spec")} >Add Action   </Button>
        </Box> */}
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Action Spec"}
        subTitle={`Are you sure you want to delete ${selectAction?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            loading={isLoading}
            rowCount={actionData?.length ?? 0}
            columns={columns}
            rows={actionData ?? []}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            disableColumnSelector
            disableDensitySelector
            paginationModel={paginationModel}
            filterModel={filterModel}
            sortModel={sortModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>

    // <>waiting</>
  );
};

export default Actions;
