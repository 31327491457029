import {
  Box,
  Button,
  FormControl, InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllOrdersQuery } from "../services/OrderService";
import { useGetAllRemittanceConfigurationsQuery } from "../services/RemittanceConfigurationService";
import { useCreateRemittanceJobMutation } from "../services/RemittanceJobService";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const AddRemittanceJob = () => {
  const [createRemittanceJob] = useCreateRemittanceJobMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const { isLoading: configs, data: configurations } =
    useGetAllRemittanceConfigurationsQuery();
  const { isLoading: ordersLoading, data: orders } = useGetAllOrdersQuery();
  const [orderIds, setOrderIds] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [configIds, setConfigIds] = useState(null);

  const navigate = useNavigate();

  const INITIAL_DATA = {
    orderId: "",
    orderItemId: "",
    remittanceConfigurationId: "",
    userPOId: "",
    closed: false,
    status: "INIT",
  };

  const [formData, setFormData] = useState(INITIAL_DATA);

  const updateFields = (fields) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };

  const setIds = () => {
    setConfigIds(configurations?.data?.data);
    setOrderIds(orders?.data?.data);
  };

  useEffect(() => {
    setIds();
  }, [configurations, orders]);

  const handleOrderIdChange = (e) => {
    updateFields({ orderId: e.target.value.id });
    setSelectedOrder(e.target.value);
  };

  const handleSubmitJob = async (event) => {
    event.preventDefault();
    setIsFieldsDisabled(true);

    const createRemittanceJobRequest = {
      orderId: formData.orderId,
      orderItemId: formData.orderItemId,
      remittanceConfigurationId: formData.remittanceConfigurationId,
      userPOId: formData.userPOId,
      closed: formData.closed,
      status: formData.status,
    };

    await createRemittanceJob(createRemittanceJobRequest)
      .then((response) => {
        if (response?.data?.data) {
          SuccessToast("Remittance Job created succesfully");
          navigate("/remittance/jobs");
        } else {
          ErrorToast("Failed to create Remittance Job");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Remittance Job");
      })
      .finally(() => {
        setIsFieldsDisabled(false);
      });
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Remittance Job
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "70%",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Order</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedOrder}
              label="orderId"
              name="orderid"
              onChange={handleOrderIdChange}
            >
              {orderIds?.map((order, i) => (
                <MenuItem key={i} value={order}>
                  {order.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">Order Item Id</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.orderItemId}
              label="orderItemId"
              name="orderItemId"
              onChange={(e) => updateFields({ orderItemId: e.target.value })}
            >
              {selectedOrder?.orderItems?.map((order, i) => (
                <MenuItem key={i} value={order.id}>
                  {order.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <FormControl fullWidth size="small" required>
            <TextField
              label="UserPO Id"
              value={formData.userPOId}
              onChange={(e) => updateFields({ userPOId: e.target.value })}
              fullWidth
              size="small"
            />
          </FormControl>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-select-small-label">
              Remittance Configuration
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={formData.remittanceConfigurationId}
              label="configuration Id"
              name="configurationId"
              onChange={(e) =>
                updateFields({ remittanceConfigurationId: e.target.value })
              }
            >
              {configIds?.map((config, i) => (
                <MenuItem key={i} value={config.id}>
                  {config.account}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={handleSubmitJob}
          variant="contained"
          disabled={isFieldsDisabled}
          sx={{ borderRadius: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddRemittanceJob;
