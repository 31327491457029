export class DataGridService {
  constructor(pageName) {
    this.pageName = pageName;

    this.defaultFilterModel = {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    };

    this.defaultSortModel = [];
    this.defaultPaginationModel = {
      pageSize: 25,
      page: 0,
    };
  }

  //For managing pagination
  setPaginationModel(paginationModel) {
    const page = localStorage.getItem(this.pageName);
    if (page) {
      const pageStorage = JSON.parse(page);
      pageStorage["pagination"] = paginationModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    } else {
      const pageStorage = {};
      pageStorage["pagination"] = paginationModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    }
  }
  getPaginationModel() {
    const pageStorage = localStorage.getItem(this.pageName);
    if (pageStorage && JSON.parse(pageStorage)["pagination"]) {
      return JSON.parse(pageStorage)["pagination"];
    } else {
      return this.defaultPaginationModel;
    }
  }

  //For managing sorting
  setSortModel(sortModel) {
    const page = localStorage.getItem(this.pageName);
    if (page) {
      const pageStorage = JSON.parse(page);
      pageStorage["sorting"] = sortModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    } else {
      const pageStorage = {};
      pageStorage["sorting"] = sortModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    }
  }

  getSortModel() {
    const pageStorage = localStorage.getItem(this.pageName);
    if (pageStorage && JSON.parse(pageStorage)["sorting"]) {
      return JSON.parse(pageStorage)["sorting"];
    } else {
      return this.defaultSortModel;
    }
  }

  ///For managing filtering
  setFilterModel(filterModel) {
    const page = localStorage.getItem(this.pageName);
    if (page) {
      const pageStorage = JSON.parse(page);
      pageStorage["filter"] = filterModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    } else {
      const pageStorage = {};
      pageStorage["filter"] = filterModel;
      localStorage.setItem(this.pageName, JSON.stringify(pageStorage));
    }
  }

  getFilterModel() {
    const pageStorage = localStorage.getItem(this.pageName);
    if (pageStorage && JSON.parse(pageStorage)["filter"]) {
      return JSON.parse(pageStorage)["filter"];
    } else {
      return this.defaultFilterModel;
    }
  }
}
