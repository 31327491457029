import * as React from "react";
import {
  Button,
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { responseCodeValueUsages } from "../common/dropdownsData";
import {
  useCreateResponseCodeMapMutation,
  useUpdateResponseCodeMapMutation,
} from "../../services/ResponseCodeMapService";
import { ErrorToast, SuccessToast } from "../common/Toast";
const ResponseCodeMapDialog = ({
  open,
  handleClose,
  selectedResponseCodeMap,
  type,
}) => {
  const [createResponseCodeMap] = useCreateResponseCodeMapMutation();
  const [updateResponseCodeMap] = useUpdateResponseCodeMapMutation();
  const [responseCodeMapRequest, setResponseCodeMapRequest] = React.useState(
    selectedResponseCodeMap
  );

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setResponseCodeMapRequest((prev) => ({ ...prev, [name]: value }));
  };

  const handleResetForm = () => {
    setResponseCodeMapRequest(null);
  };

  const handleCreateResponseCodeMap = async (event) => {
    event.preventDefault();
    await createResponseCodeMap(responseCodeMapRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Response code map added succesfully");
          handleResetForm();
          handleClose();
        } else {
          ErrorToast("Failed to create response code map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create response code map");
      });
  };
  const handleUpdateResponseCodeMap = async (event) => {
    event.preventDefault();
    await updateResponseCodeMap(responseCodeMapRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Response code map updated succesfully");
          handleResetForm();
          handleClose();
        } else {
          ErrorToast("Failed to update response code map");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update response code map");
      });
  };

  React.useEffect(() => {
    setResponseCodeMapRequest(selectedResponseCodeMap);
  }, [selectedResponseCodeMap]);

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <DialogTitle>
          {selectedResponseCodeMap?.id
            ? "Edit Response Code Map"
            : "Add Response Code Map"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ color: "grey", pb: "30px" }}>
              Provide fill the forms
            </Typography>
          </DialogContentText>
          <Box sx={{ display: "flex", mt: 1, alignItems: "center", gap: 1 }}>
            <TextField
              label="Response Value"
              variant="outlined"
              value={responseCodeMapRequest?.responseValue}
              fullWidth
              name="responseValue"
              onChange={handleFormChange}
              size="small"
            />
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="response-code-usage">
                Response Code usage
              </InputLabel>
              <Select
                labelId="response-code-usage"
                name="responseCodeUsage"
                value={responseCodeMapRequest?.responseCodeUsage}
                onChange={handleFormChange}
                label="Response Code Usage"
              >
                {responseCodeValueUsages.map((code, index) => (
                  <MenuItem value={code} key={index}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={
              (type === "UPDATE" && handleUpdateResponseCodeMap) ||
              (type === "ADD" && handleCreateResponseCodeMap)
            }
            variant="contained"
          >
            {type}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ResponseCodeMapDialog;
