import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceJobApi = createApi({
  reducerPath: "remittanceJobApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,

  tagTypes: ["RemittanceJobs"],
  endpoints: (builder) => ({
    getAllRemittanceJobs: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/jobs/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["RemittanceJobs"],
    }),

    getRemittanceJob: builder.query({
      query: (id) => ({
        url: `/jobs/${id}`,
        method: "GET",
      }),
      providesTags: ["RemittanceJobs"],
    }),

    searchRemittanceJobs: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/jobs?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["RemittanceJobs"],
    }),
    updateRemittanceJob: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/jobs",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceJobs"],
    }),
    createRemittanceJob: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/jobs",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceJobs"],
    }),
    triggerRemittanceAttempt: builder.mutation({
      query: (triggerRemittanceRequest) => ({
        url: "/jobs",
        method: "POST",
        body: triggerRemittanceRequest,
      }),
      invalidatesTags: ["RemittanceJobs"],
    }),
    deleteRemittanceJob: builder.mutation({
      query: (id) => ({
        url: `/jobs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RemittanceJobs"],
    }),
  }),
});

export const {
  useGetAllRemittanceJobsQuery,
  useGetRemittanceJobQuery,
  useCreateRemittanceJobMutation,
  useDeleteRemittanceJobMutation,
  useUpdateRemittanceJobMutation,
  useTriggerRemittanceAttemptMutation,
  useSearchRemittanceJobsQuery,
} = remittanceJobApi;
