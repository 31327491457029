import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { inventoryMediaUrl } from "../presentation/shared/ApiUrls";
import { queryStringOptions } from "../core/queryStringOptions";
import queryString from "query-string";

export const inventoryMediaApi = createApi({
  reducerPath: "inventoryMediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: inventoryMediaUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,

  tagTypes: ["InventoryMedia"],
  endpoints: (builder) => ({
    getAllInventoryMedia: builder.query({
      query: () => ({
        url: `/media`,
        method: "GET",
      }),
      providesTags: ["InventoryMedia"],
    }),
    getInventoryMedia: builder.query({
      query: (id) => ({
        url: `/media/${id}`,
        method: "GET",
      }),
      providesTags: ["InventoryMedia"],
    }),
    searchInventoryMedia: builder.query({
      query: (filterMediaRequest) => ({
        url: `/media?${queryString.stringify(
          filterMediaRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["InventoryMedia"],
      transformResponse: (response) => ({
        data: response.data.data,
        size: response.data.totalElements,
      }),
    }),

    updateInventoryMedia: builder.mutation({
      query: (updateInventoryMediaRequest) => ({
        url: "/media",
        method: "PUT",
        body: updateInventoryMediaRequest,
      }),
      invalidatesTags: ["InventoryMedia"],
    }),
    createInventoryMedia: builder.mutation({
      query: (addInventoryMediaRequest) => ({
        url: "/media",
        method: "POST",
        body: addInventoryMediaRequest,
      }),
      invalidatesTags: ["InventoryMedia"],
    }),
    deleteInventoryMedia: builder.mutation({
      query: (id) => ({
        url: `/media/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["InventoryMedia"],
    }),
  }),
});

export const {
  useGetAllInventoryMediaQuery,
  useSearchInventoryMediaQuery,
  useGetInventoryMediaQuery,
  useCreateInventoryMediaMutation,
  useUpdateInventoryMediaMutation,
  useDeleteInventoryMediaMutation,
} = inventoryMediaApi;
