import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FiSend } from "react-icons/fi";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useGetCaseQuery } from "../../services/disputeServices/CasesServices";
import { BiImages } from "react-icons/bi";
import { useGetSingleUserQuery } from "../../services/MobileUsersService";

const Chat = ({ caseId }) => {
  const { data, isLoading, refetch } = useGetCaseQuery(caseId);
  const [messages, setMessages] = useState(data?.room?.messages);
  const agentId = data?.assignedAgent?.userId;
  const customerId = data?.customerId;

  const [textInput, setTextInput] = useState(null);
  const [imageInput, setImageInput] = useState(null);
  const imageRef = useRef();
  const chatContainerRef = useRef(null);
  const accessToken = sessionStorage.getItem("accessToken");

  const [socket, setSocket] = useState(null);
  const url = `wss://4130-197-251-195-68.ngrok-free.app/borga/dispute/chat-socket/${caseId}/${agentId}`;

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setTextInput(value);
  };

  const { data: user } = useGetSingleUserQuery(customerId);

  const handleImageSelector = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    setImageInput(event.target.files[0]);
  };

  const sendImageMessage = () => {
    try {
      if (imageInput) {
        const reader = new FileReader();
        reader.onload = () => {
          reader.readAsDataURL(imageInput);
          const imageMessage = reader.result;
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(imageMessage));
          }
        };
        setImageInput();
      }
    } catch (err) {
    }
  };

  const sendTextMessage = () => {
    try {
      if (textInput.trim() !== null) {
        const message = { type: "text", content: textInput };
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(message));
        }
        setTextInput("");
      }
    } catch (err) {
    }
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (textInput && imageInput) {
      sendTextMessage();
      sendImageMessage();
    } else if (textInput) {
      sendTextMessage();
    } else if (imageInput) {
      sendImageMessage();
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  }));

  const MessageBox = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: "8px",
    borderRadius: "5px",
    maxWidth: "400px",
    width: "100%",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      width: "10px",
      height: "10px",
    },
  }));

  useEffect(() => {
    const socket = new WebSocket(url);
    socket.onopen = (event) => {
      const auth = { type: "auth", token: accessToken };
      socket.send(JSON.stringify(auth));
    };

    if (messages && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event?.data);
        const receivedMessage = JSON.parse(data.message);
        if (
          receivedMessage.type === "text" ||
          receivedMessage.type === "image"
        ) {
          setMessages((prevMessages) => [...prevMessages, data]);
        }
      } catch (err) {
      }
    };
    socket.onclose = () => {
    };

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [messages]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
          >
            <Avatar />
          </StyledBadge>

          <Box>
            <Typography>{user?.email}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        ></Box>
      </Box>
      <Divider />
      <Box
        sx={{
          mt: 3,
          height: "50vh",
          overflow: "auto",
          p: 1,
        }}
        ref={chatContainerRef}
      >
        {messages?.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              mt: 2,
              justifyContent:
                message?.userRef?.userId === customerId
                  ? "flex-start"
                  : "flex-end",
              gap: 2,
            }}
          >
            <Avatar
              src={`${
                message?.userRef?.userId === customerId
                  ? "/images/avatars/profile-avatar.png"
                  : "/images/avatars/avatar3.png"
              }`}
              sx={{
                width: "30px",
                height: "30px",
                order: message?.userRef?.userId === agentId && 2,
              }}
            />
            <Box
              sx={{
                display: "flex",

                mt: 2,
                gap: 2,
              }}
            >
              <Box>
                <MessageBox
                  sx={{
                    backgroundColor:
                      message?.userRef?.userId === customerId
                        ? "#027edd"
                        : "chatBox",
                    color: message?.userRef?.userId === customerId && "#fff",
                    "&::before": {
                      backgroundColor:
                        message?.userRef?.userId === customerId
                          ? "#027edd"
                          : "chatBox",
                      transform: `rotate(45deg) ${
                        message?.userRef?.userId === customerId
                          ? "translateX(-7px)"
                          : "translateX(7px)"
                      } `,
                      left: message?.userRef?.userId === customerId && 0,
                      right: message?.userRef?.userId === agentId && 0,
                      top:
                        message?.userRef?.userId === customerId &&
                        "10px !important",
                    },
                  }}
                >
                  {typeof message === "object" && message
                    ? message?.message
                    : message}
                </MessageBox>
                <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                  {moment(message?.updated).format("LLL")}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {imageInput ? (
        <img
          src={imageInput && URL?.createObjectURL(imageInput)}
          alt=""
          style={{
            width: "200px",
            objectFit: "contain",
          }}
        />
      ) : null}
      <Box sx={{ mt: 4 }}>
        <TextField
          onChange={handleTextChange}
          type="text"
          value={textInput}
          name="message"
          multiline
          maxRows={4}
          id="input-with-icon-textfield"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleSend(e);
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <input
                  sx={{ width: "10px", height: "10px" }}
                  ref={imageRef}
                  type="file"
                  hidden
                  accept="image/*"
                  name="media"
                  multiple
                  onChange={handleFileChange}
                />
                <Button onClick={handleImageSelector}>
                  {" "}
                  <BiImages />{" "}
                </Button>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Send">
                  <IconButton
                    sx={{ fontSize: "17px", color: "text.primary" }}
                    onClick={handleSend}
                    disabled={!textInput && !imageInput}
                  >
                    <FiSend />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Enter a message"
        />
      </Box>
    </>
  );
};

export default Chat;
