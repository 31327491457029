import React from "react";
import { Paper } from "@mui/material";

const PaperWrapper = ({ children, sx }) => {
  return (
    <Paper
      sx={{
        boxShadow: "none !important",
        borderRadius: "12px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "divider",
        p: "20px",
        margin: "0 auto",
        overflow: "hidden",
        ...sx,
      }}
    >
      {" "}
      {children}
    </Paper>
  );
};

export default PaperWrapper;
