import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";

export const remittanceProviderApi = createApi({
  reducerPath: "remittanceProviderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,
  
  tagTypes: ["remittanceProvider"],
  endpoints: (builder) => ({
    getAllRemittanceProviders: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/providers/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["remittanceProvider"],
    }),

    getRemittanceProvider: builder.query({
      query: (id) => ({
        url: `/providers/${id}`,
        method: "GET",
      }),
      providesTags: ["remittanceProvider"],
    }),

    searchRemittanceProviders: builder.mutation({
      query: (filterRemittanceRequest) => ({
        url: `/providers?${filterRemittanceRequest}`,
        method: "GET",
      }),
      invalidatesTags: ["remittanceProvider"],
    }),
    updateRemittanceProvider: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/providers",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["remittanceProvider"],
    }),
    createRemittanceProvider: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/providers",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["remittanceProvider"],
    }),
    deleteRemittanceProvider: builder.mutation({
      query: (id) => ({
        url: `/providers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["remittanceProvider"],
    }),
  }),
});

export const {
  useGetAllRemittanceProvidersQuery,
  useGetRemittanceProviderQuery,
  useCreateRemittanceProviderMutation,
  useDeleteRemittanceProviderMutation,
  useUpdateRemittanceProviderMutation,
  useSearchRemittanceProvidersMutation,
} = remittanceProviderApi;
