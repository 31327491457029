import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { RemittanceInstitutionTable } from "../data/RemittanceInstitutionsTable";
import {
  useDeleteRemittanceInstitutionMutation,
  useUpdateRemittanceInstitutionMutation,
  useCreateRemittanceInstitutionMutation,
  useSearchRemittanceInstitutionsQuery,
} from "../services/RemittanceInstitutionService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import RemittanceInstitutionModal from "../components/modals/RemittanceInstitutionModal";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";

const RemittanceInstitution = () => {
  const dataGridService = new DataGridService("RemittanceInstitution");
  const [filter, setFilter] = useState({});
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const { data, isLoading } = useSearchRemittanceInstitutionsQuery(filter);
  const [
    deleteRemittanceInstitutions,
    { isSuccess: isInstitutionDeleteSuccess },
  ] = useDeleteRemittanceInstitutionMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openInstitutionModal, setOpenInstitutionModal] = useState(false);
  const [type, setType] = useState("");
  const [updateRemittanceInstitution] =
    useUpdateRemittanceInstitutionMutation();
  const [createRemittanceInstitution] =
    useCreateRemittanceInstitutionMutation();

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setOpenInstitutionModal(false);
    setFormData(null);
  };

  const handleCloseInstitutionModal = () => {
    setOpenInstitutionModal(false);
    setFormData(null);
  };

  const handleOpenInstitutionModal = () => {
    setType("ADD");
    setOpenInstitutionModal(true);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedInstitution(row);
    setFormData(row);
  };

  const handleViewAction = (row) => {
    setType("UPDATE");
    setFormData(row);
    setOpenInstitutionModal(true);
  };

  const handleEditAction = (row) => {
    setType("VIEW");
    setFormData(row);
    setOpenInstitutionModal(true);
  };

  const columns = RemittanceInstitutionTable(
    handleDeleteAction,
    handleEditAction,
    handleViewAction
  );

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isInstitutionDeleteSuccess]);

  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const handleSubmitUpdateInstitution = async (event) => {
    event.preventDefault();
    await updateRemittanceInstitution(formData)
      .then((response) => {
        if (response?.data?.data) {
          handleSetFilter();
          SuccessToast("Institution Updated succesfully");
          handleCloseInstitutionModal();
        } else {
          ErrorToast("Failed to update configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Remittance Institution");
      });
  };
  const handleSubmitCreateInstitution = async (event) => {
    event.preventDefault();
    await createRemittanceInstitution(formData)
      .then((response) => {
        if (response?.data?.data) {
          handleSetFilter();
          SuccessToast("Remittance Institution created succesfully");
          handleCloseInstitutionModal();
        } else {
          ErrorToast("Failed to create Remittance Institution ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Remittance Institution");
      });
  };

  const handleDeleteSubmit = async () => {
    await deleteRemittanceInstitutions(selectedInstitution.id)
      .then((response) => {
        if (response.data.data) {
          SuccessToast("Institution deleted succesfully");
        } else {
          ErrorToast("Failed to delete Institution");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Institution");
      })
      .finally(() => {
        setOpen(false);
      });
  };
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Remittance Institutions</Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          sx={{ borderRadius: "20px" }}
          onClick={handleOpenInstitutionModal}
        >
          Add Institution
        </Button>
      </Box>
      <RemittanceInstitutionModal
        open={openInstitutionModal}
        type={type}
        institution={formData}
        updateFields={updateFields}
        handleClose={handleCloseInstitutionModal}
        handleSubmitRemittanceInstitution={
          type == "ADD"
            ? handleSubmitCreateInstitution
            : handleSubmitUpdateInstitution
        }
      />
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleDeleteSubmit}
        title={"Delete Institution"}
        subTitle={`Are you sure you want to delete ${selectedInstitution?.name}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            loading={isLoading}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            rowCount={data?.data?.size ?? []}
            rows={data?.data?.data ?? 0}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RemittanceInstitution;
