import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { warehousesUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const warehousesApi = createApi({
  reducerPath: "warehousesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: warehousesUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  tagTypes: ["Warehouses"],
  endpoints: (builder) => ({
    getAllWarehouses: builder.query({
      query: (filterWarehouseRequest) => ({
        url: `/warehouses?${filterWarehouseRequest}`,
        method: "GET",
      }),
      providesTags: ["Warehouses"],
      transformResponse: (response) => response.data.data,
    }),
    getWarehouse: builder.query({
      query: (id) => ({
        url: `/warehouses/${id}`,
        method: "GET",
      }),
      providesTags: ["Warehouses"],
      transformResponse: (response) => response.data,
    }),
    searchWarehouses: builder.query({
      query: (filterWarehouseRequest) => ({
        url: `/warehouses?${queryString.stringify(
          filterWarehouseRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["Warehouses"],
    }),
    updateWarehouse: builder.mutation({
      query: (updateWarehouseRequest) => ({
        url: "/warehouses",
        method: "PUT",
        body: updateWarehouseRequest,
      }),
      invalidatesTags: ["Warehouses"],
    }),
    createWarehouse: builder.mutation({
      query: (addWarehouseRequest) => ({
        url: "/warehouses",
        method: "POST",
        body: addWarehouseRequest,
      }),
      invalidatesTags: ["Warehouses"],
    }),
    deleteWarehouse: builder.mutation({
      query: (id) => ({
        url: `/warehouses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Warehouses"],
    }),
  }),
});

export const {
  useGetAllWarehousesQuery,
  useGetWarehouseQuery,
  useSearchWarehousesQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
} = warehousesApi;
