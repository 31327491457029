import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoyaltyUrl } from "../../presentation/shared/ApiUrls";


export const LoyaltyTransactionApiService = createApi({
    reducerPath: "loyaltyTransactionApi",
    baseQuery: fetchBaseQuery({
        baseUrl: LoyaltyUrl,
        prepareHeaders: (headers) => {
            headers.append(
                "Authorization",
                `Bearer ${sessionStorage.getItem("accessToken")}`
            );
            return headers;
        },
    }),
    refetchOnReconnect: true,
    tagTypes: ["loyaltyTransactionApi"],
    endpoints: (builder) => ({
        getAllLoyaltyTransaction: builder.query({
            query: () => ({
                url: `/loyalty/account/histories/transactions?page=0&size=1000`,
                method: "GET",
            }),
            providesTags: ["loyaltyTransactionApi"],
            transformResponse: (response) => response.data,
        }),
        getOneLoyaltyTransaction: builder.query({
            query: (id) => ({
                url: `/loyalty/account/histories/transactions/${id}`,
                method: "GET",
            }),
            providesTags: ["loyaltyTransactionApi"],
            transformResponse: (response) => response.data,
        }),
        refundLoyaltyTransaction: builder.mutation({
            query: (orderId) => ({
                url: `https://adamatest.borga.money/borga/order-test/api/v1/orders/loyalty/reversal/${orderId}`,
                method: "PUT",
            }),
            invalidatesTags: ["loyaltyTransactionApi"],
         
        }),
    }),
});

export const {
useGetAllLoyaltyTransactionQuery,
useGetOneLoyaltyTransactionQuery,
useRefundLoyaltyTransactionMutation,
} = LoyaltyTransactionApiService;
