import { FiberManualRecord, WarningOutlined, Paid } from "@mui/icons-material";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const OrdersTable = (handleDeleteAction) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",

      filterable: false,
      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },

    {
      field: "name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Name",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "createdBy",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      headerName: "Created By",
      valueGetter: ({ value }) => value ?? "N/A",
    },
    {
      field: "paid",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: ({ value }) =>
        value ? (
          <Paid color="success" fontSize="small" />
        ) : (
          <Paid color="error" fontSize="small" />
        ),
    },
    {
      field: "deliveryAddress",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      headerName: "Location",
      valueGetter: ({ value }) =>
        `${value?.city ?? "N/A"} - ${value?.country ?? "N/A"}`,
    },
    {
      field: "active",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      headerName: "Active",
      renderCell: ({ value }) =>
        value ? (
          <FiberManualRecord fontSize={"small"} color="success" />
        ) : (
          <FiberManualRecord fontSize={"small"} color="error" />
        ),
    },

    {
      field: "action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/order/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              disabled={row?.paid}
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
