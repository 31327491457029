import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import queryString from "query-string";
import { useGetAllCurrencyQuery } from "../../services/CurrencyService";
import { useGetAllMerchantsQuery } from "../../services/MerchantService";
import { useGetAllProductsQuery } from "../../services/ProductService";
import { useGetAllWarehousesQuery } from "../../services/WarehouseService";
import {
  productsToOptions,
  wareHousesToOptions,
} from "../../presentation/helpers";
import { useParams } from "react-router-dom";

const InventoryModal = ({
  open,
  type,
  handleClose,
  inventory,
  formChanges: {
    handleFormChange,
    handleWarehouseFormChange,
    handleProductFormChange,
    handleBooleanFieldChange,
  },
  handleSubmit,
}) => {
  const {id} =useParams()
  const filter = queryString.stringify({ size: 10000 });
  const { data: currencyData } = useGetAllCurrencyQuery(filter);
  const { data: productData, isLoading: isProductLoading } =
    useGetAllProductsQuery(filter);
  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useGetAllWarehousesQuery(filter);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle textAlign={"center"}>{`${type} INVENTORY`}</DialogTitle>
      <DialogContent>
        <Grid spacing={2} container padding={1}>
          {type === "ADD" && (
            <>
              <Grid item sm={12} xs={12} md={6}>
                <Autocomplete
                  fullWidth
                  id="tags-filled"
                  loading={isProductLoading}
                  options={productData ? productsToOptions(productData) : []}
                  onChange={handleProductFormChange}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Product"
                      placeholder="Product"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid sm={12} xs={12} item md={6}>
                <Autocomplete
                  fullWidth
                  id="tags-filled"
                  name="warehouseId"
                  loading={isWarehouseLoading}
                  options={
                    warehouseData ? wareHousesToOptions(warehouseData) : []
                  }
                  onChange={handleWarehouseFormChange}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Warehouse"
                      placeholder="Warehouse"
                      size="small"
                    />
                  )}
                />
              </Grid>{" "}
              <Grid sm={12} xs={12} item md={6}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="demo-select-small-label">Currency</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Currency"
                    name="currency"
                    onChange={handleFormChange}
                  >
                    {currencyData &&
                      currencyData.map((currency) => (
                        <MenuItem value={currency.initial}>
                          {`${currency.name}(${currency.initial})`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid sm={12} xs={12} item md={6}>
            <TextField
              label="Price"
              variant="outlined"
              fullWidth
              value={inventory?.price?.amount}
              name="amount"
              onChange={handleFormChange}
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            {" "}
            <TextField
              label="Quantity "
              variant="outlined"
              value={inventory?.quantity}
              onChange={handleFormChange}
              name="quantity"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid sm={12} xs={12} item md={6}>
            {" "}
            <TextField
              label="Minimum Stock Level"
              variant="outlined"
              name="minStockLevel"
              onChange={handleFormChange}
              value={inventory?.minStockLevel}
              fullWidth
              size="small"
            />
          </Grid>

          {type === "UPDATE" && (
            <>
              <Grid item sm={12} xs={12} md={12}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      onChange={handleBooleanFieldChange}
                      control={
                        <Checkbox
                          name="active"
                          size="small"
                          checked={inventory?.active}
                          onChange={handleBooleanFieldChange}
                        />
                      }
                      label="Active"
                    />
                  </span>
                </FormGroup>
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <FormGroup>
                  <span>
                    <FormControlLabel
                      onChange={handleBooleanFieldChange}
                      control={
                        <Checkbox
                          name="autoReplenishQuantity"
                          size="small"
                          checked={inventory?.autoReplenishQuantity}
                          onChange={handleBooleanFieldChange}
                        />
                      }
                      label="Auto Replenish Quantity?"
                    />
                  </span>
                </FormGroup>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {type !== "VIEW" ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="error" onClick={handleClose} variant="contained">
              CANCEL
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              {type}
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button onClick={handleClose} variant="contained">
              OK
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InventoryModal;
