import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetRemittancePayloadCustomMapQuery } from "../services/RemittancePayloadCustomMapService";
import moment from "moment";
import { MdBusiness } from "react-icons/md";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import { Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { remittanceCustomMapTypes } from "../components/common/dropdownsData";

const SingleRemittancePayloadCustomMap = () => {
  const { id } = useParams();
  //   const { data: customMap, isLoading } = useGetRemittanceInstitutionQuery(id);
  const { data: customMapData, isLoading } =
    useGetRemittancePayloadCustomMapQuery(id);

  const printRef = useRef();

  if (customMapData?.data) {
    let customMap = customMapData.data;
    return (
      <Box
        sx={{
          pt: "80px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Link to={-1}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5"> Payload Custom Map Details</Typography>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
          ref={printRef}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.id ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Merchant Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.merchantId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Inventory Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.inventoryId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceCustomMapTypes[customMap?.type] ?? "N/A"}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Remittance Attribute:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.remittanceAttribute ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Created By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.createdBy ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {customMap?.lastModifiedBy ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(customMap?.created).format("LLL") ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(customMap?.updated).format("LLL") ?? "N/A"}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleRemittancePayloadCustomMap;
