import { Button, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import React from "react";
import UpdatePassword from "../../pages/UpdatePassword";

const Password = (id) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Typography variant="subtitle1">Password Reset</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.8, pb: 2 }}>
        Update your password here
      </Typography>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <Button
          onClick={handleOpenModal}
          variant="contained"
        >
          Update Password
        </Button>
      </Box>

      <UpdatePassword
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        userId={id}
      />
    </Box>
  );
};

export default Password;
