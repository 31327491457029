import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import LoadingPage from "../components/LoadingPage";
import SwipeableTextMobileStepper from "../components/common/ProductImageCarousel";
import ViewAvailableMeasurementComponent from "../components/common/ViewAvailableMeasurementComponent";
import ViewAvailableWeightComponent from "../components/common/ViewAvailableWeightComponent";
import ViewAvailableVolumeComponent from "../components/common/ViewAvailableVolumeComponent";
import moment from "moment/moment";

const SingleProductDescription = ({ product }) => {
  if (product) {
    return (
      <Box sx={{ pb: "20px" }}>
        <Paper
          sx={{
            fontSize: "25px",
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          <Grid container spacing={14} sx={{ py: 5, px: 5 }}>
            <Grid item xs={12} md={6}>
              <SwipeableTextMobileStepper />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Product ID:<span style={{ opacity: 0.7 }}> product</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Name of product:{" "}
                <span style={{ opacity: 0.7 }}>{product.name}</span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Type:
                <span style={{ opacity: 0.7 }}>
                  {" "}
                  {product.type.replace("_", " ").toLowerCase()}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Date created:
                <span style={{ opacity: 0.7 }}>
                  {" "}
                  {product.created ? moment(product.created).format("LLLL"):"N/A"}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={2} md={2}>
              <ViewAvailableMeasurementComponent
                availableMeasurement={product.availableMeasurement}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <ViewAvailableWeightComponent
                availableWeight={product.availableWeight}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <ViewAvailableVolumeComponent
                availableVolume={product.availableVolume}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="enabled"
                    disabled
                    checked={product?.enabled}
                    control={<Checkbox />}
                    label="Enable product after creation"
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="platformSKU"
                    disabled
                    checked={product?.platformSKU}
                    control={<Checkbox />}
                    label="Is it a platform stock keeping unit?"
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="service"
                    checked={product?.service}
                    disabled
                    control={<Checkbox />}
                    label="Is it a service?"
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="customerAmountAccepted"
                    checked={product.customerAmountAccepted}
                    disabled
                    control={<Checkbox />}
                    label="Is customer amount accepted?"
                  />
                </span>
              </FormGroup>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="licensingDocumentRequired"
                    checked={product?.licensingDocumentRequired}
                    disabled
                    control={<Checkbox />}
                    label="Is licensing document required?"
                  />
                </span>
              </FormGroup>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleProductDescription;
